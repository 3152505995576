import React, { useState, useEffect } from "react";

import { IconMdFileUpload } from "../../../icons/components";

import styles from "./styles.module.css";

export const UploadIcon = ({ onFileSelect, text, imageUrl, disabled = false }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [previewImage, setPreviewImage] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreviewImage(undefined);
      if(imageUrl) setPreviewImage(imageUrl);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreviewImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, imageUrl]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    onFileSelect(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <div className={`${styles.uploadBox} ${disabled ? styles.disabled : ''}`}>
        <div className={styles.imgBox}>
          {
            selectedFile || previewImage? 
            <img src={previewImage} alt="" className={styles.image} /> :
            <IconMdFileUpload className={styles.icon} />
          }
        </div>
        <div className={`${styles.text}`}>{text}</div>
        <input type="file" disabled={disabled} onChange={onSelectFile} className={styles.input} />
      </div>

    </React.Fragment>
  );
};
export default UploadIcon;
