import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslations } from 'shared-modules/translation-module';
import { MTableContainer, MTable, MTableBody } from '../../../material/components';
import TableHeadComponent from './components/TableHeadComponent';
import TableRowComponent from './components/TableRowComponent';
import TableBottomComponent from './components/TableBottomComponent';
import NoDataMessage from '../NoDataMessage';

import styles from './styles.module.css';

export const CustomTable = ({
  classText,
  data,
  detailsButton,
  editButton,
  deleteButton,
  retryButton,
  downloadButton,
  archiveButton,
  previewButton,
  sortButton,
  refundButton,
  sendButton,
  analyticButton,
  powerOffButton,
  powerOffHandler,
  cencelButton,
  cencelHandler,
  analyticHandler,
  sendHandler,
  detailsHandler,
  editHandler,
  deleteHandler,
  retryHandler,
  downloadHandler,
  archiveHandler,
  previewHandler,
  sortHandler,
  refundHandler,
  outsideSort,

  totalCount,
  pagesCount,
  currentPage,
  onPageChange,
  handleRowPerPageChange,
  rowPerPageCount,
  dragHandler,
  isDraggable = false,
}) => {
  const { t } = useTranslations();

  const [rows, setRows] = useState([]);
  const [reRenderKey, setReRenderKey] = useState(Math.random().toString());
  const [isPositive, setIsPositive] = useState(true);
  const [buttonsLength, setButtonsLength] = useState(0);
  const [isDragOccurring, setIsDragOccurring] = useState(false);

  useEffect(() => {
    setRows(data.row);
    return () => setRows([]);
  }, [data.row]);

  useEffect(
    () =>
      setButtonsLength(
        (editButton ? Number(editButton) : 0) +
          (deleteButton ? Number(deleteButton) : 0) +
          (detailsButton ? Number(detailsButton) : 0) +
          (retryButton ? Number(retryButton) : 0) +
          (downloadButton ? Number(downloadButton) : 0) +
          (sortButton ? Number(sortButton) : 0) +
          (refundButton ? Number(refundButton) : 0) +
          (analyticButton ? Number(analyticButton) : 0) +
          (powerOffButton ? Number(powerOffButton) : 0) +
          (cencelButton ? Number(cencelButton) : 0)
      ),
    []
  );

  const handleSort = (index, isFromServer) => {
    if (isFromServer && outsideSort) {
      outsideSort(index);
    } else if (!isFromServer) {
      const sorted = rows.sort(function (a, b) {
        let nameA = a.col[index].label[0]?.toLowerCase();
        let nameB = b.col[index].label[0]?.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      if (!isPositive) sorted.reverse();

      setRows(sorted);
      setReRenderKey(Math.random().toString());
      setIsPositive(!isPositive);
    }
  };

  /*Drag*/
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((el, index) => ({ ...el, sortIndex: index + 1 }));
  };

  const onDragStart = () => {
    // vibrate on mobile device when drag started
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const draggableId = result.draggableId;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let rankBeforeId = null;
    let rankAfterId = null;

    let oldIndex = rows[sourceIndex]?.index; //index from back-end
    let newIndex = rows[destinationIndex]?.index; //index from back-end

    if (sourceIndex > destinationIndex) {
      rankBeforeId = rows[destinationIndex].id.toString();
    } else {
      rankAfterId = rows[destinationIndex].id.toString();
    }

    const newState = reorder(rows, result.source.index, result.destination.index);

    setRows(newState);

    dragHandler?.({
      draggableId,
      oldIndex,
      newIndex,
      rankBeforeId,
      rankAfterId,
    });
  };

  return rows?.length ? (
    <React.Fragment>
      <DragDropContext
        onDragStart={onDragStart}
        onBeforeDragStart={() => setIsDragOccurring(true)}
        onDragEnd={onDragEnd}
      >
        <MTableContainer className={`${classText} ${isDraggable ? 'DraggableTable-root' : ''}`}>
          <MTable stickyHeader aria-label="sticky table" key={reRenderKey}>
            <TableHeadComponent
              data={data}
              isPositive={isPositive}
              buttonsLength={buttonsLength}
              handleSort={handleSort}
            />
            <Droppable isDropDisabled={!isDraggable} droppableId="droppableTableMain">
              {(droppableProvided, droppableSnapshot) => (
                <MTableBody innerRef={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  <TableRowComponent
                    isDraggable={isDraggable}
                    isDragOccurring={isDragOccurring}
                    rows={rows}
                    buttonsLength={buttonsLength}
                    detailsButton={detailsButton}
                    editButton={editButton}
                    deleteButton={deleteButton}
                    sendButton={sendButton}
                    retryButton={retryButton}
                    downloadButton={downloadButton}
                    archiveButton={archiveButton}
                    previewButton={previewButton}
                    sortButton={sortButton}
                    refundButton={refundButton}
                    analyticButton={analyticButton}
                    analyticHandler={analyticHandler}
                    detailsHandler={detailsHandler}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    retryHandler={retryHandler}
                    sendHandler={sendHandler}
                    downloadHandler={downloadHandler}
                    archiveHandler={archiveHandler}
                    previewHandler={previewHandler}
                    sortHandler={sortHandler}
                    refundHandler={refundHandler}
                    powerOffButton={powerOffButton}
                    cencelButton={cencelButton}
                    powerOffHandler={powerOffHandler}
                    cencelHandler={cencelHandler}
                  />
                  {droppableProvided.placeholder}
                </MTableBody>
              )}
            </Droppable>
          </MTable>
        </MTableContainer>
      </DragDropContext>
      {totalCount && (
        <TableBottomComponent
          rowPerPageCount={rowPerPageCount}
          handleRowPerPageChange={handleRowPerPageChange}
          totalCount={totalCount}
          pagesCount={pagesCount}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </React.Fragment>
  ) : (
    <NoDataMessage title={t['no-table-data']} />
  );
};

export default CustomTable;
