//create languages for auth module
let data = {
  roles: 'Roles',
  Users: 'Users',
  userDetails: 'User Details',
  rolesSuccessfulyDeleted: 'User successfully deleted',
  rolesUnSuccessfulyDeleted: "Role can't deleted",
  rolesSuccessfulyEdeted: 'Role successfully Edeted',
  rolesUnSuccessfulyEdeted: "Role can't Edeted",
  usersSuccessfulyDeleted: 'Users successfully Deleted',
  usersUnSuccessfulyDeleted: "Users can't Deleted",
  userSuccessfulyAdded: 'User Added',
  userUnSuccessfulyAdded: "User can't Added",
  confirmations: 'Confirmations',
  isUserActive: 'Is User Active',
};

export default data;
