//create languages for logs module
let data = {
    "offerName": "შეთავაზების სახელი",
    "offers-list": "შეთავაზებების სია",
    "addNewOffer": "ახალი შეთავაზების დამატება",
    "updateOffer": "შეთავაზების განახლება",
    "offerType": "შეთავაზების ტიპი",
    "chooseCompany": "აირჩიეთ კომპანია",
    "addGroup": "ჯგუფის დამატება",
    "unRestrictedGroups": "დასაშვები ჯგუფები",
    "restrictedGroups": "შესაზღუდი ჯგუფები",
    "whatToDoInCaseOfStockOut": "ქმედება მარაგის ამოწურვისას",
    "offerPublished": "შეთავაზება გამოქვეყნდა",
    "offerUnPublished": "შეთავაზების გამოქვეყნების გაუქმება",
    "youShouldCheckType": "შენ უნდა მონიშნო ტიპი",
    "offerStartEnddate": "შეთავაზების დაწყების - დამთავრების თარიღი",
    "uploadCover": "ქოვერის ატვირთვა",
    "uploadHover": "ჰოვერის ატვირთვა",
    "mainPhoto": "მთავარი ფოტო",
    "shortDescription": "მოკლე აღწერა",
    "oldPrice": "ძველი ფასი",
    "newPrice": "ახალი ფასი",
    "qty": "რაოდ",
    "minus": "დააკლდეს",
    "addPrimaryCategories": "დაამატე მთავარი კატეგორიები",
    "whetherOrNotToDisplayOfferInTheList": "გამოჩნდეს თუ არა შეთავაზება სიაში",
    "addPrimaryCategorie": "დაამატე მთავარი კატეგორია",
    "allCategories": "ყველა კატეგორია",
    "addonType": "დამატების ტიპი",
    "perCategories": "კატეგორიებზე",
    "perProducts": "პროდუქტებზე",
    "primaryCategory": "პირველი კატეგორის დამატება",
    "secondaryCategory": "მეორე კატეგორის დამატება",
    "optionalGiftOffer": "არასავალდებულო შეთავაზების დამატება",
    "giftOffer": "სასაჩუქრე შეთავაზების დამატება",
    "maxQuantity" : "მაქს რაოდენობა",
    "minQuantity" : "მინ რაოდენობა",
    "youMustChoosePrimaryCategory" : "თქვენ უნდა აირჩიოთ მთავარი კატეგორია",
    "uniqueNewPriceQTY" : "უნიკალური რაოდენობა",
    "add_gift_categories": "საჩუქრის კატეგორიის დამატება"
}

export default data;