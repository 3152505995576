import errorToast from './error';
import infoToast from './info';
import successToast from './success';
import warningToast from './warning';
import notificationToast from './notification';

export const MToast = {
    errorToast,
    infoToast,
    successToast,
    warningToast,
    notificationToast
}

export default MToast;