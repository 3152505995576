import React, { useEffect, useState } from 'react';

export const MuseMediaQuery = (brakePoint) => {
  const [state, setState] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', setState);
    return () => {
      window.removeEventListener('resize', setState);
    };
  }, []);

  if (brakePoint === 'xxl') return window.innerWidth > 1679;
  else if (brakePoint === 'xl') return window.innerWidth < 1680;
  else if (brakePoint === 'lg') return window.innerWidth < 1280;
  else if (brakePoint === 'md') return window.innerWidth < 960;
  else if (brakePoint === 'sm') return window.innerWidth < 768;
  else if (brakePoint === 'xs') return window.innerWidth < 576;
};
export default MuseMediaQuery;
