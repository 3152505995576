import React from "react";
import {
  IconMdKeyboardArrowDown,
  IconMdOutlineDragIndicator,
} from "../../../../../../icons";
import style from "../style.module.css";

const ListLabel = ({
  dragDisabled,
  dragHandleProps,
  label,
  index,
  withIndexes,
  hasChild = false,
  isExpanded,
  setExpanded,
}) => {
  return (
    <div className={style.listLabel}>
      <div className={`${style.dragIndicator} ${dragDisabled && style.dragDisabled}`} {...dragHandleProps}>
        <IconMdOutlineDragIndicator />
      </div>

      {withIndexes && <div className={style.listIndex}>{index + 1}.</div>}
      <div className={style.label}>{label}</div>
      {hasChild && (
        <div
          className={`${style.arrowIcon} ${
            isExpanded ? style.iconExpanded : ""
          }`}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <IconMdKeyboardArrowDown />
        </div>
      )}
    </div>
  );
};

export default ListLabel;
