import moment from "moment";

export const utcToLocal = (date) => date ? moment.utc(date).local() : null;
export const localToUtc = (date) => date ? moment.utc(date) : null;
export const localUtcDate = (date) => date ? moment.utc(date).local().format('DD.MM.YYYY') : null;
export const utcToLocalWithoutHoursFormat = (date) => date ? moment.utc(date).local().format('DD/MM/YYYY') : null;
export const utcToLocalWithHoursFormat = (date) => date ? moment.utc(date).local().format('DD/MM/YYYY, HH:mm:ss') : null;


/**
 * Converts a UTC date string to a local date and time string.
 *
 * @param {?string} dateString - A UTC date string.
 * @returns {?string} A local date and time string formatted according to the user's locale, or null if the input is invalid.
 */
export const convertUtcToLocalTime = (dateString) => {
    if (!dateString) return null;
    return moment(new Date(dateString)).local().toLocaleString();
};

/**
 * Converts a local date and time string to a UTC ISO string.
 *
 * @param {string} date - A local date and time string.
 * @returns {string} A UTC ISO string representation of the input date and time.
 */
export const convertLocalToUtcIsoString = (date) => {
    return moment(date).utc().toISOString();
};
