import { createContext } from "react";
import i18n from "../i18n";
import { LanguagesLocalStorageStore, LocalStorageEvents } from "shared-modules/local-storage-module";

//i18next dynamic configure class
export class I18NConfigStore {
    currentLanguage = i18n.language;
    currentNamespace = i18n.options.defaultNS;

    getCurrentLanugage = () => this.currentLanguage;
    getCurrentNamespace = () => this.currentNamespace;
    
    //change language method
     setLanguage = (lng, callback) => {
        const languageLocalStorageStore = new LanguagesLocalStorageStore();
        languageLocalStorageStore.setLanguageCode(lng);
        this.currentLanguage = lng;
        i18n.changeLanguage(lng, callback);
    }

    //change namespace method
     setNamespace = (ns, callback) => {
        this.currentNamespace = ns;
        i18n.loadNamespaces(ns, callback);
    }

    //change language, namespace and set changes to config
     GetAndSetLanguageResource = (props, cbProps) => {
        var lngCb = undefined;
        var nsCb = undefined;

        if(cbProps) {
            if(cbProps.lngCallBack) lngCb = cbProps.lngCallBack;
            if(cbProps.nsCallBack) nsCb = cbProps.nsCallBack;
        }

        this.setLanguage(props.lng, lngCb);
        this.setNamespace(props.ns, nsCb);

        return this.GetLanguageResource(props);
    }

    //get translation without changing configuration
     GetLanguageResource = (props) => 
        i18n.getResourceBundle(props.lng, props.ns);

    //get translation form current configuration
     GetCurrentLanguageResource = () => {
        return i18n.getResourceBundle(this.currentLanguage, this.currentNamespace);
    }
        
}

export default createContext(new I18NConfigStore());