import React, { useEffect, useState } from "react";
import { useTranslations } from "shared-modules/translation-module";

import { MuseMediaQuery } from "../../../hooks/MuseMediaQuery/";
import { MMenuItem, MMenu } from "../../../material/components";
import { IconMdKeyboardArrowDown, IconMdSort } from "../../../icons/components";

import styles from "./styles.module.css";

export const SortableSelect = ({ options = [{value:  "newest", isDefault: true}, {value:  "oldest", isDefault: false}], setCurrentSort, urlsKey, value = undefined }) => {
  let params = new URLSearchParams(window.location.search); 
  const { t } = useTranslations();
  const mediaQueryLG = MuseMediaQuery("lg");

  const [selected, setSelected] = useState(value !== undefined ? value : params.get(urlsKey) ?? options.find(option => option.isDefault)?.value?.toString());
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (value) => {
      setSelected(value);
      setCurrentSort(value);
  };

  return (
    <React.Fragment>
      {mediaQueryLG ? (
        <div onClick={(event) => setAnchorEl(event.currentTarget)}>
          <IconMdSort className="custom-icon big" />
        </div>
      ) : (
        <div className={styles.label} onClick={(event) => setAnchorEl(event.currentTarget)} >
          <span className={styles.labelTitle}>{t['sort']}</span>
          <IconMdKeyboardArrowDown className={`${styles.arrow} ${Boolean(anchorEl) ? styles.visible : ""}`}/>
        </div>
      )}
      <MMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
      >
        {options.map((option, index) => (
          <MMenuItem
            key={`company-sort-options-${index}`}
            value={option?.value}
            onClick={() => {
              handleChange(option?.value);
              setAnchorEl(null);
            }}
            className={`${styles.menuItem} ${selected === option?.value ? styles.active : "" }`}
          >
            {t[option?.value] ?? option?.value}
          </MMenuItem>
        ))}
      </MMenu>
    </React.Fragment>
  );
};

export default SortableSelect;
