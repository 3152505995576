//create languages for auth module
let data = {
    "roles": "Roles",
    "role": "Role",
    "mode": "Mode",
    "administrator": "Adminitrator",
    "RoleDetails": "Role Details",
    "UserList": "User List",
    "roleSuccessfulyDeleted": "Role successfully deleted",
    "roleSuccessfulyUpdated": "Roles successfully updated",
    "roleUnSuccessfulyUpdated": "Role can't updated",
    "roleSuccessfulyCreated": "Role created",
    "roleUnSuccessfulyCreated": "Role can't created",
    "deleteUsersFromRoleTitle": "Delete Users From Role"
}

export default data;