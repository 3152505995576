import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const CustomSearchStore = createContext(new class {
 input = '';

 setInput = (text) => this.input= text;
  
  constructor() {
    makeAutoObservable(this);
  }

}());
