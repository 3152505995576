import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';

import mobxRouting from '../historyStore';

export const PaginationClassStore = class {
  currentPageKey = 'page';
  currentPage = 1;

  initStore = () =>
    (this.currentPage = parseInt(new URLSearchParams(location.search).get(this.currentPageKey) ?? 1));

  setPage = (event, index) => (this.currentPage = index);

  reset = () => (this.currentPage = 1);

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.currentPage,
      async (currentPage) => {
        let search = new URLSearchParams(mobxRouting.location.search);
        search?.set(this.currentPageKey, currentPage);
        // mobxRouting.history.replace({ search: search?.toString() });
        window.history.pushState({}, '', `${window.location.pathname}?${search?.toString()}`);
      }
    );
  }
};

export const PaginationStore = createContext(new PaginationClassStore());
