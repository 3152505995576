import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
import { ApiManager } from 'shared-modules/api-manager-module';

import mobxRouting from '../historyStore';

export const LanguageStore = createContext(
  new (class {
    languageCodeFromUrlKey = 'language';

    allLanguages = [];
    defautlLanuageObj = {};
    showLoading = false;
    defaultLanguage = 'En';
    currentLanugage = this.defaultLanguage;
    setInUrl = true;

    initStre = () => {
      this.getAllLanguages().then((languages) => {
        if (languages) {
          this.defautlLanuageObj = languages.find((language) => language.isDefault);
          this.defaultLanguage = this.defautlLanuageObj?.languageCode;
          this.currentLanugage = this.getTabFromUrl() ?? this.defaultLanguage;
        }
      });
    };

    get isDefaultTab() {
      return this.defaultLanguage === this.currentLanugage;
    }

    getTabFromUrl = () =>
      new URLSearchParams(window.location.search).get(this.languageCodeFromUrlKey)?.toString() ??
      this.defaultLanguage;

    onTabChangeClick = (event, newValue) => {
      if (newValue === this.currentLanugage) return;
      this.currentLanugage = newValue;
    };

    setLanguageFromUrl = (code) => {
      if (this.allLanguages.length > 0) {
        var languageCode = this.allLanguages
          .find((o) => o.languageCode?.toLowerCase() === code?.toLowerCase())
          ?.languageCode?.toLowerCase();
        if (languageCode) {
          this.currentLanugage = languageCode;
        } else {
          this.currentLanugage = this.defaultLanguage;
          mobxRouting.replace({ search: `` });
        }
      }
    };
    //GET Methods form server
    getAllLanguages = async () => {
      try {
        if (this.allLanguages.length > 0) return this.allLanguages;
        this.showLoading = true;
        let res = await ApiManager.getData('/Language/GetAllLanguages');
        return (this.allLanguages = res?.data);
      } catch (ex) {
        this.allLanguages = [];
      } finally {
        this.showLoading = false;
      }
    };

    constructor() {
      makeAutoObservable(this);
      reaction(
        () => this.currentLanugage,
        async (code) => {
          let params = new URLSearchParams(window.location.search);
          if (this.allLanguages?.length) {
            const languages = await this.getAllLanguages();
            if (!languages?.length) return;
            if (code === null) {
              this.currentLanugage = languages.find((o) => o.isDefault)?.languageCode?.toLowerCase();
              return;
            }

            if (
              languages?.some((language) => language?.languageCode?.toLowerCase() === code?.toLowerCase())
            ) {
              params.set(this.languageCodeFromUrlKey, code);
              mobxRouting.history.replace({ search: params.toString() });
            } else {
              params.delete(this.languageCodeFromUrlKey);
              mobxRouting.history.replace({ search: params.toString() });
            }
          }
        }
      );
    }
  })()
);
