import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
import mobxRouting from '../historyStore';

export const UrlSearchStore = createContext(
  new (class {
    urlKey = 'search';
    currentSearch = '';

    setSearch = (searchQuery) => {
      this.currentSearch = searchQuery;
    };

    getQuery = () => {
      let params = new URLSearchParams(window.location.search);
      return params.get(this.urlKey);
    };

    constructor() {
      makeAutoObservable(this);
      reaction(
        () => this.currentSearch,
        (currentSearch) => {
          let params = new URLSearchParams(window.location.search);

          if (!currentSearch) {
            params.delete(this.urlKey);
            mobxRouting.replace({ search: params?.toString() ?? '' });
          } else {
            params.set(this.urlKey, currentSearch);
            mobxRouting.replace({ search: params?.toString() });
          }
        }
      );
    }
  })()
);
