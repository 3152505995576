import { forwardRef, memo } from 'react';
import { IconMdLocationPin } from '../../../icons/components/MdLocationPin';

import styles from './styles.module.css';

export const GoogleMap = memo(
  forwardRef(({ children, height, className, onCurrentLocation, disabled }, ref) => {
    return (
      <div className={styles.googleMapContainer}>
        <div id="map" ref={ref} className={className} style={{ height: height }}>
          {children}
        </div>
        {onCurrentLocation && (
          <div className={styles.currentLocationIconWrap}>
            <IconMdLocationPin className={styles.currentLocationIcon} onClick={onCurrentLocation} />
          </div>
        )}
        {disabled && <div className={styles.mapOverlay}></div>}
      </div>
    );
  })
);
