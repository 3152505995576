//create languages for auth module
let data = {
    "countries": "Countries",
    "country": "Country",
    "region": "Region",
    "city": "City",
    "parentRegion": "Parent region",
    "parentCountries": "Parent countries",
    "countryAddedSuccessfully": "Country Added Successfully",
    "regionAddedSuccessfully": "Region Added Successfully",
    "cityAddedSuccessfully": "City Added Successfully",
    "districtAddedSuccesfully": "District Added Succesfully",
    "districtUpdatedSuccesfully": "district Updated Succesfully",
    "countryUpdatedSuccessfully": "Country Updated Successfully",
    "regionUpdatedSuccessfully": "Region Updated Successfully",
    "cityUpdatedSuccessfully": "City Updated Successfully",
    "removedSuccesfully": "Removed Succesfully"
}

export default data;