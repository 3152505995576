import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import {
  MFormControl,
  MInputLabel,
  MSelect,
  MMenuItem,
  MCheckbox,
  MFormControlLabel,
} from "../../../../material/components";
import { SecondaryCustomSearch } from "../../../../custom/components";
import { ComponentLoader } from '../../ComponentLoader'
import {
  IconMdKeyboardArrowDown,
  IconMdHome,
} from "../../../../icons/components";
import styles from "../styles.module.css";
import { useEffect } from "react";
import { useTranslations } from 'shared-modules/translation-module';
import PageLoader from "../../PageLoader";
import { SelectsStore } from "../store";

export const MultipleTypingSelect = ({
  noDataMessage = { message: "No Data" },
  names = { id: "id", value: "name" },
  propsData,
  propsSearchText = undefined,
  selectedData,
  handleMultipleTypingSelectChange,
  searchHandler,
  minRequiredChars = 0,
  placeholder,
  needDefault,
  selectedDefault,
  singleSelect,
  hasAllCheck = false,
  isAllChecked,
  onAllSelectChange,
  isDoubleCheck,
  selectedDoubleCheckedItems,
  isLoading,
  disabled,
  showLoading = false,
}) => {
  const { t } = useTranslations();

  const [SELELCT_ID, setSELELCT_ID] = useState(uuidv4());
  const [selectState, setSelectState] = useState([]);
  const [data, setData] = useState([]);
  const [defaultId, setDefaultId] = useState();

  const selectChange = (event) => {
    let value = Number(event.target.value);
    if(isNaN(value)) value = event.target.value ?? event;
    selectState.length === 0 && setDefaultId(value);

    if (singleSelect) {
      setSelectState([value]);
      handleMultipleTypingSelectChange([value], needDefault ? defaultId : null, doubleCheckedItems)
      return;
    }

    let isValue = selectState.find((o) => o === value);
    if (isValue) {
      let localSelectState = selectState.filter((o) => o !== value);
      if (value === defaultId) {
        localSelectState.length > 0
          ? setDefaultId(localSelectState[0])
          : setDefaultId(null);
      }
      setSelectState(localSelectState);
      setDoubleCheckedItems(doubleCheckedItems?.filter(o => o !== value))
      handleMultipleTypingSelectChange(localSelectState, needDefault ? defaultId : null, doubleCheckedItems)
    } else {
      handleMultipleTypingSelectChange([...selectState, value], needDefault ? defaultId : null, doubleCheckedItems)
      setSelectState([...selectState, value]);
    }
  };

  useEffect(() => {
    if(selectedDefault) setDefaultId(selectedDefault)
  }, [selectedDefault]);

  const [doubleCheckedItems, setDoubleCheckedItems] = useState([]);
  useEffect(() => {
    if(selectedDoubleCheckedItems?.length) {
      setDoubleCheckedItems(selectedDoubleCheckedItems);
    }
  }, [selectedDoubleCheckedItems])
  
  const onDoubleCheckedItemsChanged = (id) => {

    if(doubleCheckedItems.some(o => o === id)) {
      setDoubleCheckedItems(o => o.filter(i => i !== id))
      handleMultipleTypingSelectChange(selectState, needDefault ? defaultId : null, doubleCheckedItems.filter(i => i !== id))
    }
    else {
      setDoubleCheckedItems(o => [...o, id]);
      handleMultipleTypingSelectChange(selectState, needDefault ? defaultId : null, [...doubleCheckedItems, id])
    }
  }

  const MenuItem = (data) => {
    return (
      <MMenuItem className={styles.textGroup} >
        <MFormControlLabel
          className={styles.checkbox}
          control={
            <MCheckbox
              onClick={selectChange}
              value={data[names.id]}
              checked={selectState.some((o) => o === data[names.id])}
              className="tonal medium"
            />
          }
          label={data[names.value]}
        /> {data?.extraText ? <div className={styles.extraText} > ({data?.extraText}) </div> : ''}
      </MMenuItem>
    );
  };

  const AllSelectMenuItem = () => (
    <MMenuItem className={styles.textGroup} >
      <MFormControlLabel
        className={styles.checkbox}
        control={
          <MCheckbox
            onClick={() => {
              onAllSelectChange?.(!isAllChecked);
            }}
            value="all"
            checked={isAllChecked}
            className="tonal medium"
          />
        }
        label={t["all"]}
      />
    </MMenuItem>
  )

  const SetAsDefaultComp = (data) => {
    if(isDoubleCheck) {
      return (
        <div className={styles.defaultWrap} key={Math.random()}>
        {
          selectState.length > 0 && data[names.id] && doubleCheckedItems.some(o => o === data[names.id])
          ? 
          <div className={styles.setAsDefaultText} onClick={() => onDoubleCheckedItemsChanged(data[names.id])}>
            {t["withClicldrens"]}
          </div>
          :
          selectState.find(o => o === data[names.id]) && 
          <div onClick={() => onDoubleCheckedItemsChanged(data[names.id])} className={styles.setAsDefaultText}>
            {t["checkWithChildrens"]}
          </div>
        }
        </div>
      )
    }

    return (
      <div className={styles.defaultWrap} key={defaultId}>
        {selectState.length > 1 &&
        data[names.id] &&
        selectState.some((o) => o === data[names.id]) ? (
          defaultId === data[names.id] ? (
            <div className={styles.defaultItem}>
              <IconMdHome />
              default
            </div>
          ) : (
            <div className={styles.setAsDefaultText} onClick={() => {
              setDefaultId(data[names.id])
              handleMultipleTypingSelectChange(selectState, needDefault ? data[names.id] : null, doubleCheckedItems)
            }}>
              set as default
            </div>
          )
        ) : (
          defaultId === data[names.id] && (
          <div className={styles.defaultItem}>
            <IconMdHome />
            default
          </div>
          )
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!selectedData) return;
    setSelectState(selectedData?.map((o) => o[names.selectedId]));
  }, [selectedData]);

  useEffect(() => {
    if (!propsData) return;
    setData(propsData);
  }, [propsData]);

  const [searchInput, setSearchInput] = useState(propsSearchText ?? '');


  const searchOnChange = async (e) => {
    const inputVal = e.target.value;
    if (!searchInput && inputVal === " ") return;
    setSearchInput(inputVal);
    if(inputVal.length >= minRequiredChars) {
      searchHandler(inputVal)
    }
  };

  const getValues = () => {
    const values = [];
    if(isAllChecked) values.push("All");
    if(!data) return values;

    const selectedValues = data
      ?.filter((o) => selectState.some((el) => el === o[names.id]))
      .map((o) => o[names.value])

    return [...values, ...selectedValues];
  }
  return (
    <MFormControl>
      {
        showLoading && (
          <div className={styles.inputLoading}>
            <ComponentLoader />
          </div>
        )
      }
      <MSelect
        variant="outlined"
        onChange={(e) => SelectsStore.checkValidation(SELELCT_ID)}
        multiple
        selelctid={SELELCT_ID}
        disabled={showLoading || disabled}
        selectedcount={selectState?.filter(o => o && o> 0)?.length ?? 0}
        value={getValues()}
        renderValue={(selected) =>
          singleSelect ? selected : selected.join(", ")
        }
        MenuProps={{
          classes: { paper: styles.selectDropdown },
        }}
        IconComponent={showLoading ? ()=> null : IconMdKeyboardArrowDown} 
      >
        <SecondaryCustomSearch
          classText="select-search"
          input={searchInput}
          onChange={searchOnChange}
        />
        {hasAllCheck && <AllSelectMenuItem/>}
        {
          isLoading 
          ? <ComponentLoader />
          : (
            data?.length > 0 ? (
              data?.map((o, i) => (
                <div className={styles.menuItemBox} key={o[names.id]}>
                  {MenuItem(o)}
                  {(needDefault || isDoubleCheck) && SetAsDefaultComp(o)}
                </div>
              ))
            ) : (
              <span className={styles.noDataText}>{noDataMessage.message}</span>
            )
          )
        }
      </MSelect>
      <MInputLabel>{placeholder}</MInputLabel>
    </MFormControl>
  );
};

export default MultipleTypingSelect;