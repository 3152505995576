import React from "react";

import styles from "./styles.module.css";

import logoBlack from "../../../assets/img/logo-black.svg";
import logoWhite from "../../../assets/img/logo-white.svg";

export const MeamaLogo = () => (
  <div className={`${styles.logo} auth-logo`}>
    <img src={logoBlack} alt="" />
    <img src={logoWhite} alt="" />
    <style>{`
      html.dark .auth-logo img:nth-child(1) {
        display: none;
      }
      html.dark .auth-logo img:nth-child(2) {
        display: block;
      }
    `}</style>
  </div>
);
export default MeamaLogo;
