//create languages for auth module
let data = {
    "commentaries": "Commentaries",
    "tags": "Tags",
    "comment": "Comment",
    "title" : "Title",
    "dateTime": "Date - Time",
    "assigned": "Assigned",
    "customer": "Customer",
    "author": "Author",
    "customerFrom": "Customer From",
    "remaindDate": "Remaind Date",
    "commentAddedSuccesfully": "Comment Added Succesfully",
    "commentUpdatedSuccesfully": "Comment Updated Succesfully",
    "commentRemovedSuccessfully": "Comment Removed Succesfully",
    "replyAddedSuccesfully": "Reply Added Succesfully",
    "replyUpdatedSuccesfully": "Reply Updated Succesfully",
    "replyRemovedSuccessfully": "Reply Removed Successfully",
    "commentMustNotBeEmpty": "Comment Must Not Be Empty",
}

export default data;