// import { createContext } from "react";
import { makeAutoObservable } from 'mobx';

import mobxRouting from '../historyStore';

export const SearchParamsStore = class {
  static urlKey = 'modalDetails';

  static addUrlKey = 'modalAdd';
  static updateUrlKey = 'modalUpdate';
  static detailUrlKey = 'modalDetails';

  static addhandler = () => {
    let searchQuery = new URLSearchParams(window.location.search);
    searchQuery.set(this.addUrlKey, '');
    mobxRouting.history.replace({ search: searchQuery?.toString() ?? '' });
  };

  static updatehandler = (openid) => {
    let searchQuery = new URLSearchParams(window.location.search);
    if (openid) searchQuery.set(this.updateUrlKey, openid);
    else searchQuery.delete(this.updateUrlKey);
    mobxRouting.history.replace({ search: searchQuery?.toString() ?? '' });
  };

  static detailhandler = (openid) => {
    let searchQuery = new URLSearchParams(window.location.search);
    if (openid) searchQuery.set(this.detailUrlKey, openid);
    else searchQuery.delete(this.detailUrlKey);
    mobxRouting.history.replace({ search: searchQuery?.toString() ?? '' });
  };

  static removeParamsFromurl = () => {
    let params = new URLSearchParams(window.location.search);
    params.delete(this.addUrlKey);
    params.delete(this.updateUrlKey);
    params.delete(this.detailUrlKey);
    mobxRouting.history.replace({ search: params?.toString() ?? '' });
  };

  // addHandler = () =>  {
  //   let searchQuery = new URLSearchParams(window.location.search);
  //   searchQuery.set(this.urlKey, "");
  //   mobxRouting.history.replace({ search: searchQuery?.toString() ?? '' });
  // };

  static hasParam = (param = null) => new URLSearchParams(window.location.search).has(param ?? this.urlKey);

  static getAddParamFromUrl = () => new URLSearchParams(window.location.search).has(this.addUrlKey);
  static getUpdateParamFromUrl = () => new URLSearchParams(window.location.search).get(this.updateUrlKey);
  static getDetailParamFromUrl = () => new URLSearchParams(window.location.search).get(this.detailUrlKey);

  static openModel = () => {
    let params = new URLSearchParams(window.location.search);

    if (params.get(this.updateUrlKey)) return Boolean(params.get(this.updateUrlKey));
    if (params.get(this.detailUrlKey)) return Boolean(params.get(this.detailUrlKey));
    if (params.has(this.addUrlKey)) return true;

    return false;
  };

  constructor(urlKey = undefined) {
    if (urlKey) this.urlKey = urlKey;

    makeAutoObservable(this);
  }
};
