import React, { useEffect, useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import AutoComplete from "react-google-autocomplete"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { IconMdMap, IconMdLocationPin, IconMdSearch } from "../../../icons/components";
import { CustomSearch } from "../CustomSearch";
import customPin from "assets/img/google-map-pin.svg";

import { 
  MTextValidator,
  MInputAdornment,
  MDialog,
  MDialogTitle,
  MDialogContent,
  MDialogActions,
  MButton
} from "../../../material/components";

import styles from "./styles.module.css";
import { useTranslations } from "shared-modules/translation-module";

const DEFAULT_CORDINATES = { name: "Tbilis, Georgia" , lat: 41.7151, lng: 44.8271 };

const Map = withScriptjs(withGoogleMap((props) =>
  {
    const {cordinates, setCordinates} = props;

    return (
     <div style={{backgroundColor: 'red'}}>
      <GoogleMap
        defaultZoom={8}
        zoom={12}
        defaultCenter={{ lat: cordinates?.lat ?? DEFAULT_CORDINATES.lat, lng: cordinates?.lng ?? DEFAULT_CORDINATES.lng }}
        center={{ lat: cordinates?.lat ?? DEFAULT_CORDINATES.lat, lng: cordinates?.lng ?? DEFAULT_CORDINATES.lng }}
        onClick={async place => {
          var url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${cordinates?.lat},${cordinates?.lng}&key=${process.env.REACT_APP_GOOLEMAP_APIKEY}`;
          let res = await fetch(url);
          let parsesRes = await res.json();
          let adressName = "";
          if(parsesRes?.results?.length) {
            let streetNumber = parsesRes?.results[0]?.address_components?.find(o => o?.types?.includes("street_number"))?.long_name ?? "";
            let streetName = parsesRes?.results[0]?.address_components?.find(o => o?.types?.includes("route"))?.long_name ?? "";
            adressName = streetName ? streetName + ", " + streetNumber : streetNumber;
          };
          if(!props.disabled) setCordinates({
            lat: place?.latLng?.lat(), 
            lng: place?.latLng?.lng(), 
            placeId: place?.placeId, 
            name: adressName ?? ""});
        }}
        defaultOptions = {{ disableDefaultUI: true, }}
      >
        {props.isMarkerShown && <Marker animation={2} icon={{ url: customPin, anchor: new google.maps.Point(17, 46), scaledSize: new google.maps.Size(37, 37)}} position={{ lat: cordinates?.lat ?? DEFAULT_CORDINATES.lat, lng: cordinates?.lng ?? DEFAULT_CORDINATES.lng }} />}
      </GoogleMap>
     </div>
    )
  }
))

export const CustomLocation = ({
  disabled = false,
  disabledAddressName = false,
  lng,
  lat,
  propsCordinates,
  locaitonHandler = () => {},
}) => {
  const {t} = useTranslations();

  const [openModalState, setOpenModalState] = useState(false);
  const [cordinates, setCordinates] = useState(propsCordinates ?? {});

  const {placesService} = usePlacesService({apiKey: process.env.REACT_APP_GOOLEMAP_APIKEY});

  useEffect(() => {
    // get data when marked and placeId is avalable
    if(cordinates?.placeId)
      placesService?.getDetails(
        {placeId: cordinates?.placeId,},
        (placeDetails) => setCordinates({name: placeDetails?.formatted_address, lat: placeDetails?.geometry?.location?.lat(), lng: placeDetails?.geometry?.location?.lng() })
      );
  }, [cordinates]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <MTextValidator  
            className={styles.locationInput}
            type="text" 
            name="address"
            autoComplete="off"
            label={t["address"]}
            validators={['required']}
            errorMessages={[t["thisFieldIsRequired"]]}
            disabled={disabledAddressName}
            value={cordinates?.name}
            onChange={e => {
              setCordinates({...cordinates, name: e.target.value});
              locaitonHandler({...cordinates, name: e.target.value})
            }}
            InputProps={{
              endAdornment: (
                <MInputAdornment position="end">
                  <div className="custom-icon" onClick={() => setOpenModalState(true)}><IconMdMap /></div>
                </MInputAdornment>
              ),
            }}
          />
        </div>
        {
          lng &&
            <div className="col-6 col-sm-12">
              <MTextValidator 
                type="number" 
                name="longitude"
                label={t["long"]}
                validators={['required']}
                errorMessages={[t["thisFieldIsRequired"]]}
                autoComplete="off"
                disabled={disabled}
                value={cordinates?.lng}
                onChange={(e) => setCordinates({...cordinates, lng: Number(e.target.value)})}
              />
            </div>
        }
        {
          lat &&
            <div className="col-6 col-sm-12">
              <MTextValidator
                type="number"
                name="latitude"
                label={t["lat"]}
                validators={['required']}
                errorMessages={[t["thisFieldIsRequired"]]}
                autoComplete="off"
                disabled={disabled}
                value={cordinates?.lat}
                onChange={(e) => setCordinates({...cordinates, lat: Number(e.target.value)})}
              />
            </div>
        }
      </div>

      <MDialog open={openModalState} onClose={() => setOpenModalState(false)} className="large">
        <MDialogTitle>
          <div className="dialog-main-title">{t["AddYourAddress"]}</div>
        </MDialogTitle>
          <MDialogContent>
            <div className={styles.searchLocationInputWrapper}>
              <AutoComplete
                apiKey={process.env.REACT_APP_GOOLEMAP_APIKEY}
                className={styles.searchLocationInput}
                placeholder={t["search"]}
                options={{
                  types: ["geocode"],
                }}
                onPlaceSelected={(place) => setCordinates({lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng(), name: place?.formatted_address})}
              />
              <IconMdSearch className={styles.searchIcon}/>
            </div>
           
            <div className={styles.mapBox}>
              <Map
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOLEMAP_APIKEY}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                disabled={disabled}
                cordinates={cordinates}
                setCordinates={setCordinates}
              />
            </div>
            {(cordinates?.name && (cordinates?.lat && cordinates?.lng)) ? <div className={`${styles.currentLoc} d-flex align-items-center`}>
              <IconMdLocationPin className={styles.currentLocIcon} /> 
              <div className={styles.currentLocTitle}>{cordinates?.name ?? `${cordinates?.lat ?? ""}, ${cordinates?.lng ?? ""}`}</div>
            </div> : null}
          </MDialogContent>
          <MDialogActions>
            <MButton className="f-bold rounded transparent" onClick={() => {
              setOpenModalState(false);
              setCordinates(propsCordinates);
            }}>{t["close"]}</MButton>
            <MButton className="f-bold rounded tonal" disabled={disabled} processing={false} onClick={() => {
              if(cordinates?.lat && cordinates?.lng) locaitonHandler(cordinates);
              else {
                setCordinates({ name: "Tbilis, Georgia" , lat: DEFAULT_CORDINATES.lat, lng: DEFAULT_CORDINATES.lng });
                locaitonHandler({ name: "Tbilis, Georgia" , lat: DEFAULT_CORDINATES.lat, lng: DEFAULT_CORDINATES.lng });
              }
              setOpenModalState(false);
            }}>{t["save"]}</MButton>
          </MDialogActions>
      </MDialog>
    </>
  );
};
export default CustomLocation;
