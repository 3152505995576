import { useState, useCallback } from 'react';

function useModalState(initialValue = false) {
  const [isOpen, setIsOpen] = useState(initialValue);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((prevValue) => !prevValue);
  }, []);

  return { isOpen, open, close, toggle };
}

export default useModalState;