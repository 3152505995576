// exporting json from ts file
var data = {
  id: 'Id',
  uniqueId: 'Unique Id',
  email: 'Email',
  name: 'Name',
  password: 'Password',
  keepMe: 'Keep me signed in',
  signIn: 'SIGN IN',
  confirmingEmail: 'Confirming Email',
  confirmedEmail: 'Confirmed Email',
  forgotPassword: 'Forgot password?',
  goToLoginPage: 'Login',
  information: 'Information',
  general: 'General',
  send: 'Send',
  newPassword: 'New Password',
  confirmPassword: 'Confirm New Password',
  oldPassword: 'Old Password',
  resetPassword: 'RESET PASSWORD',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  from: 'From',
  to: 'To',
  receiver: 'Receiver',
  delete: 'Delete',
  cancel: 'Cancel',
  done: 'Done',
  close: 'Close',
  and: 'And',
  exit: 'Exit',
  addHours: 'Add New Hours',
  notAdded: 'Not Added',
  update: 'Update',
  SortByCreateDateDesc: 'Create Date Descending',
  SortByCreateDateAsc: 'Create Date Ascending',
  SortByUpdateDateDesc: 'Update Date Descending',
  SortByUpdateDateAsc: 'Update Date Ascending',
  add: 'Add',
  oldest: 'Oldest',
  newest: 'Newest',
  key: 'Key',
  value: 'Value',
  translated: 'Translated',
  notTranslated: 'Not Translated',
  sort: 'Sort',
  all: 'All',
  seeAll: 'See All',
  products: 'Products ',
  productCategory: 'Product Category',
  productStock: 'Product Stock',
  subTotal: 'SubTotal ',
  totalOfferQuantity: 'Total Offer Quantity',
  soldQuantity: 'Sold Quantity',
  availableQuantity: 'Available Quantity',
  analytic: 'Analytic',
  status: 'Status',
  catalog: 'Catalog',
  config: 'Config',
  footerConfig: 'Footer Config',
  generalConfig: 'General Config',
  'general-config': 'General Config',
  'pages-config': 'Pages Config',
  featuresConfig: 'Features Config',
  generalInfo: 'General Info',
  ecommerceConfiguration: 'E-commerce Configuration',
  footerEndPage: 'Footer End Page',
  footerParent: 'Footer Parent',
  openInNewTab: 'Open In New Tab',
  menuConfig: 'Menu Config',
  selectAll: 'Select All',
  suretoDelete: 'Do you really want to delete?',
  suretoRefresh: 'Do you really want to Refresh?',
  suretoPowerOff: 'Do you really want to PowerOFF?',
  suretoPowerOn: 'Do you really want to PowerON?',
  deletedSuccessfully: 'Successfully Deleted',
  addedSuccessfully: 'Added Successfully',
  removedSuccessfully: 'Removed Successfully',
  sentSuccessfully: 'Sent Successfully',
  updatedSuccessfully: 'Updated Successfully',
  title: 'Title',
  subtitle: 'Subtitle',
  uploadedSuccessfully: 'Uploaded Successfully',
  loggedInSuccessfully: 'Logged In Successfully',
  archivedSuccessfully: 'Archived Successfully',
  syncedSuccesfully: 'Synced Succesfully',
  uploadMobilePhoto: 'Upload Mobile Photo',
  sapNumber: 'SapNumber',
  orderSource: 'Order Source',
  migration: 'Migration',
  paymentStatus: 'Payment Status',
  orderStatus: 'Order Status',
  orderCreator: 'Order Creator',
  orderCreateDate: 'Order Create Date',
  paymentMethod: 'Payment Method',
  deliveryMethod: 'Delivery Method',
  dismissOrder: 'Dismiss Order',
  disable: 'Disable',
  pleaseCommentReasonAndThenDismissOrder: 'Please Comment Reason And Then Dismiss Order',
  choosecategory: 'Choose Category',
  productID: 'Product ID',
  stock: 'Stock',
  createOrder: 'Create Order',
  updateOrder: 'Update Order',
  customizeOffer: 'Customize Offer',
  chooseDeliveryMethod: 'Choose Delivery Method',
  chooseContactPerson: 'Choose Contact Person',
  choosePaymentMethod: 'Choose Payment Method',
  orderPreview: 'Order Preview',
  orderInformation: 'Order Infromation',
  contactInformation: 'Contact Infromation',
  clientInformation: 'Client Information',
  mobileNumber: 'Mobile Number',
  deliveryInformation: 'Delivery Information',
  payment: 'Payment',
  create: 'Create',
  migrationStatus: 'Migration Status',
  lastUpdate: 'Last Update',
  invoice: 'Invoice',
  machineSapCode: 'Machine Sap Code',
  sapID: 'sap ID',
  offerExpired: 'Offer Expired',
  identification: 'Identification',
  identifier: 'Identifier',
  lastConnection: 'Last Connection',
  connection: 'Connection',
  connectionStatus: 'Connection Status',
  clientsList: 'Client List',
  format: 'Format',
  mainInformation: 'Main Information',
  compatibleFormat: 'Compatible Format',
  recomendedProportions: 'Recomended 1X1 Proportions',
  meamaDevice: 'Meama Device',
  cleaning: 'Cleaning',
  verification: 'Verification',
  machinedId: 'Machined Id',
  registerMachineId: 'Register Machined Id',
  size: 'Size',
  machineType: 'Machine Type',
  machineModel: 'Machine Model',
  meamaMachine: 'Meama Machine',
  otherMachine: 'Other machine',
  unVerified: 'Unverified',
  serialNumber: 'Serial Number',
  warrantyNumber: 'Warranty Number',
  registrationDate: 'Registration Date',
  machineStatus: 'Machine Status',
  meamaCoffeeMachine: 'Meama Coffe Machine',
  machineOwner: 'Machine Owner',
  prevValue: 'Prev Value',
  currentValue: 'Current Value',
  customerMachineId: 'Customer Machine Id',
  registerDate: 'Register Date',
  cleaningStatus: 'Cleaning Status',
  meamaMachineList: 'Meama Machine List',
  machine: 'Machines',
  compatibleCapsule: 'Compatible Capsule',
  MachineDetails: 'Machine Details',
  ChangesHistory: 'Change History',
  deliveryTypes: 'Delivery Types',
  deliveryTypesId: 'Delivery Types ID',
  integration: 'Integration',
  deliverTypesUpdate: 'Deliver Types Update',
  deliveryIcon: 'Delivery Icon',
  recomendedSvgFormat: 'Recomended SVG Format',
  cityDistrict: 'city/district',
  actualWeight: 'Actual Weight',
  volumeWeight: 'Volume Weight',
  pickUpPointName: 'Pick Up Point Name',
  pickUpPoint: 'Pick Up Point',
  deliveryIntegrationType: 'Delivery Integration Type',
  trackingNumber: 'Tracking Number',
  trackingLink: 'Tracking Link',
  deliveryStatus: 'Delivery Status',
  deliveryInfoMessage: 'Delivery Info Message',
  subscriptionCategory: 'Subscription Category',
  innerPageTitle: 'Inner Page Title',
  subscriptionUsers: 'Subscription Users',
  'subscription-users': 'Subscription Users',
  nextChargeDue: 'Next Charge Due',
  pickUpPointWarehouse: 'Pick Up Point Warehouse',
  deliveryVehicle: 'Delivery Vehicle',
  car: 'Car',
  motorbike: 'Motorbike',
  isErpWarning: 'Is Erp Warning',
  isDeliveryWarning: 'Is Delivery Warning',
  deliveryMigrationStatus: 'Delivery Migration Status',
  deliveryMigrationStatusId: 'Delivery Migration Status Id',
  allFilteredData: 'All/Filtered Data',
  leaveBlank: 'In case of subscription, leave Start-End Date blank',
  cancelReason: 'Cancel reason',
  cancellationComment: 'Cancellation Comment',
  devicesControllers: 'Devices Controllers',
  refresh: 'Refresh',
  powerOff: 'PowerOFF',
  powerOn: 'PowerON',
  allProductsBanner: 'All Products Banner',
  enabled: 'Enabled',

  language: 'Language',
  languages: 'Languages',
  menuoptions: 'Menu parameters',
  question: 'Question',
  answer: 'Answer',
  addNewWarehouse: 'Add New Warehouse',
  dark: 'Dark',
  ligth: 'ligth',
  menuChangedSuccessfully: 'Menu changed successfully',
  menuChangedError: 'Menu changed Error',
  feild: 'Feild',
  save: 'Save',
  saveAndUpdate: 'Save & Update',
  reject: 'Reject',
  home: 'Home',
  core: 'Core',
  coreId: 'Core Id',
  dashboard: 'Dashboard',
  search: 'Search',
  searching: 'Searching',
  searchByName: 'Name',
  searchById: 'Id',
  searchByEmail: 'Email',
  searchByPhoneNumber: 'Phone Number',
  go: 'Go',
  continue: 'Continue',
  sorry_no_suggestions: 'Sorry no suggestions',
  companies: 'Companies',
  companie: 'companie',
  company: 'Company',
  darkTheme: 'Dark theme',
  changeLanguage: 'Change language',
  back: 'Back',
  changePassword: 'Change password',
  profile: 'Profile',
  logout: 'Logout',
  favorites: 'Favorites',
  horeka: 'Horeka',
  finances: 'Finances',
  promotions: 'Promotions',
  clients: 'Clients',
  B2C: 'B2C',
  B2B: 'B2B',
  B2CList: 'B2C List',
  B2BList: 'B2B List',
  draftAgreements: 'Draft agreements',
  comment: 'Comment',
  list: 'List',
  groups: 'Groups',
  physical: 'physical',
  pointsAndWarehouses: 'Points & Warehouses',
  salesPoints: 'Sales Points',
  salesPoint: 'Sales Point',
  'sales-points': 'Sales Points',
  pointsandwarehouses: 'Sales Points',
  addOrUpdate: 'Add Or Update',
  companies_module: 'Companies',
  addNew: 'Add New',
  subscriptionName: 'Subscription Name',
  subscriptionId: 'Subscription ID',
  subscriptionPlanId: 'Subscription Plan ID',
  SubscriptionPlanUniqueId: 'Subscription Plan Unique ID',
  minifiedPages: 'Minified Pages',
  pages: 'Pages',
  pagename: 'Page Name',
  ecommercepageurl: 'Ecommerce Page URL',
  chargeDate: 'Charge Date',
  noDeliverySettings: 'This company does not have any delivery setup',
  'delivery-guest-config': 'Delivery Guest Config',

  addPhoneNumber: 'Add Phone Number',
  productsList: 'Products List',
  units: 'Units',
  localtion_module: 'Locations',
  countries: 'Countries',
  regions: 'Regions',
  cities: 'Cities',
  postalCode: 'Postal Code',
  districts: 'Districts',
  roles_module: 'Roles',
  roles: 'Roles',
  parent: 'Parent',
  user: 'User',
  details: 'Details',
  edit: 'Edit',
  editPhoneNumber: 'Edit Phone Number',
  retry: 'Retry',
  download: 'Download',
  refund: 'Refund',
  filter: 'Filter',
  orderBy: 'Order By',
  clearFilter: 'Clear Filter',
  symbol: 'Symbol',
  joined: 'Joined',
  joinDate: 'Join Date',
  departments: 'Departments',
  active: 'Active',
  inactive: 'Inactive',
  canceled: 'Canceled',
  suspended: 'Suspended',
  notActive: 'Not Active',
  blocked: 'Blocked',
  verified: 'Verified',
  unverified: 'Unverified',
  male: 'Male',
  female: 'Female',
  none: 'Other',
  userDetails: 'User details',
  sendLink: 'Send link',
  checkWithChildrens: 'Check with childrens',
  withClicldrens: 'With clicldrens',
  isDefault: 'Default',
  notDefault: 'is not default',
  time: 'Time',
  timeZones: 'Time Zones',
  price: 'Price',
  sellingPrice: 'Selling Price',
  sellersDetail: 'Sellers Detail',
  prices: 'prices',
  percent: 'Percent',
  range: 'Range',
  photo: 'Photo',
  editTable: 'Edit Table',
  exportData: 'Export Data',
  thisFieldIsRequired: 'This Field Is Required',
  specifyTheSlugInCorrectFormat: 'Specify The slug In Correct Format',
  specifyTheSeoInCorrectFormat: 'Specify The seo In Correct Format',
  gallery: 'Gallery',
  recomendedSize1600x1200: 'Recomended Size 1600x1200',
  recomendedSize: 'Recomended Size',
  transactionType: 'Transaction Type',
  paymentChannel: 'Payment Channel',
  salesPointName: 'Sales Point Name',
  salesPointType: 'Sales Point Type',
  warehouseCode: 'Warehouse Code',
  companyName: 'Company Name',
  chooseCompanie: 'Choose Company',
  workingTimes: 'Working Times',
  dateOfCreation: 'Date Of Creation',
  startDate: 'Start Date',
  endDate: 'End Date',
  startEndDate: 'Start-end Date',
  chooseVariant: 'Choose Variant',
  productsSuccessfulyAdded: 'Products Successfuly added',
  notificationSendedSuccessfuly: 'Notification sended successfuly',
  quantity: 'Quantity',
  priceChange: 'Price Change',
  clearAll: 'Clear All',
  addAll: 'Add All',
  addedProducts: 'Added Products',
  allProducts: 'All Products',
  min: 'Min',
  max: 'Max',
  plus: 'plus',
  resendNewCode: 'Resend New Code',
  supply: 'Supply',
  subDevice: 'sub device',
  clientId: 'Client Id',
  sources: 'Sources',
  source: 'Source',
  registerSource: 'Register Source',
  addSubMenu: 'Add Submenu',
  fontColorHex: 'Font Color Hex',
  displayOrder: 'Display Order',
  orderNumber: 'Order Number',
  itemHasChildrenDelete: 'If you delete this item, child items will also be deleted',
  parentMenu: 'Parent Menu',
  menu: 'Menu',
  social: 'Social',
  parentMenuConfig: 'Parent Menu Config',
  childMenuConfig: 'Child Menu Config',
  downloadDocument: 'Download Document',
  downloadFile: 'Download File',
  allData: 'All Data',
  thisPage: 'This Page',
  sortedSuccefully: 'sortedSuccefully',
  socialConfig: 'Social Config',
  uploadSocialSvg: 'uploadSocialSvg',
  addNewLinkAndSVG: 'Add New Link + SVG',
  pleaseEnterCorrectTimeFormat: 'please Enter Correct Time Format',
  idNumber: 'ID Number',
  additionalSpecification: 'Additional Specification',
  descriptionBox: 'Description Box',
  accordionBox: 'Accordion Box',
  banner: 'Banner',
  banner1: 'Banner 1',
  banner2: 'Banner 2',
  bannerImage: 'Banner Image',
  index: 'index',
  customer: 'Customer',
  categoryTypes: 'Category Types',
  youNeedToSaveTheCategoryBeforeYouCanAddProductsForThisCategoryPage:
    'You need to save the category before you can add products for this category page',
  reorderedSuccesfully: 'Reordered Succesfully',
  showInListing: 'Show In Listing',
  removeFromListing: 'Remove From Listing',
  changeIndex: 'Change Index',
  newIndex: 'New Index',
  currentIndex: 'Current Index',

  slogan: 'Slogan',
  domainTitle: 'Domain Title',
  domain: 'Domain',
  cdnBaseUrl: 'CDN Base Url',
  firstTitle: 'First Title',
  secondTitle: 'Second Title',
  secondaryInfo: 'Secondary Additional Info',
  logos: 'Logos',
  largeLogo: 'largeLogo',
  smallLogo: 'smallLogo',
  additionalInfo: 'Additional info',
  authentificationImages: 'Authentification Images',
  firstImage: 'First Image',
  secontImage: 'Secont Image',
  label: 'Label',
  linkedCoreCategorie: 'Linked Core Categorie',
  widgetSubTitleSumOfOrders: 'Sum Of Orders',
  widgetSubTitleSumOfSubscription: 'Sum Of Subscription',
  widgetSubTitleNumberOfOrders: 'Number Of Orders',
  widgetSubTitleNumberOfSubscription: 'Number Of Subscription',
  orderDiscount: 'Order Discount',
  ERPsalesOrderId: 'ERP sales order ID',
  latitude: 'Latitude',
  longitude: 'Longitude',

  // location translations
  country: 'Country',
  region: 'Region',
  city: 'City',
  district: 'District',
  street: 'Street',
  'location-configuration': 'Locations Configuration',
  location: 'location',
  'FAQ&SEO': 'FAQ & SEO',
  'FAQ-&-SEO': 'FAQ & SEO',
  branch: 'Branch',
  address: 'Address',
  addressId: 'Address Id',
  zipCode: 'Zip Code',
  locationCategories: 'Location Categories',
  workingStartTime: 'Working Start Time',
  workingEndTime: 'Working End Time',

  // ecommerce offer translations
  'ecommerce-offers': 'Offers',
  'offer-categories': 'Offer Categories',
  filterName: 'Filter Name',
  'link-offers': 'Link Offers',
  coreOfferID: 'Core Offer ID',
  ecomOfferName: 'ecom Offer Name',
  chooseOfferType: 'Choose Offer Type',
  addNewPhotoBoxes: 'Add New Photo Boxes',
  addNewMeamaProducts: 'Add New Meama Products',
  slider: 'Slider',
  blogPost1: 'Blog Post 1',
  offerType: 'Offer Type',
  offerName: 'Offer Name',
  'offer-listing': 'Offer Listing',
  OfferListing: 'Offer Listing',
  faq: 'F.A.Q',

  //validators
  moreThanZero: 'value must be more than zero',
  mustBeCorrectRange: 'Must Be Correct Range',
  ThisFieldIsRequired: 'Field is required',
  selectAtLeastOneDay: 'Select At Least One Day',
  YousShouldCheckCountCheckBoxes: 'Yous should check {0} checkboxes',
  YousShouldSelectCount: 'Yous should select {0}',
  MaxCountIsIncorrect: 'MaxCountIsIncorrect',
  MinCountIsIncorrect: 'MinCountIsIncorrect',
  minimum6Character: 'Must contain at least 6 characters',
  passwordMismatch: 'Password Mismatch',
  atLeastOneUpperCase: 'At Least One Upper Case',
  atLeastOneNumber: 'At Least One Number',
  atLeastOneSpecialSymbol: 'At Least One Special Symbol',
  invalidUrl: 'Enter Valid Url',
  url: 'Url',

  generationIsNotFinish: 'Generation is not finished',
  clearTable: 'Clear Table',

  //users module translations
  gender: 'Gender',
  sex: 'Sex',
  superAdmin: 'Super Admin',
  usersDepartment: 'Users Department',
  phoneNumber: 'Phone Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  userRoles: 'User Roles',
  passwordNotChanged: 'Password not changed',
  passwordChanged: 'Password changed',
  userNotUpdated: 'User not updated',
  userUpdated: 'User updated',
  systemUsersModule: 'System Users',

  deleteDialogTitle: 'Delete action',
  deleteDialogText: ' Do you want really delete',

  //Currency module Translations
  currencies: 'Currencies',
  currency: 'Currency',
  short: 'Short',
  successAddedCurrency: 'Currency added successfully',
  notAddedCurrency: 'Currency could not be added',
  successDeleteCurrency: 'Currency deleted successfully',
  notADeletedCurrency: 'Currency could not be deleted',
  successUpdateCurrency: 'Currency updated successfully',
  notAUpdatedCurrency: 'Currency could not be updated',
  youCantChoosePastInPublish: 'you Cant Choose Past In Publish Start/End Date',

  //companies module MToast translations
  youMustChooseHowDelete: 'You must choose how delete',
  successAddedCompany: 'Company added successfully',
  notAddedCompany: 'Company could not be added',
  successDeleteCompany: 'Company deleted successfully',
  notADeletedCompany: 'Company could not be deleted',

  //Strings module Translations
  'strings-drawer': 'Translations',
  strings: 'Translations',
  string: 'Translation',

  //Logs module Translations
  'logs-drawer': 'Logs',
  logs: 'Logs',

  // promo code transations
  'promo-code-setup': 'Promo Code Setup',
  'promo-codes': 'Promo Codes',
  'static-promo-codes': 'Static Promo Codes',
  'first-purchase-config': 'First Purchase Config',
  addNewPromoCode: 'Add New Promo Code',
  addNewFirstPurchaseSetup: 'Add New First Purchase Setup',
  'first-purchase-offers': 'First Purchase Offers',
  used: 'Used',
  promoCodeOfferId: 'Promo Code Offer Id',
  promoCodeScope: 'Promo Code Scope',
  numberOfCodes: 'Number of Codes',
  codeLenght: 'Code Length',
  addProducts: 'Add Products',

  //client group module Translations
  groupAdded: 'Group Added',
  groupUpdated: 'Group Updated',
  groupDeleted: 'Group Deleted',
  deleteWithFiles: 'Delete with files',
  uploadWithFiles: 'Upload with files',
  addViaPhone: 'Add via phone number',
  addedViaPhone: 'Added via phone number',
  deletedPhone: 'Phone numebr Deleted',
  pauseGroup: 'Group Paused',
  resumeGroup: 'Group Resumed',
  groupRetry: 'Group Retried',
  phoneRetry: 'Phone Retried',
  phone: 'Phone',
  sortByCustomerAmountDesc: 'Desc By Customer Amount',
  sortByCustomerAmountAsc: 'Asc By Customer Amount',
  sortByidDesc: 'Desc by id',
  sortByidAsc: 'Asc by id',
  sortByJoinDateDesc: 'Join Date Descending',
  sortByJoinDateAsc: 'Join Date Ascending',

  //Offers module Translations
  'offers-drawer': 'Offers',

  //promocodes translations
  'link-promo-codes': 'Link Promo Codes',
  promoCodeAnalytic: 'Promo Code Analytic',

  //Transactions module Translations
  'transactions-drawer': 'Transactions',
  transactions: 'Transactions',
  totalAmount: 'Total Amount',
  Id: 'Id',
  UserId: 'User Id',
  TransationRefId: 'Reference Id',
  TransactionStatusId: 'Transaction Status Id',
  PaymentDate: 'Payment Date',
  OrderDate: 'Order Date',
  CustomerId: 'Customer Id',
  TransactionStatus: 'Status',
  TransactionType: 'Type',
  PaymentChannel: 'Payment Channel',
  PaymentType: 'Payment Type',
  SalePointId: 'Sales Point Id',
  SalesPointName: 'Sales Point Name',
  SalesPointType: 'Sales Point Type',
  SalesPointTypeValue: 'Sales Point Type Value',
  customerType: 'Customer Type',
  Amount: 'Amount',
  Currency: 'Currency',
  FreeCapsuleAmount: 'Free Capsule Amount',
  Initiator: 'Initiator',
  HasCustomer: 'Has Customer',
  CompanyId: 'Company Id',
  CompanyName: 'Company',
  CardNumber: 'Card Number',
  Cashier: 'Cashier',
  OrderCode: 'Order Code',
  UniqueCode: 'Unique Code',
  ChangeTime: 'Change Time',
  DetailedInformation: 'Detailed Information',
  TransactionDetails: 'Transaction Details',
  ProductsDetails: 'Products Details',
  History: 'History',
  live: 'Live',
  transactionsIsInLive: 'Transactions is in live',
  transactionsIsNotInLive: 'Transactions is not in live',
  selected: 'Selected',
  items: 'Items',
  cost: 'Cost',
  salePrice: 'Sale Price',
  saleType: 'Type of Sales',
  promoCode: 'Promo Code',
  promoCodeList: 'List of Promo Codes',
  workingWithOtherSales: 'Working with other sales',
  promoCodeType: 'Promo Code Type',
  minPriceToActivate: 'Min price to activate',
  activate: 'activate',
  deactivate: 'deactivate',
  offerStartEnd: 'Offer Start - End Date',
  'no-data': 'No Data',
  noData: 'No Data',
  faux: 'Faux',
  warehouses: 'Warehouses',
  description: 'Description',
  descriptionIsRequeried: 'description is requeried',
  text: 'Text',
  color: 'Color',
  textColor: 'Text Color',
  backgroundColor: 'Background Color',
  default: 'Default',
  isActive: 'Is Active',
  coreLanguages: 'Core languages',
  creationDate: 'Creation Date',
  changeDate: 'Change Date',
  date: 'Date',
  vatPayer: 'VAT Payer',
  vatPercent: '% VAT Percent',
  orderDate: 'Order Date',

  //Specifications module Translations
  'specifications-drawer': 'Specifications',
  specifications: 'Specifications',
  specificationsWithLinkedProducts: 'Specifications With Linked Products',
  linkAppropriateProduct: 'Link Appropriate Product',
  linkedOffers: 'Linked Offers',
  addInput: 'Add Input',
  typeHere: 'Type Here',
  type: 'Type',
  coreOfferId: 'Core Offer Id',
  offerID: 'Offer ID',
  offerSetup: 'Offer Setup',
  outOfStock: 'Out Of Stock',

  //Product templates module Translations
  'templates-drawer': 'Templates',
  templates: 'Templates',
  templateId: 'Template Id',

  //categories module Translations
  categories: 'Categories',
  category: 'Category',
  parentCategoryId: 'Parent Category Id',
  categoryId: 'Category Id',
  structure: 'Structure',
  categoryName: 'Category Name',
  parentCategory: 'Parent Category',
  categorieDeletedSuccesfully: 'CategorieDeletedSuccesfully',
  parentCategories: 'Parent Categories',
  childCategories: 'Child Categories',
  publish: 'Publish',
  isPublished: 'Is Publish',
  published: 'Published',
  unpublished: 'Unpublished',
  scheduled: 'Scheduled',
  publishStartTime: 'Start Time',
  publishEndTime: 'End Time',
  contentType: 'Content Type',
  pageTitle: 'Page Title',
  content: 'Content',
  basics: 'Basics',
  advanced: 'Advanced',
  descriptionMustNotBeEmpty: 'Description Must Not Be Empty',
  template: 'Template',
  configTags: 'Config Tags',
  quickShopDescription: 'Quick Sho Description',
  fullDescription: 'Full Description',
  shortDescription: 'Short Description',
  youMustEnterIndex: 'You Must Enter Index',
  linkSimmilarProducts: 'Link Simmilar Products',
  addSimmilarProducts: 'Add Simmilar Products',
  specificationType: 'Specification Type',
  photos: 'Photos',
  uploadMainPhoto: 'Upload Main Photo',
  uploadHoverPhoto: 'Upload Hover Photo',
  uploadGalleryPhoto: 'Upload Gallery Photo',
  uploadPhoto: 'Upload Photo',
  mainPhoto: 'Main Photo',
  mainPhotoALTText: 'Main Photo ALT Text',
  hoverPhotoALTText: 'Hover Photo ALT Text',
  desktopPhotoALTText: 'Desktop Photo ALT Text',
  mobilePhotoALTText: 'Mobile Photo ALT Text',
  galleryALTText: 'Gallery ALT Text',
  altText: 'ALT Text',
  remove: 'Remove',
  hoverPhotoUrl: 'Hover URL',
  bundle: 'Bundle',
  discount: 'Discount',
  wholesale: 'Wholesale',
  linkedProducts: 'Linked Products',
  header: 'Header',
  buttonURl: 'button URl',
  customersAlsoBought: 'Customers Also Bought',
  visibility: 'Visibility',
  messageMustNotBeEmpty: 'Message Must Not Be Empty',
  showOnProductListing: 'Show On Product Listing',
  showOnInnerPage: 'Show On Inner Page',
  coreProduct: 'Core Product',
  productCode: 'Product Code',
  buttonUrl: 'Button Url',
  buttonText: 'Button Text',
  promoCodeValue: 'Promo Code',
  customerEmail: 'Customer Email',
  meamaBusiness: 'Meama Business',
  'meama-machine-list': 'Meama Machine List',
  machines: 'Machines',

  'registered-machines': 'Registered Machines',
  addedMachines: 'Added Machines',
  addMachines: 'Add Machines',
  machinesModel: 'Machines Model',

  // vms
  vms: 'vms',
  vmsConfiguration: 'VMS Configuration',
  'device-management': 'Device management',
  'controller-list': 'Controller list',
  'device-types': 'Device Types',
  'additional-inventory': 'Additional Inventory',
  'partner-companies': 'Partner Companies',
  kiosks: 'Kiosks',
  services: 'Services',
  alerts: 'Alerts',
  alertType: 'Alert Type',
  critical: 'Critical',
  lowVolume: 'Low Volume',
  rotate: 'Rotate',
  removeProduct: 'Remove Product',
  emptyColumn: 'Empty Column',
  lock: 'Lock',
  deviceType: 'Device Type',
  deviceName: 'Device Name',
  history: 'History',
  deviceTypeName: 'Device Type Name',
  sensors: 'Sensors',
  sensorType: 'Sensor Type',
  sensorId: 'Sensor ID',
  inventoryCodeId: 'Inventory Code/ID',
  generalInformation: 'General Information',
  number: 'Number',
  alternativeNumber: 'Alternative Number',
  'qr-codes': 'QR Codes',
  codeLength: 'Code Length',
  link: 'link',
  linkOfGeneratedFilesWillBeSentToThisMail: 'Link of generated files will be sent to this mail',
  code: 'Code',
  rows: 'Rows',
  columns: 'Columns',
  capacity: 'Capacity',
  column: 'Column',
  addColumn: 'Add Column',
  addRow: 'Add Row',
  reset: 'Reset',
  clearAllRow: 'Clear All Row',
  rowAndColumnsTooltip: 'Clear All Row',

  //Products List module Translations
  'products-list-drawer': 'Product List',
  orderByNameAsc: 'Order By Name Asc',
  orderByNameDesc: 'Order By Name Desc',
  orderByDateAsc: 'Order By Date Asc',
  orderByDateDesc: 'Order By Date Desc',
  ascProductName: 'Product By Name Asc',
  descProductName: 'Product By Date Desc',
  ascCategoryName: 'Category By Name Asc',
  descCategoryName: 'Category By Date Desc',

  orderByIdDesc: 'Order By ID Desc',
  orderByIdAsc: 'Order By ID Asc',

  //Notifications module Translations
  notifications: 'Notifications',
  sent: 'Sent',
  inbox: 'Inbox',
  read: 'Read',
  unread: 'Unread',
  compose: 'Compose',
  sendMessage: 'send',
  sentNotification: 'Sent Notification',
  subject: 'Subject',
  message: 'Message',

  //Tags module Translations
  'tags-drawer': 'Tags',
  tags: 'tags',

  //ecommerce orders module Translations
  orderDetails: 'Order Details',
  deliveryDetails: 'Delivery Details',
  productsDetails: 'Products Details',
  erpIntegraionDetails: 'ERP Integration Details',
  erpCode: 'ERP Code',
  erpName: 'ERP Name',
  erpClientId: 'ERP Client Id',
  subscriptionOrder: 'Subscription Order',
  orderCancellationRequestWasSuccessfullySent: 'Order Cancellation Request Was Successfully Sent',
  retryRequestWasSentSuccessfully: 'Retry Request Was Sent Successfully',
  tracking: 'Tracking',
  importData: 'import Data',

  //popup module
  popups: 'Popups',
  popup_list: 'Popup List',
  displayDuration: 'Display Duration',
  sec: 'Sec',
  viewInEcommerce: 'View In Ecommerce',

  showOption: 'Show Option',
  exactCheck: 'Exact Check',

  //promocode setup
  use: 'Use',
  lastUpdateBy: 'Last Update By',

  orderId: 'Order ID',

  userName: 'User Name',
  pricePerProduct: 'Price Per Product',
  ecommerceId: 'Ecommerce Id',
  orderUniqueId: 'Order Unique Id',
  orderGuid: 'Order Guid',
  ecommerceOrderId: 'Ecommerce Order Id',
  payemntOrderId: 'Payemnt Order Id',
  erpOrderId: 'ERP Order Id',
  saveCard: 'Save Card',
  orderLanguageCode: 'Order Language Code',
  orderStatusId: 'Order Status Id',
  orderStatusName: 'Order Status',
  paymentTypeId: 'Payment Type Id',
  paymentTypeName: 'Payment Type',
  shippingTypeId: 'Shipping Type Id',
  shippingTypeName: 'Shipping Type',
  subTotalAmount: 'SubTotal Amount',
  shippingAmount: 'Shipping Amount',
  currencyId: 'Currency Id',
  currencySymbol: 'Currency Symbol',
  coreUserId: 'Core User Id',
  userId: 'User Id',
  userPhoneNumber: 'User Phone Number',
  isDiplomat: 'Is Diplomat',
  lastModifiedUtcDate: 'Last Modification Date',
  createdUtc: 'Creation Date',
  saved: 'Saved',
  notSaved: 'Not Saved',
  erpMigrationDate: 'ERP Migration Date',
  erpStatus: 'ERP Status',
  erpMigrationStatus: 'ERP Migrate Status',
  erpMigrationStatusId: 'ERP Migrate Id',
  delivery: 'Delivery',
  delivery_types: 'Delivery Types',
  deliveryType: 'Delivery Type',
  pick_up_points: 'Pick Up Points',
  pick_up_name: 'Pick Up Name',
  addPickUp: 'Add Pick Up',
  updatePickUp: 'Update Pick Up',
  'delivery-config': 'Delivery Config',
  deliveryAddress: 'Delivery Address',
  pickupPoint: 'Pickup Point',
  priceConfig: 'Price Config',
  deliveryPrice: 'Delivery Price',
  orderPrice: 'Order Price',
  minimum: 'Minimum',
  maximum: 'Maximum',
  delivery_settings_setup: 'Delivery Settings Setup',

  //Subscriptions module translations
  subscriptions: 'Subscriptions',
  subscription: 'Subscription',
  chooseFAQ: 'choose F.A.Q',
  innerPage: 'Inner Page',
  monthlyPrice: 'Monthly Price',
  firstMonthPrice: 'First Month Price',
  months: 'Months',
  month: 'Month',
  monthsBeforeCancellationAllowed: 'Months Before Cancellation Allowed',
  duration: 'Duration',
  subscriptionStatus: 'Subscription Status',
  merchantDurationKey: 'merchant Duration Key',
  invalidGuid: 'Invalid Guid',
  subscriptionPlanUniqueId: 'Subscription Plan Unique Id',
  isSubscriptionOrder: 'Subscription Order',
  'cencelation-config': 'Cancelation Config',
  reason: 'Reason',
  cencelationReason: 'Cancelation Reason',
  withComment: 'With Comment',
  cencel: 'Cancel',
  cencelSubscription: 'Cancel Subscription',
  cencelation: 'Cencelation',
  feePerMonth: 'fee Per Month',
  cencelationTransactions: 'Cencelation Transactions',
  eCommerceId: 'eCommerce Id',
  cancellationFee: 'Cancellation Fee',
  transactionId: 'Transaction Id',
  requestDate: 'Request Date',
  paymentOrderId: 'Payment Order Id',
  failReason: 'Fail Reason',

  discountAmount: 'Discount Amount',
  cardNumber: 'Card Number',
  receiverFirstName: 'Receiver First Name',
  receiverLastName: 'Receiver Last Name',
  receiverPhoneNumber: 'Receiver Phone Number',
  shippingAddressName: 'Shipping Address Name',
  shippingAddressId: 'Shipping Address ID',

  //Commentaries module Translations
  commentaries: 'Commentaries',
  'commentaries-list-drawer': 'Commentaries List',

  //statuses module translations
  'statuses-drawer': 'Statuses',

  //Languages module Translations
  'languages-drawer': 'Languages',

  //textPage module Translations
  textPage: 'Texts',

  //MainPage Translations
  'main-page': 'Main Page',
  'page-setup-seo': 'Page Setup Seo',
  'page-setup': 'Page Setup',
  'page-components': 'Page Components',
  'title-section': 'Title Section',
  'main-slider': 'Main Slider',
  'grid-slider': 'Grid Slider',
  'photo-boxes': 'Photo Boxes',
  'cover-flow-slider': 'Cover Flow Slider',
  'centered-slider': 'Centered Slider',
  'info-box': 'Info Box',
  'blog-slider': 'Blog Slider',

  sliderTextAndDescription: 'Slider Text And Description',
  titleAndDescription: 'title And Description',

  //StarterPage Translations
  'starter-page': 'Starter Page',
  'starter-slider': 'Starter Slider',
  'comparison-flow-one': 'Comparison Flow One',
  'comparison-flow-two': 'Comparison Flow Two',
  'comparison-info-one': 'Comparison Info One',
  'comparison-info-two': 'Comparison Info Two',
  //FAQ Translations
  FAQ: 'F.A.Q',

  //Module Translations
  ProjectPolicys_B2BHoreka: 'B2B Horeka',
  ProjectPolicys_CoreModule: 'Core',
  ProjectPolicys_FinancesModule: 'Finances',
  ProjectPolicys_ProductsModule: 'Products',
  ProjectPolicys_ProductModule: 'Product',
  ProjectPolicys_PromoCodeModule: 'Promo Code',
  ProjectPolicys_PaymentModule: 'Payment',
  ProjectPolicys_SupportUkraineModule: 'Support Ukrine',
  ProjectPolicys_CustomersModule: 'Customers Module',
  ProjectPolicys_ERPModuleModule: 'ERP Module',
  ProjectPolicys_OffersModule: 'Offers',
  ProjectPolicys_ECommerceModule: 'Ecommerce',

  //Ui-lib-module Translations
  deleteAll: 'Delete All',
  deleteText: 'Do you want delete?',
  'no-table-data': 'No Table Data',
  'entries-in-the-list': 'entries in the list',
  'file-is-too-large': 'File is too large',
  'maximum-file-size-is': 'Maximum file size is',
  'file-of-invalid-type': 'File of invalid type',
  'the-maximum-number-of-files-is': 'The maximum number of files is',

  //local errors
  'Network Error': 'Network Error',

  'Clients-drawer': 'Clients',
  'clients-group-drawer': 'Clients Group',
  'sales-points-drawer': 'Sales Points',
  baseType: 'Base Type',

  nameAsc: 'Name Ascending',
  nameDesc: 'Name Descenging',
  typeAsc: 'Type Ascending',
  typeDesc: 'Type Descending',
  dateAsc: 'Date Ascending',
  sortByDateAsc: 'Date Ascending',
  sortByDeliveryDateAsc: 'sortByDeliveryDateAsc',
  sortByDeliveryDateDesc: 'sortByDeliveryDateDesc',
  deliveryDate: 'Delivery Date',
  sortByAmountHigh: 'Sort By Amount High',
  sortByAmountLow: 'Sort By Amount Low',
  dateDesc: 'Date Descending',
  durationDaysDesc: 'Duration Days Descending',
  durationDaysAsc: 'Duration Days Ascending',
  sortByDateDesc: 'Date Descending',
  companyIdAsc: 'Company Id Ascending',
  companyIdDesc: 'Company Id Descending',
  Status_Changed_To: 'Status changed to',
  sortByIdDesc: 'ID Descending',
  sortByIdAsc: 'ID Ascending',
  sortByAddDateDesc: 'Add Date Descending',
  sortByAddDateAsc: 'Add Date Ascending',
  startDateDesc: 'Start Date Descending',
  startDateAsc: 'Start Date Ascending',

  'file-invalid-type': 'File of invalid type',
  'file-too-large': 'File must not be larger then',
  'too-many-files': 'too many files',

  //WidgetsModule
  widgetTitleTotalAmount: 'Total Amount',
  widgetTitleLastWeek: 'Last Week',
  widgetTitleThisWeek: 'This Week',
  lastWeek: 'Last Week',
  sentMessages: 'Messages Sent',
  widgetTitleThisYear: 'This Year',
  widgetTitleStatuses: 'Statuses',
  widgetTitleSalesPointType: 'Sales Point Type',
  widgetSubTitleNumberOfTransactions: 'Number of transactions',
  widgetSubTitleSumOfTransactions: 'Sum of transactions',
  widgetSubTitleNumberOfRegistration: 'Number of registrations',
  widgetTitleTopProducts: 'Top Selling Products',
  widgetTitleTopBundles: 'Top Bundle Offers',
  widgetTitleTopSubscription: 'Top Subscription Offers',
  callcenter: 'CallCenter',
  dropper: 'Dropper',
  ecommerce: 'Ecommerce',
  ecommerce_CMS: 'E-COMMERCE CMS',
  shop_meama_ge: 'Ecommerce',
  'shop_meama_ge,dropper': 'Vending & ECommerce',
  completed: 'Completed',
  paid: 'Paid',
  other: 'Other',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
  'widget-no-data': 'No Data',

  syncIndexes: 'Sync Indexes',

  //comments
  commentsCount: 'Comments Count',
  reply: 'Reply',
  addComment: 'Add Comment',

  chooseColor: 'Choose Color',

  // table buttons
  archive: 'Archive',
  preview: 'Preview',
  'workingDays&Hours': 'Working Days & Hours',
  workingDays: 'Working Days',
  orders: 'Orders',
  offers: 'Offers',
  cart: 'Cart',
  'deliveryMethod&Payment': 'Delivery method & payment',
  previewOrder: 'Preview Order',
  warehouse: 'Warehouse',
  'ecommerce-orders': 'Orders',
  dismiss: 'Dismiss',
  next: 'Next',
  today: 'Today',
  deliveryTypeName: 'Delivery Type Name',
  messages: 'Messages',
  notify: 'Notify',
  notifyTemplates: 'Notify Templates',
  saveAsTemplate: 'Save As Template',
  sendTime: 'Send Time',
  templateName: 'Name of Template',
  opened: 'opened',
  unopened: 'Unopened',
  failed: 'Failed',
  succeed: 'Succeed',
  warning: 'Warning',
  inProgress: 'In Progress',
  created: 'Created',
  'google-analytics': 'Google Analytics Config',
  acceptedType: 'Accepted Type',
  viewId: 'View Id',
  google: 'Google',
  googleAnalytics: 'Google Analytics',
  nowActiveUsers: 'Now active users',
  activeUsersByYear: 'User activity',
  activityByCountry: 'Activity By Country',
  activityByPages: 'Activity By Pages',
  session: 'Session',
  analytics: 'Analytics',
  'order-analytics': 'Orders Analytics',
  'subscription-analytics': 'Subscription Analytics',
};

export default data;
