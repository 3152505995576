import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
// import { ApiManager } from "shared-modules/api-manager-module";

import mobxRouting from '../historyStore';

export const SortStore = class {
  params = new URLSearchParams(window.location.search);

  urlKey = 'sort';

  SortEnum = Object.freeze({
    sortOptions: {
      oldest: 'oldest',
      newest: 'newest',
      byStatus: 'byStatus',
    },
  });

  sortedItems = [];
  currentSort = '';
  defaultSort;

  sortHandler = (sort, originalData) => {
    let isSort = Object.keys(this.SortEnum.sortOptions).some((o) => this.SortEnum.sortOptions[o] === sort);
    //if sort is same or not have sort return original data
    if (!sort || !isSort) {
      this.currentSort = null;
      return originalData;
    }
    this.currentSort = sort;
    const sortOptions = this.SortEnum.sortOptions;
    let finalData = [];

    if (sort === undefined) finalData = originalData;
    if (sort === sortOptions.oldest) finalData = originalData;
    if (sort === sortOptions.newest) {
      let newRolesArr = originalData.map((o) => o);
      finalData = newRolesArr.reverse();
    }
    if (sort === sortOptions.byStatus) {
      let avalable = [];
      let unAvalable = [];
      originalData.map((el) => (el.status === 'Available' ? avalable.push(el) : unAvalable.push(el)));
      finalData = [...avalable, ...unAvalable];
    }
    return finalData;
  };

  constructor(sortDefaultValue) {
    makeAutoObservable(this);
    this.defaultSort = sortDefaultValue ?? this.SortEnum.sortOptions.oldest;
    reaction(
      () => this.currentSort,
      async (sort) => {
        let params = new URLSearchParams(window.location.search);
        if (sort === this.defaultSort || !sort) {
          params.delete(this.urlKey);
          mobxRouting.replace({ search: params?.toString() ?? '' });
        } else if (sort) {
          params.set(this.urlKey, sort);
          mobxRouting.replace({ search: params?.toString() });
        }
      }
    );
  }
};

export default createContext(new SortStore());
