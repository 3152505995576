import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import {
  MFormControl,
  MInputLabel,
  MSelect,
  MMenuItem,
  MCheckbox,
  MFormControlLabel,
} from "../../../../material/components";
import { ComponentLoader } from '../../ComponentLoader'
import { SecondaryCustomSearch } from "../../../../custom/components";
import {
  IconMdKeyboardArrowDown,
  IconMdHome,
} from "../../../../icons/components";
import styles from "../styles.module.css";
import { useEffect } from "react";
import { useTranslations } from 'shared-modules/translation-module';
import { Observer } from "mobx-react";
import { SelectsStore } from "../store";

export const MultipleSelect = ({
  name="multi-select",
  placeholder,
  singleSelect,
  disabled,
  disabledItems = [],
  propsData,
  selectedData,
  needDefault,
  selectedDefault,
  isDoubleCheck,
  selectedDoubleCheckedItems,
  shouldReturnOriginalData = false,
  handleMultipleSelectChange,
  names = { id: "id", value: "name", selectedId: 'id' },
  showLoading = false,
  setAsDefaultText = "set as default",
  defaultText = "default"
}) => {
 
  const [SELELCT_ID, setSELELCT_ID] = useState(uuidv4());

  const { t } = useTranslations();
  const [selectState, setSelectState] = useState([]);
  const [data, setData] = useState([]);
  const [defaultId, setDefaultId] = useState();

  const selectChange = (event) => {
    let value = shouldReturnOriginalData ? event.target.value : Number(event.target.value);
    if(isNaN(value)) value = event.target.value ?? event;
    selectState.length === 0 && setDefaultId(value);

    if (singleSelect) {
      setSelectState([value]);
      handleMultipleSelectChange([value], needDefault ? defaultId : null, doubleCheckedItems)
      return;
    }

    let isValue = selectState.find((o) => o === value);
    if (isValue) {
      let localSelectState = selectState.filter((o) => o !== value);
      if (value === defaultId) {
        localSelectState.length > 0
          ? setDefaultId(localSelectState[0])
          : setDefaultId(null);
      }
      setSelectState(localSelectState);
      setDoubleCheckedItems(doubleCheckedItems?.filter(o => o !== value))
      handleMultipleSelectChange(localSelectState, needDefault ? defaultId : null, doubleCheckedItems)
    } else {
      handleMultipleSelectChange([...selectState, value], needDefault ? defaultId : null, doubleCheckedItems)
      setSelectState([...selectState, value]);
    }
  };

  useEffect(() => {
    if(selectedDefault) setDefaultId(selectedDefault)
  }, [selectedDefault]);

  const [doubleCheckedItems, setDoubleCheckedItems] = useState([]);
  
  useEffect(() => {
    if(selectedDoubleCheckedItems?.length) {
      setDoubleCheckedItems(selectedDoubleCheckedItems);
    }
  }, [selectedDoubleCheckedItems])
  
  const onDoubleCheckedItemsChanged = (id) => {

    if(doubleCheckedItems.some(o => o === id)) {
      setDoubleCheckedItems(o => o.filter(i => i !== id))
      handleMultipleSelectChange(selectState, needDefault ? defaultId : null, doubleCheckedItems.filter(i => i !== id))
    }
    else {
      setDoubleCheckedItems(o => [...o, id]);
      handleMultipleSelectChange(selectState, needDefault ? defaultId : null, [...doubleCheckedItems, id])
    }
  }

  const MenuItem = (data) => {
    return (
      <MMenuItem className={styles.textGroup} >
        <MFormControlLabel
          // className={`${styles.checkbox} ${disabledItems?.some(o => o[names.id] === data[names.id])  ? styles.disabled : ""}`}
          className={`${styles.checkbox}`}
          disabled={disabledItems?.some(o => o ? (o[names.id] === data[names.id]) : false)}
          control={
            <MCheckbox
           //   selectedcount={count} 
              onClick={selectChange}
              value={data[names.id]}
              checked={selectState.some((o) => o === data[names.id])}
              className="tonal medium"
            />
          }
          label={data[names.value]}
        /> {data?.extraText ? <div className={styles.extraText} > {data?.extraText} </div> : ''}
      </MMenuItem>
    );
  };

  const SetAsDefaultComp = (data) => {
    if(isDoubleCheck) {
      return (
        <div className={styles.defaultWrap} key={Math.random()}>
        {
          selectState.length > 0 && data[names.id] && doubleCheckedItems.some(o => o === data[names.id])
          ? 
          <div className={styles.setAsDefaultText} onClick={() => onDoubleCheckedItemsChanged(data[names.id])}>
            {t["withClicldrens"]}
          </div>
          :
          selectState.find(o => o === data[names.id]) && 
          <div onClick={() => onDoubleCheckedItemsChanged(data[names.id])} className={styles.setAsDefaultText}>
            {t["checkWithChildrens"]}
          </div>
        }
        </div>
      )
    }

    return (
      <div className={styles.defaultWrap} key={defaultId}>
        {selectState.length > 1 &&
        data[names.id] &&
        selectState.some((o) => o === data[names.id]) ? (
          defaultId === data[names.id] ? (
            <div className={styles.defaultItem}>
              <IconMdHome />
              {defaultText}
            </div>
          ) : (
            <div className={styles.setAsDefaultText} onClick={() => {
              setDefaultId(data[names.id])
              handleMultipleSelectChange(selectState, needDefault ? data[names.id] : null, doubleCheckedItems)
            }}>
              {setAsDefaultText}
            </div>
          )
        ) : (
          defaultId === data[names.id] && (
          <div className={styles.defaultItem}>
            <IconMdHome />
            {defaultText}
          </div>
          )
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!selectedData) return;
    setSelectState(selectedData?.map((o) => o[names.selectedId]));
  }, [selectedData, propsData]);

  useEffect(() => {
    if (propsData) setData(propsData);
  }, [propsData]);

  const [searchInput, setSearchInput] = useState("");

  const searchData = (searchParam) => {
    return propsData.filter((o) =>
      o[names.value]
        ?.toLocaleLowerCase()
        .includes(searchParam.toLocaleLowerCase())
    );
  };

  const searchOnChange = async (e) => {
    const inputVal = e.target.value;
    let result = searchData(inputVal);

    if ((result.length === 0 && inputVal.includes(" ")) || inputVal === " ")
      return;

    setSearchInput(inputVal);
    setData(result);
  };

  return (
    <Observer
      render={() => {
        return (
          <MFormControl>
            {
              showLoading && (
                <div className={styles.inputLoading}>
                  <ComponentLoader />
                </div>
              )
            }
            <MSelect
              name={name}
              onChange={(e) => SelectsStore.checkValidation(SELELCT_ID)}
              multiple
              variant="outlined"
              MenuProps={{classes: { paper: styles.selectDropdown}}}
              disabled={showLoading || disabled || !propsData?.length}
              selelctid={SELELCT_ID}
              onClose={() => {
                setSearchInput("")
                setData(propsData);
              }}
              selectedcount={selectState?.length ?? 0}
              value={data?.length > 0 ? data ?.filter((o) => selectState.some((el) => el === o[names.id])).map((o) => o[names.value]) : selectedData?.map(o => o[names.value]) ?? []}
              renderValue={(selected) => singleSelect ? selected : selected.join(", ")}
              IconComponent={showLoading ? ()=> null : IconMdKeyboardArrowDown} 
            >
              {propsData?.length > 20 && (
                <SecondaryCustomSearch
                  classText="select-search"
                  onChange={searchOnChange}
                  input={searchInput}
                />
              )}
              {data?.length > 0 ? (
                data?.map((o, i) => (
                  <div className={styles.menuItemBox} key={i}>
                    {MenuItem(o)}
                    {(needDefault || isDoubleCheck) && SetAsDefaultComp(o)}
                  </div>
                ))
              ) : null}
            </MSelect>
            <MInputLabel>{placeholder}</MInputLabel>
          </MFormControl>
        )
      }}/>
  );
};

export default MultipleSelect;