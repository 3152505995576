import { createContext } from "react";
import { makeAutoObservable } from "mobx";

import { MToast } from "shared-modules/ui-lib-module";

import { TypeExtentionStore } from "../typeExtenstionMethods";

const typeExtentionStore = new TypeExtentionStore();

export const ValidatorExtenstions = class {

  static moreThanZero = function (propedValue) {
    if(!propedValue || propedValue === "" || propedValue === " ") return true;
    if(typeExtentionStore.isNumber(propedValue)) propedValue = propedValue.toString();

    if(propedValue.charAt(0) === "0" && propedValue.charAt(1) !== ".") return false;
      let value = Number(propedValue);
      if(isNaN(parseInt(value))) return false;
      else if(propedValue?.length  === 0) return true;
      else return value > 0;
  };

  static correctRange = (min, max) => {
    return ((value) => {
      if(!value || value === "" || value === " ") return true;

      if(Number(value) <= Number(max) && Number(value) >= Number(min)){
        return true
      } else {
        return false
      }
    })
  }

  static isEpmtyQuillEditor = (text, toastText = "descriptionIsRequeried") => {
    if(!text || text?.trim() === "" || text?.replace(/>\s+</g,'><') === "<p></p>" || text === "<p><br></p>") {
      MToast.warningToast(toastText);
      return true;
    } else {
      return false;
    }
  };

  static httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  constructor() {makeAutoObservable(this)}
};

export default createContext(new ValidatorExtenstions());