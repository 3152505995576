import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { IconMdDelete, IconMdPhotoCamera } from '../../../icons/components';

import styles from './styles.module.css';
import Preview from './preview';
import {
  convertMgToBytes,
  createKeyValueObjectFromArray,
  fetchAndDownloadFile,
  filterById,
  createFileObjectWithId,
  getErrorCode,
} from './utils';
import { MButton, MTextValidator } from '../../../material';
import { MToast } from '../Toast';
import { useTranslations } from 'shared-modules/translation-module';

const useFiles = ({ maxFiles, onFilesChange, urls }) => {
  const [files, setFiles] = useState([]);

  const updateFiles = (newFiles, isMultipleMode = true) => {

    if (isMultipleMode) {
      setFiles((prevFiles) => {
        const newState = [...prevFiles, ...newFiles];
        onFilesChange(newState)
        return newState;
      });
    } else {
      setFiles(newFiles);
      onFilesChange(newFiles);
    }
  };

  const limitAcceptedFiles = (acceptedFiles) => {
    if (maxFiles) {
      const remainingFileCount = maxFiles - files.length;

      if (remainingFileCount > 0) {
        return acceptedFiles.splice(0, remainingFileCount);
      }

      return acceptedFiles;
    }

    return acceptedFiles;
  };

  const downloadFiles = async (urls) => {
    if (!urls) return;

    const downloadedFiles = await Promise.all(urls.map(fetchAndDownloadFile));

    updateFiles(downloadedFiles);
  };

  const deleteFile = (fileId) => {
    setFiles((files) => {
      const filteredFiles = filterById(files, fileId);
      onFilesChange(filteredFiles);
      return filteredFiles;
    });
  };

  const getCurrentIndex = (items = [], index) => {
    if (items?.length < 1) {
      return ++index;
    } else {
      return items[items.length - 1]?.sortIndex + 1;
    }
  };

  return {
    files,
    setFiles,
    updateFiles,
    limitAcceptedFiles,
    downloadFiles,
    deleteFile,
    getCurrentIndex,
  };
};

export function FileObjectUploader({
  title,
  subTitle,
  icon: Icon = IconMdPhotoCamera,
  urls,
  isMultiple = false,
  acceptFileTypes = ['image/*'],
  maxFiles = 0,
  maxFileSize = 5,
  isInputRequired,
  inputValue,
  isInputRequiredIfFileExists,
  inputValueChange,
  inputPlaceholder = '',
  hasInput = false,
  isDeleteBtnShown,
  isReorderDisabled = true,
  isFullWidth = false,
  isRequired = false,
  isDisabled = false,
  isInputDisabled = false,
  onReorder,
  onImageRemove,
  onDeleteBtnClick,
  onFilesChange,
}) {
  const { t } = useTranslations();
  const isDownloaded = useRef(false);
  const { files, setFiles, updateFiles, limitAcceptedFiles, downloadFiles, deleteFile, getCurrentIndex } =
    useFiles({ maxFiles, onFilesChange, urls });

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: createKeyValueObjectFromArray(acceptFileTypes),
    noClick: true,
    noKeyboard: true,
    multiple: isMultiple,
    maxFiles,
    disabled: isDisabled,
    maxSize: convertMgToBytes(maxFileSize) || Infinity,
    onDropAccepted: (acceptedFiles) => {
      const limited = limitAcceptedFiles(acceptedFiles);

      const newFiles = limited.map((f, i) =>
        createFileObjectWithId({ file: f, sortIndex: getCurrentIndex(files, i) })
      );

      updateFiles(newFiles, isMultiple);
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = getErrorCode(rejectedFiles);
      MToast.errorToast(errorMessage);
    },
  });

  const handelDelete = (fileId, isFromServer) => {
    deleteFile(fileId);
    onImageRemove?.(fileId, isFromServer);
  };

  const hasOnlyStrings = (urls) => {
    return urls.every(({ file }) => typeof file === 'string');
  };

  useEffect(() => {
    if (!isDownloaded.current && urls?.length) {
      // if (hasOnlyStrings(urls)) {
        isDownloaded.current = true;
        downloadFiles(urls);
      // }
    }
  }, []);

  // useEffect(() => {
  //   onFilesChange?.(files);
  // }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const isTextRequired = isInputRequired ? true : isInputRequiredIfFileExists ? !!files.length : false;

  const isMaxFilesUploaded = !!(maxFiles && files.length >= maxFiles);
  const isDropzoneVisible = isMultiple ? !isMaxFilesUploaded : !files.length;
  const showRequiredAlert = !!(isRequired && files.length === 0);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission when Enter is pressed
    }
  };

  return (
    <div
      className={`
      ${styles.imageUploadBox} 
      ${isMultiple ? styles.multiple : ''} 
      ${isFullWidth ? styles.fullWidth : ''}
      `}
    >
      <UploaderHeader title={title} subTitle={subTitle} icon={<Icon className={styles.uploadIcon} />} />
      {hasInput && (
        <MTextValidator
          className={styles.titleInput}
          autoComplete="off"
          variant="outlined"
          id={'imageDesc'}
          name="key"
          type="text"
          disabled={isInputDisabled}
          // useValueFromProps={false}
          onKeyPress={handleKeyPress}
          validators={isTextRequired ? ['required'] : []}
          errorMessages={isTextRequired ? ['this field is required'] : []}
          value={inputValue}
          label={inputPlaceholder ?? t['typeHere']}
          onChange={inputValueChange}
        />
      )}
      <div {...getRootProps({ className: 'dropzone' })}>
        <input
          {...getInputProps({
            required: showRequiredAlert,
            className: 'dropzone-input',
          })}
        />
        <DropZone isVisible={isDropzoneVisible} onClick={open} />
      </div>
      <Preview
        files={files}
        setFiles={setFiles}
        handelDelete={handelDelete}
        onReorder={onReorder}
        // onDragEnd={onDragEnd}
        isSingleFile={!isMultiple}
        isDisabled={isDisabled}
        isReorderDisabled={!isMultiple || isReorderDisabled}
      />
      {isDeleteBtnShown && (
        <div className="d-flex justify-content-end">
          <MButton className={`${styles.deleteButton} f-bold transparent`} onClick={onDeleteBtnClick}>
            <IconMdDelete />
            {t['delete']}
          </MButton>
        </div>
      )}
    </div>
  );
}

export default FileObjectUploader;

const UploaderHeader = ({ title, subTitle, icon }) => {
  return (
    <div className="d-flex align-items-center">
      {icon}
      <div>
        {title && <div className={`${styles.title} f-bold`}>{title}</div>}
        {subTitle && <div className={`${styles.subTitle}`}>{subTitle}</div>}
      </div>
    </div>
  );
};

const DropZone = ({ isVisible, onClick }) => {
  if (!isVisible) return <></>;

  return (
    <div className={styles.dropZone} onClick={onClick}>
      <label className={styles.plus} />
    </div>
  );
};
