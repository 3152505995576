import { useEffect, useState } from 'react';

export const useSearchQuerySize = (onEmptyQuery = () => {}) => {
  let query = new URLSearchParams(window.location.search);
  const [querySize, setQuerySize] = useState(query.size);

  //This executes when the search query is empty and does not match the size of the previous query size
  useEffect(() => {
    if (query.size === 0 && querySize !== query.size) {
      onEmptyQuery();
    }
    // sets new query size
    setQuerySize(query.size);
  }, [query]);
};
