import React, { useState } from 'react';
import Chart from '../../Chart';
import PageLoader from '../../PageLoader';
import IconMdKeyboardArrowDown from '../../../../icons/components/MdKeyboardArrowDown';
import { MMenu, MMenuItem } from '../../../../material';
import { useTranslations } from 'shared-modules/translation-module';

import styles from '../styles.module.css';

export const AreaChartWidget = ({
  loading,
  labels,
  data,
  symbol,
  colors,
  widgetMainTitle,
  widgetMainSubTitle,
  widgetLargeText,
  currencies,
  currentCurrency,
  handleCurrencyChange,
}) => {
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeCurrencyItemState, setActiveCurrencyItemState] = useState(0);

  const series = [
    {
      data: data,
    },
  ];

  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    plotOptions: {
      area: {
        expandOnClick: false,
      },
    },
    xaxis: {
      crosshairs: {
        width: 0,
      },
    },
    stroke: { curve: 'smooth', width: 2 },
    markers: { size: 0, style: 'hollow' },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        let data = `<div class="apex-chart-tooltip">${labels ? labels[dataPointIndex] + ':' : ''} ${
          series[seriesIndex][dataPointIndex]
        } ${symbol ? symbol : ''}</div>`;
        return data;
      },
    },
    colors: colors ?? [],
    fill: {
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  };

  return (
    <div className={`${styles.widgetBox} ${styles.areaChartWidget}`}>
      <PageLoader isLoading={loading}>
        <div className={styles.top}>
          {widgetMainTitle && <div className={`${styles.widgetMainTitle}`}>{widgetMainTitle}</div>}
          {currencies && currencies.length > 1 && (
            <React.Fragment>
              <div
                className={styles.currency}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <div className={styles.currencyVal}>{currentCurrency}</div>
                <IconMdKeyboardArrowDown
                  className={`${styles.currencyArrow} ${Boolean(anchorEl) ? styles.visible : ''}`}
                />
              </div>
              <MMenu
                className="widget-currency-dropdown"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
              >
                {currencies?.map((o, index) => {
                  return (
                    <MMenuItem
                      className={`widget-currency-dropdown-item ${
                        activeCurrencyItemState == index ? 'active' : ''
                      }`}
                      key={`${o.id}-${index}`}
                      value={o.id}
                      onClick={() => {
                        setActiveCurrencyItemState(index);
                        handleCurrencyChange(o.id);
                        setAnchorEl(null);
                      }}
                    >
                      {o.currencySymbol}
                    </MMenuItem>
                  );
                })}
              </MMenu>
            </React.Fragment>
          )}
        </div>
        {data.length > 0 ? (
          <div className={styles.widgetContent}>
            <div className={styles.left}>
              {widgetLargeText && <div className={`${styles.widgetLargeText} f-bold`}>{widgetLargeText}</div>}
              {widgetMainSubTitle && (
                <div className={`${styles.widgetMainSubTitle}`}>{widgetMainSubTitle}</div>
              )}
            </div>
            <div className={styles.right}>
              <div className={styles.chartBox}>
                <Chart options={options} series={series} type="area" height="100%" width="100%" />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noData}>{t['widget-no-data']}</div>
        )}
      </PageLoader>
    </div>
  );
};
export default AreaChartWidget;
