import React, { useState } from "react";
import { Observer } from "mobx-react";
import {
  MMenuItem,
  MCheckbox,
  MFormControlLabel,
  MMenu,
} from "../../../material/components";
import {
  IconMdKeyboardArrowDown
} from "../../../icons/components";
import styles from "./styles.module.css";

export const TableSingleSelect = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null)
  };

  const handleItemClick = (id) => {
    props.onChange(id)
    onClose()
  };

  return (
    <Observer render={()=> (
    <>
      <div className={`${styles.tableSelect} ${props.className} ${props.disabled ? styles.disabled : ""}`} aria-controls="simple-menu" aria-haspopup="true" onClick={onOpen}>
        <div className={styles.colorCircle} style={{backgroundColor: props.propsData.find((o) => o.id === props.checkedId)?.color}}></div>
        <div className={styles.label}>{props.propsData.find((o) => o.id === props.checkedId)?.name}</div>
        <IconMdKeyboardArrowDown className={`${styles.arrow} ${anchorEl && styles.active}`}/>
      </div>
      <MMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {props.propsData.map((o)=> (
          <MMenuItem onClick={()=> handleItemClick(o.id)}>
            <MFormControlLabel
              className={styles.checkbox}
              style={{ pointerEvents: "none" }}
              control={
                <MCheckbox
                  checked={props.checkedId === o?.id}
                  className="tonal medium"
                />
              }
              label={o?.name}
            />
          </MMenuItem>
        ))}
      </MMenu>
    </>
    )}/>
  );
};

export default TableSingleSelect;
