import React, { useState } from "react";
import {
  MCollapse,
} from "../../../material/components";
import {IconMdKeyboardArrowDown } from "../../../icons/components";
import styles from "./styles.module.css";
import { MTransitionGroup } from "../../../transitions";
import { Observer } from "mobx-react";

export const CustomExpandConteiner = ({
  children, 
  title = "", 
  defaultIsExspanded = true, 
  openCloseFunction = ()=>{}
}) => {
  const [isExspanded, setIsExspanded] = useState(defaultIsExspanded)
  const toggleExpand = () => {
    setIsExspanded(prev => !prev)
    openCloseFunction()
  } 
  return (
    <MTransitionGroup>
      <Observer render={()=>(
        <div  className={styles.sectionContainer}>
          <div className="d-flex justify-content-between">
              <div className={`${styles.colTitle} ${styles.removedMargin}`}>{title}</div>
              <IconMdKeyboardArrowDown 
                className={`flex-shrink-0 ${styles.dropdownArrow} ${isExspanded ? styles.rotateDropDownArrow : ""}`} 
                onClick={toggleExpand}
              />
          </div>
          <MTransitionGroup>
            {
              isExspanded && (
                <MCollapse key={1} timeout={400}>
                  {
                    children
                  }
                </MCollapse>
              )
            }
          </MTransitionGroup>
        </div>
      )}/>
    </MTransitionGroup>
  );
};

export default CustomExpandConteiner;
