//create languages for logs module
let data = {
    "id": "Id",
    "name": "სახელი",
    "email": "მეილი",
    "action": "მოქმედება",
    "history": "ისტორია",
    "type": "ტიპი",
    "date": "თარიღი",
}

export default data;