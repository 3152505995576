import React, { useState } from 'react';
import { IconMdMoreVert } from '../../../icons';
import { MMenu, MMenuItem } from '../../../material/components';

import {
  DetailsButton,
  EditButton,
  DeleteButton,
  RetryButton,
  DownloadButton,
  RefundButton,
  PowerOffButton,
  SendButton,
  CencelButton,
} from '../';
import styles from './styles.module.css';
import ArchiveButton from '../ArchiveButton';
import PreviewButton from '../PreviewButton';
import AnalyticButton from '../AnalyticButton';

export const RespActionsButtons = ({
  id,
  detailsButton,
  disableDetailsButton,
  hideDetailsButton,
  editButton,
  disableEditButton,
  hideEditButton,
  sendButton,
  disableSendButton,
  hideSendButton,
  deleteButton,
  disableDeleteButton,
  hideDeleteButton,
  retryButton,
  disableRetryButton,
  hideRetryButton,
  downloadButton,
  refundButton,
  analyticButton,
  powerOffButton,
  hidePowerOffButton,
  disablePowerOffButton,
  cencelButton,
  hideCencelButton,
  disableCencelButton,
  disableAnaliticButton,
  hideAnaliticButton,
  disableRefundButton,
  hideRefundButton,
  disableDownloadButton,
  hideDownloadButton,
  archiveButton,
  disableArchiveButton,
  hideArchiveButton,
  previewButton,
  disablePreviewButton,
  hidePreviewButton,
  isActive,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconMdMoreVert className="custom-icon" aria-describedby={id} onClick={handleClick} />
      <MMenu
        className="resp-table-actions-menu"
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        {refundButton && (
          <MMenuItem>
            <RefundButton
              label="refund"
              onClick={refundButton.onClick}
              disabled={refundButton.disabled || disableRefundButton}
              hide={refundButton.hide || hideRefundButton}
            />
          </MMenuItem>
        )}
        {downloadButton && (
          <MMenuItem>
            <DownloadButton
              label="download"
              onClick={downloadButton.onClick}
              disabled={downloadButton.disabled || disableDownloadButton}
              hide={downloadButton.hide || hideDownloadButton}
            />
          </MMenuItem>
        )}
        {sendButton && (
          <MMenuItem>
            <SendButton
              label="send"
              onClick={sendButton.onClick}
              disabled={sendButton.disabled || disableSendButton}
              hide={sendButton.hide || hideSendButton}
            />
          </MMenuItem>
        )}
        {previewButton && (
          <MMenuItem>
            <PreviewButton
              label="preview"
              onClick={previewButton.onClick}
              disabled={previewButton.disabled || disablePreviewButton}
              hide={previewButton.hide || hidePreviewButton}
            />
          </MMenuItem>
        )}
        {analyticButton && (
          <MMenuItem>
            <AnalyticButton
              label="analytic"
              onClick={analyticButton.onClick}
              disabled={analyticButton.disabled || disableAnaliticButton}
              hide={analyticButton.hide || hideAnaliticButton}
            />
          </MMenuItem>
        )}
        {archiveButton && (
          <MMenuItem>
            <ArchiveButton
              label="archive"
              onClick={archiveButton.onClick}
              disabled={archiveButton.disabled || disableArchiveButton}
              hide={archiveButton.hide || hideArchiveButton}
            />
          </MMenuItem>
        )}
        {retryButton && (
          <MMenuItem>
            <RetryButton
              label="retry"
              onClick={retryButton.onClick}
              disabled={retryButton.disabled || disableRetryButton}
              hide={retryButton.hide || hideRetryButton}
            />
          </MMenuItem>
        )}
        {detailsButton && (
          <MMenuItem>
            <DetailsButton
              label="details"
              onClick={detailsButton.onClick}
              disabled={detailsButton.disabled || disableDetailsButton}
              hide={detailsButton.hide || hideDetailsButton}
            />
          </MMenuItem>
        )}
        {editButton && (
          <MMenuItem>
            <EditButton
              label="edit"
              onClick={editButton.onClick}
              disabled={editButton.disabled || disableEditButton}
              hide={editButton.hide || hideEditButton}
            />
          </MMenuItem>
        )}
        {powerOffButton && (
          <MMenuItem>
            <PowerOffButton
              label={isActive ? 'deactivate' : 'activate'}
              onClick={powerOffButton.onClick}
              disabled={powerOffButton.disabled || disablePowerOffButton}
              hide={powerOffButton.hide || hidePowerOffButton}
            />
          </MMenuItem>
        )}
        {cencelButton && (
          <MMenuItem>
            <CencelButton
              label="cencel"
              onClick={cencelButton.onClick}
              disabled={cencelButton.disabled || disableCencelButton}
              hide={cencelButton.hide || hideCencelButton}
            />
          </MMenuItem>
        )}
        {deleteButton && (
          <MMenuItem>
            <DeleteButton
              label="delete"
              onClick={deleteButton.onClick}
              disabled={deleteButton.disabled || disableDeleteButton}
              hide={deleteButton.hide || hideDeleteButton}
            />
          </MMenuItem>
        )}
      </MMenu>
    </React.Fragment>
  );
};

export default RespActionsButtons;
