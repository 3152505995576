import React from "react";
import { MFormControlLabel, MRadio } from "../../../material/components";
import styles from "./styles.module.css";

export const RadioButton = (props) => {
  return (
    <div className={`${styles.radioButton} salespointTypeRadio`}>
      <MFormControlLabel
        value={props.value ?? ""}
        control={<MRadio className="tonal" />}
        label={props.label ?? ""}
        disabled={props.disabled ?? false}
        {...props}
      />
    </div>
  );
};

export default RadioButton;
