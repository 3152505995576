import React from 'react';
import { IconMdErrorOutline, MButton } from 'shared-modules/ui-lib-module';
import moment from 'moment';
import { mobxRouting } from "shared-modules/url-helper-module";

const NotificationPopup = ({ closeToast = () => {}}) => {

    return (
        <>
            <div className="top d-flex align-items-center">
                {
                    NotificationPopup.notificationItem?.SenderUser ?
                    <div className="img-box initials f-bold">{NotificationPopup.notificationItem?.SenderUser?.Name[0]}{NotificationPopup.notificationItem?.SenderUser?.LastName[0]}</div>
                    : <IconMdErrorOutline className="img-box icon" />
                }
                <div className="name-and-date-box d-flex align-items-center justify-content-between">
                    {
                        NotificationPopup.notificationItem?.SenderUser ?
                        <h3 className="name f-bold">{NotificationPopup.notificationItem?.SenderUser?.Name} {NotificationPopup.notificationItem?.SenderUser?.LastName}</h3>
                        :<h3 className="name f-bold">System Notification</h3>
                    }
                    <div className="date">{moment.utc(NotificationPopup.notificationItem?.SendDate ?? '').local().format('DD.MM.YYYY / h:mma')}</div>
                </div>
            </div>
            <div className="line"></div>
            <div className="info-box">
                <h4 className="title f-bold">{NotificationPopup.notificationItem?.Message?.Title}</h4>
                <div className="text">
                {NotificationPopup.notificationItem?.Message?.ShortDescription}
                </div>
                <div className="d-flex justify-content-end">
                    <MButton onClick={closeToast} className="close-btn f-bold transparent">Close</MButton>
                    <MButton onClick={() => {
                        NotificationPopup.notificationsUIContext.closeNotification();
                        NotificationPopup.notificationsUIContext.openNotification(NotificationPopup.notificationForRead)
                        mobxRouting.push(`/core/notifications-list`);
                        closeToast();
                    }} className="more-btn f-bold tonal rounded">Read More</MButton>
                </div>
            </div>
        </>
    )
}

export default NotificationPopup;