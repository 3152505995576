import React, { useEffect, useState } from 'react';
import PageLoader from '../../PageLoader';
import { MButton, MDialog, MDialogActions, MDialogContent, MDialogTitle } from '../../../../material';
import { useTranslations } from 'shared-modules/translation-module';

import styles from '../styles.module.css';
import { Observer } from 'mobx-react';
import { IconMdLocalCafe } from '../../../../icons';

const ListItem = ({ name, hasImage, disableImage, imageUrl, count, percentage }) => {
  return (
    <Observer
      render={() => (
        <div className={styles.listItem}>
          {!disableImage && (
            <>
              {imageUrl ? (
                <img className={styles.image} src={imageUrl} />
              ) : (
                <IconMdLocalCafe className={`${styles.image} icon`} />
              )}
            </>
          )}

          <div className={styles.middleContent}>
            <div className={styles.name}>{name}</div>
            <div className={styles.progressBar} style={{ width: `${percentage}%` }}></div>
          </div>
          <div className={`${styles.count} f-bold`}>{count}</div>
        </div>
      )}
    />
  );
};

const ListModal = ({ data = [], open = false, disableImage, closeHandler = () => {}, title }) => {
  const { t } = useTranslations();
  const [total, settotal] = useState(0);

  useEffect(() => {
    let totalCount = 0;
    if (data?.length) {
      totalCount = data?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue?.count;
      }, 0);
    }

    settotal(totalCount);
  }, [data]);

  return (
    <Observer
      render={() => (
        <MDialog
          open={open}
          onClose={() => {
            closeHandler();
          }}
          className="large"
        >
          <MDialogTitle>
            <div className="dialog-main-title">{title}</div>
          </MDialogTitle>
          <PageLoader isLoading={false}>
            <MDialogContent>
              <div className={styles.listContainer}>
                {data?.map((o, index) => {
                  const percentage = total !== 0 ? (o.count / total) * 100 : 0;

                  return (
                    <ListItem
                      key={index}
                      name={o.name}
                      imageUrl={o.imageUrl}
                      disableImage={disableImage}
                      count={o.count}
                      percentage={percentage}
                    />
                  );
                })}
              </div>
            </MDialogContent>
            <MDialogActions>
              <MButton className="f-bold rounded" onClick={closeHandler}>
                {t['close']}
              </MButton>
            </MDialogActions>
          </PageLoader>
        </MDialog>
      )}
    />
  );
};

export const ListWidget = ({
  loading,
  data = [],
  hasImage = false,
  disableImage = false,
  title,
  showCount = 3,
  heightAuto = false,
}) => {
  const { t } = useTranslations();

  const [total, settotal] = useState(0);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    let totalCount = 0;
    if (data?.length) {
      totalCount = data?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue?.count;
      }, 0);
    }

    settotal(totalCount);
  }, [data]);

  return (
    <Observer
      render={() => (
        <>
          <div
            style={heightAuto ? { height: 'auto' } : null}
            className={`${styles.widgetBox} ${styles.pieChartWidget}`}
          >
            <PageLoader isLoading={loading}>
              {data?.length ? (
                <>
                  <div className={styles.topBar}>
                    <div className={styles.listWidgetTitle}>{title}</div>{' '}
                    <div className={styles.detailsText} onClick={() => setOpen(true)}>
                      {t['seeAll']}
                    </div>
                  </div>

                  <div className={styles.listContainer}>
                    {data?.slice(0, showCount)?.map((o, index) => {
                      const percentage = total !== 0 ? (o.count / total) * 100 : 0;

                      return (
                        <ListItem
                          key={index}
                          name={o.name}
                          imageUrl={o.imageUrl}
                          hasImage={hasImage}
                          disableImage={disableImage}
                          count={o.count}
                          percentage={percentage}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.topBar}>
                    <div>{title}</div>
                  </div>
                  <div className={styles.top}></div>
                  <div className={styles.noData}>{t['widget-no-data']}</div>
                </>
              )}
            </PageLoader>
          </div>

          <ListModal
            open={open}
            closeHandler={() => setOpen(false)}
            data={data}
            hasImage={hasImage}
            disableImage={disableImage}
            title={title}
          />
        </>
      )}
    />
  );
};
export default ListWidget;
