import React from "react";
import { useTranslations } from "shared-modules/translation-module";
import { MPagination } from "../../../../material/components";
import TableViewPerPageComponent from "./TableViewPerPageComponent";
import styles from "../styles.module.css";

export const TableBottomComponent = ({ totalCount, pagesCount, currentPage, onPageChange, handleRowPerPageChange, rowPerPageCount }) => {
  const { t } = useTranslations();
  
  return (
    <div className={styles.tableBottom}>
      <div className="row">
        <div className="col-4 d-flex d-md-none align-items-center">
          {totalCount && <div className={`${styles.resultsCountText} f-bold`}>{totalCount} {t["entries-in-the-list"]}</div>}
        </div>
        {
          totalCount > 10 &&
          <div className="col-8 col-md-12 d-flex d-sm-block align-items-center justify-content-end justify-content-xs-center">
            {(rowPerPageCount && handleRowPerPageChange) && <TableViewPerPageComponent rowPerPageCount={rowPerPageCount} handleRowPerPageChange={handleRowPerPageChange} />}
            {rowPerPageCount < totalCount && <MPagination className="f-bold" page={currentPage} count={pagesCount} onChange={onPageChange} />}
          </div> 
        }
      </div>
    </div>
  );
};

export default TableBottomComponent;
