import React, { useContext, useEffect, useState } from 'react';
import { Observer } from 'mobx-react';

import { EcommerceLanguageStore } from 'shared-modules/url-helper-module';
import { useTranslations } from 'shared-modules/translation-module';

import { MFormControlLabel, MCheckbox, MTextValidator, MValidatorForm } from '../../../material';

import styles from './styles.module.css';
import AddMarckupModal from './AddMarckupModal';
import FileUploader from '../FileUploader';
import EditButton from '../EditButton';
import CustomExpandConteiner from '../CustomExpandContainer';

let initialSeoData = {
  noIndex: false, //bool
  noFollow: false, //bool
  image: null, //base64
  shouldDeleteSocialShareImage: false, //bool
  hreflang: [], //{languageCode: "", hreflangUrl: ""}
  slug: '', //string
  title: '', //string
  seoDescription: '', //string
  socialDescription: '', //string
  canonical: '', //string
  structedDataMarkup: '', //string - json
  socialTitle: '', //string
  description: '', //string
  ogURL: '', //string
  ogLocal: '', //string
  ogType: '', //string
  ogSiteName: '', //string
  ogAltName: '', //string
  ogSite: '', //string
  ogHandle: '', //string
  ogCartType: '', //string
};

export const Seo = ({ onDataChange = () => {}, data = initialSeoData, isDisabled = false }) => {
  const ecommerceLanguageContext = useContext(EcommerceLanguageStore);
  const { t } = useTranslations();

  const [openDataMarkUpModal, setOpenDataMarkUpModal] = useState(false);

  useEffect(() => {
    onDataChange(data);

    MValidatorForm.addValidationRule('slugValidation', (value) => {
      const regEx = /^[A-Za-z0-9-!@#$%^&*()_+=-~\][{}|';:.,?><]*$/;
      return regEx.test(value);
    });
    MValidatorForm.addValidationRule('seoValidation', (value) => {
      const regEx = /^[A-Za-z0-9-!@#$%^&*()_+=-~\][{}|';:.,?></]*$/;
      return regEx.test(value);
    });
    return () => {
      MValidatorForm.removeValidationRule('slugValidation');
      MValidatorForm.removeValidationRule('seoValidation');
    };
  }, [data]);

  //methods
  const seoInputsOnchange = (e) => {
    e.persist();
    data[e.target?.name] = e.target?.value;
  };
  const setSeoHrefFlangUrl = (e, currentLanguageCode) => {
    let currentEl = data.hreflang?.find((x) => x.languageCode === currentLanguageCode);
    let currentIndex = data.hreflang?.indexOf(currentEl);
    if (currentIndex > -1) {
      data.hreflang[currentIndex].hreflangUrl = e.target.value;
    } else {
      data.hreflang?.push({
        languageCode: currentLanguageCode,
        hreflangUrl: e.target.value,
      });
    }
  };

  const isDetails = window.location.pathname.includes('details');

  return (
    <Observer
      render={() => (
        <>
          <CustomExpandConteiner title="SEO">
            <div className={`${styles.title}`}>SEO {t['basics']}</div>
            <div className={`${styles.innerBox} row`}>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="slug"
                      validators={['required', 'slugValidation']}
                      errorMessages={[t['thisFieldIsRequired'], t['specifyTheSlugInCorrectFormat']]}
                      label={'Slug'}
                      disabled={isDetails || isDisabled}
                      value={data.slug}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>

              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="title"
                      label={'Title'}
                      disabled={isDetails || isDisabled}
                      value={data.title}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>

              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="description"
                      label={'Seo Description'}
                      disabled={isDetails || isDisabled}
                      value={data.description}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>

              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="canonical"
                      name="canonical"
                      label={'Canonical'}
                      disabled={isDetails || isDisabled}
                      value={data.canonical}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
              <Observer
                render={() =>
                  ecommerceLanguageContext.allLanguages?.map((o) =>
                    o.languageCode !== ecommerceLanguageContext.currentLanugage ? (
                      <React.Fragment key={o?.languageCode}>
                        <div className={`col-6 col-sm-12`}>
                          <Observer
                            render={() => (
                              <MTextValidator
                                type="Hreflang"
                                name="hreflang"
                                label={'Hreflang (' + o?.languageCode + ')' ?? ''}
                                disabled={isDetails || isDisabled}
                                validators={['required', 'seoValidation']}
                                errorMessages={[t['thisFieldIsRequired'], t['specifyTheSeoInCorrectFormat']]}
                                value={
                                  data.hreflang?.find(
                                    (x) => x.languageCode?.toLowerCase() === o?.languageCode?.toLowerCase()
                                  )?.hreflangUrl ?? ''
                                }
                                autoComplete="off"
                                onChange={(e) => setSeoHrefFlangUrl(e, o.languageCode)}
                              />
                            )}
                          />
                        </div>
                      </React.Fragment>
                    ) : null
                  )
                }
              />
            </div>

            <div className={styles.innerBox}>
              <div className={`${styles.title}`}>Seo {t['advanced']}</div>
              <div className="d-flex align-items-center">
                <label className={styles.subTitle}>Structured Data Markup</label>
                <EditButton disabled={isDetails || isDisabled} onClick={() => setOpenDataMarkUpModal(true)} />
              </div>
            </div>

            {ecommerceLanguageContext.isDefaultTab && (
              <div className={styles.innerBox}>
                <div className={`${styles.title}`}>Robots Meta Tag</div>
                <div className={`${styles.checkboxContainer}`}>
                  <Observer
                    render={() => (
                      <MFormControlLabel
                        control={
                          <MCheckbox
                            className="tonal"
                            name="noindex"
                            disabled={isDetails || isDisabled}
                            checked={data.noIndex}
                            onClick={() => (data.noIndex = !data.noIndex)}
                          />
                        }
                        label="noindex"
                      />
                    )}
                  />
                </div>
                <div className={`${styles.checkboxContainer}`}>
                  <Observer
                    render={() => (
                      <MFormControlLabel
                        control={
                          <MCheckbox
                            className="tonal"
                            name="nofollow"
                            disabled={isDetails || isDisabled}
                            checked={data.noFollow}
                            onClick={() => (data.noFollow = !data.noFollow)}
                          />
                        }
                        label="nofollow"
                      />
                    )}
                  />
                </div>

                <div className={styles.innerBox}>
                  <div className={`${styles.title}`}>Social Share</div>
                  <FileUploader
                    title={t['uploadPhoto']}
                    subTitle={`${t['recomendedSize']} 676x540`}
                    maxFileSize={1}
                    isDisabled={isDetails || isDisabled}
                    urls={data?.imageUrl ? [{ file: data?.imageUrl }] : []}
                    onImageRemove={() => (data.shouldDeleteSocialShareImage = true)}
                    onFilesChange={(files) => {
                      if (files?.length > 0) {
                        !files[0]?.isFromServer && (data.image = files[0]?.file);
                      }
                    }}
                  />
                </div>
              </div>
            )}

            <div className={`${styles.innerBox} row`}>
              <div className={`col-12`}>
                <div className={`${styles.title}`}>OpenGraph</div>
              </div>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="socialDescription"
                      label={'socialDescription'}
                      disabled={isDetails || isDisabled}
                      value={data.socialDescription}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="ogURL"
                      label={'ogURL'}
                      disabled={isDetails || isDisabled}
                      value={data.ogURL}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="ogLocal"
                      label={'ogLocal'}
                      disabled={isDetails || isDisabled}
                      value={data.ogLocal}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="ogType"
                      label={'ogType'}
                      disabled={isDetails || isDisabled}
                      value={data.ogType}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="ogSiteName"
                      label={'ogSiteName'}
                      disabled={isDetails || isDisabled}
                      value={data.ogSiteName}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
              <div className={`col-6 col-sm-12`}>
                <Observer
                  render={() => (
                    <MTextValidator
                      type="text"
                      name="ogAltName"
                      label={'ogAltName'}
                      disabled={isDetails || isDisabled}
                      value={data.ogAltName}
                      autoComplete="off"
                      onChange={seoInputsOnchange}
                    />
                  )}
                />
              </div>
            </div>

            {ecommerceLanguageContext.isDefaultTab && (
              <div className={`${styles.innerBox} row`}>
                <div className={`col-12`}>
                  <div className={`${styles.title}`}>Twitter</div>
                </div>
                <div className={`col-6 col-sm-12`}>
                  <Observer
                    render={() => (
                      <MTextValidator
                        type="text"
                        name="ogHandle"
                        label={'ogHandle'}
                        disabled={isDetails || isDisabled}
                        value={data.ogHandle}
                        autoComplete="off"
                        onChange={seoInputsOnchange}
                      />
                    )}
                  />
                </div>
                <div className={`col-6 col-sm-12`}>
                  <Observer
                    render={() => (
                      <MTextValidator
                        type="text"
                        name="ogSite"
                        label={'ogSite'}
                        disabled={isDetails || isDisabled}
                        value={data.ogSite}
                        autoComplete="off"
                        onChange={seoInputsOnchange}
                      />
                    )}
                  />
                </div>
                <div className={`col-6 col-sm-12`}>
                  <Observer
                    render={() => (
                      <MTextValidator
                        type="text"
                        name="ogCartType"
                        label={'ogCartType'}
                        disabled={isDetails || isDisabled}
                        value={data.ogCartType}
                        autoComplete="off"
                        onChange={seoInputsOnchange}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </CustomExpandConteiner>

          <AddMarckupModal
            disabled={false}
            open={openDataMarkUpModal}
            close={() => setOpenDataMarkUpModal(false)}
            data={data.structedDataMarkup}
            saveHandler={(value) => (data.structedDataMarkup = value)}
          />
        </>
      )}
    />
  );
};

export default Seo;
