import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { useTranslations } from 'shared-modules/translation-module';

import {
  MFormControl,
  MInputLabel,
  MSelect,
  MMenuItem,
  MCheckbox,
  MFormControlLabel,
} from '../../../../material/components';
import { SecondaryCustomSearch } from '../../../../custom/components';
import { ComponentLoader } from '../../ComponentLoader';
import { IconMdKeyboardArrowDown } from '../../../../icons/components';
import styles from '../styles.module.css';
import { SelectsStore } from '../store';

export const SingleSelect = ({
  placeholder,
  helperText,
  handleSingleSelectChange,
  propsData,
  selectedData = undefined,
  disabledItemIds = [],
  disabled,
  ItemRerenderKey,
  names = { id: 'id', value: 'name' },
  showLoading = false,
}) => {
  const { t } = useTranslations();

  //select unique ID
  const [SELELCT_ID, setSELELCT_ID] = useState(uuidv4());

  const [opneSelect, setOpneSelect] = useState(false);
  const [selectState, setSelectState] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [data, setData] = useState(propsData);

  const selectChange = (data) => {
    setOpneSelect(false);

    if (data[names.id] === selectState[names.id]) {
      setSelectState({});
      handleSingleSelectChange({});
      return;
    }

    handleSingleSelectChange(data);
    setSelectState(data);
  };

  const searchData = (searchParam) => {
    return propsData.filter((o) => {
      if (!searchParam || searchParam === '') return true;
      if (isNaN(Number(o[names.value])))
        return o[names.value]?.toLowerCase()?.includes(searchParam.toLowerCase());
      else return o[names.value] === Number(searchParam);
    });
  };

  const searchOnChange = async (e) => {
    const inputVal = e.target.value;
    let result = searchData(inputVal);

    if ((result.length === 0 && inputVal.includes(' ')) || inputVal === ' ') return;
    setSearchInput(inputVal);
    setData(result);
  };

  useEffect(() => {
    if (selectedData !== undefined && selectedData) setSelectState(selectedData);
  }, [selectedData]);

  useEffect(() => {
    if (propsData !== undefined && propsData) setData(propsData);
  }, [propsData]);

  const MenuItem = (data) => {
    return (
      <MMenuItem
        key={selectState + ItemRerenderKey}
        className={styles.textGroup}
        disabled={disabledItemIds === undefined ? false : disabledItemIds?.some((o) => o === data[names.id])}
        value={data[names.id]}
        onClick={(e) => {
          selectChange(data);
          setOpneSelect(false);
        }}
      >
        <MFormControlLabel
          className={styles.checkbox}
          style={{ pointerEvents: 'none' }}
          control={<MCheckbox checked={selectState[names.id] === data[names.id]} className="tonal medium" />}
          label={data[names.value]}
        />
        {data?.extraText ? <div className={styles.extraText}> ({data?.extraText}) </div> : ''}
      </MMenuItem>
    );
  };

  return (
    <Observer
      render={() => (
        <MFormControl>
          {showLoading && (
            <div className={styles.inputLoading}>
              <ComponentLoader />
            </div>
          )}
          <MSelect
            onChange={() => SelectsStore.checkValidation(SELELCT_ID)}
            selelctid={SELELCT_ID}
            multiple
            key={selectState}
            variant="outlined"
            disabled={showLoading || disabled || !propsData?.length}
            onClick={() => !opneSelect && setOpneSelect(true)}
            // open={opneSelect}
            selectedcount={selectState[names.value] ? 1 : 0}
            value={selectState[names.value] ? [selectState[names.value]] : []}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              classes: { paper: styles.selectDropdown },
            }}
            IconComponent={showLoading ? () => null : IconMdKeyboardArrowDown}
          >
            {propsData?.length > 20 && (
              <SecondaryCustomSearch
                classText="select-search"
                onChange={searchOnChange}
                input={searchInput}
              />
            )}
            {data?.map((o, i) => (
              <div className={styles.menuItemBox} key={i}>
                {MenuItem(o)}
              </div>
            ))}
          </MSelect>
          <MInputLabel className={`${disabled ? 'Mui-disabled' : ''}`}>{placeholder}</MInputLabel>
          <label className={styles.helperText}>{helperText}</label>
        </MFormControl>
      )}
    />
  );
};

export default SingleSelect;
