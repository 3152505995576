import React from "react";
import NavItemHeader from "./NavItemHeader";


const NavItem = (props) => {
  return (
    !(props.item.moduleKeyFromServer === 'D4tvJoI4HEmmoJ2un7LNVg' && props.item.children.length === 0) && (
      <NavItemHeader
      item={props.item}
      firstItem={props.firstItem}
      hideDrawer={props.hideDrawer}
    />
    )
  );
};

export default NavItem;
