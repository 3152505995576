import React, { useState, useContext } from "react";

import { MMenu, MMenuItem } from "../../../material";

import {
  IconMdLanguage,
  IconMdKeyboardArrowDown,
  IconMdCheckCircle,
} from "../../../icons/components";

import styles from "./styles.module.css";
import { useTranslations, languages } from "shared-modules/translation-module";
import LanguagesLocalStorageStore from "shared-modules/local-storage-module/LanguagesLocalStorageStore";

export const LanguageButton = () => {
  const { configStore } = useTranslations();
  const languagesLocalStorageContext = useContext(LanguagesLocalStorageStore);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(
    languagesLocalStorageContext.getLanguageCode() ?? "en"
  );

  const handleLanguageChange = (lang) => {
    setAnchorEl(null);
    setCurrentLanguage(lang);
    configStore.setLanguage(lang);
  };

  return (
    <div className={`${styles.langBtnBox} f-bold`}>
      <div
        aria-controls="lang-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={styles.langBtn}
      >
        <IconMdLanguage className={styles.langIcon} />
        <span className={styles.currentLangText}>{currentLanguage}</span>
        <IconMdKeyboardArrowDown className={styles.arrowIcon} />
      </div>
      <MMenu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
      >
        {languages.map((o, index) => (
          <MMenuItem
            onClick={() => handleLanguageChange(o.code)}
            key={index}
          >
            <div
              className={`${styles.langItem} language-item ${
                currentLanguage == o.code && "active"
              }`}
            >
              <div className={`${styles.shortLangText} f-bold`}>{o.code}</div>
              <div className={`${styles.langText} f-bold`}>{o.name}</div>
              <div className={`${styles.icon} language-icon`}>
                <IconMdCheckCircle />
              </div>
            </div>
          </MMenuItem>
        ))}
        <style>{`
          .language-item.active .language-icon {
            border-color: transparent;
          }
          .language-item.active .language-icon svg {
            opacity: 1;
          }
        `}</style>
      </MMenu>
    </div>
  );
};
export default LanguageButton;
