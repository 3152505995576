import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { ApiManager } from 'shared-modules/api-manager-module';

export class EntryModuleStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = {
    drawerLoading: false,
  };

  userData = {};
  allCalms = [];
  userCompanies = [];

  getUser = async (showLoading = true) => {
    try {
      this.loading.drawerLoading = showLoading;
      let res = await ApiManager.getData('/api/UIData/GetUserWithUIData');
      this.allCalms = res.data.user?.roles?.flatMap((o) => o?.roleClaims) ?? [];
      this.userCompanies = res.data?.user?.companies;
      this.userData = res.data;
      return res;
    } catch (ex) {
      return false;
    } finally {
      this.loading.drawerLoading = false;
    }
  };
}

export const entryStore = new EntryModuleStore();
export const entryStoreContext = createContext(entryStore);
