import React, { useLayoutEffect, useRef } from 'react';
import { MTableCell } from '../../../../material/components';
import TableInitialsComponent from './TableInitialsComponent';
import TableImageComponent from './TableImageComponent';
import TableMultipleValueComponent from './TableMultipleValueComponent';
import TableTonalComponent from './TableTonalComponent';
import TableVerifyStatusComponent from './TableVerifyStatusComponent';
import TableColorBoxComponent from './TableColorBoxComponent';
import { IconMdOutlineDragIndicator } from '../../../../icons';

export const TableCellComponent = ({ rowItem, dragHandleProps, isDragOccurring, isDraggable }) => {
  const cellRefs = useRef([]);

  // set initial width to cells before drag started
  useLayoutEffect(() => {
    if (isDragOccurring && isDraggable) {
      cellRefs.current.forEach((currentCell) => {
        const rect = currentCell.getBoundingClientRect();
        currentCell.style.width = `${rect.width}px`;
        // currentCell.style.height = `${rect.height}px`;
      });
    }
  }, [isDragOccurring]);

  return rowItem?.col?.map((item, index) => (
    <MTableCell
      innerRef={(el) => (cellRefs.current[index] = el)}
      key={index}
      className={`
          ${item.isCheckbox ? 'checkbox-cell' : ''} 
          ${item.isDropdownIcon ? 'dropdown-icon-cell' : ''} 
          ${rowItem?.blocked ? 'blocked' : ''}
          ${
            rowItem?.liveAnimation
              ? 'live-animation live-animation-' +
                rowItem?.liveAnimationtTonal?.toLowerCase()?.replace(' ', '-') +
                ''
              : ''
          }
          ${
            rowItem?.rowColor
              ? 'row-color row-color-' + rowItem?.rowColorTonal?.toLowerCase()?.replace(' ', '-') + ''
              : ''
          }
        `}
    >
      <div className="table-cell-flex">
        {index === 0 && isDraggable && (
          <div className="Table-dragIndicator" {...dragHandleProps}>
            <IconMdOutlineDragIndicator />
          </div>
        )}
        <TableInitialsComponent item={item} />
        <TableImageComponent item={item} />
        <TableColorBoxComponent item={item} />
        {item?.label?.length > 1 ? (
          <TableMultipleValueComponent item={item} index={index} />
        ) : item?.tonal ? (
          <TableTonalComponent item={item} />
        ) : item?.verified ? (
          <TableVerifyStatusComponent item={item} />
        ) : (
          <TableBaseItem item={item} />
        )}
      </div>
    </MTableCell>
  ));
};

export default TableCellComponent;

const TableBaseItem = ({ item }) => {
  const { styles = {}, label } = item;

  return <div style={styles}>{label}</div>;
};
