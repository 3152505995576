import React, { useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { v4 as uuidv4 } from 'uuid';

import { useTranslations } from "shared-modules/translation-module";

import { StaticValidator } from "../FormControl/FormControl/StaticValidationForSelects/ValidatorForm";

import "./style.css";

const currentFormId = uuidv4()

const MCustumValidatorForm = ({id = null, onSubmit, children, minCheckedCheckboxCount = 0}, props) => {
    const { t } = useTranslations();
    const originalSubmit = onSubmit;
    onSubmit = (e) => {
        let isScrolled = false;
        let validateArr = [];
        const currentVaidators = []?.slice?.call(document.getElementsByClassName("custom-validator"));

        currentVaidators.map(currentValidator => {

            if(typeof currentValidator?.getAttribute !== "function") return;
            if(
                Number(currentValidator?.getAttribute("minCheck")) !== 0 && 
                currentValidator?.getAttribute("minCheck") !== null &&
                currentValidator?.getAttribute("minCheck") !== undefined &&
                currentValidator?.getAttribute("minCheck") &&
                currentValidator?.getAttribute("minCheck") > 0
                ) {

                if(currentValidator?.getAttribute("isselelct") == "true") {
                    []?.slice?.call(currentValidator?.getElementsByClassName("MuiInputBase-root"))?.map(select => {
                        if(Number(select?.getAttribute("selectedcount")) >= Number(currentValidator?.getAttribute("minCheck"))) validateArr.push(true);
                        else {
                            validateArr.push(false);
                            []?.slice?.call(currentValidator?.getElementsByClassName("MuiSelect-root"))?.map(o => {
                                if(!isScrolled) {
                                    currentValidator?.scrollIntoView({behavior: "smooth", block: "center"});
                                    isScrolled=true;
                                 }
                                if([]?.slice?.call(o?.lastChild?.classList)?.includes("error")) return;
                                const node = document.createElement("label");
                                if(currentValidator?.getAttribute("errorText")) {
                                    const textnode = document.createTextNode(currentValidator?.getAttribute("errorText"));
                                    node.appendChild(textnode);
                                    node.classList.add("error");
                                    o?.append(node);
                                }
                                o?.classList?.add("errorBorder");
                            });
                            // Number(currentValidator?.getAttribute("minCheck")) > 0 && MToast.infoToast(t["YousShouldSelectCount"]?.replace("{0}", currentValidator?.getAttribute("minCheck") + " " + currentValidator?.getElementsByClassName("MuiFormLabel-root")[0]?.textContent ?? ""));
                        }
                    })
               } else {
                    let isValdiate = currentValidator?.getElementsByClassName("Mui-checked")?.length >= Number(currentValidator?.getAttribute("minCheck"));   
                           
                    if(!isValdiate && Number(currentValidator?.getAttribute("minCheck")) > 0) {
                        if(!isScrolled) {
                           currentValidator?.scrollIntoView({behavior: "smooth", block: "center"});
                           isScrolled=true;
                        };
                        console.log([]?.slice?.call(currentValidator?.getElementsByClassName("MuiFormGroup-root")));
                        []?.slice?.call(currentValidator?.getElementsByClassName("MuiFormGroup-root"))?.map((o, index) => {
                            []?.slice?.call(currentValidator?.getElementsByClassName("MuiFormControlLabel-root"))?.map(el => el?.classList?.add("errorBorder"));
                            console.log(o, "ess")
                            if([]?.slice?.call(o?.lastChild?.classList)?.includes("error")) return;
                            const node = document.createElement("div");
                            if(currentValidator?.getAttribute("errorText")){
                                const textnode = document.createTextNode(currentValidator?.getAttribute("errorText"));
                                node.appendChild(textnode);
                                o?.append(node);
                            };
                            node.classList.add("error");
                        });
                        // Number(currentValidator?.getAttribute("minCheck")) > 0 && MToast.infoToast(t["YousShouldCheckCountCheckBoxes"]?.replace("{0}", currentValidator?.getAttribute("minCheck")));
                        validateArr.push(false);
                    } else {
                        validateArr.push(true);
                        // currentValidator.classList.remove("error");
                    }
               }
            } 
        })

        if(!validateArr.some(o => o === false)) originalSubmit(e);
    } 

    useEffect(() => { 
        return () => StaticValidator.validators = []
    }, [])

    return <ValidatorForm id={id === null ? currentFormId : id} onSubmit={onSubmit} {...props}>{children}</ValidatorForm>
};


MCustumValidatorForm.addValidationRule = ValidatorForm.addValidationRule;
MCustumValidatorForm.removeValidationRule = ValidatorForm.removeValidationRule;

export const MValidatorForm = MCustumValidatorForm;
export default MValidatorForm;