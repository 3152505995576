import React, { useState, useContext } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react'

import "react-quill/dist/quill.snow.css";
import styles from "./styles.module.css";
import QuillEditorStore from "./QuilEditorStore";
import { useEffect } from "react";

const CustomUndo = () => <svg viewBox="0 0 18 18"><polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" /><path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" /></svg>;
const CustomRedo = () => <svg viewBox="0 0 18 18"><polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" /><path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" /></svg>;

function undoChange() { this.quill.history.undo() }
function redoChange() { this.quill.history.redo() }

const Size = Quill.import("formats/size");
Size.whitelist = ["size-10", "size-12", "size-14", "size-16", "size-18", "size-20"];

Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize)

export const QuillEditor = ({ children, ...props }) => {
  const modules = {
    toolbar: { 
      container: "#quillId-"+props.quillId, 
      handlers: { 
        undo: undoChange, 
        redo: redoChange 
      } 
    },
    history: { 
      delay: 500, 
      maxStack: 100, 
      userOnly: true 
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: [ 'Resize', 'DisplaySize' ]
    }
  };

  const quillEditorContext = useContext(QuillEditorStore);

  const [text, setText] = useState();
  // const [timer, setTimer] = useState(null);

  useEffect(() => {
    setText(props.value)
  }, [props.value]);

  const handleChange = (value) => {
    setText(value)
    // clearTimeout(timer)
    // const newTimer = setTimeout(() => props.onChange(value), 500)
    // setTimer(newTimer);
    props.onChange(value);
    quillEditorContext.setHtmlValue(value, props.quillId);
  };

  return (
    <div className={`${styles.textEditorBox} text-editor ${props.size ? props.size : "medium"} ${props?.disabled ? "disabled" : ""}`}>
      <div id={`text-editor-${props.quillId}`} style={{display: "none" }} dangerouslySetInnerHTML={{__html: text}}></div>
      <div id={`quillId-${props.quillId}`}>
        <span className="ql-formats">
          <select className="ql-size" defaultValue="size-14">
            <option value="size-10">Size: 10px</option>
            <option value="size-12">Size: 12px</option>
            <option value="size-14">Size: 14px</option>
            <option value="size-16">Size: 16px</option>
            <option value="size-18">Size: 18px</option>
            <option value="size-20">Size: 20px</option>
          </select>
          <select className="ql-header" defaultValue="normal">
            <option value="normal">Normal</option>
            <option value="1">H1</option>
            <option value="2">H2</option>
            <option value="3">H3</option>
            <option value="4">H4</option>
            <option value="5">H5</option>
            <option value="6">H6</option>
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
          <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" />
          <select className="ql-color" />
          <select className="ql-background" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-image" />
          <button className="ql-video" />
        </span>
        <span className="ql-formats">
          <button className="ql-code-block" />
        </span>
        <span className="ql-formats">
          <button className="ql-undo"><CustomUndo /></button>
          <button className="ql-redo"><CustomRedo /></button>
        </span>
      </div>
      <ReactQuill
        theme="snow"
        value={text ?? ""}
        onChange={handleChange}
        placeholder={props.placeholder ?? ''}
        modules={modules}
      />
    </div>
  );
};

export default QuillEditor;
