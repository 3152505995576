import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslations } from "shared-modules/translation-module";

import { 
  MDialog, 
  MDialogActions, 
  MDialogContent, 
  MDialogTitle,
  MTextValidator,
  MValidatorForm
 } from "../../../material";

 import styles from "./styles.module.css";

const timerKey = "OTPkey";
const minTimeInSecconds = 60;

export const OtpVerficationPopup = ({
  open = false, 
  title , 
  text,
  buttons,
  disableAllInput = false,
  inputValues = [],
  onCodeChange = () => {},
  resendHandler = () => {},
  onSubmit = () => {}
}) => {

  const [disableInputs, setDisableInputs] = useState([1,2,3]);
  const [timer, setTimer] = useState()

  const {t} = useTranslations();

  const handleChange = (e) => {
    let value = e.target.value;
    let currentElIndex = Number(e.target.name);
    let numberArr = inputValues;

    if(Boolean(value[0])) {
      setDisableInputs(prev => prev?.filter(o => o !== currentElIndex+1));
    } else {
      setDisableInputs(prev => [...prev, currentElIndex+1]);
    }

    // check if value is two number
    if(e.target.value?.length > 1 ) {
      let nums = e.target.value?.split("");
      value = nums?.find(i => i !== inputValues[currentElIndex]) ?? e.target.value[0] ;
    };
    // update current value
    numberArr[currentElIndex] = value[0];
    // send code to parent
    onCodeChange(numberArr?.filter(o => o));

    //focuse next input 
    let inputs = document.getElementsByClassName("OTPinput");
    if(inputs[currentElIndex+1]?.getElementsByTagName("input")?.length && Boolean(value[0])) {
      setTimeout(() =>  inputs[currentElIndex+1]?.getElementsByTagName("input")[0]?.focus(), 100)
    }
  };

  const onKeydownHandler = async (e) => {
    const valueLength = inputValues?.filter(o => o)?.length;
    let inputs = document.getElementsByClassName("OTPinput");

    if(e.keyCode === 8) {
      inputs[valueLength - 1]?.getElementsByTagName("input")[0]?.focus();
    } else if(e.ctrlKey && e.keyCode == 86) {
      const code = await navigator.clipboard.readText();
      if(code?.length <= 4) {
        const codesStr = code?.split("")?.filter(num => !isNaN(Number(num)));
        onCodeChange(codesStr);
        
        setDisableInputs(prev => {
          return prev?.filter(o => o >= codesStr?.length)
        });

        if(inputs[codesStr?.length-1]?.getElementsByTagName("input")?.length) {
          if(codesStr?.length === 4) {
            setTimeout(() => inputs[codesStr?.length-1]?.getElementsByTagName("input")[0]?.focus(), 100);
          } else {
            setTimeout(() => inputs[codesStr?.length]?.getElementsByTagName("input")[0]?.focus(), 100)
          }
        }
      } 
    };
  };

  const timerRecursion = () => {
    let startTime = localStorage.getItem(timerKey);
    startTime = JSON.parse(startTime);
    if(startTime) {
      let timePlusTime = moment(startTime.timerStartTime).add(minTimeInSecconds, 'seconds');
      let currentTime = moment();
      if(timePlusTime.toDate() >= currentTime.toDate() && !timer) {
        setTimer(timePlusTime.diff(currentTime) / 1000);
        setTimeout(timerRecursion, 1000)
        return;
      }
      setTimer(undefined);
      localStorage.removeItem(timerKey);
    }
  }

  useEffect(() => {
    let startTime = localStorage.getItem(timerKey);
    if(startTime) {
      timerRecursion();
    }
  }, [])

  useEffect(() => {
    return () => {
      onCodeChange([]);
      setDisableInputs([1,2,3]);
    }
  }, [open]);

  return (
		<MDialog open={open} onClose={close} className="medium">
      {title && (
        <MDialogTitle> 
          <div className="d-flex justify-content-center">
            <div className={`dialog-main-title`}>{ title }</div>  
          </div>
        </MDialogTitle>
      )}
      {
        text && (
          <div className={styles.text}>{ text }</div>
        )
      }
			<MValidatorForm onSubmit={onSubmit} autoComplete="off">
				<MDialogContent>
          <div className="d-flex justify-content-center">
            <div className={`${styles.inputsWrapper} d-flex`}>
              <MTextValidator 
                className={`${inputValues?.length === 0 ? styles.active : ""} OTPinput`}
                type="number"
                name="0"
                disabled={disableAllInput}
                validators={['required']}
							  errorMessages={[]}
                value={inputValues?.length > 0 ? inputValues[0] : ""}
                onChange={handleChange}
                onKeyDown={onKeydownHandler}
              />
              <MTextValidator 
                className={`${inputValues?.length === 1 ? styles.active : ""} OTPinput`}
                type="number"
                name="1"
                disabled={disableAllInput || disableInputs?.includes(1)}
                validators={['required']}
							  errorMessages={[]}
                value={inputValues?.length > 1 ? inputValues[1] : ""}
                onChange={handleChange}
                onKeyDown={onKeydownHandler}
              />
              <MTextValidator 
                className={`${inputValues?.length === 2 ? styles.active : ""} OTPinput`}
                type="number"
                name="2"
                disabled={disableAllInput || disableInputs?.includes(2)}
                validators={['required']}
							  errorMessages={[]}
                value={inputValues?.length > 2 ? inputValues[2] : ""}
                onChange={handleChange}
                onKeyDown={onKeydownHandler}
              />
              <MTextValidator 
                className={`${inputValues?.length === 3 ? styles.active : ""} OTPinput`}
                type="number"
                name="3"
                disabled={disableAllInput || disableInputs?.includes(3)}
                validators={['required']}
							  errorMessages={[]}
                value={inputValues?.length > 3 ? inputValues[3] : ""}
                onChange={handleChange}
                onKeyDown={onKeydownHandler}
              />
            </div>
          </div>

          <div className={'d-flex justify-content-center'} style={Number(timer) > 0 ? {pointerEvents: "none", opacity: 0.6} : {}}>
            <div className={styles.resend} onClick={() => {
              resendHandler();
              let startTime = localStorage.getItem(timerKey);
              if(!startTime) {
                localStorage.setItem(timerKey, `{ 
                  "userId": "sent", 
                  "timerStartTime": "${moment().toISOString()}"
                }`);
                timerRecursion();
              }
              timerRecursion()
            }} >{t["resendNewCode"]} {timer ? `(${Math.floor(timer)})` : ""} </div>
          </div>
				</MDialogContent>

        {buttons && <MDialogActions>{ buttons }</MDialogActions>}
			</MValidatorForm>
		</MDialog>
	)
};
export default OtpVerficationPopup;
