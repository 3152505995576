import React from "react";
import styles from "./styles.module.css";

export const CustomValidator = ({children, minCheck = 0, errorText = undefined, isSelelct = false}) => {
  return (
    <div className="custom-validator" mincheck={minCheck} isselelct={`${isSelelct}`} errortext={errorText ?? ""} >
      {children}
    </div>
  );
};
export default CustomValidator;
