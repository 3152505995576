import React from "react";
import { ViewMoreValuesBtn } from "../../../components";

export const TableMultipleValueComponent = ({item, index}) => {
  return (
    <ViewMoreValuesBtn 
      id={`view-more-values-btn-${index}`} 
      classText="list-dropdown"
      data={ item.label && item.label?.map((item) => {return { label: item }})}
      name="label"
    />
  );
};

export default TableMultipleValueComponent;
