import React from "react";

import styles from "./styles.module.css";

export const ComponentLoader = ({ color }) => {
  return (
    <div className={`${styles.loader} page-loader-comp`}>
      <div className={`${styles.bounce} page-loader-comp-bounce`}>
        <div className={styles.bounce1} style={{ backgroundColor: color }}></div>
        <div className={styles.bounce2} style={{ backgroundColor: color }}></div>
        <div className={styles.bounce3} style={{ backgroundColor: color }}></div>
      </div>
    </div>
  );
};
export default ComponentLoader;
