import { AuthLocalStorageStore } from "shared-modules/local-storage-module";
//log out user remove auth data form local storage
export function logout() {
    const authLocalStorageStore = new AuthLocalStorageStore();

    //remove refresh token
    authLocalStorageStore.removeRefreshToken();
    //remove user token
    authLocalStorageStore.removeUserToken();
    //remove is keep me
    authLocalStorageStore.removeIsKeepMe();
    //refresh page for re render auth module
    location.replace(window.location.pathname + window.location.search);
}

export default logout;