import React, { useEffect, useState } from "react";
import { MdRemove, MdAdd } from "react-icons/md";

import styles from "./styles.module.css";
import { isWholeNumber } from "helpers";

export const QuantitySelector = ({ isDisabled = false, value = 0, onValueChange, maxQuantity = 0 }) => {
  const [localValue, setLocalValue] = useState(value);
  const isMaximum = localValue === maxQuantity;
  const handleIncrement = () => {
    setLocalValue((prev) => (prev >= maxQuantity ? prev : prev + 1));
  };

  const handleDecrement = () => {
    setLocalValue((prev) => (prev === 0 ? prev : prev - 1));
  };

  const handleInputChange = (e) => {
    let value = e.target.valueAsNumber;

    if (!value) {
      setLocalValue("");
      return;
    } else if (!isWholeNumber(value)) {
      return;
    } else if (value > maxQuantity) {
      setLocalValue(maxQuantity);
      return;
    }

    setLocalValue(value);
  };

  const onFocus = (e) => {
    if (e.target.valueAsNumber === 0) {
      e.target.value = "";
    }
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      e.target.value = 0;
    }
  };

  useEffect(() => {
    if (value !== localValue) {
      onValueChange(localValue);
    }
  }, [localValue]);

  return (
    <div className={`${styles.quantitySelector}  ${isDisabled ? styles.quantitySelectorDisabled : ""}`}>
      <button className={styles.quantitySelectorButton} onClick={handleDecrement}>
        <MdRemove />
      </button>
      <input
        value={localValue}
        type="number"
        className={styles.quantitySelectorQuantity}
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button
        className={`${styles.quantitySelectorButton} ${(isMaximum && !isDisabled) && styles.disabledBtn}`}
        onClick={handleIncrement}
      >
        <MdAdd />
      </button>
    </div>
  );
};

export const BasicQuantitySelector = ({ isDisabled = false, value = 0, onValueChange, onPlus, onMinus }) => {
  const handleInputChange = (e) => {
    let valueAsNumber = e.target.valueAsNumber;

    if (!valueAsNumber) {
      // onValueChange(0);
      return;
    } else if (!isWholeNumber(valueAsNumber)) {
      return;
    } else if (valueAsNumber < 0) {
      onValueChange(0);
      return;
    }

    onValueChange(valueAsNumber);
  };

  const onFocus = (e) => {
    if (e.target.valueAsNumber === 0) {
      e.target.value = "";
    }
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      e.target.value = 0;
    }
  };

  return (
    <div className={`${styles.quantitySelector}  ${isDisabled ? styles.quantitySelectorDisabled : ""}`}>
      <button className={styles.quantitySelectorButton} onClick={onMinus}>
        <MdRemove />
      </button>
      <input
        value={value}
        type="number"
        className={styles.quantitySelectorQuantity}
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button className={styles.quantitySelectorButton} onClick={onPlus}>
        <MdAdd />
      </button>
    </div>
  );
};
