import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { IconMdOutlineFileDownload } from "../../../icons/components";

import { downloadFile } from "./utils";

import styles from "./styles.module.css";

const Preview = ({
  files,
  setFiles,
  handelDelete,
  onReorder,
  isDisabled,
  isReorderDisabled,
  isSingleFile = true,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFiles((files) => {
        const oldIndex = files.findIndex(({ id }) => id == active.id);
        const newIndex = files.findIndex(({ id }) => id == over.id);
        onReorder?.(oldIndex, newIndex);
        return arrayMove(files, oldIndex, newIndex);
      });
    }
  }

  return (
    <div className={`${styles.previewContainer} ${isSingleFile ? styles.single : ''} ${isDisabled ? styles.disabled : ""}`}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={files}
          strategy={rectSortingStrategy}
          disabled={isReorderDisabled}
        >
          {files?.map((item) => (
            <PreviewCard
              key={item.id}
              data={item}
              handelDelete={handelDelete}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default Preview;

const PreviewCard = ({ data, handelDelete }) => {
  const { id, file, isFromServer } = data;
  const [isPreviewFailed, setIsPreviewFailed] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className={`${styles.previewImage}`}>
        {isPreviewFailed ? (
          <div className={styles.filePreviewCard}>
            <IconMdOutlineFileDownload
              className={styles.downloadIcon}
              onClick={() => downloadFile(file.preview, file.name)}
            />
            <p className={styles.fileName}>{file.name}</p>
          </div>
        ) : (
          <img
            src={file.preview}
            alt=""
            onLoad={() => {
              // URL.revokeObjectURL(file.preview);
            }}
            onError={() => {
              setIsPreviewFailed(true);
            }}
          />
        )}
      <button
        type="button"
        className={styles.filePreviewDelete}
        onClick={(e) => {
          e.preventDefault();
          handelDelete?.(id, isFromServer);
        }}
      >
      </button>
      </div>
    </div>
  );
};
