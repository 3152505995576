import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { res } from "shared-modules/translations-for-translation-module";
import XHR from "i18next-browser-languagedetector";
import { LanguagesLocalStorageStore } from "shared-modules/local-storage-module";

const options = {
  // translation configuration by languages
  resources: res.resources,
  // namespaces should be module names
  ns: res.namespaces,
  //....
  // don't touch this
  fallbackLng: res.defaultLanguage,
  defaultNS: res.defaultNS,
  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: res.defaultNS,
  },
  //...
};

// don't touch this
i18n
  .use(LanguageDetector)
  .use(XHR)
  .init(options, (t) => {
    const languageLocalStorageStore = new LanguagesLocalStorageStore();

    const localStorageLngKey = languageLocalStorageStore.getLanguageCode();
    if(localStorageLngKey.includes("-")) {
      i18n.changeLanguage(options.fallbackLng);
    }
  });

export default i18n;
//...
