import React from "react";

import styles from "./styles.module.css";
export const PriceChangeIndicator = ({ standardPrice, oldPrice, newPrice, currency= "" }) => {
  const isPriceChange = oldPrice || newPrice;
  return (
    <div>
      {isPriceChange ? (
        <>
          <p>{newPrice} {currency}</p>
          <p className={styles.priceChangeOld}>{oldPrice} {currency}</p>
        </>
      ) : (
        <p>{standardPrice} {currency}</p>
      )}
    </div>
  );
};

export const BasicPriceChangeIndicator = ({ price, newPrice, currency="" }) => {
  return (
    <div>
      {newPrice ? (
        <>
          <p>{newPrice} {currency}</p>
          <p className={styles.priceChangeOld}>{price} {currency}</p>
        </>
      ) : (
        <p>{price} {currency}</p>
      )}
    </div>
  );
};
