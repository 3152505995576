import React from "react";
import TableMultipleValueComponent from "../../../../CustomTable/components/TableMultipleValueComponent";
import TableTonalComponent from "../../../../CustomTable/components/TableTonalComponent";
import TableVerifyStatusComponent from "../../../../CustomTable/components/TableVerifyStatusComponent";
import TableImageComponent from "../../../../CustomTable/components/TableImageComponent";
import style from "../style.module.css";

const ListItemCells = ({ data = [] }) => {
  if (data.length === 0) {
    return null;
  }

  return data?.map((cell, index) => (
    <div className={style.listItemCell} key={index}>
      <TableImageComponent item={cell} />
      {cell?.label?.length > 1 ? (
        <TableMultipleValueComponent item={cell} index={index} />
      ) : cell?.tonal ? (
        <TableTonalComponent item={cell} />
      ) : cell?.verified ? (
        <TableVerifyStatusComponent item={cell} />
      ) : (
        cell?.label
      )}
    </div>
  ));
};

export default ListItemCells;
