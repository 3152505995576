import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { ApiManager } from 'shared-modules/api-manager-module';

export const TranslationStore = class {
  constructor() {
    makeAutoObservable(this);
  }

  CompanyEnum = Object.freeze({});

  allLanguages = [];

  loading = {
    mainLoading: false,
  };

  //GET Methods
  getAllLanguages = async () => {
    try {
      if (this.allLanguages.length > 0) return this.allLanguages;
      this.loading.mainLoading = true;
      let res = await ApiManager.getData('/Language/GetAllLanguages');
      if (res.data === undefined) {
        return false;
      }
      this.allLanguages = res.data;
      return res.data ?? [];
    } catch (ex) {
      return false;
    } finally {
      this.loading.mainLoading = false;
    }
  };
};

export default createContext(new TranslationStore());
