import React from "react";
import ERROR_404 from "../ErrorPages/ERROR_404";
import ERROR_500 from "../ErrorPages/ERROR_500";
import ErrorPage from "../ErrorPages/ErrorPage";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: "", fullError: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message, fullError: error };
  }

  componentDidCatch(error, errorInfo) {
    // appInsights.trackException({ exception: new Error(JSON.stringify({ error, errorInfo })) });
    console.log(error, errorInfo, "catch");
  }

  render() {
    if (this.state.hasError) {
      if (this.state.message === "404") return <ERROR_404 />;
      if (this.state.message === "500") return <ERROR_500 />;
      return <ErrorPage/>
      // return this.props.children;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
