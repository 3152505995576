import React from 'react';
import { MTableCell } from '../../../../material/components';
import {
  DetailsButton,
  EditButton,
  DeleteButton,
  RetryButton,
  DownloadButton,
  RespActionsButtons,
  SortButton,
  RefundButton,
  PowerOffButton,
  CencelButton,
} from '../../../components';
import { MuseMediaQuery } from '../../../../hooks/MuseMediaQuery';
import ArchiveButton from '../../ArchiveButton';
import PreviewButton from '../../PreviewButton';
import AnalyticButton from '../../AnalyticButton';
import SendButton from '../../SendButton';

export const TableButtonsComponent = ({
  index,
  rowItem,
  buttonsLength,
  detailsButton,
  editButton,
  deleteButton,
  retryButton,
  downloadButton,
  archiveButton,
  previewButton,
  refundButton,
  sortButton,
  analyticButton,
  powerOffButton,
  cencelButton,
  cencelHandler,
  sendButton,
  powerOffHandler,
  analyticHandler,
  detailsHandler,
  editHandler,
  sendHandler,
  deleteHandler,
  retryHandler,
  downloadHandler,
  archiveHandler,
  previewHandler,
  refundHandler,
  sortHandler,
}) => {
  const mediaQueryLG = MuseMediaQuery('lg');

  return buttonsLength ? (
    <MTableCell
      className={`
        actions sticky 
        ${rowItem?.blocked ? 'blocked' : ''} 
        ${
          rowItem?.liveAnimation
            ? 'live-animation live-animation-' +
              rowItem?.liveAnimationtTonal?.toLowerCase()?.replace(' ', '-') +
              ''
            : ''
        }
        ${
          rowItem?.rowColor
            ? 'row-color row-color-' + rowItem?.rowColorTonal?.toLowerCase()?.replace(' ', '-') + ''
            : ''
        }
      `}
    >
      <div className="table-cell-buttons">
        {mediaQueryLG && buttonsLength > 1 ? (
          <RespActionsButtons
            id={index}
            isActive={rowItem?.isActive}
            detailsButton={detailsButton && { onClick: () => detailsHandler(rowItem) }}
            disableDetailsButton={rowItem?.disabledButtons?.disableDetails === true}
            hideDetailsButton={rowItem?.hiddenButtons?.hideDownload === true ? 'true' : undefined}
            sendButton={sendButton && { onClick: () => sendHandler(rowItem) }}
            disableSendButton={rowItem?.disabledButtons?.disableSend === true}
            hideSendButton={rowItem?.hiddenButtons?.sendButton === true ? 'true' : undefined}
            archiveButton={archiveButton && { onClick: () => archiveHandler(rowItem) }}
            disableArchiveButton={rowItem?.disabledButtons?.disabledArchive === true}
            hideArchiveButton={rowItem?.hiddenButtons?.hideArchive === true ? 'true' : undefined}
            previewButton={previewButton && { onClick: () => previewHandler(rowItem) }}
            disablePreviewButton={rowItem?.disabledButtons?.disabledPreview === true}
            hidePreviewButton={rowItem?.hiddenButtons?.hidePreview === true ? 'true' : undefined}
            analyticButton={analyticButton && { onClick: () => analyticHandler(rowItem) }}
            disableAnalyticButton={rowItem?.disabledButtons?.disabledAnalytic === true}
            hideAnalyticButton={rowItem?.hiddenButtons?.hideAnalytic === true ? 'true' : undefined}
            editButton={editButton && { onClick: () => editHandler(rowItem), disabled: rowItem?.isHQ }}
            disableEditButton={rowItem?.disabledButtons?.disableEdit === true}
            hideEditButton={rowItem?.hiddenButtons?.hideEdit === true ? 'true' : undefined}
            deleteButton={deleteButton && { onClick: () => deleteHandler(rowItem), disabled: rowItem?.isHQ }}
            disableDeleteButton={rowItem?.disabledButtons?.disableDelete === true}
            hideDeleteButton={rowItem?.hiddenButtons?.hideDelete === true ? 'true' : undefined}
            retryButton={retryButton && { onClick: () => retryHandler(rowItem), disabled: rowItem?.isHQ }}
            disableRetryButton={rowItem?.disabledButtons?.disableRetry === true}
            hideRetryButton={rowItem?.hiddenButtons?.hideRetry === true ? 'true' : undefined}
            downloadButton={
              downloadButton && { onClick: () => downloadHandler(rowItem), disabled: rowItem?.isHQ }
            }
            disableDownloadButton={rowItem?.disabledButtons?.disableDownload === true}
            hideDownloadButton={rowItem?.hiddenButtons?.hideDownload === true ? 'true' : undefined}
            powerOffButton={
              powerOffButton && { onClick: () => powerOffHandler(rowItem), disabled: rowItem?.isHQ }
            }
            disablePowerOffButton={rowItem?.disabledButtons?.disablePowerOff === true}
            hidePowerOffButton={rowItem?.hiddenButtons?.hidePowerOff === true ? 'true' : undefined}
            cencelButton={cencelButton && { onClick: () => cencelHandler(rowItem), disabled: rowItem?.isHQ }}
            hideCencelButton={rowItem?.hiddenButtons?.hideCencel === true ? 'true' : undefined}
            disableCencelButton={rowItem?.disabledButtons?.disableCencel === true}
            refundButton={refundButton && { onClick: () => refundHandler(rowItem), disabled: rowItem?.isHQ }}
            disableRefundButton={rowItem?.disabledButtons?.disableRefund === true}
            hideRefundButton={rowItem?.hiddenButtons?.hideRefund === true ? 'true' : undefined}
            sortButton={sortButton && { onClick: () => sortHandler(rowItem), disabled: rowItem?.isHQ }}
            disableSortButton={rowItem?.disabledButtons?.disableSort === true}
            hideSortButton={rowItem?.hiddenButtons?.hideSort === true ? 'true' : undefined}
          />
        ) : (
          <>
            {refundButton && (
              <RefundButton
                onClick={() => refundHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disableRefund === true}
                hide={rowItem?.hiddenButtons?.hideRefund === true ? 'true' : undefined}
              />
            )}
            {downloadButton && (
              <DownloadButton
                onClick={() => downloadHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disableDownload === true}
                hide={rowItem?.hiddenButtons?.hideDownload === true ? 'true' : undefined}
              />
            )}
            {sendButton && (
              <SendButton
                onClick={() => sendHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disableSend === true}
                hide={rowItem?.hiddenButtons?.hideSend === true ? 'true' : undefined}
              />
            )}
            {previewButton && (
              <PreviewButton
                onClick={() => previewHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disablePreview === true}
                hide={rowItem?.hiddenButtons?.hidePreview === true ? 'true' : undefined}
              />
            )}
            {archiveButton && (
              <ArchiveButton
                onClick={() => archiveHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disableArchive === true}
                hide={rowItem?.hiddenButtons?.hideArchive === true ? 'true' : undefined}
              />
            )}
            {retryButton && (
              <RetryButton
                onClick={() => retryHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disableRetry === true}
                hide={rowItem?.hiddenButtons?.hideRetry === true ? 'true' : undefined}
              />
            )}
            {detailsButton && (
              <DetailsButton
                onClick={() => detailsHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disableDetails === true}
                hide={rowItem?.hiddenButtons?.hideDetails === true ? 'true' : undefined}
              />
            )}
            {analyticButton && (
              <AnalyticButton
                onClick={() => analyticHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disabledAnalytic === true}
                hide={rowItem?.hiddenButtons?.hideAnalytic === true ? 'true' : undefined}
              />
            )}
            {editButton && (
              <EditButton
                onClick={() => editHandler(rowItem)}
                disabled={rowItem?.isHQ || rowItem?.disabledButtons?.disableEdit === true}
                hide={rowItem?.hiddenButtons?.hideEdit === true ? 'true' : undefined}
              />
            )}
            {powerOffButton && (
              <PowerOffButton
                onClick={() => powerOffHandler(rowItem)}
                disabled={rowItem?.disabledButtons?.disablePowerOff === true}
                hide={rowItem?.hiddenButtons?.hidePowerOff === true ? 'true' : undefined}
              />
            )}
            {deleteButton && (
              <DeleteButton
                onClick={() => deleteHandler(rowItem)}
                disabled={rowItem?.isHQ || rowItem?.disabledButtons?.disableDelete === true}
                hide={rowItem?.hiddenButtons?.hideDelete === true ? 'true' : undefined}
              />
            )}
            {sortButton && (
              <SortButton
                onClick={() => sortHandler(rowItem)}
                disabled={rowItem?.isHQ || rowItem?.disabledButtons?.disableSort === true}
                hide={rowItem?.hiddenButtons?.hideSort === true ? 'true' : undefined}
              />
            )}
            {cencelButton && (
              <CencelButton
                onClick={() => cencelHandler(rowItem)}
                disabled={rowItem?.isHQ || rowItem?.disabledButtons?.disableCencel === true}
                hide={rowItem?.hiddenButtons?.hideCencel === true ? 'true' : undefined}
              />
            )}
          </>
        )}
      </div>
    </MTableCell>
  ) : (
    <></>
  );
};

export default TableButtonsComponent;
