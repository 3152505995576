import React from "react";
import { IconMdOutlineFileDownload, IconMdFolder, IconMdPhotoCamera, IconMdVisibility} from "../../../icons/components";
import styles from "./styles.module.css";

export const DownloadFileBox = ({
  donwnloadButton = true,
  downloadHandler = ()=> {},
  handlePreview,
  fileUrl,
  fileName,
  fullWidth}) => {

  return (
    <div className={`${styles.downloadFileBox} ${fullWidth && styles.fullWidth}`} >
      <div className='d-flex'>
        <div className='d-flex flex-column justify-content-center'>
          {
            fileUrl?.includes("jpg") ||
            fileUrl?.includes("jpeg") ||
            fileUrl?.includes("png") ||
            fileUrl?.includes("gif") ?
            <IconMdPhotoCamera className={styles.fileIcon}/>
            : <IconMdFolder className={styles.fileIcon}/>
          }
        </div>
          <div className={`d-flex flex-column justify-content-center ${styles.contentWrapper}`}>
            <div className={`${styles.fileTitle} f-bold`}>{fileName}</div>
              <div className={`d-flex ${styles.iconsWrapper}`}>
                {
                  donwnloadButton && <a onClick={downloadHandler} href={fileUrl && ""}><IconMdOutlineFileDownload className={styles.buttonIcon}/></a>
                }
                {
                  Boolean(handlePreview) && <IconMdVisibility className={styles.buttonIcon} onClick={handlePreview}/>
                }
              </div>
            </div>
        </div>
    </div>
  );
};
export default DownloadFileBox;
