import { lazy } from "react";

export const DashboardAccess = class {
  static PageKey = "ProjectPolicys_CustomersModule_CommentTagsPage";

  static Route = [
    {
      name: "",
      url: ``,
      drawer: false,
      icon: ""
    },
  ];
  static Component = lazy(() => import("../pages/Dashboard"));
};

export default DashboardAccess;
