import React, {memo} from 'react';
import { MTab, MTabList } from "../../../material";


const TabLanguage = ({ language }) => {
  return (
    <div className="tab-item">
      <img className="icon rounded" src={language?.flagIconUrl} alt="" />
      {language?.displayName}
    </div>
  );
};

export const LangTabList = ({ onChange, languages, defaultLanguage, isShown }) => {
  return (
    <MTabList onChange={onChange}>
      {isShown ? (
        languages?.map((o) => (
          <MTab
            key={o?.displayName}
            label={<TabLanguage language={o} />}
            value={o?.languageCode}
          />
        ))
      ) : (
        <MTab
          label={<TabLanguage language={defaultLanguage} />}
          value={defaultLanguage?.languageCode}
        />
      )}
    </MTabList>
  );
};


export default memo(LangTabList);