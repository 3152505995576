import React, { useState } from "react";
import { useEffect } from "react";
import { MRadioGroup, MRadio } from "../../../material/components";

import styles from "./styles.module.css";

export const SecondaryColorSelect = ({
  data = null,
  handleChange,
  checkedColor = null,
}) => {
  const [localData, setLocalData] = useState([]);
  const [checkedValue, setCheckedValue] = useState(checkedColor ?? '');

  const handleColorChange = (e) => {
    handleChange(e.target.value);
    setCheckedValue(e.target.value);
  }

  useEffect(() => {
    if (data !== null) {
      const ramdomColorCode = data[Math.floor(Math.random() * data?.length)]?.color;
      setLocalData([...data, checkedColor].filter(o => o?.color));
      setCheckedValue(() => {
        if(!checkedColor?.color) return ramdomColorCode;
        return checkedColor?.color;
      });
      handleChange(checkedColor?.color ? checkedColor?.color : ramdomColorCode);
    }
    return () => {
      setLocalData([]);
    }
  }, [data, checkedColor]);


  return (
    <React.Fragment>
      <div className={styles.box}>
        <MRadioGroup name="company-color">
          <div className={styles.colorsBox}>
            {localData?.map((item, index) => (
              <div className={styles.colorBox} key={index}>
                <div
                  className={styles.inner}
                  style={{ backgroundColor: item?.color }}
                >
                    <MRadio
                      value={item?.color}
                      checked={item?.color === checkedValue}
                      onChange={handleColorChange}
                      className="color"
                    />
                </div>
              </div>
            ))}
          </div>
        </MRadioGroup>
      </div>
    </React.Fragment>
  );
};

export default SecondaryColorSelect;
