import { useContext } from 'react';
import { MToast } from "shared-modules/ui-lib-module";
import NotificationPopup from './NotificationPopup';
import { Socket } from "shared-modules/signalr-socket-module";
import NotificationsStore from '../store/NotificationStore';
import NotificationsUIStore from '../store/NotificationUIStore';
const socketContext = new Socket();


export const useNotificationsHook = () => {
    const notificationsContext = useContext(NotificationsStore);
    const notificationsUIContext = useContext(NotificationsUIStore);
    NotificationPopup.notificationsUIContext = notificationsUIContext;

    if(!socketContext.isConnected) {
        socketContext.isConnected = true;

        socketContext.setRequestUrl("/Notifications/ReciveNotification");
        socketContext.buildConnection()
        .then(o => {
            socketContext.invoke("Open");
            socketContext.on("OnNotificationRecive", (msg) => {
                NotificationPopup.notificationItem = JSON.parse(msg);
                
                var notification = {}
                Object.getOwnPropertyNames(NotificationPopup.notificationItem).forEach(o => notification[o.charAt(0).toLocaleLowerCase() + o.slice(1)] = NotificationPopup.notificationItem[o]);
                var notificationMessage = {};
                Object.getOwnPropertyNames(notification.message).forEach(o => notificationMessage[o.charAt(0).toLocaleLowerCase() + o.slice(1)] = notification.message[o]);
                notification.message = notificationMessage;
                if(notification.senderUser) {
                    var senderUser = {};
                    Object.getOwnPropertyNames(notification.senderUser).forEach(o => senderUser[o.charAt(0).toLocaleLowerCase() + o.slice(1)] = notification.senderUser[o]);
                    notification.senderUser = senderUser;
                }

                if(notification.reciverUser) {
                    var reciverUser = {};
                    Object.getOwnPropertyNames(notification.reciverUser).forEach(o => reciverUser[o.charAt(0).toLocaleLowerCase() + o.slice(1)] = notification.reciverUser[o]);
                    notification.reciverUser = reciverUser;
                }

                notificationsContext.notifications.count++;
                notificationsContext.notifications.notifications = [notification, ...notificationsContext.notifications.notifications];

                notificationsContext.unReadedNotificationsCount++;
                NotificationPopup.notificationForRead = notification;
                MToast.notificationToast(NotificationPopup, {autoClose: true, closeOnClick: false});
            });
        });
    }
}

export default useNotificationsHook;