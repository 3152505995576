import { userActivityTracker } from "shared-modules/user-manager-module";
import { useState, useEffect } from 'react';
// import { AzureApplicationInsightsConfigure } from "@meama/azure-application-insights-module";
// import { StoreBrowserHistory } from '../../EntryComponent/SyncHistoryWithStore';

const UserActivityComponent = ({ loc, userContext, authLocalStorageContext }) => {
    const [inited, setInited] = useState(false);
  
    useEffect(() => {
        try {
            // if(userContext?.user?.id && authLocalStorageContext.getUserToken() && process.env.REACT_APP_AZURE_Can_Log === "true") {
            //     AzureApplicationInsightsConfigure(StoreBrowserHistory.routerHistory, userContext?.user?.id);
            // }

            if(userContext?.user?.id && authLocalStorageContext.getUserToken() && !inited) {
                const CallbackToServer = (userId) => { 
                    try {
                        setTimeout(() => {
                        userActivityTracker.send("CallBackFromUser", userId);
                        CallbackToServer(userId);
                        }, 20000)
                    } catch (e) {}
                };
        
                const startConnection = () => {
                    userActivityTracker.setRequestUrl("/users/UserTracker");
                    userActivityTracker.buildConnection()
                    .then(o => {
                    userActivityTracker.invoke("Open");
                    CallbackToServer(userContext?.user?.id)
                    })
                }
    
                startConnection();
                setInited(true);
            }

            window.onbeforeunload = (e) => {
                e.preventDefault();
                userActivityTracker.invoke("Close");
        
                return undefined;
            }
        } catch (err) {  }
    }, [loc.key, userContext?.user])
  
    return null;
}
  
export default UserActivityComponent;