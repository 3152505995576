import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { MToast } from "shared-modules/ui-lib-module";

export const TypeExtentionStore = class {
  isString = function (x) {
    return Object.prototype.toString.call(x) === '[object String]';
  };
  isNumber = function (x) {
    return Object.prototype.toString.call(x) === '[object Number]';
  };
  isArray = function (x) {
    return Object.prototype.toString.call(x) === '[object Array]';
  };
  isObject = function (x) {
    return Object.prototype.toString.call(x) === '[object Object]';
  };
  isFunction = function (x) {
    return Object.prototype.toString.call(x) === '[object Function]';
  };
  isDate = function (x) {
    if(isNaN(Date.parse(x)) || typeof x === 'number') return false
    return true
    // return Object.prototype.toString.call(x) === '[object Date]';
  };
  isValue = function (x) {
    return !this.isObject(x) && !this.isArray(x);
  };

  isEpmtyQuillEditor = (text, toastText) => {
    if(!text || text?.trim() === "" || text?.replace(/>\s+</g,'><') === "<p></p>" || text === "<p><br></p>") {
      MToast.warningToast(toastText);
      return true;
    } else {
      return false;
    }
  };

  constructor() {makeAutoObservable(this)}
};

export default createContext(new TypeExtentionStore());