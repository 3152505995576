//create languages for logs module
let data = {
    "id": "Id",
    "name": "Name",
    "email": "Email",
    "action": "Action",
    "history": "History",
    "type": "Type",
    "date": "Date",
}

export default data;