//create languages for auth module
let data = {
    "keyName": "Key name",
    "translated": "Translated",
    "module": "Module",
    "type": "Type",
    "keySuccessfulyDeleted": "Key successfully deleted",
    "translation": "Translation",
    "keySuccessfulyAdded": "Key successfully added",
    "keySuccessfulyUpdated": "Key Successfuly Updated",
    "languageCode": "Language Code"
}

export default data;