import React, { useContext, useEffect } from "react";
import { Observer } from "mobx-react";
import { useTranslations } from "shared-modules/translation-module";

import { MButton, MBox } from "../../../material";
import { IconMdSearch } from "../../../icons/components";
import SearchStore from "./store";

import styles from "./styles.module.css";
import { UrlSearchStore } from "shared-modules/url-helper-module";

export const FourthCustomSearch = ({
  classText,
  data,
  searcPropertyName = 'name',
  searchHandler,
}) => {
  const { t } = useTranslations();

  const searchStore = useContext(SearchStore);
  const urlSearchStore = useContext(UrlSearchStore);

  const searchOnChange = (e) => {
    const value = e?.target?.value ?? e;
    searchStore.inputText = value;
  }

  useEffect(() => {
   if(data?.length) searchStore.inputText = urlSearchStore.getQuery();
  }, [data])

  let searchTime = undefined;

  return <Observer render={() =>
          <React.Fragment>
            <MBox noValidate autoComplete="off" className={styles.customSearchForm}>
              <input
                placeholder={t["search"]}
                label={t["search"]}
                name="search"
                autoComplete="off"
                className={`${styles.searchInput} ${classText}`}
                value={searchStore.inputText ?? ''}
                onChange={searchOnChange}
                onKeyDown={(e) => {
                  clearTimeout(searchTime);
                  searchTime = setTimeout(() => {
                    searchHandler(searchStore.searchHandler(data, searchStore.inputText ?? '', searcPropertyName));
                    urlSearchStore.setSearch(searchStore.inputText);
                  }, 500);
                }}
              />
              <MButton className={`${styles.searchInputBtn} f-bold`} type="submit">
                <IconMdSearch className={styles.searchInputIcon}/>
              </MButton>
            </MBox>
          </React.Fragment> }
        /> 

};
export default FourthCustomSearch;
