//create languages for specifications module
let data = {
    "sales-points": "გაყიდვის წერტილები",
    "baseType": "ბაზის ტიპი",
    "addNewSalesPoint": "დაამატე ახალი გაყიდვის წერტილი",
    "generalInformation": "ძირითადი ინფორმაცია",
    "location": "ლოკაცია",
    "address": "მისამართი",
    "long": "გრძედი",
    "lat": "განედი",
    "city": "ქალაქი",
    "workingHours": "სამუშაო საათები",
    "workingTimes": "სამუშაო დრო",
    "addHours": "დაამატე საათები",
    "AddYourAddress": "დაამატეთ თქვენი მისამართი",
    "salesPointCompanyNoErp": "გაყიდვის წერტილის კომპანიას არ აქვს ERP"
}

export default data;