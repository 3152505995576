import React from 'react';
import { IconIoCloseCircleOutline } from '../../../icons/components';
import { useTranslations } from 'shared-modules/translation-module';
export const CencelButton = (props) => {
  const { t } = useTranslations();

  if (props.label) {
    return (
      <div
        className={`
            custom-icon-box f-bold d-flex align-items-center
            ${props?.disabled ? 'disabled' : ''}
            ${props?.hide ? 'hide' : ''}
           
          `}
        {...props}
      >
        <IconIoCloseCircleOutline
          className={`
              custom-icon black small 
              ${props?.disabled ? 'disabled' : ''}
              ${props?.hide ? 'hide' : ''}
              ${props?.isActive ? 'activeTableButton' : ''}
            `}
        />
        <span className="custom-icon-label">{t[props.label] ?? ''}</span>
      </div>
    );
  }
  return (
    <IconIoCloseCircleOutline
      className={`
          custom-icon black small 
          ${props?.disabled ? 'disabled' : ''}
          ${props?.hide ? 'hide' : ''}
          ${props?.isActive ? 'activeTableButton' : ''}
        `}
      {...props}
    />
  );
};
export default CencelButton;
