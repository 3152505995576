//create languages for auth module
let data = {
    "deleteWithFile": "ფაილით წაშლა",
    "deleteClientsFromGroup": "გრუპიდან წაშლა",
    "addClientsToGroup": "გრუპაში დამატება",
    "groupList": "ჯგუფის სია",
    "uploadFile": "ფაილის ატვირთვა",
    "addClientGroup": "ჯგუფის დამატება",
    "uploadedFiles": "ატვირთული ფაილები",
    "addViaPhoneNumber": "ტელეფონის ნომრით დამატება",
    "pauseGroup": "დაპაუზება",
    "resumeGroup": "გაგრძელება",
    "clientId": "კლიენტის Id",
    "clientName": "კლიენტის სახელი",
    "phoneNumber": "ტელეფონის ნომერი",
    "clientList": "კლიენტების სია",
    "processEndDate": "დასრულების დრო",
    "groupName": "გრუპის სახელი",
    "numebrOfClients": "კლიენტების რაოდენობა",
    "uploadedUsersamount": "ატვირთული მომხმარებლების რაოდენობა",
    "source": "წყარო",
    "status": "სტატუსი",
    "failed": "შეცდომა",
    "action": "მოქმედება"
}

export default data;