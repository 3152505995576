import React from "react";

const Error_404 = () => {
    return (
        <>
            <style>{` body iframe { display: none; } `}</style>
            <h1>404 Error</h1>
        </>
    
    
    )
}

export default Error_404;