//create languages for specifications module
let data = {
    "specifications": "სპეციფიკაციები",
    "specificationName": "სპეციფიკაციის სახელი",
    "specificationTemplateName": "სპეციფიკაციის შაბლონის სახელი",
    "chooseType": "აირჩიე ტიპი",
    "chooseColor": "აირჩიე ფერი",
    "uploadPhoto": "ატვირთე ფოტო",
}

export default data;