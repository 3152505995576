import React from "react";
import { IconMdPerson } from "../../../../icons/components";

export const TableImageComponent = ({item}) => {
  return (
    item.hasImg ? (
      <div className={`img-box ${item.roundedImg ? "rounded" : ""}`}>
        {item.hasOnlineStatus && <div className={`online-status ${item.isOnline ? "online" : 'offline'}`}></div>}
        {item.imgUrl ? <img src={item.imgUrl} alt="" className="img" /> : item.imgIcon ? item.imgIcon : <IconMdPerson className="icon" />}
      </div>
    ) : <></>
  );
};

export default TableImageComponent;
