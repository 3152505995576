//create languages for auth module
let data = {
    "keyName": "გასაღების სახელი",
    "translated": "გადათარგმნილი",
    "module": "მოდული",
    "type": "ტიპი",
    "keySuccessfulyDeleted": "გასაღები წარმატებით წაიშალა",
    "translation": "თარგმანი",
    "keySuccessfulyAdded": "გასაღები წარმატებით დაემატა",
    "keySuccessfulyUpdated": "გასაღები წარმატებით განახლდა"
}

export default data;