import React, { useContext, useEffect } from 'react';
import { Observer } from 'mobx-react';

import { useTranslations } from 'shared-modules/translation-module';
import { UrlSearchStore } from 'shared-modules/url-helper-module';

import SearchStore from './store';

import { MBox, MTextField, MTextValidator } from '../../../material';
import { IconMdSearch } from '../../../icons/components';

import styles from './styles.module.css';

export const ThirdCustomSearch = React.forwardRef(
  ({ classText, disabeld, data, searcPropertyName = 'searchItems', searchHandler }, ref) => {
    const { t } = useTranslations();
    const searchStore = useContext(SearchStore);
    const urlSearchStore = useContext(UrlSearchStore);

    const searchOnChange = (e) => {
      searchStore.inputText = e?.target?.value ?? '';
    };
    useEffect(() => {
      if (data?.length) {
        searchStore.inputText = urlSearchStore.getQuery() ?? '';
      }
    }, [data]);

    useEffect(() => {
      if (data?.length > 0 && searchStore.inputText)
        searchHandler(
          searchStore.searchHandler(data, urlSearchStore.getQuery(), searcPropertyName)?.filter((o) => o)
        );
      else if (data?.length) {
        searchHandler(data);
      }
    }, [searchStore.inputText]);

    let searchTime = undefined;

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission when Enter is pressed
      }
    };

    return (
      <div className={classText} style={{ pointerEvents: disabeld ? 'none' : 'auto' }}>
        <MBox noValidate autoComplete="off" className={`${styles.customSearchForm} `}>
          <IconMdSearch className={styles.searchInputIcon} />
          <Observer
            render={() => {
              return (
                <MTextField
                  name=""
                  type="text"
                  label={t['search']}
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                  variant="outlined"
                  value={searchStore.inputText}
                  onChange={searchOnChange}
                  onKeyDown={(e) => {
                    clearTimeout(searchTime);
                    searchTime = setTimeout(() => {
                      urlSearchStore.setSearch(searchStore.inputText);
                    }, 500);
                  }}
                />
              );
            }}
          />
        </MBox>
      </div>
    );
  }
);
export default ThirdCustomSearch;
