import logout from "./logOut";
import { AuthLocalStorageStore } from "shared-modules/local-storage-module";

//refreshing user token without log out user
export async function requestRefreshToken(originalRequest, Axios) {
    const authLocalStorageStore = new AuthLocalStorageStore();
    //get refresh token from local storage
    const refToken = authLocalStorageStore.getRefreshToken();
    //check if refresh token is exsists
    if(refToken) {
        //make request to server and get user token and new refresh token
        var resp = (await Axios.post("/RefreshToken", { refreshtoken: refToken }))?.data;
        //check if all ok
        if(!resp.isError) {
            //set new refresh token
            authLocalStorageStore.setRefreshToken(resp.data.refreshToken);
            //set new user token
            authLocalStorageStore.setUserToken(resp.data.token);

            //set new user token to axios api manager
            Axios.defaults.headers.common.Authorization = `Bearer ${resp?.data?.token}`

            //set new token to prev reqvest and retray pev request again
            if(originalRequest !== null) {
                originalRequest.headers.Authorization = `Bearer ${resp?.data?.token}`;
                return await Axios(originalRequest);
            }
        } 
        //if server crashed logout user
        else logout();
    }
    //if refresh token does not exist in local storage
    else logout();
}

export default requestRefreshToken;