import React from "react";

import { IconMdClose } from "../../../icons/components";

import styles from "./styles.module.css";

export const SelectedTags = ({ data, handleDelete = () => {}, disabled = false }) => {
  return (
    <React.Fragment>
      {
        data?.length ?
          <div className={`${styles.items}`}>
            {
              data.map((item, index) => {
                return(
                  <div className={`${styles.item} ${disabled ? styles.disabled : ''}`} key={index}>
                    {item?.name}

                    {
                      !disabled && <div className={styles.removeButton} onClick={() => handleDelete(item?.id)} ><IconMdClose /></div>
                    }
                  </div>
                )
              })
            }
          </div>
        : null
      }
    </React.Fragment>
  );
};
export default SelectedTags;
