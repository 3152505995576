import React, { useContext } from "react";
import { Observer } from "mobx-react";

import { UserManagerStore } from "shared-modules/user-manager-module";
import { PageLoader, PageContent, MBox } from "shared-modules/ui-lib-module";
import { LanguageStore } from "shared-modules/url-helper-module";

import { entryStoreContext } from "shared-modules/shared-store-module";

import Header from "./Header";
import Drawer from "./Drawer";

import styles from "./styles.module.css";

const Layout = ({ children }) => {
  const userStore = useContext(UserManagerStore);
  const entryModuleStore = useContext(entryStoreContext);
  const languageStore = useContext(LanguageStore);

  return  <Observer render={() => (
    <div>
      {userStore.isAuthorized ? (
        <MBox className={styles.mainWrapper}>
          <Drawer />
          <MBox className={styles.rightSide}>
            <Header />
            <main>
              <PageContent>
                <Observer render={() => (
                  <PageLoader isLoading={entryModuleStore.loading.drawerLoading || languageStore.showLoading}>{children}</PageLoader>
                )} />
              </PageContent>
            </main>
          </MBox>
        </MBox>
      ) : children}
      {/* {!userStore.isAuthorized && children} */}
    </div>
  ) }/> 
};

export default Layout;
