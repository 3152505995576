import { I18NConfigStore } from "shared-modules/translation-module/classes";
import { toast } from "react-toastify";
import { IconMdError } from "../../../../icons";


const warningToast = (message, options) => {
    const i18NConfigStore = new I18NConfigStore();
    const t = i18NConfigStore.GetLanguageResource({ lng: localStorage.i18nextLng, ns: location.pathname.split('/').length > 2 ? location.pathname.split('/')[1] : 'default' });

    toast.warning(t ? t[message] ?? message : "Message", {
        icon: IconMdError,
        toastId: message,
        ...options
    });
}

// export default  
export default warningToast
