import React  from "react";

import MTextField from "../../../material/components/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MomentUtils from '@date-io/moment';

import styles from "./styles.module.css";

export const CustomDatePicker = ({label, date, onChange, ...props}) => (
  <LocalizationProvider dateAdapter={MomentUtils}>
    <DatePicker
      {...props}
      label={label}
      value={date}
      onChange={onChange}
      classes={{
        root: 'date-picker-wrap'
      }}
      renderInput={(params) => <MTextField autoComplete="off" variant="outlined" {...params} />}
    />
  </LocalizationProvider>
);

export default CustomDatePicker;
