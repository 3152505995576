import mobxRouting from '../historyStore';

export const useSearchParams = () => {
  const getFromUrlParams = (key) => {
    return getFromUrlParams().get(key);
  };

  const addSearchParams = (newQueryString) => {
    let searchQuery = getUrlSearchParams();

    mobxRouting.history.push({ search: `${searchQuery.toString()}&${newQueryString}` });
  };

  const removeSearchParams = (keysToRemove) => {
    const params = getUrlSearchParams();

    keysToRemove?.map((key) => {
      params.delete(key);
    });

    mobxRouting.history.replace({ search: params?.toString() ?? '' });
  };

  const getUrlSearchParams = () => {
    return new URLSearchParams(window.location.search);
  };

  return {
    getFromUrlParams,
    addSearchParams,
    removeSearchParams,
  };
};
