import React from "react";
import moment from "moment";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import MomentUtils from '@date-io/moment';

import styles from "./styles.module.css";
import { MTextValidator, MValidatorForm } from "../../../material";

export const CustomDateTimePicker = ({label, validators = [], errorMessages= [], disabled = false, date, onChange}) => (
  <LocalizationProvider libInstance={moment} dateAdapter={MomentUtils}>
    <DateTimePicker
      ampm={false}
      label={label}
      value={date}
      onChange={disabled ? () => {} : onChange}
      disabled={disabled}
      classes={{
        root: 'date-picker-wrap'
      }}
      renderInput={(params) => 
        <MValidatorForm>
          <MTextValidator 
            {...params} 
            autoComplete="off" 
            variant="outlined" 
            disabled={disabled} 
            validators={validators} 
            errorMessages={errorMessages}  
            value={date ? moment(date) : true}
          />
        </MValidatorForm>
      }
    />
  </LocalizationProvider>
);

export default CustomDateTimePicker;
