import React from "react";
import { MButton } from "../../../material";
import { useTranslations } from "shared-modules/translation-module";

export const PageBottomButtons = ({ isProcessing, onCancel, submitText, saveBtn = true, cancelBtn = true }) => {
  const { t } = useTranslations();


  let saveText = submitText ? submitText : t["save"]

  return (
    <div className="page-bottom-btns">
      {
        saveBtn && (
          <MButton className="f-bold" type="submit" processing={isProcessing}>
            {saveText}
          </MButton>
        )
      }
      {   
        cancelBtn && (
          <MButton className={`f-bold ${!saveBtn ? 'tonal' : 'transparent'}`} onClick={onCancel}>
            {t["cancel"]}
          </MButton>
        )
      }
    </div>
  );
};

export default PageBottomButtons;
