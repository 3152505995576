import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const AuthLocalStorageStore = class {
    constructor() {
        makeAutoObservable(this);
        this.refreshTokenKey = "refreshToken";
        this.userTokenKey = "userToken";
        this.HQTokenKey = "HQToken";
        this.isKeepMeKey = "keepMeSignedIn";
    }

    setRefreshToken(value) {
        localStorage.setItem(this.refreshTokenKey, value);
    }

    setUserToken(value) {
        localStorage.setItem(this.userTokenKey, value);
        if(this.getRefreshToken()) {
            document.dispatchEvent(new Event('refreshTokenChangeEvent'));
        }
    }

    setHQToken(value) {
        localStorage.setItem(this.HQTokenKey, value);
    }

    setIsKeepMe(value) {
        localStorage.setItem(this.isKeepMeKey, value);
    }

    replaceUserTokeWithHQToken() {
        if(!localStorage.getItem(this.HQTokenKey)) return false;
        this.setUserToken(localStorage.getItem(this.HQTokenKey));
        this.removeHQToken();
        return true;
    }


    getRefreshToken() {
        return localStorage.getItem(this.refreshTokenKey);
    }

    getUserToken() {
        return localStorage.getItem(this.userTokenKey);
    }

    getIsKeepMe() {
        return localStorage.getItem(this.isKeepMeKey);
    }


    removeRefreshToken() {
        return localStorage.removeItem(this.refreshTokenKey);
    }

    removeUserToken() {
        return localStorage.removeItem(this.userTokenKey);
    }

    removeHQToken() {
        return localStorage.removeItem(this.HQTokenKey);
    }

    removeIsKeepMe() {
        return localStorage.removeItem(this.isKeepMeKey);
    }
};

export default createContext(new AuthLocalStorageStore());
