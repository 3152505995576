//create languages for specifications module
let data = {
    "sales-points": "Sales Points",
    "baseType": "Base Type",
    "addNewSalesPoint": "Add New Sales Point",
    "generalInformation": "General Information",
    "location": "Location",
    "address": "Address",
    "long": "longitude",
    "lat": "latitude",
    "city": "City",
    "workingHours": "Working Hours",
    "workingTimes": "Working Times",
    "addHours": "Add Hours",
    "AddYourAddress": "Add Your Location Address",
    "salesPointCompanyNoErp": "Sales point company has't ERP"
}

export default data;