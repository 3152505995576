import React, { useState, useRef, useContext } from "react";
import { Observer } from "mobx-react";

import { UrlSearchStore } from "shared-modules/url-helper-module";
import { useTranslations } from "shared-modules/translation-module";

import { MButton, MBox } from "../../../material";
import { IconMdSearch, IconMdAccessTime } from "../../../icons/components";
import { MCSSTransition } from "../../../transitions/components/CSSTransition";

import styles from "./styles.module.css";
import { useEffect } from "react";
import { CustomSearchStore } from "./store";

export const CustomSearch = ({
  classText,
  data,
  setSearchedDataHenler,
  searcPropertyName = "name",
  disableSuggestions = false,
  placeholder = "search",
  type = "text"
}) => {
  const params = new URLSearchParams(window.location.search);
  const customSearchStore = useContext(CustomSearchStore);
  const urlSearchStore = useContext(UrlSearchStore);

  const ref = useRef(null);
  const { t } = useTranslations();

  const [isSearched, setIsSearched] = useState(false);
  const [isInputFoused, setIsInputFoused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchedItems, setSearchedItems] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handleClickOutside = (event) => {
    if (ref.current && !ref?.current?.contains(event?.target)) {
      setIsInputFoused(false);
    }
  };

  useEffect(()=> {
    document.addEventListener("click", handleClickOutside, true);
    return ()=> {
      document.removeEventListener("click", handleClickOutside);
    }
  },[])

  const searchHandler = (searchParam) => {
    return data
      ?.map((pageWithModuleName) => {
        return {
          name: pageWithModuleName[searcPropertyName]
            ?.toLocaleLowerCase()
            ?.replace(searchParam?.toLocaleLowerCase(), "@-@")
            ?.replace(/\s/g, "!")
            ?.split(/(@-@)/)
            ?.map((part) => {
              return part === "@-@"
                ? `<span>${searchParam
                    ?.toLocaleLowerCase()
                    ?.replace(
                      " ",
                      '<span style="margin: 0 2px"></span>'
                    )}</span>`
                : `<span style="opacity: 0.3"}>${part}</span>`;
            })
            .join("")
            .replace(/\!/g, '<span style="margin: 0 2px"></span>'),
          normalName: pageWithModuleName[searcPropertyName],
          module: pageWithModuleName.moduleName,
          icon: pageWithModuleName["icon"],
          url: pageWithModuleName.url,
          companyData: pageWithModuleName,
        };
      })
      .filter((o) =>
        o.normalName
          ?.toLocaleLowerCase()
          ?.includes(searchParam?.toLocaleLowerCase())
      );
  };

  const searchOnChange = async (e) => {
    const inputVal = e?.target?.value ?? e;

    if (inputVal === "") setFilteredData(data);
    if (customSearchStore.input === "" && inputVal === " ") return;
    if (showSuggestions && customSearchStore.input === "" && inputVal === "") {
      return;
    }

    if (!inputVal && !isSearched) {
      setFilteredData(data);
    }

    let searchResult = searchHandler(inputVal);

    customSearchStore.setInput(inputVal);
    setShowSuggestions(true);
    setSearchedItems(searchResult?.map((o) => o?.companyData));
    setFilteredSuggestions(searchHandler(inputVal));
  };

  const handleSearch = (e, isEnter) => {
    setShowSuggestions(true);
    searchOnChange(customSearchStore.input);
    if (customSearchStore.input === "") setFilteredData(data);
    if (!customSearchStore.input) return;
    if (
      isSearched &&
      !customSearchStore.input &&
      isEnter
      // || (customSearchStore.input && filteredSuggestions?.length < 1)
    ) {
      setFilteredData(data);
      return;
    }

    if (searchedItems?.length === 1)
      customSearchStore.setInput(searchedItems[0][searcPropertyName]);
    if (searchedItems?.length > 0) setShowSuggestions(false);

    if (isSearched && !customSearchStore.input) {
      setIsSearched(false);
      setSearchedItems([]);
      setFilteredSuggestions([]);
      setFilteredData(searchedItems);
      return;
    }

    if (isEnter && isSearched && filteredSuggestions?.length > 0) {
      setFilteredData(searchedItems);
      setIsSearched(false);
      return;
    }

    if (searchedItems?.length > 0 && searchedItems?.length > 0 && !isSearched) {
      setFilteredData(searchedItems);
      setIsSearched(true);
    } else {
      setFilteredData(searchedItems);
      setIsSearched(true);
    }
  };

  const chooseField = (data) => {
    setFilteredData([data]);
    setIsSearched(true);
  };

  useEffect(() => {
    filteredData?.length > 0 &&
      setSearchedDataHenler(filteredData, customSearchStore.input);
  }, [filteredData]);

  useEffect(() => {
    if (data?.length && params.get(UrlSearchStore.urlKey)) {
      searchOnChange(params.get(UrlSearchStore.urlKey) ?? "");
      handleSearch();
    }
  }, [data]);

  const SuggestionsListComponent = () => {
    return filteredSuggestions?.length !== 0 ? (
      <ul className={`${styles.suggestionsBox} suggestions-box`} ref={ref}>
        <h3 className={`${styles.suggestionsSearchingTitle} f-bold`}>
          {t["searching"]}
        </h3>
        {filteredSuggestions?.map((suggestion, index) => {
          return (
            <li
              className={styles.findedSuggestionItem}
              key={index}
              onClick={() => {
                chooseField(suggestion?.companyData);
                customSearchStore.setInput(
                  suggestion.companyData[searcPropertyName]
                );
                setIsInputFoused(false);
              }}
            >
              <div className={styles.findedSuggestionIcon}>
                <IconMdAccessTime />
              </div>
              <p className={`${styles.findedSuggestionTitle} f-bold`}>
                <span
                  className={`current-suggestion-text`}
                  dangerouslySetInnerHTML={{ __html: suggestion["name"] }}
                ></span>
                <span className={`${styles.findedSuggestionModuleName} f-bold`}>
                  Column Name
                </span>
              </p>
            </li>
          );
        })}
      </ul>
    ) : (
      <div className={styles.suggestionsBox} ref={ref}>
        <h3 className={`${styles.suggestionsSearchingTitle} f-bold`}>
          {t["searching"]}
        </h3>
        <p className={`${styles.noSuggestionsText} f-bold`}>
          {t["sorry_no_suggestions"]}
        </p>
      </div>
    );
  };

  return (
    <Observer
      render={() => (
        <React.Fragment>
          <MBox noValidate className={styles.customSearchForm}>
            <input
              placeholder={placeholder}
              label={placeholder}
              type={type}
              name="companySearch"
              autoComplete="off"
              className={`${styles.searchInput} ${classText}`}
              onChange={searchOnChange} //onChange
              onBlur={() => setTimeout(() => setIsInputFoused(false), 200)}
              onFocus={() => setIsInputFoused(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(e, true);
                  if (disableSuggestions)
                    setSearchedDataHenler(
                      filteredData,
                      customSearchStore.input
                    );
                }
              }}
              value={customSearchStore.input ?? ""}
              // autoFocus={true}
            />
            <MButton className={`${styles.searchInputBtn} f-bold`}>
              <IconMdSearch
                className={styles.searchInputIcon}
                onClick={() => {
                  handleSearch();
                  setSearchedDataHenler(filteredData, customSearchStore.input);
                }}
              />
            </MButton>
            <MCSSTransition
              in={Boolean(
                showSuggestions &&
                  customSearchStore.input &&
                  isInputFoused &&
                  !disableSuggestions
              )}
              unmountOnExit
              timeout={100}
            >
              <SuggestionsListComponent />
            </MCSSTransition>
          </MBox>
          <style>{`
          // .resized-search {
          //   width: 155px;
          // }
          
          // .resized-search:focus {
          //   width: 344px;
          // }
  
          .suggestions-box.enter-active,
          .suggestions-box.enter-done {
            opacity: 1;
          }
  
          .suggestions-box.exit-active,
          .suggestions-box.exit-done {
            opacity: 0;
          }
        `}</style>
        </React.Fragment>
      )}
    />
  );
};
export default CustomSearch;
