import React from "react";
import { IconMdOutlineCloudOff } from "../../../icons/components";
import styles from "./styles.module.css";

export const NoDataMessage = ({ title, hasIcon = true }) => {
  return (
    <div className={`d-flex align-items-center flex-column ${styles.wrapper}`}>
      { hasIcon && <IconMdOutlineCloudOff className={styles.icon} /> }
      <h2 className={`${styles.message} f-upper f-bold`}>{title}</h2>
    </div>
  )
};
export default NoDataMessage;
