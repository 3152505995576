//create languages for auth module
let data = {
    "client": "კლიენტი",
    "clients": "კლიენტები",
    "clientsList": "კლიენტების ლისტი",
    "shop": "მაღაზია",
    "birthDate": "დაბადების თარიღი",
    "registerSource": "პირველწყარო",
    "sources": "წყარო",
    "User": "მომხმარებელი",
    "userDetails": "დეტალები",
    "rolesSuccessfulyDeleted": "როლები წარმატებით წაიშალა",
    "rolesUnSuccessfulyDeleted": "როლიები ვერ წაიშალა",
    "rolesSuccessfulyEdeted": "მომხამრებლის როლები წარმატებით შეიცვალა",
    "rolesUnSuccessfulyEdeted": "მომხამრებლის როლების  შეიცვალა ვერ მხოერხდა",
    "usersSuccessfulyDeleted": "წარმატებით წაიშალნენ მომხამრებლები",
    "usersUnSuccessfulyDeleted": "მომხამრებლების წაშლა ვერ მოხერხდა",
    "userSuccessfulyAdded": "მომხმარებელი წარმატებით დაემატა",
    "userUnSuccessfulyAdded": "მომხმარებლის დამატება ვერ მოხერხდა",
    //transactions
    "transactions": "ტრაზაქციები",
    "id": "Id",
    "Id": 'Id',
    "UserId": 'მომხმარებლის Id',
    "TransationRefId": 'გარე ID',
    "PaymentDate": 'გადახდის თარიღი',
    "OrderDate": 'შეკვეთის თარიღი',
    "CustomerId": 'მომხმარებლის Id',
    "TransactionStatus": 'სტატუსი',
    "TransactionType": 'ტიპი',
    "PaymentChannel": 'გადახდის არხი',
    "PaymentType": 'გადახდის ტიპი',
    "SalePointId": 'გაყიდვის არხის Id',
    "SalesPointName": 'გაყიდვის არხის სახელი',
    "SalesPointType": 'გაყიდვის არხი',
    "SalesPointTypeValue": 'გაყიდვის არხის ტიპის მნიშვნელობა',
    "Amount": 'ღირებულება',
    "Currency": 'ვალუტა',
    "FreeCapsuleAmount": 'უფასო კაფსულა',
    "CompanyId": 'კომპანია Id',
    "CompanyName": 'კომპანია',
    "CardNumber": 'ბარათის ნომერი',
    "Cashier": 'მოლარე',
    "OrderCode": 'შეკვეთის კოდი',
    "UniqueCode": 'უნიკალური კოდი',
    "ChangeTime": 'შეცვლის დრო',
    "DetailedInformation": 'დეტალის ინფორმაცია',
    "TransactionDetails": 'ტრანზაქციის დეტალი',
    "ProductsDetails": 'პროდუქტის დეტალი',
    "History": 'ისტორია',
    "city": 'ქალაქი',
    "district": "რაიონი",
    "default": 'ძირითადი',
    "address": 'მისამართი',
    "changeInfo": "ცვლილების ინფორმაცია",
    "comments": "კომენტარები",
    "smsInfo": "SMS ინფორმაცია",
    "title": "სათაური",
    "sentDate": "გაგზავნის თარიღი",
    "message": "შეტყობინება",
    "callCenter": "ქოლ ცენტრი",
    "date": "თარიღი",
    "message": "შეტყობინება",
    "primary": "პირველადი",
    "isDiplomat": "დიპლომატია",
    "contactInfo": "საკონტაქტო ინფორმაცია",
    'verificationCode': 'ვერიფიკაციის კოდი',
    "PleaseTypeTheVerificationCodeSentTo": "გთხოვთ ჩაწეროთ ვერიფიკაციის კოდი რომელიც გაიგზავნა ნომერზე",
    "selectNumber": "აირჩიე ნომერი",
    "sendSuccessfully": "წარმატებით გაიგზავნა",
    "customerAddedSuccessfully": "მომხმარებელი წარმატებით დაემატა",
    "verifyClient": "კლიენტის ვერიფიკაცია",
    "districtId": "რაიონის ID",
    "sendOtpToUser": "OTP გაიგზავნა"
}

export default data;