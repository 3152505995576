import React, { useState } from "react";
import { MMenuItem, MMenu } from "../../../../material/components";
import { IconMdKeyboardArrowDown } from "../../../../icons/components";

import styles from "../styles.module.css";

export const TableViewPerPageComponent = ({ rowPerPageCount, handleRowPerPageChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (count) => handleRowPerPageChange(count);

  return (
    <React.Fragment>
      <div className={`${styles.viewPerPageBtn} d-flex align-items-center`}>
        <div className={styles.viewPerPageTitle}>Rows per page:</div>
        <div
          className={styles.viewPerPageDropdownBtn}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <span className={styles.viewPerPageDropdownBtnTitle}>{rowPerPageCount}</span>
          <IconMdKeyboardArrowDown
            className={`${styles.viewPerPageDropdownBtnArrow} ${
              Boolean(anchorEl) ? styles.visible : ""
            }`}
          />
        </div>
        <MMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          id="table-view-per-page-menu"
        >
        <MMenuItem
          key={`page-per-view-sort-options-1`}
          onClick={() => {
            handleChange(10);
            setAnchorEl(null);
          }}
          className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 10 ? styles.active : ""}`}
        >
          10
        </MMenuItem>
        <MMenuItem
          key={`page-per-view-sort-options-1`}
          onClick={() => {
            handleChange(20);
            setAnchorEl(null);
          }}
          className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 20 ? styles.active : ""}`}
        >
          20
        </MMenuItem>
          <MMenuItem
            key={`page-per-view-sort-options-2`}
            onClick={() => {
              handleChange(50);
              setAnchorEl(null);
            }}
            className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 50 ? styles.active : ""}`}
          >
            50
          </MMenuItem>
          <MMenuItem
            key={`page-per-view-sort-options-3`}
            onClick={() => {
              handleChange(100);
              setAnchorEl(null);
            }}
            className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 100 ? styles.active : ""}`}
          >
            100
          </MMenuItem>
        </MMenu>
      </div>
    </React.Fragment>
  );
};

export default TableViewPerPageComponent;
