import { createContext } from 'react';
import { makeObservable, observable, computed, action, reaction } from 'mobx';
import mobxRouting from '../historyStore';
import { getLocalFilterStore, hasFilterSet } from '../utils/functions';

export const UrlFilterStore = class {
  // #region local filter methods
  setFilter = (queryString) => mobxRouting.replace({ search: queryString });

  filterHandler = (data, filterOptions, ignoreProperties = []) => {
    let params = new URLSearchParams(window.location.search);
    let filteredData = data;
    Object.keys(filterOptions).map((key) => {
      if (!filterOptions[key] || !filterOptions[key].length) {
        params.delete(key);
        return;
      }
      //check types of sort options
      if (typeof filterOptions[key] === 'string') {
        //set querystring
        params.set(key, filterOptions[key]);
        if (!ignoreProperties.some((o) => o === key))
          filteredData = filteredData?.filter((el) =>
            String(el[key])?.toLowerCase()?.includes(filterOptions[key]?.toLowerCase())
          );
      }
      //if filteroption is array
      else if (filterOptions[key]) {
        if (!filterOptions[key]?.length) {
          this.deleteQueryString([key]);
          return;
        }
        //set querystring
        params.set(key, filterOptions[key]);
        if (!ignoreProperties.some((o) => o === key))
          filteredData = filteredData.filter((el) => filterOptions[key].some((i) => el[key]?.includes(i)));
      }
    });
    //set finnaly query string
    this.setFilter(params.toString());
    return filteredData;
  };

  deleteQueryString = (querys) => {
    let params = new URLSearchParams(window.location.search);
    querys.map((query) => params.delete(query));
    mobxRouting.replace({ search: params?.toString() ?? '' });
  };
  // #endregion

  // #region server filter methods
  //filter objs
  filterModel = {};
  savedFilters = [];
  filter = this.filterModel;
  observable = 0;
  arrowAction = () => {};

  // Non-annotated instance fields are overridable
  overridableArrowAction = action(() => {});

  // Annotated prototype methods/getters are overridable
  action() {}
  actionBound() {}
  get computed() {}

  //sort objs
  sortOptions = [{ value: 'noSort', isDefault: true }];
  sort = { sort: '' };

  disableFilterProps = [];

  get generateFilterUrlForServer() {
    this.initFromUrl();
    const keys = Object.keys({ ...this.filterModel, ...this.sort });
    const filterAndSortValues = { ...this.filter, ...this.sort };
    let searchParams = '';
    keys.map((key) => {
      if (filterAndSortValues[key]?.length === 0 || filterAndSortValues[key] === 0 || key === 'sort') return; //|| filterAndSortValues[key] === 'false'
      if (
        Array.isArray(filterAndSortValues[key]) &&
        filterAndSortValues[key]?.length > 0 &&
        typeof filterAndSortValues[key] !== 'string'
      ) {
        filterAndSortValues[key]?.forEach((o) => {
          if (o && o !== '' && o !== ' ') searchParams += `&${key}=${o}`;
        });
      } else if (filterAndSortValues[key]) {
        searchParams += `&${key}=${filterAndSortValues[key]}`; // && filter[key] !== 'false'
      }
    });
    return searchParams;
  }

  params = (data) => {
    let search = new URLSearchParams(window.location.search);
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (!data[key]) search.delete(key);
      // if(data[key] === "false") search.delete(key);
      if (data[key]?.length === 0) search.delete(key);

      if (data[key] && data[key]?.length !== 0) {
        search.set(key, encodeURIComponent(data[key]));
      }
    });
    return search.toString();
  };

  initFromUrl = () => {
    this.initFromQuery(mobxRouting.history.location.search);
  };

  initFromQuery = (queryString) => {
    let query = new URLSearchParams(queryString);
    //filter
    Object.getOwnPropertyNames(this.filterModel).forEach((propName) => {
      if (query.get(propName)) {
        if (Array.isArray(this.filterModel[propName])) {
          this.filter[propName] = decodeURIComponent(query.get(propName))?.split(',') ?? [];
        } else {
          this.filter[propName] = decodeURIComponent(query.get(propName));
        }
      }
    });

    //sort
    Object.getOwnPropertyNames(this.sort).forEach((key) => {
      if (key !== 'sort' && query.get(key)) {
        this.sort[key] = query.get(key);
      }
    });
  };

  saveFiltersInLocalstorage = (filterName) => {
    if (!filterName) return;

    const filtersStore = [...this.savedFilters];
    const newFilter = { name: filterName, filterQuery: this.generateFilterUrlForServer };

    const { index: filterIndex } = this.findFilterByName(filterName);

    if (filterIndex !== -1) {
      filtersStore[filterIndex] = newFilter;
    } else {
      filtersStore.push(newFilter);
    }

    this.savedFilters = filtersStore;
  };

  setFiltersFromLocalstorage = (filterName) => {
    this.clearFilter(false);

    const { filter } = this.findFilterByName(filterName);

    if (!filter) {
      return console.warn(`Could now find filter with name ${filterName}`);
    }

    this.initFromQuery(filter.filterQuery);
  };

  removeSavedFilter = (filterName) => {
    this.savedFilters = this.savedFilters.filter(({ name }) => name !== filterName);
  };

  saveLocalStore = (filters) => {
    localStorage.setItem(window.location.pathname, JSON.stringify(filters));
  };

  findFilterByName = (filterName) => {
    const index = this.savedFilters.findIndex((filterItem) => filterItem.name === filterName);
    const filter = index !== -1 ? this.savedFilters[index] : null;

    return {
      filter,
      index,
    };
  };

  setUrl = () => mobxRouting.replace({ search: this.params({ ...this.filter, ...this.sort }) });

  clearFilter = (syncUrl = true) => {
    const keys = Object.keys(this.filterModel);
    let search = new URLSearchParams(mobxRouting.location.search);
    keys.forEach((key) => {
      if (!this.disableFilterProps?.some((o) => o?.toLowerCase() === key?.toLowerCase())) {
        search.delete(key);
        this.filter[key] = this.filterModel[key];
      }
    });

    if (syncUrl) {
      mobxRouting.replace({ search: search.toString() });
    }
  };

  removeFilterByKey = (key) => {
    let search = new URLSearchParams(mobxRouting.location.search);
    search.delete(key);
    mobxRouting.replace({ search: search.toString() });
  };

  // #endregion

  getChoosedFilter = () => {
    let params = new URLSearchParams(window.location.search);
    return Object.keys(this.filterModel)
      .map((o) => {
        if (
          params.get(o) &&
          // params.get(o) !== 'false' &&
          o?.toLowerCase() !== 'sortByDateDesc'.toLowerCase() &&
          o?.toLowerCase() !== 'descending' &&
          o?.toLowerCase() !== 'orderby'
        )
          return true;
        return false;
      })
      .filter((o) => o);
  };

  get getSavedFiltersNames() {
    return this.savedFilters.map(({ name }) => name);
  }

  get hasFilter() {
    return hasFilterSet(this.filter);
  }

  get filtersCount() {
    return this.getChoosedFilter()?.length;
  }

  constructor(value) {
    makeObservable(this, {
      observable: observable,
      arrowAction: action,
      action: action,
      actionBound: action.bound,
      computed: computed,

      filter: observable,
      sort: observable,
      savedFilters: observable,
      saveFiltersInLocalstorage: action,
    });

    reaction(
      () => this.savedFilters,
      (filters) => {
        this.saveLocalStore(filters);
      }
    );

    this.savedFilters = getLocalFilterStore();
    this.filter = { ...this.filterModel };
  }
};

export default createContext(new UrlFilterStore());
