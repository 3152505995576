import React from "react";
import { MTableHead, MTableRow, MTableCell } from "../../../../material/components";
import { IconMdArrowDownward, IconMdArrowUpward } from "../../../../icons/components";

export const TableHeadComponent = ({data, isPositive, buttonsLength, handleSort}) => {
  return (
    <MTableHead>
      <MTableRow>
        {data.head?.map((item, index) => (
          <MTableCell 
            className={`
            ${item.isCheckbox ? "checkbox-cell" : ""} 
            ${item.isDropdownIcon ? "dropdown-icon-cell" : ""} 
              ${item.isSortable ? "sortable-head-label" : ""} 
              f-bold
            `} 
            key={index}>
            <span className={`table-head-label`}>
              {item.label}
              {item.isSortable && (
                isPositive ? <IconMdArrowDownward onClick={() => handleSort(index, item.isSortableFromServer)} className="sortable-icon" /> 
                : <IconMdArrowUpward onClick={() => handleSort(index, item.isSortableFromServer)} className="sortable-icon" />
                )}
            </span>
          </MTableCell>
        ))}
        {buttonsLength > 0 && <MTableCell className="f-bold actions sticky"></MTableCell>}
      </MTableRow>
    </MTableHead>
  );
};

export default TableHeadComponent;
