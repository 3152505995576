import React, { useContext, useEffect } from "react";

import { CustomSearchStore } from "../CustomSearch/store";

import styles from "./styles.module.css";

export const PageFilterSection = ({
  SyncChangesComponent,
  ExportDataComponent,
  SearchComponent,
  FilterComponent,
  SortComponent
}) => {
  const customSearchStore = useContext(CustomSearchStore);

  useEffect(() => {
    customSearchStore.setInput('');
  }, []);

  return (
    <div className={styles.filterBox}>
      {SyncChangesComponent && (
        <div className={`${styles.right} ${styles.syncChangesBox}`}>
          <div className={styles.filterItem}>
            <SyncChangesComponent />
          </div>
        </div>
      )}
      {ExportDataComponent && (
        <div className={`${styles.right} ${styles.exportDataBox}`}>
          <div className={styles.filterItem}>
            <ExportDataComponent />
          </div>
        </div>
      )}
      {SearchComponent && (
        <div className={styles.left}>
          <div className={styles.filterItem}>
            <SearchComponent />
          </div>
        </div>
      )}
      {FilterComponent && (
        <div className={styles.right}>
          <div className={styles.filterItem}>
            <FilterComponent />
          </div>
        </div>
      )}
      {SortComponent && (
        <div className={styles.right}>
          <div className={styles.filterItem}>
            <SortComponent />
          </div>
        </div>
      )}
    </div>
  );
};
export default PageFilterSection;
