import React from "react";

import { useTranslations } from "shared-modules/translation-module";

import styles from "./styles.module.css";

const SuggestionsListComponent = ({
  filteredSuggestions,
  chooseField,
  history,
  activeIndex = 0,
}) => {
  const { t } = useTranslations();

  return filteredSuggestions.length !== 0 ? (
    <ul className={styles.suggestionsBox}>
      <h3 className={`${styles.suggestionsSearchingTitle} f-bold`}>
        {t["searching"]}
      </h3>
      {filteredSuggestions.map((suggestion, index) => {
        return (
          <li
            className={`${styles.findedSuggestionItem} ${activeIndex === index ? styles.activeItem +" active-search-suggestion" : ""}`}
            key={index}
            onClick={() => {
              chooseField(suggestion["normalName"]);
              history && history.push(suggestion["url"]);
            }}
          >
            <div className={styles.findedSuggestionIcon}>
              {suggestion["icon"] ? suggestion["icon"]() : null}
            </div>
            <div className={`${styles.findedSuggestionTitle} f-bold`}>
              <div
                className={`current-suggestion-text`}
                dangerouslySetInnerHTML={{ __html: suggestion["name"] }}
              ></div>
              <span className={`${styles.findedSuggestionModuleName} f-bold`}>
                {suggestion["module"]}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  ) : (
    <div className={styles.suggestionsBox}>
      <h3 className={`${styles.suggestionsSearchingTitle} f-bold`}>
        {t["searching"]}
      </h3>
      <p className={`${styles.noSuggestionsText} f-bold`}>
        {t["sorry_no_suggestions"]}
      </p>
    </div>
  );
};

export default SuggestionsListComponent;
