// exporting json from ts file
var data = {
  id: 'Id',
  uniqueId: 'უნიკალური Id',
  email: 'მეილი',
  name: 'სახელი',
  password: 'პაროლი',
  keepMe: 'დამიმახსოვრე',
  signIn: 'შესვლა',
  confirmingEmail: 'მეილი დასტურდება',
  confirmedEmail: 'მეილიდ დადასტურდა !',
  forgotPassword: 'დაგავიწყდა პაროლი ?',
  goToLoginPage: 'ავტორიზაცია',
  information: 'ინფორმაცია',
  general: 'მთავარი',
  send: 'გააგზავნა',
  newPassword: 'ახალი პაროლი',
  confirmPassword: 'დაადასტურე პაროლო',
  oldPassword: 'ძველი პაროლი',
  resetPassword: 'პაროლის გაახლება',
  yes: 'კი',
  no: 'არა',
  title: 'სათაური',
  subtitle: 'ქვესათაური',
  confirm: 'დადასტურება',
  from: 'დან',
  to: 'მდე',
  receiver: 'მიმღები',
  delete: 'წაშალე',
  cancel: 'გაუქმება',
  done: 'დასრულება',
  close: 'დახურვა',
  and: 'და',
  exit: 'გამოსვლა',
  notAdded: 'ჯერ არ დამატებულა',
  update: 'გაახლება',
  add: 'დამატება',
  oldest: 'უძველესი',
  newest: 'უახლესი',
  typeDesc: 'Type Desc',
  typeAsc: 'Type Asc',
  key: 'Key',
  value: 'მნიშვნელობა',
  translated: 'გადათარგმნილი',
  notTranslated: 'გადასათარგმნი',
  sort: 'სორტირება',
  all: 'ყველა',
  seeAll: 'სრულად',
  products: 'პროდუქტები',
  productCategory: 'პროდუქტის კატეგორია',
  productStock: 'პროდუქტის მარაგი',
  supply: 'მომარაგება',
  subDevice: 'პერიფერიული მოწყობილობა',
  clientId: 'კლიენტის Id',
  sources: 'წყაროები',
  source: 'წყარო',
  registerSource: 'რეგისტრაციის წყარო',
  addSubMenu: 'ქვემენიუს დამატება',
  fontColorHex: 'ფონტის Hex ფერი',
  displayOrder: 'რიგითი ნომერი',
  orderNumber: 'რიგითი ნომერი',
  itemHasChildrenDelete: 'თუ ამ ჩანაწერს წაშლით, ასევე წაიშლება შვილობილი ჩანაწერებიც',
  parentMenu: 'მშობელი მენიუ',
  menu: 'მენიუ',
  addHours: 'ახალი დროის დამატება',
  social: 'სოციალური',
  parentMenuConfig: 'მშობელი მენიუს კონფიგურაცია',
  childMenuConfig: 'შვილი მენიუს კონფიგურაცია',
  featuresConfig: 'მახასიათებლების კონფიგურაცია',
  downloadDocument: 'დოკუმენტის გადმოწერა',
  SortByCreateDateDesc: 'შექმნის დრო კლებადობით',
  SortByCreateDateAsc: 'შექმნის დრო ზრდადობით',
  SortByUpdateDateDesc: 'განახლების დრო კლებადობით',
  SortByUpdateDateAsc: 'განახლების დრო ზრდადობით',
  downloadFile: 'ფაილის გადმოწერა',
  allData: 'ყველა მონაცემი',
  disable: 'გაუქმება',
  thisPage: 'ეს გვერდი',
  sortedSuccefully: 'წარმატებით დასორტირდა',
  socialConfig: 'სოციალური კონფიგურაცია',
  uploadSocialSvg: 'ატვირთე სოციალური SVG',
  addNewLinkAndSVG: 'დაამატე ლინკი და SVG',
  customer: 'მომხმარებელი',
  question: 'კითხვა',
  answer: 'პასუხი',
  addNewWarehouse: 'ახალი საწყობის დამატება',
  identification: 'იდენტიფიკაცია',
  identifier: 'იდენტიფიკატორი',
  lastConnection: 'ბოლო კავშირი',
  connection: 'კავშირი',
  connectionStatus: 'კავშირის სტატუსი',
  clientsList: 'კლიენტების ლისტი',
  format: 'ფორმატი',
  mainInformation: 'მთავარი ინფორმაცია',
  compatibleCapsule: 'თავსებადი კაფსულა',
  compatibleFormat: 'თავსებადი ფორმატი',
  recomendedProportions: 'რეკომენდირებულია 1X1 პროპორცია',
  meamaDevice: 'მეამას აპარატი',
  cleaning: 'წმენდა',
  meamaMachineList: 'მეამას აპარატების სია',
  MachineDetails: 'აპარატის დეტალები',
  ChangesHistory: 'ცვლილებების ისტორია',
  deliveryTypes: 'მიწოდების ტიპი',
  deliveryTypesId: 'დელივერის ტიპის ID',
  integration: 'ინტეგრაცია',
  deliverTypesUpdate: 'მიწოდების ტიპის Update',
  deliveryIcon: 'Delivery Icon',
  recomendedSvgFormat: 'რეკომენდირებულია SVG ფორმატი',
  cityDistrict: 'ქალაქი/უბანი',
  actualWeight: 'რეალური წონა',
  volumeWeight: 'მოცულობითი წონა',
  pickUpPointName: 'აღების წერტილის სახელი',
  pickUpPoint: 'აღების წერტილი',
  deliveryIntegrationType: 'მიწოდების ინტეგრაციის ტიპი',
  trackingNumber: 'სამეთვალყურეო ნომერი',
  trackingLink: 'სამეთვალყურეო ლინკი',
  deliveryStatus: 'მიწოდების სტატუსი',
  deliveryInfoMessage: 'მიწოდების ინფორმაციის მესიჯი',
  subscriptionCategory: 'გამოწერის კატეგორია',
  innerPageTitle: 'შიდა გვერდის სათაური',
  subscriptionUsers: 'გამოწერის მომხმარებლები',
  'subscription-users': 'გამოწერის მომხმარებლები',
  nextChargeDue: 'შემდეგი გადახდის თარიღი',
  pickUpPointWarehouse: 'საწყობის აღების წერტილი',
  deliveryVehicle: 'ტრანსპორტის ტიპი',
  car: 'ავტომობილი',
  motorbike: 'მოპედი',
  isErpWarning: 'Erp პრობლემა',
  isDeliveryWarning: 'მიწოდების პრობლემა',
  deliveryMigrationStatus: 'მიწოდების მიგრაციის სტატუსი',
  deliveryMigrationStatusId: 'მიწოდების მიგრაციის სტატუსის Id',
  allFilteredData: 'ყველა/გაფილტრული მონაცემები',
  leaveBlank: 'გამოწერის შემთხვევაში Start-End Date დატოვეთ ცარიელი',
  cancelReason: 'გაუქმების მიზეზი',
  cancellationComment: 'გაუქმების კომენტარი',
  devicesControllers: 'მოწყობილობების კონტროლერები',
  refresh: 'განაახლეთ',
  powerOff: 'გამორთე',
  powerOn: 'ჩართე',
  allProductsBanner: 'ყველა პროდუქტის ბანერი',
  enabled: 'ჩართული',

  subTotal: 'ჯამური ღირებულება ',
  totalOfferQuantity: 'მთლიანი შეთავაზების რაოდენობა',
  soldQuantity: 'გაყიდული',
  availableQuantity: 'ხელმისაწვდომი',
  analytic: 'ანალიტიკა',
  status: 'სტატუსი',
  catalog: 'კატალოგი',
  config: 'კონფიგურაცია',
  footerConfig: 'ფუტერის კონფიგურაცია',
  generalConfig: 'ზოგადი კონფიგურაცია',
  'general-config': 'ზოგადი კონფიგურაცია',
  'pages-config': 'გვერდების კონფიგურაცია',
  generalInfo: 'ზოგადი ინფორმაცია',
  ecommerceConfiguration: 'ონლაინ მაღაზიის კონფიგურაცია',
  footerEndPage: 'ფუტერის დასასრულის გვერდი',
  footerParent: 'ფუტერის მშობელი',
  openInNewTab: 'ახალ ტაბში გახსნა',
  menuConfig: 'მენიუს კონფიგურაცია',
  selectAll: 'ყველას მონიშვნა',
  suretoDelete: 'ნამდვილად გსურს წაშლა?',
  suretoRefresh: 'ნამდვილად გსურს განახლება?',
  suretoPowerOff: 'ნამდვილად გსურს გამორთვა?',
  suretoPowerOn: 'ნამდვილად გსურს ჩართვა?',
  deletedSuccessfully: 'წარმატებით წაიშალა',
  addedSuccessfully: 'წარმატებით დაემატა',
  removedSuccessfully: 'წარმატებით წაიშალა',
  sentSuccessfully: 'წარმატებით გაიგზავნა',
  updatedSuccessfully: 'წარმატებით გაახლდა',
  uploadedSuccessfully: 'წარმატებით აიტვირთა',
  loggedInSuccessfully: 'ავტორიზაცია წარმატებით დასრულდა',
  archivedSuccessfully: 'წარმატებით დაარქივდა',
  syncedSuccesfully: 'Synced Succesfully',
  uploadMobilePhoto: 'მობილური ფოტოს ატვირთვა',
  sapNumber: 'Sap ნომერი',
  orderSource: 'შეკვეთის წყარო',
  migration: 'მიგრაცია',
  paymentStatus: 'გადახდის სტატუსი',
  orderStatus: 'შეკვეთის სტატუსი',
  orderCreator: 'შეკვეთის შემქმნელი',
  orderCreateDate: 'შეკვეთის შექმნის თარიღი',
  paymentMethod: 'გადახდის სერვისი',
  deliveryMethod: 'მიწოდების სერვისი',
  volumeWeight: 'მოცულობითი წონა',
  dismissOrder: 'გააუქმოს ბრძანება',
  pleaseCommentReasonAndThenDismissOrder: 'გთხოვთ დააკომენტაროთ მიზეზი და შემდეგ გააუქმოთ შეკვეთა',
  choosecategory: 'აირჩიეთ კატეგორია',
  productID: 'პროდუქტის ID',
  stock: 'მარაგი',
  createOrder: 'შეკვეთის შექმნა',
  updateOrder: 'შეკვეთის განახლება',
  customizeOffer: 'შეთავაზების მორგება',
  chooseDeliveryMethod: 'აირჩიეთ მიწოდების მეთოდი',
  chooseContactPerson: 'აირჩიეთ საკონტაქტო პირი',
  choosePaymentMethod: 'აირჩიეთ გადახდის მეთოდი',
  orderPreview: 'შეკვეთის გადახედვა',
  orderInformation: 'შეკვეთის ინფორმაცია',
  clientInformation: 'კლიენტის ინფორმაცია',
  mobileNumber: 'მობილურის ნომერი',
  deliveryInformation: 'მიწოდების ინფორმაცია',
  payment: 'გადახდა',
  create: 'შექმნა',
  migrationStatus: 'მიგრაციის სტატუსი',
  lastUpdate: 'ბოლო განახლება',
  invoice: 'ინვოისი',
  machineSapCode: 'მანქანის საპ კოდი',
  sapID: 'საპ ID',
  offerExpired: 'შეთავაზებას ვადა გაუვიდა',
  meamaBusiness: 'მეამა ბიზნესი',
  verification: 'ვერიფიკაცია',

  language: 'ენა',
  languages: 'ენები',
  dark: 'ბნელი',
  ligth: 'ნათელი',
  menuoptions: 'მენიუს პარამეტრები',
  menuChangedSuccessfully: 'მენიუ წარმატებით შეიცვალა',
  menuChangedError: 'მენიუს ცვლილებისას მოხდა შეცდომა',
  feild: 'შეცდომა',
  save: 'შენახვა',
  saveAndUpdate: 'შენახვა & განახლება',
  reject: 'უარყოფა',
  home: 'სახლი',
  core: 'ქორი',
  coreId: 'ქორის Id',
  dashboard: 'დაფა',
  search: 'ძებნა',
  searching: 'მოძებნილები',
  searchByName: 'სახელი',
  pagename: 'გვერდის სახელი',

  searchById: 'Id',
  searchByEmail: 'მეილი',
  searchByPhoneNumber: 'ტელეფონის ნომერი',
  go: 'წინ',
  continue: 'გაგრძელება',
  sorry_no_suggestions: 'მსგავსი ვერ მოიძებნა',
  companies: 'კომპანიები',
  company: 'კომპანია',
  postalCode: 'ფოსტის კოდი',
  darkTheme: 'მუქი დიზაინი',
  changeLanguage: 'ენის შეცვლა',
  back: 'უკან',
  changePassword: 'პაროლის შეცვლა',
  profile: 'პროფილის პარამეტრები',
  logout: 'გასვლა',
  favorites: 'ფავორიტები',
  companies_module: 'კომპანიები',
  horeka: 'ჰორეკა',
  finances: 'ფინანსები',
  promotions: 'აქციები',
  clients: 'კლიენტები',
  B2C: 'B2C',
  B2B: 'B2B',
  B2CList: 'B2C სია',
  B2BList: 'B2B სია',
  draftAgreements: 'ხელშეკრულებები',
  commentaries: 'კომენტარები',
  comment: 'კომენტარი',
  list: 'სია',
  groups: 'ჯგუფები',
  physical: 'ფიზიკური',
  pointsAndWarehouses: 'გაყიდვის წერტილები და საწყობები',
  salesPoints: 'გაყიდვის წერტილები',
  salesPoint: 'გაყიდვის წერტილი',
  'sales-points': 'გაყიდვის წერტილები',
  pointsandwarehouses: 'გაყიდვის წერტილები',
  addNew: 'ახლის დამატება',
  addPhoneNumber: 'ტელეფონის ნომრის დამატება',
  productsList: 'პროდუქტების სია',
  units: 'საზომი ერთეულები',
  localtion_module: 'ლოკაციები',
  countries: 'ქვეყნები',
  regions: 'რეგიონები',
  cities: 'ქალაქები',
  districts: 'რაიონები',
  addOrUpdate: 'დაამატე ან გაანახლე',
  roles_module: 'როლები',
  roles: 'როლები',
  parent: 'მშობელი',
  user: 'მომხმარებელი',
  details: 'დეტალები',
  edit: 'რედაქტირება',
  editPhoneNumber: 'ნომრის რედაქტირება',
  retry: 'გამეორება',
  download: 'გადმოწერა',
  refund: 'თანხის დაბრუნება',
  filter: 'ფილტრი',
  clearFilter: 'ფილტრის გასუფთავება',
  symbol: 'სიმბოლო',
  joined: 'გაწევრიანებული',
  joinDate: 'გაწევრიანების თარიღი',
  departments: 'დეპარტამენტები',
  active: 'აქტიური',
  inactive: 'არა აქტიური',
  canceled: 'გაუქმებული',
  suspended: 'შეჩერებული',
  notActive: 'არა აქტიური',
  blocked: 'დაბლოკილი',
  verified: 'ვერიფიცირებული',
  unverified: 'არა ვერიფიცირებული',
  male: 'კაცი',
  female: 'ქალი',
  none: 'სხვა',
  logs: 'ლოგები',
  userDetails: 'მომხმარებლის დეტალი',
  sendLink: 'ლინკის გაგზავნა',
  checkWithChildrens: 'მონიშნე შვილებიანად',
  withClicldrens: 'შვილებიანად მონიშნულია',
  isDefault: 'მთავარი',
  notDefault: 'მეორეული',
  time: 'დრო',
  timeZones: 'დროის ზონები',
  price: 'ფასი',
  sellingPrice: 'გაყიდვის ფასი',
  sellersDetail: 'მყიდველების დეტალები',
  prices: 'ფასები',
  percent: 'პროცენტი',
  range: 'დიაპაზონი',
  photo: 'ფოტო',
  editTable: 'ცხრილის შეცვლა',
  exportData: 'ექსპორტი',
  thisFieldIsRequired: 'ველის შევსება სავალდებულოა',
  specifyTheSlugInCorrectFormat: 'მიუთითე სლაგი სწორი ფორმატით',
  specifyTheSeoInCorrectFormat: 'მიუთითე სეო სწორი ფორმატით',
  selectAtLeastOneDay: 'აირჩიე მინიმუმ ერთი დღე',
  gallery: 'გალერეა',
  recomendedSize1600x1200: 'რეკომენდირებული ზომა 1600x1200',
  recomendedSize: 'რეკომენდირებული ზომა',
  warehouseCode: 'საწყობის კოდი',
  companyName: 'კომპანიის სახელი',
  chooseCompanie: 'აირჩიე კომპანია',
  workingTimes: 'სამუშაო დრო',
  dateOfCreation: 'შექმნის თარიღი',
  startDate: 'დაწყების დრო',
  endDate: 'დასრულების დრო',
  startEndDate: 'დაწყება - დასრულების დრო',
  chooseVariant: 'ვარიანტის არჩევა',
  productsSuccessfulyAdded: 'პროდუქტი წარმატებით დაემატა',
  notificationSendedSuccessfuly: 'ნოტიფიკაცია წარმატებით გაიგზავნა',
  quantity: 'რაოდენობა',
  priceChange: 'ფასის ცვლილება',
  clearAll: 'ყველას გასუფთავება',
  addAll: 'ყველას დამატება',
  addedProducts: 'დამატებული პროდუქტები',
  allProducts: 'ყველა პროდუქტი',
  min: 'Min',
  max: 'Max',
  plus: 'დამატებითი',
  resendNewCode: 'ახალი კოდის გაგზავნა',
  pleaseEnterCorrectTimeFormat: 'მიუთითე დრო სწორი ფორმატით',
  idNumber: 'პირადი ნომერი',
  additionalSpecification: 'დამატებითი სპეციფიკაცია',
  descriptionBox: 'Description Box',
  accordionBox: 'Accordion Box',
  banner: 'ბანერი',
  banner1: 'ბანერი 1',
  banner2: 'ბანერი 2',
  bannerImage: 'ბანერის ფოტო',

  categoryTypes: 'კატეგორიის ტიპები',
  youNeedToSaveTheCategoryBeforeYouCanAddProductsForThisCategoryPage:
    'პირველ რიგში საჭიროა კატეგორიის ცვლილების შენახვა, შემდეგ კი შეგეძლება პროდუქტის დამატება კატეგორიის სიაში',
  reorderedSuccesfully: 'წარმატებით გადანაცვლდა',
  showInListing: 'სიაში ჩვენება',
  removeFromListing: 'სიიდან წაიშალა',
  changeIndex: 'შეცვალე ინდექსი',
  newIndex: 'ახალი ინდექსი',
  currentIndex: 'ახლანდელი ინდექსი',
  index: 'ინდექსი',
  widgetSubTitleSumOfOrders: 'შეკვეთების ჯამი',
  widgetSubTitleSumOfSubscription: 'გამოწერის ჯამი',
  widgetSubTitleNumberOfOrders: 'შეკვეთების რაოდენობა',
  widgetSubTitleNumberOfSubscription: 'შეკვეთების გამოწერა',
  orderDiscount: 'შეკვეთის ფასდაკლება',
  ERPsalesOrderId: 'ERP გაყიდვის შეთავაზების ID',

  slogan: 'სლოგანი',
  domainTitle: 'დომეინის სათაური',
  domain: 'დომეინი',
  cdnBaseUrl: 'CDN საბაზისო Url',
  firstTitle: 'პირველი სათაური',
  secondTitle: 'მეორე სათაური',
  secondaryInfo: 'მეორე დამატებითი ინფორმაცია',
  logos: 'ლოგოები',
  largeLogo: 'დიდი ლოგო',
  smallLogo: 'პატარა ლოგო',
  additionalInfo: 'დამატებითი ინფორმაცია',
  authentificationImages: 'ავთენტიფიკაციის სურათები',
  firstImage: 'პირველი სურათი',
  secontImage: 'მეორე სურათი',
  label: 'ლეიბლი',
  linkedCoreCategorie: 'ქორის კატეგორია',

  // location translations
  country: 'ქვეყანა',
  region: 'რეგიონი',
  city: 'ქალაქი',
  district: 'რაიონი',
  street: 'ქუჩა',
  'location-configuration': 'ლოკაციის კონფიგურაცია',
  location: 'ლოკაცია',
  'FAQ&SEO': 'FAQ&SEO',
  'FAQ-&-SEO': 'FAQ & SEO',
  branch: 'ფილიალი',
  address: 'მისამართი',
  addressId: 'მისამართი Id',
  zipCode: 'zip კოდი',
  locationCategories: 'მდებარეობების კატეგორია',
  workingStartTime: 'სამუშაოს დაწყების დრო',
  workingEndTime: 'სამუშაოს დასრულების დრო',
  promoCodeValue: 'პრომო კოდი',
  customerEmail: 'მომხმარებლის მეილი',
  promoCodeAnalytic: 'პრომო კოდის ანალიტიკა',

  latitude: 'განედი',
  longitude: 'გრძედი',

  subscriptionName: 'გამოწერის სახელი',
  subscriptionId: 'გამოწერის ID',
  subscriptionPlanId: 'გამოწერის გეგმის ID',
  SubscriptionPlanUniqueId: 'გამოწერის გეგმის უნიკალური ID',
  minifiedPages: 'Minified Pages',
  chargeDate: 'გადახდის თარიღი',
  noDeliverySettings: 'ამ კომპანიას არ აქვს მიწოდების სეტაფი',
  'delivery-guest-config': 'სტუმრის კონფიგურაცია',

  // ecommerce offer translations
  ecommercepageurl: 'იკომერსის გვერდის URL',
  'ecommerce-offers': 'შეთავაზებები',
  'offer-categories': 'შეთავაზების კატეგორიები',
  filterName: 'ფილტრის სახელი',
  'link-offers': 'შეთავაზების დაკავშირება',
  coreOfferID: 'ქორის შეთავაზების ID',
  ecomOfferName: 'ecom შეთავაზების სახელი',
  chooseOfferType: 'აირჩიე შეთავაზების ტიპი',
  addNewPhotoBoxes: 'დაამატე ახალი ფოტო ბოქსი',
  addNewMeamaProducts: 'დაამატე ახალი მეამას პროდუქტი',
  slider: 'სლაიდერი',
  blogPost1: 'ბლოგის პოსტი 1',
  offerType: 'შეთავაზების ტიპი',
  offerName: 'შეთავაზების სახელი',
  'offer-listing': 'შეთავაზებების სია',
  OfferListing: 'შეთავაზებების სია',
  faq: 'F.A.Q',

  //validators
  moreThanZero: 'მნიშვნელობა მეტი უნდა იყოს ნულზე',
  mustBeCorrectRange: 'უნდა იყოს სწორი შუალედი',
  invalidUrl: 'შეიყვანეთ ვალიდური URL მისამართი',
  url: 'Url',

  generationIsNotFinish: 'დაგენერირება არ არის დასრულებული',
  clearTable: 'ცხრილის გასუფთავება',

  //TODO: this is shit
  //user module translations
  superAdmin: 'სუპერ ადმინი',
  usersDepartment: 'დეპარტამენტი',
  gender: 'სქესი',
  sex: 'სქესი',
  phoneNumber: 'ტელეფონის ნომერი',
  phone: 'ტელეფონი',
  userRoles: 'მომარებლების როლები',
  passwordNotChanged: 'პაროლი ვერ შეიცვალა',
  passwordChanged: 'პაროლი შეიცვალა',
  userNotUpdated: 'მომხმარებელი ვერ განახლდა',
  userUpdated: 'მომხმარებელი განახლდა',
  systemUsersModule: 'სისტემური მომხმარებლები',

  //Currency module Translations
  currencies: 'ვალუტები',
  currency: 'ვალუტა',
  short: 'მოკლე',
  successAddedCurrency: 'ვალუტა წარმატებით დაემატა',
  notAddedCurrency: 'ვალუტა ვერ დაემატა',
  successDeleteCurrency: 'ვალუტა წარმატებით წაიშალა',
  notADeletedCurrency: 'ვალუტა ვერ წაიშალა',
  successUpdateCurrency: 'ვალუტა გაახლდა წარმატებით',
  notAUpdatedCurrency: 'ვალუტა ვერ გაახლდა',
  youCantChoosePastInPublish: 'შენ ვერ აირჩვ წარსულ დროს გამოქვეყნების დაწყების/დასრულების ველში',

  //companies module translations
  youMustChooseHowDelete: 'ჯერ უნდა აირჩიო როგორ გსურს წაშლა',
  successAddedCompany: 'კომპანია წარმატებით დაემატა',
  notAddedCompany: 'კომპანია ვერ დაემატა',
  successDeleteCompany: 'კომპანია წარმატებით წაიშალა',
  notADeletedCompany: 'კომპანია ვერ წაიშალა',

  ProjectPolicys_B2BHoreka: 'B2B ჰორეკა',
  ProjectPolicys_CoreModule: 'ქორი',
  ProjectPolicys_FinancesModule: 'ფინანსები',
  ProjectPolicys_ProductsModule: 'პროდუქტები',
  ProjectPolicys_ProductModule: 'პროდუქტები',
  ProjectPolicys_PromoCodeModule: 'პრომო კოდი',
  ProjectPolicys_PaymentModule: 'გადახდა',
  ProjectPolicys_SupportUkraineModule: 'უკრაინის მხარდასაჭერა',
  ProjectPolicys_CustomersModule: 'მომხმარებლები',
  ProjectPolicys_ERPModuleModule: 'ERP მოდული',
  ProjectPolicys_OffersModule: 'შეთავაზებები',
  ProjectPolicys_ECommerceModule: 'ონლაინ მაღაზია',

  //Strings module Translations
  'strings-drawer': 'თარგმნები',
  strings: 'თარგმნები',
  string: 'თარგმანი',

  //Logs module Translations
  'logs-drawer': 'ლოგები',

  // promo code transations
  'promo-code-setup': 'პრომო კოდების კონფიგურაცია',
  'promo-codes': 'პრომო კოდები',
  'static-promo-codes': 'სტატიკური პრომო კოდები',
  'first-purchase-config': 'პირველი გადახდის კონფიგურაცია',
  addNewPromoCode: 'დაამატე ახალი პრომო კოდი',
  addNewFirstPurchaseSetup: 'დაამატე ახალი პირველი გადახდის სეტაპი',
  'first-purchase-offers': 'პირველი გადახდის შეთავაზებები',
  used: 'გამოყენებული',
  promoCodeOfferId: 'პრომო კოდის ოფერის id',
  promoCodeScope: 'პრომო კოდი ვრცელდება',
  numberOfCodes: 'პრომო კოდის რაოდენობა',
  codeLenght: 'კოდის სიგრძე',
  addProducts: 'პროდუქტების დამატება',

  //client group module Translations
  groupAdded: 'ჯგუფი დაემატა',
  groupUpdated: 'ჯგუფი გაახლდა',
  groupDeleted: 'ჯგუფი წაიშალა',
  deleteWithFiles: 'წაშლა ფაილით',
  uploadWithFiles: 'გაახლება ფაილით',
  addViaPhone: 'დამატება ტელეფონის ნომრით',
  addedViaPhone: 'ტელეფონის ნომერი დაემატა',
  deletedPhone: 'ტელეფონის ნომერი წაიშალა',
  pauseGroup: 'ჯგუფის ატვირთვა გაჩერდა',
  resumeGroup: 'ჯგუფის ატვირთვა გაგრძელდა',
  groupRetry: 'თავიდან ცდა',
  phoneRetry: 'თავიდან ცდა ტელეფონის ნომრით',
  sortByCustomerAmountDesc: 'კლებადობით დალაგება',
  sortByCustomerAmountAsc: 'ზრდადობით დალაგება',
  sortByidDesc: 'კლებადბით Id მიხედვით',
  sortByidAsc: 'ზრდადობით Id მიხედვით',
  sortByJoinDateDesc: 'გაწევრიანების თარიღი კლებადობით',
  sortByJoinDateAsc: 'გაწევრიანების თარიღი ზრდადობით',
  companyIdAsc: 'ზრდადობით კომპანიის Id',
  companyIdDesc: 'კლებადობით კომპანიის Id',

  // B2B module

  b2bClientsList: 'B2B Clients',

  //Offers module Translations
  'offers-drawer': 'შეთავაზებები',

  //promocodes translations
  'link-promo-codes': 'დააკავშირე პრომო კოდები',

  //categories module Translations
  categories: 'კატეგორიები',
  category: 'კატეგორია',
  parentCategoryId: 'მშობლის კატეგორიის Id',
  categoryId: 'კატეგორიის Id',
  structure: 'სტრუქტურა',
  categoryName: 'კატეგორიის სახელი',
  parentCategory: 'მშობელი კატეგორია',
  categorieDeletedSuccesfully: 'კატეგორია წარმატებით წაიშალა',
  parentCategories: 'მშობელი კატეგორიები',
  childCategories: 'შვილი კატეგორიები',
  publish: 'გამოქვეყნება',
  isPublished: 'გამოქვეყნებულია',
  published: 'გამოქვეყნებული',
  unpublish: 'გაუქმება',
  unpublished: 'გამოუქვეყნებელი',
  contentType: 'კონტენტის ტიპი',
  scheduled: 'დაგეგმილი',
  publishStartTime: 'დაწყების დრო',
  publishEndTime: 'დამთავრების დრო',

  //Transactions module Translations
  'transactions-drawer': 'ტრანზაქციები',
  transactions: 'ტრაზაქციები',
  Id: 'Id',
  UserId: 'მომხმარებლის Id',
  TransationRefId: 'გარე ID',
  PaymentDate: 'გადახდის თარიღი',
  OrderDate: 'შეკვეთის თარიღი',
  CustomerId: 'მომხმარებლის Id',
  TransactionStatus: 'სტატუსი',
  TransactionStatusId: 'ტრანზაქციის სტატუს ID',
  TransactionType: 'ტიპი',
  PaymentChannel: 'გადახდის არხი',
  PaymentType: 'გადახდის ტიპი',
  SalePointId: 'გაყიდვის არხის Id',
  SalesPointName: 'გაყიდვის არხის სახელი',
  SalesPointType: 'გაყიდვის არხი',
  SalesPointTypeValue: 'გაყიდვის არხის ტიპის მნიშვნელობა',
  customerType: 'მომხმარებლის ტიპი',
  Amount: 'ღირებულება',
  Currency: 'ვალუტა',
  faux: 'ბანერები',
  warehouses: 'საწყობები',
  FreeCapsuleAmount: 'უფასო კაფსულა',
  Initiator: 'ინიციატორი',
  HasCustomer: 'ყავს კლიენტი',
  CompanyId: 'კომპანია Id',
  CompanyName: 'კომპანია',
  CardNumber: 'ბარათის ნომერი',
  Cashier: 'მოლარე',
  OrderCode: 'შეკვეთის კოდი',
  UniqueCode: 'უნიკალური კოდი',
  activate: 'აქტივაცია',
  deactivate: 'გამორთვა',
  vatPayer: 'დღგ-ს გადამხდელი',
  vatPercent: '% დღგ-ს პროცენტი',
  orderDate: 'შეკვეთის თარიღი',

  ChangeTime: 'შეცვლის დრო',
  DetailedInformation: 'დეტალის ინფორმაცია',
  contactInformation: 'საკონტაქტო ინფორმაცია',
  TransactionDetails: 'ტრანზაქციის დეტალი',
  ProductsDetails: 'პროდუქტის დეტალი',
  History: 'ისტორია',
  live: 'ლაივი',
  transactionsIsInLive: 'ტრანზაქციები ლაივშია',
  transactionsIsNotInLive: 'ტრანზაქციები არ არის ლაივში',
  transactionType: 'ტრანზაქციის ტიპი',
  paymentChannel: 'გადახდის არხი',
  salesPointName: 'გაყიდვის წერტილის სახელი',
  salesPointType: 'გაყიდვის წერტილის ტიპი',
  Status_Changed_To: 'Status changed to',
  orderBy: 'დალაგება',
  selected: 'არჩეული',
  items: 'პროდუქტი',
  cost: 'ღირებულება',
  salePrice: 'გაყიდვის ფასი',
  saleType: 'ფასდაკლების ტიპი',
  promoCode: 'პრომო კოდი',
  promoCodeList: 'პრომო კოდების სია',
  workingWithOtherSales: 'მოქმედებს სხვა ფასდაკლებებთან ერთად',
  promoCodeType: 'ფასდაკლების სახეობა',
  minPriceToActivate: 'გააქტიურების მინ თანხა',
  offerStartEnd: 'შეთავაზების დაწყება დამთავრების თარიღი',
  'no-data': 'No Data',
  noData: 'ჩანაწერი ვერ მოიძებნა',
  description: 'აღწერა',
  descriptionIsRequeried: 'აღწერა სავალდებულოა',
  text: 'ტექსტი',
  color: 'ფერი',
  textColor: 'ტექსტის ფერი',
  backgroundColor: 'Background Color',
  default: 'ნაგულისხმევი',
  isActive: 'აქტიურია',
  coreLanguages: 'ქორის ენები',
  creationDate: 'შექმნის თარიღი',
  changeDate: 'ცვლილების თარიღი',
  date: 'თარიღი',
  pageTitle: 'გვერდის სათაური',
  content: 'კონტენტი',
  basics: 'საფუძვლები',
  advanced: 'დეტალური',
  descriptionMustNotBeEmpty: 'აღწერა არ უნდა იყოს ცარიელი',
  template: 'შაბლონი',
  configTags: 'კონფიგურაციის თეგები',
  quickShopDescription: 'მოკლე აღწერა',
  fullDescription: 'სრული აღწერა',
  shortDescription: 'მოკლე აღწერა',
  youMustEnterIndex: 'შენ უნდა შეიყვანო ინდექსი',
  linkSimmilarProducts: 'დაუკავშირე მსგავსი პროდუქტები',
  addSimmilarProducts: 'დაამატე მსგავსი პროდუქტები',
  specificationType: 'სპეციფიკაციის ტიპი',
  photos: 'ფოტოები',
  uploadMainPhoto: 'მთავარი ფოტოს ატვირთვა',
  uploadHoverPhoto: 'ჰოვერის ფოტოს ატვირთვა',
  uploadGalleryPhoto: 'გალერიის ფოტოს ატვირთვა',
  uploadPhoto: 'ფოტოს ატვირთვა',
  mainPhoto: 'მთავარი ფოტო',
  mainPhotoALTText: 'მთავარი ფოტოს ALT ტექსტი',
  hoverPhotoALTText: 'ჰოვერის ფოტოს ALT ტექსტი',
  desktopPhotoALTText: 'დესკტოპის ფოტოს ALT ტექსტი',
  mobilePhotoALTText: 'მობილურის ფოტოს ALT ტექსტი',
  galleryALTText: 'გალერიის ფოტოს ALT ტექსტი',
  altText: 'ALT ტექსტი',
  remove: 'წაშლა',
  hoverPhotoUrl: 'ჰოვერის ფოტოს URL',
  bundle: 'შეკვრა',
  discount: 'ფასდაკლება',
  wholesale: 'საბითუმო',
  linkedProducts: 'დაკავშირებული პროდუქტები',
  header: 'სათაური',
  buttonURl: 'ღილაკის URl',
  customersAlsoBought: 'მომხმარებლებმა ასევე შეიძინეს',
  visibility: 'ხედვა',
  messageMustNotBeEmpty: 'შეტყობინება არ უნდა იყოს ცარიელი',
  showOnProductListing: 'პროდუქტის სიაში ჩვენება',
  showOnInnerPage: 'შიდა გვერდზე ჩვენება',
  coreProduct: 'ქორის პროდუქტი',
  productCode: 'პროდუქტის კოდი',
  buttonUrl: 'ღილაკის URL',
  buttonText: 'ღილაკის ტექსტი',

  //Product templates module Translations
  'templates-drawer': 'შაბლონები',
  templates: 'შაბლონები',
  templateId: 'შაბლონის Id',
  orderByNameAsc: 'დალაგება სახელის ზრდადობით',
  orderByNameDesc: 'დალაგება სახელის კლებადობით',
  orderByDateAsc: 'დალაგება დროის ზრდადობით',
  orderByDateDesc: 'დალაგება დროის კლებადობით',

  //Specifications module Translations
  'specifications-drawer': 'სპეციფიკაციები',
  specifications: 'სპეციფიკაციები',
  specificationsWithLinkedProducts: 'სპეციფიკაციები დაკავშირებული პროდუქტებით',
  linkAppropriateProduct: 'დაუკავშირე შესაბამისის პროდუქტი',
  linkedOffers: 'დაკავშირებული შეთავაზებები',
  addInput: 'ველის დამატება',
  typeHere: 'დაწერე აქ',
  type: 'ტიპი',
  coreOfferId: 'ქორის შეთავაზების ID',
  offerID: 'შეთავაზების ID',

  offerSetup: 'შეთავაზების აწყობა',
  outOfStock: 'არ არის მარაგში',

  ThisFieldIsRequired: 'ეს ველი სავალდებულოა',
  YousShouldCheckCountCheckBoxes: 'შენ უნდა მონიშნო {0} ჩექ ბოქსი',
  YousShouldSelectCount: 'უნდა აირჩიო მიმიმუმ {0} სელექთი',
  MaxCountIsIncorrect: 'MaxCountIsIncorrect',
  MinCountIsIncorrect: 'MinCountIsIncorrect',
  minimum6Character: 'უნდა შეიცავდეს მინიმუმ 6 სიმბოლოს',
  passwordMismatch: 'პაროლები არ ემთხვევა',
  atLeastOneUpperCase: 'უნდა შეიცავდეს მინიმუმ ერთ დიდ ასოს',
  atLeastOneNumber: 'უნდა შეიცავდეს მინიმუმ ერთ ციფრს',
  atLeastOneSpecialSymbol: 'უნდა შეიცავდეს მინიმუმ ერთ განსაკუთრებულ სიმბოლოს',

  // vms
  vms: 'vms',
  vmsConfiguration: 'VMS Configuration',
  'device-management': 'მოწყობილობის მართვა',
  'controller-list': 'კონტროლერების სია',
  'additional-inventory': 'დამატებითი ინვენტარი',
  'partner-companies': 'პარტნიორი კომპანიები',
  kiosks: 'კიოსკი',
  services: 'სერვისები',
  alerts: 'გაფრთხილებები',
  alertType: 'შეტყობინების ტიპი',
  critical: 'კრიტიკული',
  lowVolume: 'Low Volume',
  rotate: 'rotate',
  removeProduct: 'Remove Product',
  emptyColumn: 'Empty Column',
  lock: 'Lock',
  deviceType: 'მოწყობილობის ტიპი',
  deviceName: 'მოწყობილობის სახელი',
  history: 'ისტორია',
  'device-types': 'აპარატის ტიპები',
  deviceTypeName: 'აპარატის ტიპის სახელი',
  sensors: 'სენსორები',
  sensorType: 'სენსორის ტიპი',
  sensorId: 'სენსორის ID',
  inventoryCodeId: 'ინვენტარის კოდი/ID',
  generalInformation: 'ზოგადი ინფორმაცია',
  number: 'ნომერი',
  alternativeNumber: 'ალტერნატიული ნომერი',
  'qr-codes': 'QR კოდები',
  codeLength: 'კოდის სიგრძე',
  link: 'ლინკი',
  linkOfGeneratedFilesWillBeSentToThisMail: 'ფაილის ლინკი გაიგზავნება მეილზე',
  code: 'კოდი',
  rows: 'რიგები',
  columns: 'სვეტები',
  capacity: 'ტევადობა',
  column: 'სვეტი',
  addColumn: 'სვეტის დამატება',
  addRow: 'რიგის დამატება',
  reset: 'გასუფთავება',
  clearAllRow: 'ყველა რიგის შაშლა',
  rowAndColumnsTooltip: 'Clear All Row',

  //Products List module Translations
  'products-list-drawer': 'პროდუქტების სია',
  ascProductName: 'პროდუქტის სახელი ზრდადობით',
  descProductName: 'პროდუქტის სახელი კლებადობით',
  ascCategoryName: 'კატეგორრის სახელი ზრდადობით',
  descCategoryName: 'კატეგორრის სახელი კლებადობით',

  orderByIdDesc: 'ორდერის ID კლებადობით',
  orderByIdAsc: 'ორდერის ID ზრდადობით',

  //Notifications module Translations
  notifications: 'შეტყობინებები',
  sent: 'გაგზავნილები',
  inbox: 'შემოსულები',
  read: 'წაკითხული',
  unread: 'წაუკითხავი',
  compose: 'გაგზავნა',
  sendMessage: 'გაგზავნა',
  sentNotification: 'შეტყობინების გაგზავნა',
  subject: 'თემა',
  message: 'შეტყობინება',

  //Tags module Translations
  'tags-drawer': 'თეგები',
  tags: 'თეგები',

  //ecommerce orders module Translations
  orderDetails: 'შეკვეთის დეტალები',
  deliveryDetails: 'მიტანის დეტალები',
  productsDetails: 'პროდუქტების დეტალები',
  erpIntegraionDetails: 'ERP ინტეგრაციის დეტალები',
  erpCode: 'ERP კოდი',
  erpName: 'ERP სახელი',
  erpClientId: 'ERP კლიენტის Id',
  subscriptionOrder: 'გამოწერით შეკვეთა',
  orderCancellationRequestWasSuccessfullySent: 'შეკვეთის გაუქმების მოთხოვნა წარმატებით გაიგზავნა',
  retryRequestWasSentSuccessfully: 'ხელახლა ცდის მოთხოვნა წარმატებით გაიგზავნა',
  tracking: 'კონტროლი',
  viewInEcommerce: 'იკომერსში ნახვა',
  importData: 'დატის შეიმპორტება',

  //popup module
  popups: 'პოპაპები',
  popup_list: 'პოპაპების სია',
  displayDuration: 'ჩვენების დრო',
  sec: 'წამი',
  showOption: 'ჩვენების ვარიანტი',
  pages: 'გვერდები',
  exactCheck: 'ზუსტი',

  //promocode setup
  use: 'გამოყენება',
  lastUpdateBy: 'ბოლოს განაახლა',

  orderId: 'ორდერის ID',

  userName: 'მომხმარებლის სახელი',
  pricePerProduct: 'ფასი ერთ პროდუქტზე',
  ecommerceId: 'იკომერსის Id',
  orderUniqueId: 'ორდერის უნიკალური Id',
  orderGuid: 'ორდერის Guid',
  ecommerceOrderId: 'იკომერსის შეკვეთის Id',
  payemntOrderId: 'გადახდის შეკვეთის Id',
  erpOrderId: 'ERP შეკვეთის Id',
  saveCard: 'შეინახე ბარათი',
  orderLanguageCode: 'შეკვეთის ენის კოდი',
  orderStatusId: 'შეკვეთის სტატუსის Id',
  orderStatusName: 'შეკვეთის სტატუსის სახელი',
  paymentTypeId: 'შეკვეთის ტიპის Id',
  paymentTypeName: 'შეკვეთის ტიპის სახელი',
  shippingTypeId: 'მიტანის ტიპის Id',
  shippingTypeName: 'მიტანის ტიპის Id',
  totalAmount: 'სრული ფასი',
  subTotalAmount: 'SubTotal რაოდენობა',
  shippingAmount: 'მიტანის ფასი',
  currencyId: 'ვალუტა Id',
  currencySymbol: 'ვალუტის სიმბოლო',
  coreUserId: 'ქორის მომხმარებლის Id',
  userId: 'მომხმარებლის Id',
  firstName: 'სახელი',
  lastName: 'გვარი',
  userPhoneNumber: 'მომხმარებლის მობილური',
  isDiplomat: 'დიპლომატი',
  lastModifiedUtcDate: 'ბოლო რედაქტირების თარიღი',
  createdUtc: 'შექმნის თარიღი',
  saved: 'შენახული',
  notSaved: 'შეუნახავი',
  erpMigrationDate: 'ERP ინტეგრაციის თარიღი',
  erpStatus: 'ERP სტატუსი',
  delivery: 'მიტანის სერვისი',
  delivery_types: 'მიტანის ტიპები',
  deliveryType: 'მიტანის ტიპი',
  pick_up_points: 'აღების წერტილი',
  pick_up_name: 'აღების სახელი',
  addPickUp: 'დაამატე აღების წერტილი',
  updatePickUp: 'განაახლე აღების წერტილი',
  'delivery-config': 'მიტანის კონფიგურაცია',
  deliveryAddress: 'მიწოდების ადგილი',
  pickupPoint: 'მიტანის წერტილი',
  priceConfig: 'ფასის კონფიგურაცია',
  deliveryPrice: 'მიწოდების ფასი',
  orderPrice: 'შეკვეთის ფასი',
  minimum: 'მინიმუმი',
  maximum: 'მაქსიმუმი',
  delivery_settings_setup: 'მიტანის სეთინგების სეტაპი',

  //Subscriptions module translations
  subscriptions: 'გამოწერები',
  subscription: 'გამოწერა',
  chooseFAQ: 'აირჩიე F.A.Q',
  innerPage: 'შიდა გვერდი',
  monthlyPrice: 'ყოველთვიური ფასი',
  firstMonthPrice: 'პირველი თვის გადასახადი',
  months: 'თვე',
  month: 'თვე',
  monthsBeforeCancellationAllowed: 'გაუქმების შესაძლებლობამდე დარჩენილი თვეები',
  duration: 'ხანგრძლივობა',
  merchantDurationKey: 'merchant Duration Key',
  invalidGuid: 'არავალიდური Guid',
  subscriptionPlanUniqueId: 'გამოწერის უნიკალური Id',
  isSubscriptionOrder: 'გამოწერის ორდერი',
  'cencelation-config': 'გაუქმების კონფიგურაცია',
  reason: 'მიზეზი',
  cencelationReason: 'გაუქმების მიზეზი',
  withComment: 'კომენტარით',
  cencel: 'გაუქმება',
  cencelSubscription: 'გამოწერის გაუქმება',
  cencelation: 'გაუქმება',
  feePerMonth: 'გადასახადი თვეში',
  cencelationTransactions: 'გაუქმების ტრანზაქციები',
  eCommerceId: 'eCommerce Id',
  cancellationFee: 'გაუქმების საფასური',
  transactionId: 'ტრანზაქციის Id',
  requestDate: 'მოთხოვნის თარიღი',
  paymentOrderId: 'გადახდის შეკვეთის Id',
  failReason: 'შეცდომის მიზეზი',

  'meama-machine-list': 'მანქანების სია',

  machine: 'მანქანები',
  'registered-machines': 'რეგისტრირებული აპარატები',
  addMachines: 'აპარატის დამატება',
  machinesModel: 'აპარატის მოდელი',
  machinedId: 'აპარატის Id',
  registerMachineId: 'აპარატის რეგისტრაციის Id',
  size: 'ზომა',
  machineType: 'აპარატის ტიპი',
  machineModel: 'აპარატის მოდელი',
  meamaMachine: 'მეამას აპარატი',
  otherMachine: 'სხვა აპარატი',
  unVerified: 'არა ვერიფიცირებული',
  serialNumber: 'სერიული ნომერი',
  warrantyNumber: 'გარანტიის ნომერი',
  registrationDate: 'რეგისტრაციის თარიღი',
  machineStatus: 'აპარატის სტატუსები',
  meamaCoffeeMachine: 'მეამას ყავის აპარატი',
  machineOwner: 'აპარატის მფლობელი',
  prevValue: 'წინა მოქმედება',
  currentValue: 'მიმდინარე მოქმედება',
  customerMachineId: 'კლიენტის აპარატის Id',
  registerDate: 'რეგისტრაციის თარიღი',
  cleaningStatus: 'წმენდის სტატუსი',

  erpMigrationStatus: 'ERP მიგრაციის სტატუსი',
  erpMigrationStatusId: 'ERP მიგრაციის Id',
  machines: 'მანქანები',

  discountAmount: 'ფასდაკლების რაოდენობა',
  cardNumber: 'ბარათის ნომერი',
  receiverFirstName: 'მიმღების სახელი',
  receiverLastName: 'მიმღების გვარი',
  receiverPhoneNumber: 'მიმღების ნომერი',
  shippingAddressName: 'მიმღების მისამართის სახელი',
  shippingAddressId: 'მიმღების მისამართის ID',

  //Commentaries module Translations
  'commentaries-list-drawer': 'კომენტარების სია',

  //statuses module translations
  'statuses-drawer': 'სტატუსები',

  //Languages module Translations
  'languages-drawer': 'ენები',

  //textPage module Translations
  textPage: 'ტექსტები',

  //MainPage Translations
  'main-page': 'მთავარი გვერდი',
  'page-setup-seo': 'მთავარი გვერდის სეო',
  'page-setup': 'გვერდის აწყობა',
  'page-components': 'გვერდის კომპონენტები',
  'title-section': 'სათაურის სექცია',
  'main-slider': 'მთავარი სლაიდერი',
  'grid-slider': 'გრიდის სლაიდერი',
  'photo-boxes': 'ფოტო ბოქსი',
  'cover-flow-slider': 'ქავერის სლაიდერი',
  'centered-slider': 'გაცენტრილი სლაიდერი',
  'info-box': 'ინფო ბოქსი',
  'blog-slider': 'ბლოგის სლაიდერი',

  sliderTextAndDescription: 'სლაიდერის ტექსტი და აღწერა',
  titleAndDescription: 'ტექსტი და აღწერა',

  //StarterPage Translations
  'starter-page': 'საწყისი გვერდი',
  'starter-slider': 'საწყისი სლაიდერი',
  'comparison-flow-one': 'Comparison Flow One',
  'comparison-flow-two': 'Comparison Flow Two',
  'comparison-info-one': 'Comparison Info One',
  'comparison-info-two': 'Comparison Info Two',

  //FAQ Translations
  FAQ: 'F.A.Q',

  //Ui-lib-module Translations
  deleteAll: 'ყველას წაშლა',
  deleteText: 'ნამდვილად გსურს წაშლა?',
  'no-table-data': 'ჩანაწერი არ მოიძებნა',
  'entries-in-the-list': 'ჩანაწერი სიაში',
  'file-is-too-large': 'ფაილი ძალიან დიდია',
  'maximum-file-size-is': 'ფაილის მაქსიმალური ზომაა',
  'file-of-invalid-type': 'არასწორი ტიპის ფაილი',
  'the-maximum-number-of-files-is': 'ფაილების მაქსიმალური რაოდენობა არის',

  //local errors
  'Network Error': 'ქსელთან წვდომა არ არის',

  deleteDialogTitle: 'წაშლის მოქმედება',
  deleteDialogText: 'ნამდვილად გინდა წაშლა?',

  'Clients-drawer': 'კლიენტები',
  'clients-group-drawer': 'კლიენტეის გრუპები',
  'sales-points-drawer': 'გაყიდვის წერტილები',
  baseType: 'ბაზის ტიპი',

  nameAsc: 'სახელი ზრდადობით',
  nameDesc: 'სახელი კლებადობით',
  dateAsc: 'დრო ზრდადობით',
  sortByDateAsc: 'დრო ზრდადობით',
  sortByDeliveryDateAsc: 'მიწოდების დრო ზრდადობით',
  sortByDeliveryDateDesc: 'მიწოდების დრო კლებადობით',
  deliveryDate: 'მიწოდების დრო',
  sortByAmountHigh: 'მაღალი თანხა',
  sortByAmountLow: 'დაბალი თანხა',
  dateDesc: 'დრო კლებადობით',
  durationDaysDesc: 'დღეების ხანგრძლივობა კლებადობით',
  durationDaysAsc: 'დღეების ხანგრძლივობა ზრდადობით',
  sortByDateDesc: 'დრო კლებადობით',
  sortByIdDesc: 'ID კლებადობით',
  sortByIdAsc: 'ID ზრდადობით',
  sortByAddDateDesc: 'დამატების დრო კლებადობით',
  sortByAddDateAsc: 'დამატების დრო ზრდადობით',
  startDateDesc: 'დაწყების დრო კლებადობით',
  startDateAsc: 'დაწყების დრო ზრდადობით',

  'file-invalid-type': 'ფაილის ტიპი არავალიდურია',
  'file-too-large': 'ფაილის ზომა აღემატება დასაშვებს',
  'too-many-files': 'ფაილების რაოდენობა აღემატება დასაშვებს',

  //WidgetsModule
  widgetTitleTotalAmount: 'ჯამური თანხა',
  widgetTitleLastWeek: 'ბოლო კვირა',
  widgetTitleThisWeek: 'მიმდინარე კვირა',
  lastWeek: 'გასული კვირა',
  sentMessages: 'გაგზავნილი მესიჯები',
  widgetTitleThisYear: 'ეს წელი',
  widgetTitleStatuses: 'სტატუსები',
  widgetTitleSalesPointType: 'გაყიდვის წერტილები',

  widgetSubTitleNumberOfTransactions: 'ტრანზაქციების რაოდენიბა',
  widgetSubTitleSumOfTransactions: 'ტრანზაქციების ჯამი',
  widgetSubTitleNumberOfRegistration: 'რეგისტრაციების რაოდენობა',
  widgetTitleTopProducts: 'ტოპ პროდუქტები',
  widgetTitleTopBundles: 'ტოპ ბანდლის შეთავაზებები',
  widgetTitleTopSubscription: 'ტოპ გამოწერის შეთავაზება',
  dropper: 'დროფერი',
  ecommerce: 'ონლაინ მაღაზია',
  ecommerce_CMS: 'მაღაზიის CMS',
  callcenter: 'ქოლცენტრი',
  shop_meama_ge: 'ონლაინ მაღაზია',
  'shop_meama_ge,dropper': 'ონლაინ მაღაზია & დროფერი',
  completed: 'შესრულებული',
  paid: 'გადახდილი',
  other: 'სხვა',
  jan: 'იან',
  feb: 'თებ',
  mar: 'მარ',
  apr: 'აპრ',
  may: 'მაი',
  jun: 'ივნ',
  jul: 'ივლ',
  aug: 'აგვ',
  sep: 'სექ',
  oct: 'ოქტ',
  nov: 'ნოე',
  dec: 'დეკ',
  mon: 'ორშ',
  tue: 'სამ',
  wed: 'ოთხ',
  thu: 'ხუთ',
  fri: 'პარ',
  sat: 'შაბ',
  sun: 'კვი',
  'widget-no-data': 'ჩანაწერი არ მოიძებნა',

  syncIndexes: 'სინქრონიზაცია',

  //comments
  commentsCount: 'კომენტარების რაოდენობა',
  reply: 'პასუხი',
  addComment: 'კომენტარის დამატება',

  chooseColor: 'აირჩიე ფერი',

  // table buttons
  archive: 'დაარქივება',
  preview: 'გადახედვა',
  'workingDays&Hours': 'სამუშაო დღეები & საათები',
  workingDays: 'სამუშაო საათები',
  orders: 'შეკვეთები',
  offers: 'შეთავაზებები',
  cart: 'კალათა',
  'deliveryMethod&Payment': 'მიწოდებისი სერვისი & გადახდა',
  previewOrder: 'Preview Order',
  warehouse: 'საწყობი',
  'ecommerce-orders': 'შეკვეთები',

  dismiss: 'გაუქმება',
  next: 'შემდეგი',
  today: 'დღეს',
  deliveryTypeName: 'მიწოდების ტიპის სახელი',
  messages: 'მესიჯები',
  notify: 'Notify',
  notifyTemplates: 'Notify Templates',
  saveAsTemplate: 'Save As Template',
  sendTime: 'გაგზავნის დრო',
  templateName: 'თემფლეითის სახელი',
  opened: 'გახსნილი',
  unopened: 'გაუხსნელი',
  failed: 'გაუქმებული',
  warning: 'გაფრთხილება',
  succeed: 'წარმატებული',
  inProgress: 'მიმდინარე',
  created: 'შექმნილი',
  'google-analytics': 'Google ანალიტიკის კონფიგურაცია',
  acceptedType: 'ფაილის ტიპი',
  viewId: 'ვიუს იდ',
  google: 'Google',
  googleAnalytics: 'Google ანალიტიკა',
  nowActiveUsers: 'ეხლა აქტიური მომხმარებლები',
  activeUsersByYear: 'შლის აქტივობა',
  activityByCountry: 'ქვეყნის მიხედვით აქტიურობა',
  activityByPages: 'გვერდის მიხედვით აქტიურობა',
  session: 'სესია',
  analytics: 'ანალიტიკა',
  'order-analytics': 'შეკვეთების ანალიტიკა',
  'subscription-analytics': 'გამოწერის ანალიტიკა',
};

export default data;
