import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

export const ArrayExtenstionMethods = class {
  arrayMove = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  static getListFromChildes = (arr, name = 'children') => {
    let list = [];

    const recures = (arr, list, name) => {
      if (arr?.length > 0) {
        return arr.map((company) => {
          if (company[name]?.length > 0) {
            list.push(company);
            return recures(company[name], list, name);
          } else {
            return list.push(company);
          }
        });
      }
    };
    recures(arr, list, name);
    return list;
  };

  static getTreeFromList = (items, generateTableCell = () => {}, level = 1, link = 'parentId', id = 0) =>
    items
      .filter((item) => item[link] == id)
      .map((item) => ({
        ...item,
        children: generateTableCell(
          this.getTreeFromList(items, generateTableCell, level + 1, link, item.id),
          level + 1
        ),
      }));

  static reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  constructor() {
    makeAutoObservable(this);
  }
};

export default createContext(new ArrayExtenstionMethods());
