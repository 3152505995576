import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const QuillEditorStore = class {
    value = {};
    requestToServer = async (file) => ""

    constructor() {
        makeAutoObservable(this);
    }


    setHtmlValue = (val, quillId) => this.value[quillId] = val;

    setImageUrls = async (quillId) => {
        var contentDiv = document.getElementById(`text-editor-${quillId}`);

        if(!contentDiv) {
            return false;
        };
        var imagesToUploud = contentDiv.getElementsByTagName("img");
        if(imagesToUploud && imagesToUploud?.length > 0) {
            for (let index = 0; index < imagesToUploud.length; index++) {
                const element = imagesToUploud[index];
                const file = this.dataURLtoFile(element?.currentSrc, `image-from-editor.${element?.currentSrc?.substring("data:image/"?.length, element?.currentSrc?.indexOf(";base64"))}`);
                if(file) this.value[quillId] = this.value[quillId].replace(element.currentSrc, await this.requestToServer(file));
            }
        };
        return this.value[quillId];
    }

    dataURLtoFile = (dataurl, filename) => {
        try {
            if(dataurl.includes("https://")) return;
            
            var arr = dataurl?.split(','),
            mime = arr[0]?.match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr?.length, 
            u8arr = new Uint8Array(n);
            
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], filename, {type:mime});
        } catch(ex) { 
            return new File([u8arr], filename, {type:mime});
        }
    }
};

export default createContext(new QuillEditorStore());