import React, { useState } from "react";
import { MMenu } from "../../../material";

export const CustomMenu = ({ opener, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={handleClick}>
        {opener}
      </div>
      <MMenu
        className="resp-table-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </MMenu>
    </>
  );
};

export default CustomMenu;
