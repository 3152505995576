import { makeAutoObservable, reaction } from "mobx";
import { v4 as uuidv4 } from 'uuid';

export const StaticValidator = class {
    static validators = [];


    static addStaticValidator = (validator) => {
        StaticValidator.validators = [...StaticValidator.validators, validator];
        return validator;
    }
};


