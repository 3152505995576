//add here your new modules don't forgot add your new module into package.json

export class Modules {
  authentication = import('modules/authentication-module/routes');
  user = import('modules/users-module/routes');
  dashboard = import('modules/dashboard-module/routes');
  core = import('router-modules/core-module-router/routes');
  finances = import('router-modules/finances-module-router/routes');
  products = import('router-modules/products-module-router/routes');
  promotions = import('router-modules/promotions-module-router/routes');
  clients = import('router-modules/clients-module-router/routes');
  ecommerce = import('router-modules/ecommerce-module-router/routes');
  orders = import('router-modules/orders-module-router/routes');
  vms = import('router-modules/vms-module-router/routes');
  notifications = import('router-modules/notifications-module-router/routes');
  // meamaBusiness = import("router-modules/meama-business-module-router/routes");
}
