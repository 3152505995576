import React, { useState } from "react";
import { useEffect } from "react";
import { IconMdArrowDropDown } from "../../../icons";
import { MMenu, MMenuItem } from "../../../material/components";
import styles from "./styles.module.css";

// TODO: two item not show second item
export const ViewMoreValuesBtn = ({ id, classText, data, name = "label", showFirst = true, moreCount = 1 }) => {
  if (data?.length === 0 || !data)
    return <span key={id ?? Math.random()}></span>;

  const [anchorEl, setAnchorEl] = useState(null);
  const [localData, setLocalData] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(data?.length < 1) return;

    let firstEl = data[0];
    data?.length > 1 && showFirst && firstEl 
      ? setLocalData(data?.filter((o) => o[name] !== firstEl[name]))
      : setLocalData(data);
  }, [data]);

  if(data?.length < 1) return null;

  return (
  <React.Fragment >
    {(showFirst && data?.length) && data[0] && data[0][name]}
    {data?.length > moreCount ? (
      <>
        <div
          className={`${styles.moreValueBtn} f-bold`}
          aria-describedby={id}
          onClick={handleClick}
        >
          +{showFirst ? data?.length - 1 : data?.length }
          <IconMdArrowDropDown />
        </div>
        <MMenu
          className={`${styles.optionsInfoDropdown} ${classText}`}
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          getContentAnchorEl={null}
        >
          {localData?.length > 0 && localData?.map((e, index) => (
            <MMenuItem
              key={index}
              className={`${styles.option} f-bold`}
            >
              {e ? e[name]: null}
            </MMenuItem>
          ))}
        </MMenu>
      </>
    ) : null}
  </React.Fragment>
  );
};

export default ViewMoreValuesBtn;
