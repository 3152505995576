import React, { useEffect, useState } from 'react';

import { IconMdDelete } from '../../../icons/components';
import { MTextValidator, MButton, MRadioGroup } from '../../../material/components';
import { CheckboxDays } from '../CheckboxDays';
import { useTranslations } from 'shared-modules/translation-module';

import styles from './styles.module.css';

export const CustomSetHours = ({
  startIndex = 0,
  disabled = false,
  timesValidation = false,
  required = false,
  deleteButton,
  deleteHandler,
  checkedItems = [],
  disabledItems = [],
  inputValues = {},
  onChange = () => {},
  startOnChange = () => {},
  endOnChange = () => {},
}) => {
  const { t } = useTranslations();
  const [validations, setValidations] = useState({ validators: [], errorMessages: [] });
  useEffect(() => {
    if (timesValidation) {
      setValidations({
        validators: [
          ...validations.validators,
          'matchRegexp:^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$',
        ],
        errorMessages: [...validations.errorMessages, 'Please enter correct time format'],
      });
    }

    if (required) {
      setValidations({
        validators: [...validations.validators, 'required'],
        errorMessages: [...validations.errorMessages, t['thisFieldIsRequired']],
      });
    }
  }, []);

  return (
    <div className={disabled ? styles.disabled : ''}>
      <div className={styles.box}>
        <div className={`${styles.top} d-flex align-items-center`}>
          <span className={`${styles.title} f-bold`}>{t['workingTimes']}</span>
          <MTextValidator
            className={styles.input}
            type="text"
            name="startTime"
            validators={validations.validators}
            errorMessages={validations.errorMessages}
            value={inputValues?.start}
            onChange={startOnChange}
          />
          <div className={styles.dash}>-</div>
          <MTextValidator
            className={styles.input}
            type="text"
            name="endTime"
            validators={validations.validators}
            errorMessages={validations.errorMessages}
            value={inputValues?.end}
            onChange={endOnChange}
          />
        </div>
        <div className={styles.line}></div>
        <MRadioGroup>
          <div className={`${styles.days} d-flex align-items-center justify-content-between`}>
            <CheckboxDays
              label="Mo"
              checked={checkedItems?.some((o) => o === startIndex)}
              disabled={disabledItems?.some((o) => o === startIndex)}
              onChange={() => onChange(startIndex)}
            />
            <CheckboxDays
              label="Tu"
              checked={checkedItems?.some((o) => o === startIndex + 1)}
              disabled={disabledItems?.some((o) => o === startIndex + 1)}
              onChange={() => onChange(startIndex + 1)}
            />
            <CheckboxDays
              label="We"
              checked={checkedItems?.some((o) => o === startIndex + 2)}
              disabled={disabledItems?.some((o) => o === startIndex + 2)}
              onChange={() => onChange(startIndex + 2)}
            />
            <CheckboxDays
              label="Th"
              checked={checkedItems?.some((o) => o === startIndex + 3)}
              disabled={disabledItems?.some((o) => o === startIndex + 3)}
              onChange={() => onChange(startIndex + 3)}
            />
            <CheckboxDays
              label="Fr"
              checked={checkedItems?.some((o) => o === startIndex + 4)}
              disabled={disabledItems?.some((o) => o === startIndex + 4)}
              onChange={() => onChange(startIndex + 4)}
            />
            <CheckboxDays
              label="Sa"
              checked={checkedItems?.some((o) => o === startIndex + 5)}
              disabled={disabledItems?.some((o) => o === startIndex + 5)}
              onChange={() => onChange(startIndex + 5)}
            />
            <CheckboxDays
              label="Su"
              checked={checkedItems?.some((o) => o === startIndex + 6)}
              disabled={disabledItems?.some((o) => o === startIndex + 6)}
              onChange={() => onChange(startIndex + 6)}
            />
          </div>
        </MRadioGroup>
        {deleteButton && (
          <div className={`${styles.deleteButton} d-flex justify-content-end`} onClick={deleteHandler}>
            <MButton className="f-bold danger" type="submit">
              <IconMdDelete /> {t['delete']}
            </MButton>
          </div>
        )}
      </div>
    </div>
  );
};
export default CustomSetHours;
