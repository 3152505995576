// export all translation files here
import root_en from './root/root_translation_en';
import root_ka from './root/root_translation_ka';

import companyModule_ka from './company-module/cpmpany-module_ka';
import companyModule_en from './company-module/company-module_en';

import regionsModule_ka from './locations-module/locations-module_ka';
import regionsModule_en from './locations-module/locations-module_en';

import rolesModule_ka from './roles-module/roles-module_ka';
import rolesModule_en from './roles-module/roles-module_en';

import currenciesModule_ka from './currencies-module/currencies-module_ka';
import currenciesModule_en from './currencies-module/currencies-module_en';

import departamentModule_ka from './departament-module/departament-module_ka';
import departamentModule_en from './departament-module/departament-module_en';

import usersModule_ka from './users-module/users-module_ka';
import usersModule_en from './users-module/users-module_en';

import languagesModule_ka from './languages-module/languages-module_ka';
import languagesModule_en from './languages-module/languages-module_en';

import stringsModule_ka from './strings-module/strings-module_ka';
import stringsModule_en from './strings-module/strings-module_en';

import ecommerceStringsModule_ka from './ecommerce-strings-module/ecommerce-strings-module_ka';
import ecommerceStringsModule_en from './ecommerce-strings-module/ecommerce-strings-module_en';

import logsModule_ka from './logs-module/logs-module_ka';
import logsModule_en from './logs-module/logs-module_en';

import transactionsModule_ka from './transactions-module/transactions-module_ka';
import transactionsModule_en from './transactions-module/transactions-module_en';

import physicalListModule_ka from './physical-list-module/physical-list-module_ka';
import physicalListModule_en from './physical-list-module/physical-list-module_en';

import physicalGroupsModule_ka from './physical-groups-module/physical-groups-module-module_ka';
import physicalGroupsModule_en from './physical-groups-module/physical-groups-module-module_en';

import specificationsModule_ka from './specifications-module/specifications-module_ka';
import specificationsModule_en from './specifications-module/specifications-module_en';

import productsListModule_ka from './products-list-module/products-list-module_ka';
import productsListModule_en from './products-list-module/products-list-module_en';

import salesPointsModule_ka from './sales-points-module/sales-points-module_ka';
import salesPointsModule_en from './sales-points-module/sales-points-module_en';

import offersModule_ka from './offers-module/offers-module_ka';
import offersModule_en from './offers-module/offers-module_en';

import tagsModule_ka from './tags-module/tags-module_ka';
import tagsModule_en from './tags-module/tags-module_en';

import statusesModule_ka from './statuses-module/statuses-module_ka';
import statusesModule_en from './statuses-module/statuses-module_en';

import commentariesModule_ka from './commentaries-module/commentaries-module_ka';
import commentariesModule_en from './commentaries-module/commentaries-module_en';

import ecommerceCategoriesModule_ka from './ecommerce-categories-module/departament-module_ka';
import ecommerceCategoriesModule_en from './ecommerce-categories-module/departament-module_en';

import ecommercetagsModule_ka from './ecommerce-tags-module/ecommerce-tags-module_ka';
import ecommercetagsModule_en from './ecommerce-tags-module/ecommerce-tags-module_en';

import b2bListModule_ka from './b2b-list-module/b2b-list-module_ka';
import b2bListModule_en from './b2b-list-module/b2b-list-module_en';

import b2bDraftsModule_ka from './b2b-drafts-module/b2b-drafts-module_ka';
import b2bDraftsModule_en from './b2b-drafts-module/b2b-drafts-module_en';

import unitModule_ka from './unit-module/unit-module_ka';
import unitModule_en from './unit-module/unit-module_en';

import fauxModule_ka from './faux-module/faux-module_ka';
import fauxModule_en from './faux-module/faux-module_en';

import warehouseModule_ka from './warehouse-module/warehouse-module_ka';
import warehouseModule_en from './warehouse-module/warehouse-module_en';

import ordersModule_ka from './orders-module/orders-module_ka';
import ordersModule_en from './orders-module/orders-module_en';

import ecommerceOrdersModule_ka from './ecommerce-orders-module/ecommerce-orders-module_ka';
import ecommerceOrdersModule_en from './ecommerce-orders-module/ecommerce-orders-module_en';

import promoCodeSetupModule_ka from './promo-code-setup-module/promo-code-setup-module_ka';
import promoCodeSetupModule_en from './promo-code-setup-module/promo-code-setup-module_en';

import MeamaBusinessModule_ka from './meamaBusiness-module/meamaBusiness-module_ka';
import MeamaBusinessModule_en from './meamaBusiness-module/meamaBusiness-module_en';

import vmsModule_en from './vms/vms-module_en';
import vmsModule_ka from './vms/vms-module_ka';

const resources = {
  ka: {
    // module name : file location
    user: root_ka,
    core: {
      ...root_ka,
      ...companyModule_ka,
      ...regionsModule_ka,
      ...rolesModule_ka,
      ...currenciesModule_ka,
      ...departamentModule_ka,
      ...usersModule_ka,
      ...languagesModule_ka,
      ...stringsModule_ka,
      ...logsModule_ka,
      ...salesPointsModule_ka,
      ...warehouseModule_ka,
    },

    meamaBusiness: {
      ...root_ka,
      ...MeamaBusinessModule_ka,
    },
    finances: {
      ...root_ka,
      ...transactionsModule_ka,
    },
    products: {
      ...root_ka,
      ...specificationsModule_ka,
      ...productsListModule_ka,
      ...unitModule_ka,
    },
    promotions: {
      ...root_ka,
      ...offersModule_ka,
      ...promoCodeSetupModule_ka,
    },
    clients: {
      ...root_ka,
      ...physicalListModule_ka,
      ...physicalGroupsModule_ka,
      ...b2bListModule_ka,
      ...b2bDraftsModule_ka,
      ...tagsModule_ka,
      ...statusesModule_ka,
      ...commentariesModule_ka,
    },
    ecommerce: {
      ...root_ka,
      ...ecommerceCategoriesModule_ka,
      ...ecommercetagsModule_ka,
      ...ecommerceStringsModule_ka,
      ...fauxModule_ka,
      ...ecommerceOrdersModule_ka,
    },
    orders: {
      ...root_ka,
      ...ordersModule_ka,
    },
    vms: {
      ...root_ka,
      ...vmsModule_ka,
    },
    notifications: {
      ...root_ka,
    },
    menuoptions: root_ka,
    default: root_ka,
  },

  en: {
    // module name : file location
    user: root_en,
    core: {
      ...root_en,
      ...companyModule_en,
      ...regionsModule_en,
      ...rolesModule_en,
      ...currenciesModule_en,
      ...departamentModule_en,
      ...usersModule_en,
      ...languagesModule_en,
      ...stringsModule_en,
      ...logsModule_en,
      ...salesPointsModule_en,
      ...warehouseModule_en,
    },

    meamaBusiness: {
      ...root_en,
      ...MeamaBusinessModule_en,
    },
    finances: {
      ...root_en,
      ...transactionsModule_en,
    },
    products: {
      ...root_en,
      ...specificationsModule_en,
      ...productsListModule_en,
      ...unitModule_en,
    },
    promotions: {
      ...root_en,
      ...offersModule_en,
      ...promoCodeSetupModule_en,
    },
    clients: {
      ...root_en,
      ...physicalListModule_en,
      ...physicalGroupsModule_en,
      ...b2bListModule_en,
      ...b2bDraftsModule_en,
      ...tagsModule_en,
      ...statusesModule_en,
      ...commentariesModule_en,
    },
    ecommerce: {
      ...root_en,
      ...ecommerceCategoriesModule_en,
      ...ecommercetagsModule_en,
      ...ecommerceStringsModule_en,
      ...fauxModule_en,
      ...ecommerceOrdersModule_en,
    },
    orders: {
      ...root_en,
      ...ordersModule_en,
    },
    notifications: {
      ...root_en,
    },
    vms: {
      ...root_en,
      ...vmsModule_en,
    },
    menuoptions: root_en,
    default: root_en,
  },
};

const namespaces = ['default', 'company'];

export const res = {
  resources,
  namespaces,
  defaultLanguage: 'en',
  defaultNS: 'default',
};
