import React from "react";
import { Observer } from "mobx-react";
import styles from "./styles.module.css";

export const CustomBorderedConteiner = ({
  children, 
  headlineComponent,
  title = "", 
}) => {
  const HeadlineCompoenenet = headlineComponent
  return (
      <Observer render={()=>(
        <div  className={styles.sectionContainer}>
          {
            (title || headlineComponent) ? (
              <div className="page-headline">
              <div className="d-flex align-center justify-content-between">
                {
                  title && (
                    <div className={`${styles.colTitle}`}>{title}</div>
                  )
                }
                {
                  headlineComponent && (
                    <div className="d-flex align-items-center">
                      <HeadlineCompoenenet />
                    </div>
                  )
                }
              </div>
            </div>
            ) : null
          }
          {children}
        </div>
      )}/>
  );
};

export default CustomBorderedConteiner;
