import React from "react";
import { IconMdSettings } from "../../../icons/components";
import { useTranslations } from "shared-modules/translation-module";
import styles from "./styles.module.css";

export const EditTableBtn = (props) => {
  const { t } = useTranslations();

  if (props.label) {
    return (
      <div
        className={`${styles.editTableBtn} custom-icon-box f-bold d-flex align-items-center`}
        {...props}
      >
        <IconMdSettings
          className={`custom-icon dark-green ${
            props?.disabled ? "disabled" : ""
          }`}
        />
        <span className="custom-icon-label">{t[props.label] ?? ""}</span>
      </div>
    );
  }
  return (
    <IconMdSettings
      className={`custom-icon dark-green ${
        props?.disabled ? "disabled" : ""
      }`}
      {...props}
    />
  );
};
export default EditTableBtn;
