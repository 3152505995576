import React from "react";
import { Radio } from "@material-ui/core";
import { MdCheck } from "react-icons/md";
import "../style.css";
export const MRadio = React.forwardRef((props, ref) => (
  <Radio
    {...props}
    onChange={(e) => {
      [].slice.call(document.getElementsByClassName("MuiFormGroup-root"))?.map(o => {
        let minCheckCount = Number([].slice.call(document.getElementsByClassName("custom-validator"))?.find(i =>i?.contains(o))?.getAttribute("minCheck"));
        if(o?.contains(e.target) && 
           minCheckCount <= o.getElementsByClassName("Mui-checked")?.length+1
          ) {
            if(o?.contains(e.target) && [].slice.call(o?.lastChild?.classList)?.includes("error")) o?.removeChild(o?.lastChild); 
            [].slice.call(o?.getElementsByClassName("MuiFormControlLabel-root"))?.map(el => el?.classList?.remove("errorBorder"));
        };
      })
      typeof props.onChange === "function" && props.onChange(e);
    }}
    innerRef={ref}
    icon={
      <div className="radio-icon">
        <MdCheck />
      </div>
    }
    checkedIcon={
      <div className="radio-icon">
        <MdCheck />
      </div>
    }
  />
));
export default MRadio;
