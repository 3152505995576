import { useContext } from "react";
// import { NotFound_404 } from "@meama/modules-for-routes-module";
import { UserManagerStore } from "shared-modules/user-manager-module";
import { useTranslations } from "shared-modules/translation-module";
import RoutingStore from "../Stores/RoutingStore";
import { useOnlyPages } from "shared-modules/modules-for-routes-module";

// don't touch
export const useRoutes = async () => {
  //gets user data
  const userContext = useContext(UserManagerStore);
  //custum logic for useRoutes hook
  const routesContext = useContext(RoutingStore);
  //config for i18n setting namespace
  const { configStore } = useTranslations();

  // const loc = location()

  //initial user data
  userContext.initial();

  //if user is unauthorized load only auth module
  if (!userContext.isAuthorized)
    return routesContext.getAuthentificationRoutes();

  //hook for only page modules it means page does't have module but it exsists like login form but it's not login
  const onlyPages = useOnlyPages(location.pathname);
  //return only page if it exsists
  if(onlyPages !== null) return onlyPages;

  //getting current root module
  var moduleData = await routesContext.getModule();
  //urls for react router
  var urlsArr = [];
  //check if all prev steps done
  if (moduleData && userContext.allCalms.length !== 0) {
    //set current namespace i18n
    configStore.setNamespace(moduleData.moduleKey);
    
    
  
    //check if user already authorized if its authorized return 404
    // // if (moduleData.moduleKey === "authentication")
    // //   return [{ key: "*", value: NotFound_404 }];

    //check if root module has sub modules
    if (moduleData.allModules) {
      //if user has access on module keys set to all pages access props true
      if (userContext.hasAccessOnAction(moduleData.loadedRootModule["ModuleKey"])) {
        moduleData.allModules.forEach(async (moduleWithPageKeys) => (await routesContext.getModuleAccessPages(moduleWithPageKeys.module, moduleWithPageKeys.pageKeys, moduleWithPageKeys.isSubModules, moduleWithPageKeys.subRoute)).map((o) => urlsArr.push(o)));
      }
      //check if user has access on page or page access key
      else {
        moduleData.allModules.forEach((moduleWithPageKeys) => {
          moduleWithPageKeys.pageKeys.forEach(async (pageKay) => {
            //check if user has access on page set to all access key true
            userContext.hasAccessOnAction(moduleWithPageKeys.module[pageKay]["PageKey"]) &&
            (await routesContext.setPageAccesses(moduleWithPageKeys.module, pageKay, moduleWithPageKeys.isSubModules, moduleWithPageKeys.subRoute)).map((o) => urlsArr.push(o));
            //check if user has access on page access key and set true for one or more access keys
            !userContext.hasAccessOnAction(moduleWithPageKeys.module[pageKay]["PageKey"]) &&
            (await routesContext.setClaimAccesses(moduleWithPageKeys.module, pageKay, userContext, moduleWithPageKeys.isSubModules, moduleWithPageKeys.subRoute)).map((o) => urlsArr.some(i => i.key === o.key) ? undefined : urlsArr.push(o));
          });
        });
      }
        
      //return initialized routes with loaded components on current route
      return urlsArr;
    }
  }

  return [];
};

export default useRoutes;
