//create languages for transactions module
let data = {
    "transactions": "ტრაზაქციები",
    "id": "Id",
    "Id": 'Id',
    "UserId": 'მომხმარებლის Id',
    "TransationRefId": 'გარე ID',
    "PaymentDate": 'გადახდის თარიღი',
    "OrderDate": 'შეკვეთის თარიღი',
    "CustomerId": 'მომხმარებლის Id',
    "TransactionStatus": 'სტატუსი',
    "TransactionType": 'ტიპი',
    "PaymentChannel": 'გადახდის არხი',
    "PaymentType": 'გადახდის ტიპი',
    "SalePointId": 'გაყიდვის არხის Id',
    "SalesPointName": 'გაყიდვის არხის სახელი',
    "SalesPointType": 'გაყიდვის არხი',
    "SalesPointTypeValue": 'გაყიდვის არხის ტიპის მნიშვნელობა',
    "Amount": 'ღირებულება',
    "Currency": 'ვალუტა',
    "FreeCapsuleAmount": 'უფასო კაფსულა',
    "CompanyId": 'კომპანია Id',
    "CompanyName": 'კომპანია',
    "CardNumber": 'ბარათის ნომერი',
    "Cashier": 'მოლარე',
    "OrderCode": 'შეკვეთის კოდი',
    "UniqueCode": 'უნიკალური კოდი',
    "ChangeTime": 'შეცვლის დრო',
    "DetailedInformation": 'დეტალის ინფორმაცია',
    "TransactionDetails": 'ტრანზაქციის დეტალი',
    "ProductsDetails": 'პროდუქტის დეტალი',
    "History": 'ისტორია',
    "live": "ლაივი",
    "transactionsIsInLive": "ტრანზაქციები ლაივშია",
    "transactionsIsNotInLive": "ტრანზაქციები არ არის ლაივში",
    "transactionType": "ტრანზაქციის ტიპი",
    "paymentChannel": "გადახდის არხი",
    "salesPointName": "გაყიდვის წერტილის სახელი",
    "salesPointType": "გაყიდვის წერტილის ტიპი",
}

export default data;