import React, { useEffect, useState } from "react";
import TransitionGroup from "react-transition-group/TransitionGroup";
import { MCollapse } from "../../../../../material";
import PageLoader from "../../../PageLoader";
import ListItemCells from "./components/ListItemCells";
import ListLabel from "./components/ListLabel";
import ListActionButtons from "../ListActionButtons";
import { DraggableList } from "../..";
import style from "./style.module.css";

const calculateItemStyle = (isDragging, draggableStyle, recursiveChildren) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  margin: recursiveChildren ? "16px 0 0 0" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ListItem = ({
  dragDisabled = false,
  withIndexes,
  index,
  data,
  provided,
  snapshot,
  expandHandler,
  dragHandler,
  buttonsLength,
  detailsButton,
  editButton,
  deleteButton,
  retryButton,
  downloadButton,
  detailsHandler,
  editHandler,
  deleteHandler,
  retryHandler,
  downloadHandler,
  _isRecursiveChild,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    //check if expand true and data is empty array request async request
    if (isExpanded && data.children?.length === 0) {
      expandHandler?.(data.id);
    }
  }, [isExpanded]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={calculateItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style,
        _isRecursiveChild
      )}
      className={style.listItemContainer}
    >
      <div className={style.listItem}>
        <div className={style.leftSide}>
          <ListLabel
            dragDisabled={dragDisabled}
            hasChild={data.hasChild}
            dragHandleProps={provided.dragHandleProps}
            label={data.label}
            index={index}
            isExpanded={isExpanded}
            setExpanded={setExpanded}
            withIndexes={withIndexes}
          />
        </div>

        <div className={style.rightSide}>
          <ListItemCells data={data.cells} />

          <ListActionButtons
            data={data}
            buttonsLength={buttonsLength}
            detailsButton={detailsButton}
            editButton={editButton}
            deleteButton={deleteButton}
            retryButton={retryButton}
            downloadButton={downloadButton}
            detailsHandler={detailsHandler}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            retryHandler={retryHandler}
            downloadHandler={downloadHandler}
          />
        </div>
      </div>

      <TransitionGroup>
        {isExpanded && data.hasChild && (
          <MCollapse timeout={400}>
            <PageLoader isLoading={data?.children?.length === 0}>
              <div className={style.children}>
                <DraggableList
                  dragDisabled={dragDisabled}
                  data={data.children}
                  _isRecursiveChild={true}
                  detailsButton={detailsButton}
                  editButton={editButton}
                  deleteButton={deleteButton}
                  retryButton={retryButton}
                  downloadButton={downloadButton}
                  dragHandler={dragHandler}
                  detailsHandler={detailsHandler}
                  editHandler={editHandler}
                  deleteHandler={deleteHandler}
                  retryHandler={retryHandler}
                  expandHandler={expandHandler}
                  downloadHandler={downloadHandler}
                />
              </div>
            </PageLoader>
          </MCollapse>
        )}
      </TransitionGroup>
      {provided.placeholder}
    </div>
  );
};

export default ListItem;