import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const SearchStore = class {
    inputText = '';

    searchHandler = (data, searchParam, searcPropertyName) => {
      if(data?.length)
        return data?.map(o => {
            if(o[searcPropertyName]) {
               if(o[searcPropertyName].map(i => i?.toLowerCase()?.includes(searchParam?.toLowerCase()))?.some(i => i)) return o
            } else return null
        })?.filter(o => o !== null);
    }

  constructor() {
    makeAutoObservable(this);
  }
};

export default createContext(new SearchStore());
