import { I18NConfigStore } from "shared-modules/translation-module/classes";
import { toast } from "react-toastify";
import { IconMdGppBad } from "../../../../icons";

const errorToast = (message, options) => {
    /*
    DESC:
        this is a shit but all works.
        don't touch because when token is expired and cms tray to use refresh token on previous request will failed and we should hide the error

        we handle all exception in every store methods and we qen not write this in other place.
    */
    if(message === `Cannot read properties of undefined (reading 'cancelToken')` || message === null ) return;

    const i18NConfigStore = new I18NConfigStore();
    const t = i18NConfigStore.GetLanguageResource({ lng: localStorage.i18nextLng, ns: location.pathname.split('/').length > 2 ? location.pathname.split('/')[1] : 'default' });

    toast.error(t ? t[message] ?? message : "Message", {
        icon: IconMdGppBad,
        toastId: message,
        ...options,
    });
}

// export default  
export default errorToast
