import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { Modules } from 'shared-modules/modules-for-routes-module';

export const RoutingStore = class {
  constructor() {
    makeAutoObservable(this);
  }

  //gets authorization routes when user is not logged in
  getAuthentificationRoutes = async () => {
    var urlsArr = []; //urls array
    //getting auth module form modules class gous from '@meama/modules-for-routes-module'
    const authentication = await Object.getOwnPropertyDescriptor(new Modules(), "authentication")?.value;
    const module = authentication[Object.getOwnPropertyNames(authentication)[1]]; //current authentication comdule
    //filter pages and functions returns only pages
    const pageKeys = Object.getOwnPropertyNames(module).filter(o => (typeof module[o]) === "function");
    //add authentification routes in urlsArr and mapp to correct object
    pageKeys?.forEach(o => module[o]["Route"]?.map(i => urlsArr?.push({ key: i === "/" ? `*` : i, value: module[o]["Component"] }))); 
    urlsArr?.reverse(); //reverse array this for * page now its / this page
    return urlsArr; //return urls array
  }

  //gets module key from url
  getModuleKey = () => window.location.pathname.split('/')[1];
  //get page kays by module or sub module
  getModulePageKeys = (loadedModule) => Object.getOwnPropertyNames(loadedModule).filter(o => (typeof loadedModule[o]) === "function")
  //gets module by key form midules-for-routes-module
  getModule = async (moduleKey = this.getModuleKey()) => { 
    if(!moduleKey || moduleKey === '') return null;
    //getting module form modules class this class gous from '@meama/modules-for-routes-module'
    const lazyModule = Object.getOwnPropertyDescriptor(new Modules(), moduleKey);
    if(!lazyModule) return null;
    const moduleProps = await lazyModule?.value; //load lazy module form modules class 
    //loaded module with unloaded pages and but it has access keys
    const loadedRootModule =  moduleProps[Object.getOwnPropertyNames(moduleProps)[1]];
    const pageKeysAndSubModules = this.getModulePageKeys(loadedRootModule);

    var allModules = [
      { 
        isSubModules: false,
        subRoute: "",
        module: loadedRootModule,
        pageKeys: pageKeysAndSubModules?.filter(o => !o?.includes('_subSelection'))
      },
      ...pageKeysAndSubModules?.filter(o => o?.includes('_subSelection')).map(o => {
        return {
          isSubModules: true,
          subRoute: loadedRootModule[o]["SubRoute"],
          module: loadedRootModule[o],
          pageKeys: this.getModulePageKeys(loadedRootModule[o])
        }
      })
    ]

    if(allModules.find(o => !o.module || !o.pageKeys)) return null;

    return { 
      moduleKey, // current module key 
      loadedRootModule,
      allModules,
    }
  };

  //getting module with setted access keys
  getModuleAccessPages = (module, pageKeys, isSubModule, subRoute) => {
    //array for urls
    var urlsArr = [];
    //current module key getting from url
    const moduleKey = this.getModuleKey();
    //Page kays is object names
    pageKeys.forEach(page => {
      //setting true for all access keys to true if user has access on full module
      Object.getOwnPropertyNames(module[page]["Accesses"]).forEach(accessLocalKey => module[page]["Accesses"][accessLocalKey]["Action"] = true);
      //adding routes to ursl arr and return this
      (module[page]["Route"]).map(routes => urlsArr.push(isSubModule ? { key: `/${moduleKey}/${subRoute}/${routes.url}`, value: module[page]["Component"] } : { key: `/${moduleKey}/${routes.url}`, value: module[page]["Component"] }));
    });
    //return initialized routes
    return urlsArr;
  }

  //getting page with setted access keys
  setPageAccesses = (module, page, isSubModule, subRoute) => {
    //array for urls
    var urlsArr = [];
    //current module key getting from url
    const moduleKey = this.getModuleKey();
    //setting true for all access keys to true if user has aceess on all page
    Object.getOwnPropertyNames(module[page]["Accesses"]).forEach(accessLocalKey => module[page]["Accesses"][accessLocalKey]["Action"] = true);
    //adding routes to ursl arr and return this
    (module[page]["Route"]).map(routes => urlsArr.push(isSubModule ? { key: `/${moduleKey}/${subRoute}/${routes.url}`, value: module[page]["Component"] } : { key: `/${moduleKey}/${routes.url}`, value: module[page]["Component"] }));
    //return initialized routes
    return urlsArr;
  }

  setClaimAccesses = (module, page, userContext, isSubModule, subRoute) => {
    //array for urls
    var urlsArr = [];
    
    //current module key getting from url
    const moduleKey = this.getModuleKey();

    //setting true for current access key to if user has aceess on this key
    Object.getOwnPropertyNames(module[page]["Accesses"]).forEach(accessLocalKey => {
      //setting true if key is defined
      module[page]["Accesses"][accessLocalKey]["Action"] = userContext.allCalms.find(i => i === module[page]["Accesses"][accessLocalKey]["Key"]) !== undefined;
      //check if user has access on this key and then add in urls arr
      if(module[page]["Accesses"][accessLocalKey]["Action"]) (module[page]["Route"]).map(routes => urlsArr.push(isSubModule ? { key: `/${moduleKey}/${subRoute}/${routes.url}`, value: module[page]["Component"] } : { key: `/${moduleKey}/${routes.url}`, value: module[page]["Component"] }));
    })
    //return initialized access key
    return urlsArr;
  }
};

export default createContext(new RoutingStore());