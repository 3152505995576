// b2b list module translations
let data = {
	"b2bClients": "B2B Clients",
	"addNewClient": "Add New Client",
	'addClient': "Add New Client",
	'editClient': "Edit Client",
	"dateCreated": "Date Created",
	"identification": "Identification",
	"identificationCode": "Identification Code",
	"checkNumber": "Check Number",
	"coreCompany": "Core Company",
	"salesManager": "Sales Manager",
	"accountManager": "Account Manager",
	"contractorStatus": "Contractor Status",
	"clientStatus": "Client Status",
	"identificationOrName": "Identification/Name",

	"clientGeneralInfo": "Client General Info",
	"contactInformation": "Contact information",
	"legalAddress": "Legal Address",
	"physicalAddress": "Physical Address",
	"contactPerson": "Contact Person",
	"typeOfCompany": "Type Of Company",
	"trademarkName": "Tradmeark Name",
	"branchType": "Branch Type",
	"isDefault": "Is Default",
	"email": "Email",
	"responsiblePerson": "Responsible Person",
	"addNewPerson": "Add New Person",
	"clientCategories": "Client Categories",
	"dateRange": "Date Range",

	//alerts
	"clientAddedSuccessfully": "Client Added Successfully",
	"clientUpdatedSuccessfully": "Client Updated Successfully",
	"clientRemovedSuccessfully": "Client Removed Successfully",
	"clientNotFound": "Client not found",

	"noCodeFound": "No code found",
	"checkNumberValidation": "Check number by pressing on button",

	//details
	"clientDetailedInformation": "Client Detailed information",
	"addNewBranch": "Add New Branch",
	"editBranch": "Edit Branch",
	"branchesAndContacts": "Branches/Contacts",
	"agreement": "Agreement",
	"signedDocuments": "Signed Documents",
	"generatedDocuments": "Generated Documents",
	"archivedDocuments": "Archived Documents",

	//agreement tab
	"draftName": "Draft Name",
	"documentName": "Document Name",
	"signatoryPerson": "Signatory Person",
	"assignee": "Assignee",
	"reminder": "Reminder",
	"toArchive": "Archive",
	"archive": "Archive",
	"sureToArchive": "Are you sure to archive?",
	"nameAndPhoto": "Name & Photo",
	"totalPrice": "Total Price",
	"added": "Added",
	"nameAscending": "Name Ascending",
	"nameDescending": "Name Descending",
	"selectDraft": "Select Draft",
	"generalInfo": "General Info",
	"selectors": "Selectors",
	"addProductsToAgreement": "Add products to Agreement",
	"addProducts": "Add Products and Categories",
	"addAgreementDetails": "Add Agreement Details",
	"editAgreementDetails": "Edit Agreement Details",
	"uploadDocument": "Upload Document",
	"addAgreement": "Add Agreement",
	"editDocument": "Edit Document",
	"signatory": "Signatory",
	"optional": "(*Optional)",
	"acceptedType": "Accepted Type",
	"documentDetails": "Document Details",
	"agreementDetails": "Agreement Details",
	"downloadDocument": "Download Document",
	"reminderAddedSuccessfully": "Reminder Added Successfully",

	"allProducts": "All Products",
	"addedProducts": "Added Products",

}

export default data;