import { v4 as uuidv4 } from "uuid";

/**
 * Creates a object with a file, a unique id and added preview.
 *
 * @param {object} options - An object containing the file's properties.
 * @param {string} options.id - The unique id of the file. If not provided, a new id will be generated.
 * @param {object} options.file - The file object.
 * @param {boolean} [options.isFromServer = false] - Indicates whether the file is from the server.
 * @return {object} - The file object with the unique id and added preview.
 */
export const createFileObjectWithId = ({ id, file, sortIndex, isFromServer = false }) => ({
  id: id || uuidv4(),
  isFromServer,
  sortIndex,
  file: addPreviewTofileObject(file),
});

export function addPreviewTofileObject(file) {
  return Object.assign(file, {
    preview: URL.createObjectURL(file),
  });
}

export function filterById(items, id) {
  return items.filter(({ id: itemId }) => itemId !== id);
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export async function fetchAndDownloadFile({
  id,
  file: url,
  sortIndex,
  name = "",
}) {
  const response = await fetch(url);
  // console.log(response.headers.get("content-type"));
  const blob = await response.blob();

  return createFileObjectWithId({
    id,
    isFromServer: true,
    sortIndex: sortIndex,
    file: new File([blob], name, {
      type: blob.type,
    }),
  });
}

export function createKeyValueObjectFromArray(inputArray) {
  return inputArray.reduce((acc, cur) => {
    acc[cur] = [];
    return acc;
  }, []);
}

/**
 * Converts a value in megabits to bytes.
 *
 * @param {number} value - The value in megabits.
 * @return {number} - The equivalent value in bytes.
 */
export function convertMgToBytes(value) {
  const bytesIn1MG = 1000000;
  return value * bytesIn1MG;
}

function getRequestedSizeFromString(size) {
  const regex = /^(\d+)(\D+)$/;
  const parts = size.match(regex);

  const numericValue = parts[1];
  const stringValue = parts[2];
}

export function downloadFile(fileUrl, fileName) {
  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function getErrorCode(rejectedFiles) {
  const errorCode = rejectedFiles[0].errors[0].code;
  return errorCode;
}