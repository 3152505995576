//create languages for auth module
let data = {
    "departments": "დეპარტამენტები",

    //Toast Messages
    "departamentSuccessfullyAdded": "დეპარტამენტი წარმატებით დაემატა",
    "departamentSuccessfullyUpdated": "დეპარტამენტი წარმატებით გაახლდა",
    "departamentUnSuccessfullyUpdated": "დეპარტამენტის გაახლება ვერ მოხერხდა",
    "departamentSuccessfullyDeleted": "დეპარტამენტი წარმატებით წაიშალა",
    "usersSuccessfullyDeleted": "მომხმარებლებ იწარმატებით წაიშალა"
}

export default data;