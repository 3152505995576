//real api manages imported here
import { API } from '../api-manager';

//api mangager typed adapter makes visible methods form real api manager
export const ApiManager = {
    getData: async (props, config) => await API.getData(props, config),
    postEmptyData: async (props) => await API.postEmptyData(props),
    postData: async (params, data) => await API.postData(params, data),
    postMultiFormData: async (params, data) => await API.postMultiFormData(params, data),

    // configuration methods
    setAuthorizationToken: (props) => API.setAuthorizationToken(props),
    // setConfig: (config) => setConfig(config),
}
  