import React from 'react';
import { IconMdError } from 'shared-modules/ui-lib-module';

const ErrorPage = () => {
  return (
    <div className="container">
      <div
        style={{ marginTop: '5vh' }}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <IconMdError style={{ color: 'red', fontSize: '48px' }} />
        <h1>Something went wrong...</h1>
        <h3>We apologize for the inconvenience.</h3>
        <br />
        <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.location.replace('/')}>
          Go back to home
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
