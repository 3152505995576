
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking, AppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

// exporting settings
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({ });
//this is configure method you can pass only browserHistory but if you do this you can't track who gets error or other issue
export const AzureApplicationInsightsConfigure = (browserHistory, userId) => {
  
  appInsights.config = {
    //this keys going from your runner app if you haven't this env keys azure will returns 400 in network tab
    instrumentationKey: process.env.REACT_APP_AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    connectionString: process.env.REACT_APP_AZURE_APPLICATION_INSIGHTS,
    //this is browser identity information
    extensions: [reactPlugin],
    extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } }
  };
  
  //init all setting and set all data to appInsights variable;
  appInsights.loadAppInsights();
  //i do this here because user are undefined before load app insights method;
  appInsights.context.user.accountId = userId;
}

// exporting components and hooks for logging this should be inside entry or runner app module
export const LoggingProvider = AppInsightsContext.Provider;
export const AITracking = withAITracking;
export const trackMetric = useTrackMetric;
