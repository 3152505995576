import { UserManagerStore } from 'shared-modules/user-manager-module';
import { useContext } from 'react';
import DrawerStore from '../Stores/DrawerStore';

const getPages = (routes, index = 0, pagesArr = [], currentModuleName = undefined, urls = []) => {
  let el = routes[index];
  if (el?.to && el?.to !== '' && !el?.pageKeyFromServer) urls.push(el?.to);
  if (el?.moduleKeyFromServer !== undefined) {
    currentModuleName = el?.to?.replace('/', '');
  }

  if (el?.children === undefined) {
    if (el) {
      //TODO: es ragaca nitoa
      if (el?.Icon !== '') urls = [];
      if (!urls.includes(`/${currentModuleName.toLowerCase()}`))
        urls = [`/${currentModuleName.toLowerCase()}`, ...urls];
      pagesArr.push({ ...el, to: urls.join('/') + '/' + el.to, isFavorite: true });
    }
  } else getPages(el?.children, 0, pagesArr, currentModuleName, urls);
  if (el !== undefined) return getPages(routes, ++index, pagesArr, currentModuleName, urls);
  else return pagesArr;
};

export const useDrawerRoutes = async (translation, isMenuParams = false) => {
  const userMangerContext = useContext(UserManagerStore); // getting user manager store as context
  const drawerContext = useContext(DrawerStore); // getting drawer store as context
  const allModuleNames = drawerContext.getAllModulesWithoutSendedName('authentication'); // getting all modules without authentication
  //initial user
  userMangerContext.initial();
  //then setting user manager store to drawer user manager instance
  drawerContext.setUserManager(userMangerContext);

  //array for drawer items
  let drawerItemsArr = [
    {
      label: translation['favorites'],
      //don't touch this key
      moduleKeyFromServer: 'D4tvJoI4HEmmoJ2un7LNVg',
      to: '',
      Icon: '',
      children: [],
    },
  ];

  //this loop for modules make some actions and set this actions to drawerItemsArr :) :D
  for (let modulesIndex = 0; modulesIndex < allModuleNames?.length; modulesIndex++) {
    const moduleName = allModuleNames[modulesIndex];
    let currentModuleWithSubModules = await drawerContext.getModuleWithGruppedSubRoutes(
      moduleName,
      translation
    ); // see this method comments inside drawerStore calss
    currentModuleWithSubModules.map((o) => {
      let isCheckedItem = false;
      if (
        userMangerContext.userUiData?.cmsuiDataSortableItems?.find(
          (i) => i?.pageKey === o?.moduleKeyFromServer
        )
      )
        isCheckedItem = true;
      drawerItemsArr.push({ ...o, isChecked: isCheckedItem });
    });
    drawerItemsArr.sort((a, b) => b?.isChecked - a?.isChecked);
  }

  const isDrawerChanged =
    userMangerContext?.userUiData === null ||
    userMangerContext?.userUiData?.cmsMenuFavorites?.length !== 0 ||
    userMangerContext?.userUiData?.cmsuiDataSortableItems?.length !== 0 ||
    userMangerContext?.userUiData?.cmsHiddenMenuItems?.length !== 0;

  drawerItemsArr.forEach((item) => {
    if (item?.children) item.children = item?.children?.filter((i) => i?.children?.length !== 0);
  });
  drawerItemsArr = drawerItemsArr.filter(
    (o) => o.children.length > 0 || o.moduleKeyFromServer === 'D4tvJoI4HEmmoJ2un7LNVg'
  );

  if (isMenuParams || !isDrawerChanged) return drawerItemsArr;
  else {
    var newRoutesArr = drawerItemsArr;

    if (userMangerContext?.userUiData?.cmsHiddenMenuItems?.length > 0) {
      newRoutesArr = drawerItemsArr.filter(
        (o) =>
          !userMangerContext?.userUiData?.cmsHiddenMenuItems?.some(
            (i) => i.pageKey === o?.moduleKeyFromServer
          )
      );
      if (isMenuParams) {
        var hiddenItems = drawerItemsArr.filter((o) =>
          userMangerContext?.userUiData?.cmsHiddenMenuItems?.some((i) => i.pageKey === o?.moduleKeyFromServer)
        );
        newRoutesArr = [...newRoutesArr, ...hiddenItems.map((o) => ({ ...o, isChecked: false }))];
      }
    }

    //adding favorites to favorite drawer item
    if (userMangerContext?.userUiData?.cmsMenuFavorites?.length > 0) {
      newRoutesArr.find((o) => o.moduleKeyFromServer === 'D4tvJoI4HEmmoJ2un7LNVg').children = getPages(
        drawerItemsArr
      ).filter((o) =>
        userMangerContext?.userUiData?.cmsMenuFavorites?.some((i) => i.pageKey === o?.pageKeyFromServer)
      );
    }

    if (userMangerContext?.userUiData?.cmsuiDataSortableItems?.length > 0) {
      var favItem = newRoutesArr.find((o) => o.moduleKeyFromServer === 'D4tvJoI4HEmmoJ2un7LNVg');
      var hiddenItems = [];
      if (isMenuParams) {
        hiddenItems = drawerItemsArr.filter((o) =>
          userMangerContext?.userUiData?.cmsHiddenMenuItems?.some((i) => i.pageKey === o?.moduleKeyFromServer)
        );
      }
      var sortedRoutes = userMangerContext?.userUiData?.cmsuiDataSortableItems.map(
        (sortedItemsFromServer) => {
          var currentItem = newRoutesArr.find((o) => o.moduleKeyFromServer === sortedItemsFromServer.pageKey);
          return currentItem;
        }
      );

      newRoutesArr = [favItem, ...sortedRoutes];

      if (isMenuParams) {
        newRoutesArr = [favItem, ...sortedRoutes, ...hiddenItems.map((o) => ({ ...o, isChecked: false }))];
      }
    }

    return newRoutesArr;
  }
};

export const DrawerStoreInstance = DrawerStore;
export default useDrawerRoutes;
