import React from "react";
import { IconMdSort } from "../../../icons/components";
import { useTranslations } from "shared-modules/translation-module";
import styles from "./styles.module.css";

export const SortButton = (props) => {
  const { t } = useTranslations();

  if (props.label) {
    return (
      <div
        className={
          `
            custom-icon-box f-bold d-flex align-items-center
            ${props?.disabled ? "disabled" : ""}
            ${props?.hide ? "hide" : ""}
          `
        }
        {...props}
      >
        <IconMdSort
          className={
            `
              custom-icon black small 
              ${props?.disabled ? "disabled" : ""}
              ${props?.hide ? "hide" : ""}
            `
          }
        />
        <span className="custom-icon-label">{t[props.label] ?? ""}</span>
      </div>
    );
  }
  return (
    <IconMdSort
      className={
        `
          custom-icon black small 
          ${props?.disabled ? "disabled" : ""}
          ${props?.hide ? "hide" : ""}
        `
      }
      {...props}
    />
  );
};
export default SortButton;
