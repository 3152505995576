import React from "react";
import { Checkbox } from "@material-ui/core";
import { MdCheck, MdRemove } from "react-icons/md";
import "./style.css";
export const MCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      onChange={(e) => {
        [].slice.call(document.getElementsByClassName("MuiFormGroup-root"))?.map(o => {
          let minCheckCount = Number([].slice.call(document.getElementsByClassName("custom-validator"))?.find(i =>i?.contains(o))?.getAttribute("minCheck"));
          if(o?.contains(e.target) && 
             minCheckCount <= o.getElementsByClassName("Mui-checked")?.length+1
            ) {
              if(o?.contains(e.target) && [].slice.call(o?.lastChild?.classList)?.includes("error")) o?.removeChild(o?.lastChild);
              [].slice.call(o?.getElementsByClassName("MuiFormControlLabel-root"))?.map(el => el?.classList?.remove("errorBorder"));
          };
        })
        typeof props.onChange === "function" && props.onChange(e);
      }}
      icon={<div className="checkbox-icon">
         <MdRemove className="checkbox-icon-minus" />
      </div>}
      checkedIcon={
        <div className="checkbox-icon">
          <MdCheck className="checkbox-icon-check" />
        </div>
      }
    />
  );
};
export default MCheckbox;
