import React, { useState } from 'react';
import Chart from '../../Chart';
import PageLoader from '../../PageLoader';
import IconMdKeyboardArrowDown from '../../../../icons/components/MdKeyboardArrowDown';
import { MMenu, MMenuItem } from '../../../../material';
import { useTranslations } from 'shared-modules/translation-module';

import styles from '../styles.module.css';

export const PieChartWidget = ({
  loading,
  data,
  percent,
  additionalPercents,
  labels,
  colors,
  widgetMainTitle,
  widgetMainSubTitle,
  widgetLargeText,
  currencies,
  currentCurrency,
  handleCurrencyChange,
}) => {
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeCurrencyItemState, setActiveCurrencyItemState] = useState(0);

  const sum = () => {
    return data.reduce((prevVal, curVal) => prevVal + curVal, 0);
  };

  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        bottom: 0,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex }) => {
        if (percent) {
          let data = `<div class="apex-chart-tooltip">${labels[seriesIndex]}: ${Math.round(
            (series[seriesIndex] * 100) / sum()
          )}%</div>`;
          return data;
        } else {
          let data = `<div class="apex-chart-tooltip">${labels[seriesIndex]}: ${series[seriesIndex]} ${
            additionalPercents
              ? (currentCurrency?.currencySymbol ? currentCurrency?.currencySymbol : '') +
                ` - (${additionalPercents[seriesIndex]}%)`
              : ''
          }</div>`;
          return data;
        }
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '70%',
        },
      },
    },
    stroke: { curve: 'smooth', width: 0 },
    labels: labels ?? [],
    colors: colors ?? [],
  };

  return (
    <div className={`${styles.widgetBox} ${styles.pieChartWidget}`}>
      <PageLoader isLoading={loading}>
        <div className={styles.top}>
          {widgetMainTitle && <div className={`${styles.widgetMainTitle}`}>{widgetMainTitle}</div>}
          {currencies && currencies.length > 1 && (
            <React.Fragment>
              <div
                className={styles.currency}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <div className={styles.currencyVal}>{currentCurrency?.currencySymbol}</div>
                <IconMdKeyboardArrowDown
                  className={`${styles.currencyArrow} ${Boolean(anchorEl) ? styles.visible : ''}`}
                />
              </div>
              <MMenu
                className="widget-currency-dropdown"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
              >
                {currencies?.map((o, index) => {
                  return (
                    <MMenuItem
                      className={`widget-currency-dropdown-item ${
                        activeCurrencyItemState == index ? 'active' : ''
                      }`}
                      key={`${o}-${index}`}
                      value={o.id}
                      onClick={() => {
                        setActiveCurrencyItemState(index);
                        handleCurrencyChange(o.id);
                        setAnchorEl(null);
                      }}
                    >
                      {o.currencySymbol}
                    </MMenuItem>
                  );
                })}
              </MMenu>
            </React.Fragment>
          )}
        </div>
        {data.length > 0 ? (
          <div className={styles.widgetContent}>
            <div className={styles.left}>
              {widgetLargeText && <div className={`${styles.widgetLargeText} f-bold`}>{widgetLargeText}</div>}
              {widgetMainSubTitle && (
                <div className={`${styles.widgetMainSubTitle}`}>{widgetMainSubTitle}</div>
              )}
              <div className={styles.labelList}>
                {labels?.map((o, index) => {
                  return (
                    <div className={styles.labelItem} key={index}>
                      <div
                        className={styles.labelItemCircle}
                        style={{ backgroundColor: colors[index] ?? '' }}
                      ></div>
                      <div className={styles.labelItemText}>{o}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.chartBox}>
                <Chart options={options} series={data} type="donut" height="100%" width="100%" />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noData}>{t['widget-no-data']}</div>
        )}
      </PageLoader>
    </div>
  );
};
export default PieChartWidget;
