import { lazy } from "react";
import { IconMdManageAccounts } from "shared-modules/ui-lib-module/icons/components/MdManageAccounts";

const Public_Key = "Public_Route";
const mainPageKey = "ProjectPolicys_CoreModule_IdentityPage";
export class UsersModule {
  static drawer = false;
  static ModuleName = "";
  static ModuleKey = Public_Key;

  static Profile = class {
    static PageKey = Public_Key;

    static Accesses = { };

    static Route = [
      {
        name: "",
        url: `profile`,
        drawer: false,
        icon: ""
      }
    ];
    static Component = lazy(() => import("../pages/Profile"));
  };
}


export const UsersAccess = class {
  static PageKey = mainPageKey;

  static Accesses = {
    CanGetUsers: {
      Key: "ProjectPolicys_CoreModule_IdentityPage_GetAllUsers",
      Action: false
    },
    CanAdd: {
      Key: "ProjectPolicys_CoreModule_IdentityPage_AddSystemUser",
      Action: false
    },
    CanUpdate: {
      Key: "ProjectPolicys_CoreModule_IdentityPage_UpdateUserById",
      Action: false
    },
  };

  static Route = [
    {
      name: "systemUsersModule",
      url: `users`,
      drawer: true,
      icon: IconMdManageAccounts
    }
  ];
  static Component = lazy(() => import("../pages/Users"));
};


export const AddOrUpdateUsersAccess = class {
  static PageKey = mainPageKey;

  static Accesses = {
    CanGetUsers: {
      Key: "ProjectPolicys_CoreModule_IdentityPage_GetAllUsers",
      Action: false
    },
    CanAdd: {
      Key: "ProjectPolicys_CoreModule_IdentityPage_AddSystemUser",
      Action: false
    },
    CanUpdate: {
      Key: "ProjectPolicys_CoreModule_IdentityPage_UpdateUserById",
      Action: false
    },
  };

  static Route = [
    {
      name: "",
      url: `users/add-or-update`,
      drawer: false,
      icon: ""
    },
    {
      name: "",
      url: `users/add-or-update/:id`,
      drawer: false,
      icon: ""
    },
    {
      name: "",
      url: `users/detail/:id`,
      drawer: false,
      icon: ""
    },
  ];
  static Component = lazy(() => import("../pages/AddOrUpdate"));
};


export default UsersModule;
