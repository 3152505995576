// b2b drafts translations
let data = {
	"draftAgreements": "Draft Agreements",
	"uploadDate": "Upload Date",
	"addNewSelector": "Add New Selector",
	"date": "Date",
	"chooseType": "Choose Type",
	"fileUpload": "File Upload",
	"selectors": "Selectors",
	"selectorName": "Selector Name",
	"editDraft": "Edit Draft",
	"text": "Text",
};

export default data;