import React, { useEffect } from 'react';
import { EntryComponent } from 'shared-modules/entryModule';
import { ErrorBoundary } from 'components/ErrorTracker/exHandler';

import './assets/css/fonts.css';
import './assets/css/normalize.css';
import './assets/css/variables.css';
import './assets/css/grids.css';
import './assets/css/style.css';

// import * as Sentry from '@sentry/react';
// const isProduction = process.env.NODE_ENV === 'production';
// if (isProduction) {}

// Sentry.init({
//   dsn: 'https://9a1a0a3d4d8c056a573d8cce14c31e0c@o4505951161221120.ingest.sentry.io/4505951166005248',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/test\.meamacore\.com\//],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function App() {
  useEffect(() => {
    const setWindowHeight = () =>
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01 - 0.01}px`);
    setWindowHeight();
    window.addEventListener('resize', setWindowHeight);
    return () => window.removeEventListener('resize', setWindowHeight);
  }, []);

  return (
    <div className="App">
      <ErrorBoundary>
        <EntryComponent />
      </ErrorBoundary>
    </div>
  );
}

export default App;
