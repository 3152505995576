import React, { useState } from 'react';
import Chart from '../../Chart';
import PageLoader from '../../PageLoader';
import IconMdKeyboardArrowDown from '../../../../icons/components/MdKeyboardArrowDown';
import { MMenu, MMenuItem } from '../../../../material';
import { useTranslations } from 'shared-modules/translation-module';

import styles from '../styles.module.css';

export const BarChartWidget = ({
  loading,
  data,
  colors,
  categories,
  widgetMainTitle,
  widgetMainSubTitle,
  widgetLargeText,
  currencies,
  currentCurrency,
  handleCurrencyChange,
  horizontal
}) => {
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeCurrencyItemState, setActiveCurrencyItemState] = useState(0);

  var options = {
    chart: {
      parentHeightOffset: 0,
      animations: {
        enabled: false,
      },
      sparkline: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '34px',
        borderRadius: 4,
        horizontal: horizontal ?? false
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    colors: colors ?? [],
    yaxis: {
      show: horizontal ?? false,
    },
    xaxis: {
      categories: categories ?? [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        let data = `<div class="apex-chart-tooltip">${series[seriesIndex][dataPointIndex]}</div>`;
        return data;
      },
    },
  };

  return (
    <div className={`${styles.widgetBox} ${styles.barChartWidget}`} style={horizontal ? { height: "100%" } : null}>
      <PageLoader isLoading={loading}>
        <div className={styles.top}>
          {widgetMainTitle && <div className={`${styles.widgetMainTitle}`}>{widgetMainTitle}</div>}
          {currencies && currencies.length > 1 && (
            <React.Fragment>
              <div
                className={styles.currency}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <div className={styles.currencyVal}>{currentCurrency}</div>
                <IconMdKeyboardArrowDown
                  className={`${styles.currencyArrow} ${Boolean(anchorEl) ? styles.visible : ''}`}
                />
              </div>
              <MMenu
                className="widget-currency-dropdown"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
              >
                {currencies?.map((o, index) => {
                  return (
                    <MMenuItem
                      className={`widget-currency-dropdown-item ${
                        activeCurrencyItemState == index ? 'active' : ''
                      }`}
                      key={`${o}-${index}`}
                      value={o}
                      onClick={() => {
                        setActiveCurrencyItemState(index);
                        handleCurrencyChange(o);
                        setAnchorEl(null);
                      }}
                    >
                      {o}
                    </MMenuItem>
                  );
                })}
              </MMenu>
            </React.Fragment>
          )}
        </div>
        {data.length > 0 ? (
          <div className={styles.widgetContent}>
            <div className={styles.left}>
              {widgetLargeText && <div className={`${styles.widgetLargeText} f-bold`}>{widgetLargeText}</div>}
              {widgetMainSubTitle && (
                <div className={`${styles.widgetMainSubTitle}`}>{widgetMainSubTitle}</div>
              )}
            </div>
            <div className={styles.right}>
              <div className={styles.chartBox}>
                <Chart
                  options={options}
                  series={[{ data: data ?? [] }]}
                  type="bar"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noData}>{t['widget-no-data']}</div>
        )}
      </PageLoader>
    </div>
  );
};
export default BarChartWidget;
