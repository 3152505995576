//create languages for auth module
let data = {
    "departments": "Departments",

    //Toast Messages
    "departamentSuccessfullyAdded": "Departament successfully added",
    "departamentSuccessfullyUpdated": "Departament successfully updated",
    "departamentUnSuccessfullyUpdated": "Departament can't updated",
    "departamentSuccessfullyDeleted": "Departament successfully deleted",
    "usersSuccessfullyDeleted": "users successfully deleted",
}

export default data;