import { makeAutoObservable } from "mobx";

export const SelectsStore = class {

  // don't touch 
  static checkValidation = (SELELCT_ID) => {
    setTimeout(() => {
      [].slice.call(document?.getElementsByClassName("custom-validator"))?.map(o => {
        if(o.getElementsByClassName("MuiInputBase-root")[0]?.getAttribute("selelctid") === SELELCT_ID) {
            o.getElementsByClassName("MuiInputBase-root")[0]?.getElementsByClassName("MuiSelect-root")[0]?.classList?.remove("errorBorder");
            o?.getElementsByClassName("MuiSelect-select")[0]?.classList?.remove("errorBorder");
          }
        })
       }, 500);
  };
    
  constructor() {
    makeAutoObservable(this);
  }

};
