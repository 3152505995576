import { createContext } from "react";
import * as mobx from "mobx";
import { AuthLocalStorageStore } from "shared-modules/local-storage-module";
import { LocalStorageEvents, ThemesLocalStorageStore } from "shared-modules/local-storage-module";
import userActivityTracker from '../UserSocketInstance';

export const UserManagerStore = createContext(new class {
    MainRoleName = "SuperAdmin";
    MainRoleProjectPolicys = "ProjectPolicys_SuperAdmin";
    PublicPageKey = "Public_Route";

    UserEnum = Object.freeze({
        superAdmin: 'superAdmin',
    })

    token = '';
    refreshToken = "";
    user = null;
    userUiData = null;
    isAuthorized = false;
    allCalms = [];

    constructor() {
        mobx.makeAutoObservable(this);
        mobx.configure({ enforceActions: "never" });
        this.authStore = new AuthLocalStorageStore();
        this.localStorageEvents = new LocalStorageEvents();
        this.themesLocalStorageStore  = new ThemesLocalStorageStore();
    }

    hasAccessOnAction = (claim) => {
        if(claim === this.PublicPageKey || this.allCalms.find(o => o === this.MainRoleProjectPolicys)) {
            return true;
        }
        return this.allCalms.find(o => o === claim) !== undefined;
    }

    keepMeSignedIn = (keep) => {
        this.authStore.setIsKeepMe(keep.toString());
    }

    keepMeSignedInValue = () => {
        return this.authStore.getIsKeepMe() === "true";
    }

    removeKeepMe = () => {
        this.authStore.removeIsKeepMe();
    }

    setUser = (user, userUiData) => {
        this.user = user;
        this.userUiData = userUiData;
        this.setCalms();
    }

    setCalms = () => {
        const calms = this.user?.roles?.map(o => o?.roleClaims) ?? [];
        var calmsArr = [];
        calms.forEach(o => {
            o.forEach(i => {
                calmsArr.push(i)
            });
        });
        this.allCalms = calmsArr;
    }

    initial = () => {
        var token = this.authStore.getUserToken();
        this.token = token ? token : "";
        this.isAuthorized = token !== null && token !== "null" && token !== "undefined";   
        
        var refreshToken = this.authStore.getRefreshToken();
        if(refreshToken) {
            this.refreshToken = refreshToken;
        }

        this.localStorageEvents.initLocalStorageEvents();
    }

    initialEmpty = () => {
        this.token = "";
        this.refreshToken = "";
        this.user = null;
        this.userUiData = null;
        this.isAuthorized = false;
        this.allCalms = [];
    }

    Authorize = (token, user, userUiData, refreshToken) => {
        this.user = user;
        this.userUiData = userUiData;

        if(refreshToken)
        {
            this.refreshToken = refreshToken;
            this.authStore.setRefreshToken(this.refreshToken);
        }
        else {
            this.authStore.removeRefreshToken();
        }
        
        if(token) {
            this.token = token;
            this.authStore.setUserToken(this.token);
        }
        // else {
        //     this.logOut()
        // }

        if(this.user !== null) {
            this.setCalms();
        }

        this.initial();
    }

    logOut = () => {
        this.authStore.removeUserToken();
        this.authStore.removeHQToken();
        this.authStore.removeRefreshToken();
        location.replace(window.location.pathname + window.location.search);
        this.removeKeepMe();
        this.initialEmpty();
        userActivityTracker.invoke("Close");
    };

    initTheme = () => {
        var theme = this.themesLocalStorageStore.getTheme();
        var HTMLTag = document.getElementsByTagName("html")[0].classList;

        theme == "dark" ? HTMLTag.add("dark") : HTMLTag.remove("dark");
    }
}());
