import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const LocalStorageEvents = class {
    constructor() {
        makeAutoObservable(this);
    }

    initLocalStorageEvents() {
        var originalSetItem = localStorage.setItem; 
        localStorage.setItem = function (propName, value) {
            if(localStorage.getItem(propName) !== value) {
                originalSetItem.apply(this, arguments);
                //handle language change from localStorage
                if(propName === "i18nextLng") document.dispatchEvent(new Event("changeLanguageEvent"));
                //handle theme change from localStorage
                if(propName === "theme") document.dispatchEvent(new Event('onThemeChangeEvent'));
            }
        }

        var originalRemoveItem = localStorage.removeItem; 
        localStorage.removeItem = function (propName) {
            if(localStorage.getItem(propName)) {
                originalRemoveItem.apply(this, arguments);
                //log out event for other tabs
                if(propName === "userToken") document.dispatchEvent(new Event('logoutChangeEvent'));
            }
        }
    }
};

export default createContext(new LocalStorageEvents());
