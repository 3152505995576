//create languages for auth module
let data = {
    "commentaries": "კომენტარები",
    "tags": "თეგები",
    "comment": "კომენტარი",
    "title" : "სათაური",
    "dateTime": "თარიღო - დრო",
    "assigned": "მინიჭებულია",
    "customer": "მომხმარებელი",
    "author": "ავტორი",
    "customerFrom": "მომხმარებლის წყარო",
    "remaindDate": "შეხსენების თარიღი",
    "commentAddedSuccesfully": "კომენტარი წარმატებით დაემატა",
    "commentUpdatedSuccesfully": "კომენტარი წარმატებით განახლდა",
    "commentRemovedSuccessfully": "კომენტარი წარმატებით წაიშალა",
    "replyAddedSuccesfully": "პასუხი წარმატებით დაემატა",
    "replyUpdatedSuccesfully": "პასუხი წარმატებით განახლდა",
    "replyRemovedSuccessfully": "პასუხი წარმატებით წაიშალა",
    "commentMustNotBeEmpty": "კომენტარი არ უნდა იყოს ცარიელი",
}

export default data;