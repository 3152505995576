import React from 'react';
import styles from './styles.module.css';

export const DetailsItem = ({ label, value, onClick, tonal }) => {
  return (
    <div className="col-6 col-sm-12">
      <div className={styles.detailsItem}>
        <div className="row d-flex align-items-center">
          <div className="col-6">
            <div className={styles.property}> {label} </div>
          </div>
          <div className="col-6">
            {typeof value === 'function' ? (
              value()
            ) : (
              <div
                className={`${styles.value} ${onClick ? styles.pointer : ''} ${
                  tonal ? `${styles.status} ${tonal?.toLowerCase()}` : ''
                } f-bold`}
                onClick={onClick}
              >
                {value ? value : '-'}{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DetailsList = ({ data = [] }) => {
  return (
    <div className={styles.detailsBox}>
      <div className="row no-gutters">
        {data.map((item) => (
          <DetailsItem onClick={item.onClick} label={item.label} value={item.value} tonal={item.tonal} />
        ))}
      </div>
    </div>
  );
};

export default DetailsList;
