//create languages for specifications module
let data = {
    "specifications": "Specifications",
    "specificationName": "Specification Name",
    "specificationTemplateName": "Specification Template Name",
    "chooseType": "Choose Type",
    "chooseColor": "Choose Color",
    "uploadPhoto": "Upload Photo",
}

export default data;