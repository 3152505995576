import React, { useState, useContext, useEffect } from "react";

import { UserManagerStore } from "shared-modules/user-manager-module";
import { useTranslations } from "shared-modules/translation-module";

import {
  IconMdPerson,
  IconMdKeyboardArrowDown,
  IconMdPassword,
  IconMdNotificationsNone,
  IconMdManageAccounts,
  IconMdLogout,
  IconMdKeyboardArrowLeft,
  IconMdNotifications,
} from "../../../icons/components";

import { MMenu, MMenuItem} from "../../../material/components";
import { Observer } from 'mobx-react';
import styles from "./styles.module.css";
import NotificationsStore from "shared-modules/notification-logic-module/store/NotificationStore";

export const UserButton = (props) => {
  const notificationsContext = useContext(NotificationsStore);

  useEffect(() => {
    if(!UserButton.isMounted) {
      notificationsContext.getUnReadedNotificationsCount().catch();
      UserButton.isMounted = true;
    }
  }, [notificationsContext])

  const { history } = props;
  const userManagerContext = useContext(UserManagerStore);
  const { t } = useTranslations();

  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});

  const logutHandler = () => {
    userManagerContext.logOut();
  };

  useEffect(
    () => setUser({ ...userManagerContext.user }),
    [userManagerContext.user]
  );

  return (
    <Observer render={() => { 
      return (
        <div className={`${styles.userBtnBox} f-bold`}>
              <div
                aria-controls="user-btn"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className={`${styles.userBtn} ${
                  Boolean(anchorEl) ? styles.visible : ""
                }`}
              >
                <div className={styles.userInfoBox}>
                  {/* <div className={styles.userImgBox}>
                    {
                      //TODO: core user hasnot user image
                      user.image ? (
                        <img className={styles.userImg} src={user.image} alt="" />
                      ) : null
                    }
                  </div> */}
                  
                  {
                    notificationsContext.unReadedNotificationsCount === 0 ? null :
                    <div className={`${styles.hasNotificationCircle} d-flex align-items-center justify-content-center`}><IconMdNotifications/></div>
                  }

                  {/* <span className={`${styles.count} f-bold`}> {choosedCount} </span> : null} */}
                  <div className={styles.userIconBox}>
                    <IconMdPerson className={styles.userIcon} />
                  </div>
                  <div className={styles.userNameSubTitleBox}>
                    <div className={`${styles.userName} f-bold`}>
                      {user?.roles?.map((el) => el.roleClaims.some((e) => e.toLowerCase().includes(userManagerContext.UserEnum.superAdmin.toLowerCase())))
                        .includes(true) ? (
                        t[userManagerContext.UserEnum.superAdmin]
                      ) : user?.firstname || user?.lastName ? (
                        `${user.firstname} ${user.lastName}`
                      ) : null}
                    </div>
                    <div className={`${styles.userSubTitle} f-bold`}>{user.email}</div>
                  </div>
                  <div className={styles.arrowDown}>
                    <IconMdKeyboardArrowDown />
                  </div>
                </div>
              </div>
              <MMenu
                id="user-btn"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                className={styles.userInfoDropdown}
              >
                <MMenuItem>
                  <div className={styles.userInfoBox}>
                    <div className={styles.userIconBox}>
                      <IconMdPerson className={styles.userIcon} />
                    </div>
                    <div className={styles.userNameSubTitleBox}>
                      <div className={`${styles.userName} f-bold`}>
                        {user?.roles
                          ?.map((el) => el.roleClaims.some((e) => e.toLowerCase().includes(userManagerContext.UserEnum.superAdmin.toLowerCase())))
                          .includes(true) ? (
                          t[userManagerContext.UserEnum.superAdmin]
                        ) : user.firstname || user.lastName ? (
                          `${user.firstname} ${user.lastName}`
                        ) : null}
                      </div>
                      <div className={`${styles.userSubTitle} f-bold`}>
                        {user.email}
                      </div>
                    </div>
                  </div>
                </MMenuItem>
                <MMenuItem
                  onClick={() => {
                    history.push("/core/notifications-list");
                    setAnchorEl(null);
                  }}
                >
                  <div className={`${styles.dropdownItem}`}>
                    <IconMdNotificationsNone />
                    <span className="f-bold">{t["notifications"]}</span>
                    {
                      notificationsContext.unReadedNotificationsCount === 0 ? null :
                      <div className={styles.notificationCircle}>{notificationsContext.unReadedNotificationsCount}</div>
                    }
                  </div>
                </MMenuItem>
                  {localStorage.getItem('HQToken') ? (
                  <MMenuItem onClick={props.backToHQ}>
                      <div className={`${styles.dropdownItem}`}>
                        <IconMdKeyboardArrowLeft />
                        <span className="f-bold">Back To HQ</span>
                      </div>
                  </MMenuItem>
                ) : null}
                 <MMenuItem
                  onClick={() => {
                    history.push("/profile?edit=change-password");
                    setAnchorEl(null);
                  }}
                >
                  <div className={`${styles.dropdownItem}`}>
                    <IconMdPassword />
                    <span className="f-bold">{t["changePassword"]}</span>
                  </div>
                </MMenuItem>
                <MMenuItem
                  onClick={() => {
                    history.push("/profile?edit=information");
                    setAnchorEl(null);
                  }}
                >
                  <div className={`${styles.dropdownItem}`}>
                    <IconMdManageAccounts />
                    <span className="f-bold">{t["profile"]}</span>
                  </div>
                </MMenuItem>
                <MMenuItem>
                  <div className={`${styles.dropdownItem}`} onClick={logutHandler}>
                    <IconMdLogout />
                    <span className="f-bold">{t["logout"]}</span>
                  </div>
                </MMenuItem>
              </MMenu>
            </div>
      )
    }}/>
  );
};
export default UserButton;
