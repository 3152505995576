import { I18NConfigStore } from "shared-modules/translation-module/classes";
import { toast } from "react-toastify";
import { IconMdCheckCircle } from "../../../../icons";

const successToast = (message, options) => {
    const i18NConfigStore = new I18NConfigStore();
    const t = i18NConfigStore.GetLanguageResource({ lng: localStorage.i18nextLng, ns: location.pathname.split('/').length > 2 ? location.pathname.split('/')[1] : 'default' });

    toast.success(t ? t[message] ?? message : "Message", {
        icon: IconMdCheckCircle,
        toastId: message,
        position: toast.POSITION.BOTTOM_CENTER,
        ...options
    });
}

// export default  
export default successToast
