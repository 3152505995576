import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { mobxRouting } from "shared-modules/url-helper-module";
// import { I18NConfigStore } from "@meama/translation-module/src/classes";

// const i18NConfigStore = new I18NConfigStore();
// const t = i18NConfigStore.GetLanguageResource({ lng: localStorage.i18nextLng, ns: location.pathname.split('/').length > 2 ? location.pathname.split('/')[1] : 'default' });
export const FilesGroupFilterStore = class {

  // notificationStatuses = [{ value: "false", name: t['read'] }, { value: "true", name: t["unread"] }]
  notificationStatuses = [{ value: "false", name: 'read' }, { value: "true", name: "unread"}]

  originalFilter = {
    name: "",
    title: "",
    isUnReaded: [],
    dateFrom: null,
    dateTo: null
  };

  filter = this.originalFilter;

  onChange = (e) => this.filter[e.target.name] = e.target.value;

  generateFilterUrlForServer = () => {
    if(!this.filter) return null;
    const keys = Object.keys(this.filter);
    let searchParams = '';

    keys.map(key => {
      if(this.filter[key] !== null && this.filter[key] !== "" && this.filter[key] !== " ") {
        searchParams += `&${key}=${this.filter[key]}`
      } 
    });
    return searchParams
  };

  params = (data) => {
    let search = new URLSearchParams(mobxRouting.location.search);
    const keys = Object.keys(data);
 
    keys.forEach(key => {
      if(!data[key]) search.delete(key);
      if(data[key]?.length === 0) search.delete(key);
      
      if(data[key] && data[key]?.length !== 0) {
        search.set(key, encodeURIComponent(data[key]));
      }
    });
    return search.toString();
  };

  initFromUrl = () => Object.getOwnPropertyNames(this.filter).forEach(propName =>  {
  let query = new URLSearchParams(mobxRouting.history.location.search);
  query.get(propName) ? this.filter[propName] = decodeURIComponent(query.get(propName)).split(',') : null;
  });

  setUrl = () => mobxRouting.replace({ search: this.params(this.filter) });
  
  clearFilter = () => {
    const keys = Object.keys(this.originalFilter);
    let search = new URLSearchParams(mobxRouting.location.search);
    keys.forEach(key => search.delete(key));
    this.filter = {};
    mobxRouting.replace({search: search.toString()});
  };

  removeFilterByKey = (key) => {
    let search = new URLSearchParams(mobxRouting.location.search);
    search.delete(key);
    mobxRouting.replace({ search: search.toString() });
  };

  getChoosedFilter = () => {
    let params = new URLSearchParams(window.location.search);
    return Object.keys(this.filter).map(o => {
      if(params.get(o) && 
        params.get(o) !== 'false' && 
        o?.toLowerCase() !== 'sortByDateDesc'.toLowerCase() && 
        o?.toLowerCase() !== "descending" && 
        o?.toLowerCase() !== "orderby"
      ) return true;
      return false;
    }).filter(o => o)
  };
  
  constructor() {
    makeAutoObservable(this);
  }

};

export default createContext(new FilesGroupFilterStore());
