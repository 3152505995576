//create languages for specifications module
let data = {
    "products": "პროდუქტები",
    "product": "პროდუქტი",
    "productName": "პროდუქტის სახელი",
    "coverPhoto": "ქავერის ფოტო",
    "hoverPhoto": "ჰოვერის ფოტო",
    "addIntegrationInput": "ინტეგრაციის დამატება"
}

export default data;