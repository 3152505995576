import React from "react";

import { MFormControlLabel, MCheckbox } from "../../../material/components";

import styles from "./styles.module.css";

export const CheckboxButton = ({ isChecked, onClick, id, label = "Add" }) => {
  return (
    <div className={styles.checkboxButton}>
      <MFormControlLabel
        className="f-bold"
        control={<MCheckbox onClick={() => onClick(id)} className="tonal" name="test" checked={isChecked} />}
        label={label}
      />
    </div>
  );
};
export default CheckboxButton;
