import React from "react";

export const TableInitialsComponent = ({item}) => {
  return (
    item.initials ? (
      <div style={item.color ? { backgroundColor: item.color } : {}} className="table-cell-with-initials f-bold f-upper">{item.initials}</div>
    ) : <></>
  );
};

export default TableInitialsComponent;
