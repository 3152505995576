import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { ApiManager } from 'shared-modules/api-manager-module';
import { MToast } from 'shared-modules/ui-lib-module';
import { filesGroupFilterContext } from 'modules/notification-module/components/Tabs/Inbox/Filter';
import { TypeExtentionStore } from 'shared-modules/custum-extension-methods';

const typeExtentionStore = new TypeExtentionStore();

export const NotificationStore = class {
  showLoading = false;
  listLoading = false;
  notifications = { notifications: [], count: 0 };
  unReadedNotificationsCount = 0;
  allUsersInfo = [];
  filteredUsersInfo = [];
  fromIndex = 0;
  count = 10;

  getAllNotifications = async (isMySended = false) => {
    try {
      if (
        this.notifications.notifications.length !== 0 &&
        this.notifications.notifications.length === this.notifications.count
      )
        return;

      this.listLoading = true;
      const search = filesGroupFilterContext.generateFilterUrlForServer();

      let res = await ApiManager.getData(
        `api/Notifications/GetAllNotificationByUser?FromIndex=${this.fromIndex}&Count=${this.count}${search}&shouldReturnSentMessages=${isMySended}`
      );
      if (this.fromIndex > 0) {
        this.notifications.notifications = [...this.notifications.notifications, ...res?.data.notifications];
        return;
      }
      this.notifications = res?.data;
    } catch (ex) {
      MToast.errorToast(ex.message);
      return [];
    } finally {
      this.listLoading = false;
    }
  };

  markNotificationAsRead = async (id) => {
    try {
      await ApiManager.getData(`api/Notifications/MakeNotificationAsRead/${id}`);
    } catch (ex) {
      MToast.errorToast(ex.message);
    }
  };

  getAllUserInfo = async () => {
    try {
      this.showLoading = true;
      let res = await ApiManager.getData(`api/UserInfo/GetAllUserInfo`);
      this.allUsersInfo = res?.data?.map((o) => {
        return { userId: o.userId, text: `${o?.name} ${o?.lastName} (${o.email})` };
      });
    } catch (ex) {
      MToast.errorToast(ex.message);
      return [];
    } finally {
      this.showLoading = false;
    }
  };

  getUnReadedNotificationsCount = async () => {
    try {
      var res = await ApiManager.getData(`api/Notifications/GetAllUnReadedNotificationCount`);
      this.unReadedNotificationsCount = res?.data ?? 0;
      return true;
    } catch (ex) {
      MToast.errorToast(ex.message);
    }
  };

  addMessageToSend = async (title, subj, content, files, userIds) => {
    try {
      this.showLoading = true;
      var formData = new FormData();

      formData.append('title', title);
      formData.append('shortDescription', subj);
      formData.append('content', content);
      if (userIds) {
        userIds.map((o) => {
          formData.append('userIds', o);
        });
      }
      if (files) {
        files?.map((file) => {
          formData.append('messageFiles', file?.file);
        });
      }

      if (typeExtentionStore.isEpmtyQuillEditor(content, 'messageMustNotBeEmpty')) {
        return false;
      }

      let res = await ApiManager.postMultiFormData(`api/MessageTemplates/AddMessageForUserToUser`, formData);
      return res?.data;
    } catch (ex) {
      MToast.errorToast(ex.message);
      return [];
    } finally {
      this.showLoading = false;
    }
  };

  sendNotificationToUser = async (userIds, title, subj, content, files) => {
    try {
      this.showLoading = true;
      const response = await this.addMessageToSend(title, subj, content, files, userIds);
      return response;
    } catch (ex) {
      MToast.errorToast(ex.message);
      return [];
    } finally {
      this.showLoading = false;
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
};

export default createContext(new NotificationStore());
