import { lazy } from "react";

const Public_Key = "Public_Route";
export class MenuOptionsModule {
  static drawer = false;
  static ModuleName = "temp module name";
  static ModuleKey = Public_Key;

  static MenuSettings = class {
    static PageKey = Public_Key;

    static Accesses = { };

    static Route = [
      {
        name: "",
        url: `menu-settings`,
        drawer: true,
        icon: ""
      }
    ];
    static Component = lazy(() => import("../components/MenuOptions"));
  };
}

export default MenuOptionsModule;
