export function wrapToMeamaResponse(axiosResponse) {
  if (axiosResponse?.data?.isError) throw new Error(axiosResponse?.data?.infoMessage);
  else
    return {
      data: axiosResponse?.data?.data === undefined ? axiosResponse?.data : axiosResponse?.data?.data,
      message: axiosResponse?.data?.infoMessage ?? axiosResponse?.data?.InfoMessage,
      isError: axiosResponse?.data?.isError,
    };
}

export default wrapToMeamaResponse;
