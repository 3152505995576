import React from 'react';
import { MTableRow, MTableCell, MCollapse } from '../../../../material/components';
import TableCellComponent from './TableCellComponent';
import TableButtonsComponent from './TableButtonsComponent';
import MTransitionGroup from '../../../../transitions/components/TransitionGroup';
import { Draggable } from 'react-beautiful-dnd';

const rowStyle = (isDragging, draggableStyle, isDraggable) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: isDraggable ? 'none' : '',
  // change background colour if dragging
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const TableRowComponent = ({
  isDragOccurring,
  isDraggable,
  rows,
  buttonsLength,
  detailsButton,
  editButton,
  deleteButton,
  retryButton,
  downloadButton,
  archiveButton,
  previewButton,
  sendButton,
  analyticButton,
  analyticHandler,
  sortButton,
  refundButton,
  powerOffButton,
  cencelButton,
  cencelHandler,
  detailsHandler,
  sendHandler,
  editHandler,
  deleteHandler,
  retryHandler,
  downloadHandler,
  archiveHandler,
  previewHandler,
  refundHandler,
  sortHandler,
  powerOffHandler,
}) => {
  return rows?.map((rowItem, index) => (
    <React.Fragment key={index}>
      {rowItem?.groupedData ? (
        <React.Fragment>
          <MTableRow key={index} className={rowItem?.collapsed ? 'collapsed' : ''}>
            <React.Fragment>
              <TableCellComponent rowItem={rowItem} />
              <TableButtonsComponent
                index={index}
                rowItem={rowItem}
                buttonsLength={buttonsLength}
                detailsButton={detailsButton}
                editButton={editButton}
                deleteButton={deleteButton}
                retryButton={retryButton}
                archiveButton={archiveButton}
                previewButton={previewButton}
                downloadButton={downloadButton}
                refundButton={refundButton}
                sortButton={sortButton}
                analyticButton={analyticButton}
                powerOffButton={powerOffButton}
                cencelButton={cencelButton}
                cencelHandler={cencelHandler}
                sendButton={sendButton}
                analyticHandler={analyticHandler}
                detailsHandler={detailsHandler}
                editHandler={editHandler}
                sendHandler={sendHandler}
                deleteHandler={deleteHandler}
                retryHandler={retryHandler}
                downloadHandler={downloadHandler}
                archiveHandler={archiveHandler}
                previewHandler={previewHandler}
                refundHandler={refundHandler}
                sortHandler={sortHandler}
                powerOffHandler={powerOffHandler}
              />
            </React.Fragment>
          </MTableRow>
          <MTableRow>
            <MTableCell
              colspan={rowItem.col.length}
              className={`${rowItem?.collapsed ? 'collapsed' : ''} collapse-table-cell`}
            >
              <MTransitionGroup>
                {rowItem?.collapsed && <MCollapse timeout={400}>{rowItem?.groupedData}</MCollapse>}
              </MTransitionGroup>
            </MTableCell>
          </MTableRow>
        </React.Fragment>
      ) : (
        <Draggable
          isDragDisabled={!isDraggable}
          key={rowItem?.id}
          draggableId={rowItem?.id?.toString()}
          index={index}
        >
          {(draggableProvided, draggableSnapshot) => (
            <MTableRow
              innerRef={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              style={rowStyle(
                draggableSnapshot.isDragging,
                draggableProvided.draggableProps.style,
                isDraggable
              )}
            >
              <TableCellComponent
                isDraggable={isDraggable}
                isDragOccurring={isDragOccurring}
                isDragging={draggableSnapshot.isDragging}
                dragHandleProps={draggableProvided.dragHandleProps}
                rowItem={rowItem}
              />
              <TableButtonsComponent
                index={index}
                rowItem={rowItem}
                buttonsLength={buttonsLength}
                detailsButton={detailsButton}
                editButton={editButton}
                deleteButton={deleteButton}
                retryButton={retryButton}
                archiveButton={archiveButton}
                previewButton={previewButton}
                sortButton={sortButton}
                downloadButton={downloadButton}
                refundButton={refundButton}
                sendButton={sendButton}
                analyticButton={analyticButton}
                powerOffButton={powerOffButton}
                cencelButton={cencelButton}
                cencelHandler={cencelHandler}
                analyticHandler={analyticHandler}
                detailsHandler={detailsHandler}
                editHandler={editHandler}
                sendHandler={sendHandler}
                deleteHandler={deleteHandler}
                retryHandler={retryHandler}
                downloadHandler={downloadHandler}
                archiveHandler={archiveHandler}
                previewHandler={previewHandler}
                refundHandler={refundHandler}
                sortHandler={sortHandler}
                powerOffHandler={powerOffHandler}
              />
            </MTableRow>
          )}
        </Draggable>
      )}
    </React.Fragment>
  ));
};

export default TableRowComponent;
