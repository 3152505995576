import { createContext } from "react";
import { makeAutoObservable, observable } from "mobx";

export const DrawerStore = createContext(new class {
    drawerPositionState = observable(false);
    hideDrawerState = observable(false);
    showDrawerState = observable(true);
    showRespDrawerState = observable(false);
    
    constructor() {
        makeAutoObservable(this);
    }

    setHideDrawerState(value) {
        this.hideDrawerState = observable(value);
    }

    setDrawerPositionState(value) {
        this.drawerPositionState = observable(value);
    }
   
    setShowDrawerState(value) {
        this.showDrawerState = observable(value);
    }

    getDrawerPositionState() {
        return this.drawerPositionState.value_;
    }

    getHideDrawerState() {
        return this.hideDrawerState.value_;
    }

    getShowDrawerState() {
        return this.showDrawerState.value_;
    }
   
    setShowRespDrawerState(value) {
        this.showRespDrawerState = observable(value);
    }
   
    getShowRespDrawerState() {
        return this.showRespDrawerState.value_;
    }

}());