import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const LanguagesLocalStorageStore = class {
    constructor() {
        makeAutoObservable(this);
        this.languageCodeKey = "i18nextLng";
    }

    setLanguageCode(value) {
        localStorage.setItem(this.languageCodeKey, value);
    }

    getLanguageCode() {
        return localStorage.getItem(this.languageCodeKey);
    }

    removeLanguageCode() {
        return localStorage.removeItem(this.languageCodeKey);
    }
};

export default createContext(new LanguagesLocalStorageStore());
