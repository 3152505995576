import React from "react";
import {
  MFormControlLabel,
  MCheckbox
} from "../../../material/components";
import styles from "./styles.module.css";

export const CheckboxDays = ({ label, ...props }) => {
  return (
    <div className={styles.checkboxDays}>
      <MFormControlLabel
        className="f-bold"
        control={<MCheckbox className="tonal" name="test" />}
        label={label}
        {...props}
      />
    </div>
  );
};
export default CheckboxDays;
