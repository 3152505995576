import React from 'react';
import MenuOptionsModule from 'modules/menu-option-module/routes';
import UserModule from "modules/users-module/routes";
import DashboardAccess from "modules/dashboard-module/routes";


// don't touch
export const useOnlyPages = (path) => {
    switch (path) {
        case "/": return [{ key: "/", value: DashboardAccess.Component }];
        case "/menu-options": return [{ key: "/menu-options", value: MenuOptionsModule.MenuSettings.Component }];
        case "/profile": return [{ key: "/profile", value: UserModule.Profile.Component }];
        default: return null;
    }
};

export default useOnlyPages;
