// b2b list module translations
let data = {
	"b2bClients": "B2B კლიენტები",
	"addNewClient": "ახლის დამატება",
	'addClient': "ახალი კლიენტის დამატება",
	'editClient': "კლიენტის რედაქტირება",
	"dateCreated": "შექმნის თარიღი",
	"identification": "საიდენტიფიკაციო",
	"identificationCode": "საიდენტიფიკაციო კოდი",
	"checkNumber": "შემოწმება",
	"coreCompany": "ძირითადი კომპანია",
	"salesManager": "გაყიდვების მენეჯერი",
	"accountManager": "ექაუნთ მენეჯერი",
	"contractorStatus": "კონტრაქტორის სტატუსი",
	"clientStatus": "კლიენტის სტატუსი",
	"identificationOrName": "საიდენტიფიკაციო/სახელი",

	"clientGeneralInfo": "კლიენტის ზოგადი ინფორმაცია",
	"contactInformation": "საკონტაქტო ინფორმაცია",
	"legalAddress": "იურიდიული მისამართი",
	"physicalAddress": "ფაქტობრივი მისამართი",
	"contactPerson": "საკონტაქტო პირი",
	"typeOfCompany": "კომპანიის ტიპი",
	"trademarkName": "სავაჭრო ნიშნის სახელი",
	"branchType": "ბრენჩის ტიპი",
	"isDefault": "ნაგულისხმევი",
	"email": "ელ-ფოსტა",
	"responsiblePerson": "პასუხისმგებელი პირი",
	"addNewPerson": "ახალის პირის დამატება",
	"clientCategories": "კლიენტის კატეგორიები",
	"dateRange": "თარიღის დიაპაზონი",

	//alerts
	"clientAddedSuccessfully": "კლიენტი წარმატებით დაემატა",
	"clientUpdatedSuccessfully": "კლიენტი წარმატებით განახლდა",
	"clientRemovedSuccessfully": "კლიენტი წარმატებით წაიშალა",
	"clientNotFound": "კლიენტი ვერ მოიძებნა",

	"noCodeFound": "კოდი ვერ მოიძებნა",
	"checkNumberValidation": "შეამოწმეთ კოდი ღილაკზე დაჭერით",

	//details
	"clientDetailedInformation": "კლიენტის დეტალური ინფორმაცია",
	"addNewBranch": "ახალი ბრენჩის დამატება",
	"editBranch": "ბრენჩის რედაქტირება",
	"branchesAndContacts": "ფილიალები/კონტაქტები",
	"agreement": "ხელშეკრულება",
	"signedDocuments": "ხელმოწერილი დოკუმენტები",
	"generatedDocuments": "დაგენერირებული დოკუმენტები",
	"archivedDocuments": "დაარქივებული დოკუმენტები",

	//agreement tab
	"draftName": "დრაფტის სახელი",
	"documentName": "დოკუმენტის სახელი",
	"signatoryPerson": "ხელმომწერი პირი",
	"assignee": "მინიჭებული",
	"reminder": "შეხსენება",
	"toArchive": "დაარქივება",
	"archive": "დაარქივება",
	"sureToArchive": "ნამდვილად გსურთ დაარქივება?",
	"nameAndPhoto": "სახელი & ფოტო",
	"totalPrice": "ჯამური ფასი",
	"added": "დაემატა",
	"nameAscending": "სახელი ზრდადობით",
	"nameDescending": "სახელი კლებადობით",
	"selectDraft": "დრაფტის არჩევა",
	"generalInfo": "ძირითადი ინფორმაცია",
	"selectors": "სელექტორები",
	"addProductsToAgreement": "პროდუქტების ხელშეკრულებაში დამატება",
	"addProducts": "პროდუქტების დამატება",
	"addAgreementDetails": "ხელშეკრულების დამატება",
	"editAgreementDetails": "ხელშეკრულების განახლება",
	"uploadDocument": "დოკუმენტის ატვირთვა",
	"addAgreement": "ხელშეკრულების დამატება",
	"editDocument": "დოკუმენტის განახლება",
	"signatory": "ხელმოწერა",
	"optional": "(*არასავალდებულო)",
	"acceptedType": "ფაილის ტიპი",
	"documentDetails": "დოკუმენტის დეტალები",
	"agreementDetails": "ხელშეკრულების დეტალები",
	"downloadDocument": "დოკუმენტის გადმოწერა",

	"allProducts": "ყველა პროდუქტი",
	"addedProducts": "დამატებული პროდუქტები",

}

export default data;