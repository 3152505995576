import React from 'react';

import visaSrc from 'assets/img/visa-card.svg';
import masterCardSrc from 'assets/img/mastercard-card.svg';
import amexSrc from 'assets/img/amex-card.svg';
import dinersSrc from 'assets/img/diners-card.svg';
import discoverSrc from 'assets/img/jcb-card.svg';

export const MpaymentCard = (number) => {
  if (number.match(new RegExp('^4')) != null) return <img src={visaSrc} alt="Visa" />;
  if (
    number.match(
      new RegExp('^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$')
    ) != null
  )
    return <img src={masterCardSrc} alt="MasterCard" />;
  if (number.match(new RegExp('^3[47]')) != null) return <img src={amexSrc} alt="American Express" />;
  if (number.match(new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{11}$')) != null)
    return <img src={dinersSrc} alt="Diners" />;
  if (
    number.match(
      new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
    ) != null
  )
    return <img src={discoverSrc} alt="Discover" />;
  if (number.match(new RegExp('^35(2[89]|[3-8][0-9])')) != null) return <img src={jcbSrc} alt="JCB" />;
};
export default MpaymentCard;
