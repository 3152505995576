import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';

export const Socket = class {
  serverUrl = process.env.REACT_APP_BASE_API_URL + "/hub";
  requestUrl = "";
  hubConnection = null;
  isConnected = false;

  setRequestUrl = (url) => {
    this.requestUrl = this.serverUrl + url;
  }

  buildConnection = async () => {
    try {
      const runSocket = async (isClose = false) => {
        this.hubConnection = isClose && this.hubConnection !== null ? this.hubConnection : new HubConnectionBuilder()
        .withUrl(this.requestUrl + `?access_token=${localStorage.userToken}`, { transport: HttpTransportType.WebSockets })
        .withAutomaticReconnect()
        .build();

        if(isClose) {
          this.hubConnection.invoke("Close");
          this.isConnected = false;
          return;
        }
        
        await this.hubConnection.start();
        this.isConnected = true;
        return;
      }
      await runSocket();

      window.onfocus = async function () {
        if(process.env.NODE_ENV === 'development') return;
        await runSocket();
      }; 

      // const invoke = this.hubConnection.invoke;
      window.onblur = function () {
        if(process.env.NODE_ENV === 'development') return;
        runSocket(true);
      }; 
    } catch(e) {}
  }
  
  on = (action = "", reciveMethod = (message) => {}) => {
    try {
      this.isConnected && this.hubConnection.on(action, reciveMethod);
    } catch(e) {}
  }
  send = (action = "", data = "") => {
    try {
      if(this.isConnected && this.hubConnection.connectionState === "Connected") {
        this.hubConnection.send(action, data);
      }
    } catch(e) {}
  }

  invoke = (action) => {
    try {
      if(this.hubConnection.connectionStarted)
      this.hubConnection.invoke(action);
    } catch (error) { }
  }

  constructor() {
    makeAutoObservable(this);
  }
};

export default createContext(new Socket());
