import React, { useState } from "react";
import { IconMdKeyboardArrowDown } from "../../../icons/components";
import { MPagination, MMenuItem, MMenu } from "../../../material/components";
import styles from "./styles.module.css";

export const CustomPagination = ({ totalCount, pagesCount, currentPage, onPageChange, handleRowPerPageChange, rowPerPageCount }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (count) => handleRowPerPageChange(count);
  
  return (
    totalCount < 10 ? null :
    <div className={styles.pagination}>
      <div className="row">
        {/* <div className="col-4 d-flex d-md-none align-items-center"></div> */}
        <div className="col-12 col-md-12 d-flex d-sm-block align-items-center justify-content-end justify-content-xs-center">
          
        <div className={`${styles.viewPerPageBtn} d-flex align-items-center`}>
          <div className={styles.viewPerPageTitle}>Rows per page:</div>
          <div
            className={styles.viewPerPageDropdownBtn}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <span className={styles.viewPerPageDropdownBtnTitle}>{rowPerPageCount}</span>
            <IconMdKeyboardArrowDown
              className={`${styles.viewPerPageDropdownBtnArrow} ${
                Boolean(anchorEl) ? styles.visible : ""
              }`}
            />
          </div>
          
            <MMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              id="pagination-view-per-page-menu"
            >
              <MMenuItem
                key={`pagination-per-page-view-options-1`}
                onClick={() => {
                  handleChange(10);
                  setAnchorEl(null);
                }}
                className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 10 ? styles.active : ""}`}
              >
                10
              </MMenuItem>
              <MMenuItem
                key={`pagination-per-page-view-options-1`}
                onClick={() => {
                  handleChange(20);
                  setAnchorEl(null);
                }}
                className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 20 ? styles.active : ""}`}
              >
                20
              </MMenuItem>
              <MMenuItem
                key={`pagination-per-page-view-options-2`}
                onClick={() => {
                  handleChange(50);
                  setAnchorEl(null);
                }}
                className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 50 ? styles.active : ""}`}
              >
                50
              </MMenuItem>
              <MMenuItem
                key={`pagination-per-page-view-options-3`}
                onClick={() => {
                  handleChange(100);
                  setAnchorEl(null);
                }}
                className={`${styles.viewPerPageMenuItem} ${rowPerPageCount === 100 ? styles.active : ""}`}
              >
                100
              </MMenuItem>
            </MMenu>
          </div>

          <MPagination className="f-bold" page={currentPage} count={pagesCount} onChange={onPageChange} />
        </div>
      </div>
    </div>
  );
};
export default CustomPagination;
