import React from 'react';
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from 'mobx-react-router'
import { observer, Observer, Provider } from "mobx-react";
import { mobxRouting } from 'shared-modules/url-helper-module';

import ConfigureComponent from '../ConfigureComponent';
import { LoggingProvider } from "shared-modules/azure-application-insights-module";
import { StoreBrowserHistory } from './SyncHistoryWithStore';

const browserHistory = createBrowserHistory()

export const routerHistory = syncHistoryWithStore(browserHistory, mobxRouting);
StoreBrowserHistory.routerHistory = routerHistory;
const stores = { mobxRouting }

//default Entry component makes all inner components observable for changes
export const EntryComponent = () => {
    return (
        <LoggingProvider>
            <Provider {...stores}>
                <Router history={routerHistory}>
                    <Switch> 
                        {/* configure component returns routes component for switch */}
                        <Observer render={() => <ConfigureComponent /> }/>
                    </Switch>
                </Router>
            </Provider>
        </LoggingProvider>
    )
}

export default observer(EntryComponent);