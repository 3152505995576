import React from "react";
import { IconMdDownload } from "../../../icons/components";
import { useTranslations } from "shared-modules/translation-module";
import {
  MuseMediaQuery,
} from "../../../hooks/MuseMediaQuery";
import styles from "./styles.module.css";

export const ExportDataBtn = (props) => {
  const mediaQueryLG = MuseMediaQuery("lg");
  const { t } = useTranslations();

  if (props.label) {
    return (
      <div
        className={`${styles.exportDataBtn} custom-icon-box f-bold d-flex align-items-center`}
        {...props}
      >
        <IconMdDownload
          className={`custom-icon dark-green ${
            props?.disabled ? "disabled" : ""
          }`}
        />
        { !mediaQueryLG && <span className="custom-icon-label">{t[props.label] ?? ""}</span> }
      </div>
    );
  }
  return (
    <IconMdDownload
      className={`custom-icon dark-green big ${
        props?.disabled ? "disabled" : ""
      }`}
      {...props}
    />
  );
};
export default ExportDataBtn;
