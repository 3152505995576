//create languages for auth module
let data = {
    "company": "კომპანია",
    "modules": "მოდულები",
    "salePoints": "გაყიდვის წერტილები",
    "locations": "ლოკაციები",
    "childes": 'შვილები',
    "noAreals": 'არეალი არ არის',
    "mustChooseParentData": "ჯერ უნდა აირჩიო მშობელი კომპანია!",
    'noChildren': 'შვილები არ ჰყავს',
    "modules": "მოდულები",
    "salesPoints": "გაყიდვის წერტილები",
    "locations": "არეალი",
    "children": "შვილები",
    "currency": "ვალუტა",
    'noCurrency': 'ვალუტა არ არის',
    "deleteDialogCompanyTitle": "წაშლის მოქმედება, რომელსაც ჰყავს შვილობილი კომპანიები",
    "deleteDialogCompanyText": "ამ კომპანიას ჰყავს ე.წ შვილობილი კომპანიები რომელიც ასევე წაიშლება, ნამდვილად გსურს წაშლა?",
    "deleteCompanyWithoutChildes": "წაშალე მეამა ქოლექთი (შვილების გარეშე)",
    "deleteCompanyWithChildOrFull": "წაშალე მეამა ქოლექთი (სრული ან ნაწილობრივი საგვარეულო)",
    "feildGetCompanies": "კომპანიების წამოღება ვერ მოხერხდა",
    "sort": "სორტირება",
    "lastAdded": "ბოლოს დამატებული",
    "newestAdded": "ახალი დამატებული",
    "byStatus": "სტატუსით",
    "companyColor": "კომპანიის ფერი",
    "chooseLocation": "აირჩიე მდებარეობა",
    "parentCompany": "მშობელი კომპანია",
    "companyName": "კომპანიის სახელი",
    'addNewCompany': "ახალი კომპანიის დამატება",
    "working_area": "სამუშაო არეალი",
    "successUpdateCompany": "კომპანია წარმატებით გაახლდა",
    "notAUpdatedCompany": "კომპანია ვერ გაახლდა",
    "ProductName": "პროდუქტის სახელით",
    "CategoryName": "კატეგორიის სახელით",
    "productName": "პროდუქტის სახელი",
    "categoryName": "კატეგორიის სახელი",
    "costFrom": "ღირებულება დან",
    "costTo": "ღიტებულება მდე",
    "costCurrency": "თვით ღრებულების ვალუტა",
    "priceCurrency": "გასაყიდი ფასის ვალუტა",
    "moreThan0LessThen100": "შეიყვანე რიცხვი 0.1 დან 99.99 მდე"
}

export default data;