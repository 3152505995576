import { useDrawerRoutes } from "../RoutesByPermission";

export const useDrawerSearch = async (translation) => {
  const drawerRoutes = await useDrawerRoutes(translation);
  const getPages = (routes, index = 0, pagesArr = [], currentModuleName = undefined, perrentIcon = undefined, urls = []) => {
    var el = routes[index];

    if(el?.to && el?.to !== '' && !el?.pageKeyFromServer) {
      urls.push(el?.to);
    }

    if(el?.moduleKeyFromServer !== undefined) {
      currentModuleName = el?.to?.replace("/", "");
    }

    if(el?.Icon !== undefined && el?.Icon !== "") {
      perrentIcon = el?.Icon;
    }

    if(el?.children === undefined) {
      if(el) {
        var icon = "";

        if(el.Icon) icon = el.Icon;
        else icon = perrentIcon;

        if(!urls.includes(`/${currentModuleName?.toLowerCase()}`)) urls = [`/${currentModuleName?.toLowerCase()}`, ...urls];

        pagesArr.push({ ...el, moduleName: translation[currentModuleName] ?? currentModuleName, icon: icon, label: translation[el.label] ?? el.label, url: urls.join('/') + "/" + el.to});
      }
    }
    else {
      getPages(el?.children, 0, pagesArr, currentModuleName, perrentIcon, urls);
    }

    if(el !== undefined) return getPages(routes, ++index, pagesArr, currentModuleName, perrentIcon, []);
    else return pagesArr;
  }

  var pages = getPages(drawerRoutes);
  return (name) => pages.filter(o => o.label?.toLowerCase().includes(name?.toLowerCase()) && !o.isFavorite);
};

export default useDrawerSearch;
