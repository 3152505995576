import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react";
import { v4 as uuidv4 } from 'uuid';

import {
  MFormControl,
  MInputLabel,
  MSelect,
  MMenuItem,
  MCheckbox,
  MFormControlLabel,
  MCollapse,
} from "../../../../material/components";
import { ComponentLoader } from '../../ComponentLoader'
import { SecondaryCustomSearch } from "../../../../custom/components";
import { MTransitionGroup } from "../../../../transitions/components";
import { IconMdKeyboardArrowDown } from "../../../../icons/components";
import styles from "../styles.module.css";
import { useContext } from "react";
import ThemeStore from "../../../../Store";
import { useTranslations } from 'shared-modules/translation-module';
import { SelectsStore } from "../store";

export const RecursiveSelect = ({
  propsData = [],
  handlePranetChange,
  cityDistrict = false,
  singleSelect,
  multipleSelect,
  disabled = false,
  placeholder,
  selectData = undefined,
  names = { id: "id", value: "name", mapName: "children" },
  noDataMessage = { message: "No Data" },
  showLoading = false,
}) => {
  const { t } = useTranslations();
  const [SELELCT_ID, setSELELCT_ID] = useState(uuidv4());

  const uilibStore = useContext(ThemeStore);

  const [selectedData, setSelectedData] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const [checkedArr, setCheckedArr] = useState([]);
  const [expandiedIds, setExpandiedIds] = useState([]);

  const isArray = function (x) {
    return Object.prototype.toString.call(x) === '[object Array]';
  };

  const expandedHandler = (data) => {
    let isData = expandiedIds.find((o) => o === data[names.value]);
    isData
      ? setExpandiedIds(expandiedIds.filter((o) => o !== data[names.value]))
      : setExpandiedIds([...expandiedIds, data[names.value]]);
  };

  const selectChange = (data) => {
    if (singleSelect) {
      //singleSelect states set
      if (selectState.find((o) => o[names.value] === data[names.value])) {
        handlePranetChange(undefined, null);
        setSelectState([]);
        setExpandiedIds([]);
        return;
      }
      handlePranetChange(data, null);
      setSelectState([data]);
      setExpandiedIds([
        ...uilibStore
          .findAllParentByChild(propsData, data[names.value], names?.mapName)
          .map((o) => o[names.value]),
        data[names.value],
      ]);
      return;
    } else if (multipleSelect) {
        //mulitpleSelect states set
      if (uilibStore.checkedList.some((o) => o[names.id] === data[names.id])) {
        uilibStore.checkedList = uilibStore.checkedList.filter(o => o[names.id] !== data[names.id])
        handlePranetChange(uilibStore.checkedList, null);
        return;
      }
      let allChildren = uilibStore.findAllChild(data, names.mapName);

      if(cityDistrict) {
        let children = data[names.mapName];
        if(children?.length) {
          const childIds = children.map(child => child[names.id]);
          const listWithoutChildren = uilibStore.checkedList.filter((item) => !childIds.includes(item[names.id]));

          handlePranetChange([...listWithoutChildren, data], null);
          return;
        }

        let parent = uilibStore.findAllParentByChildId(propsData, data[names.id], names.mapName)[0];

        if(parent) {
          let listWithoutParent = uilibStore.checkedList.filter((checkedItem) => checkedItem[names.id] !== parent[names.id]);
          handlePranetChange([...listWithoutParent, data], null);
          return;
        }

        handlePranetChange([...uilibStore.checkedList, data], null);
        return;
      }

      let removedParntList = uilibStore.checkedList.filter(o => !uilibStore.findAllParentByChild(propsData, data[names.value])?.some(i => i[names.value] === o[names.value]));
      uilibStore.checkedList = [...removedParntList?.filter(o => !allChildren?.some(i => i[names.value] === o[names.value])) , data];
      handlePranetChange(uilibStore.checkedList, null);
      return;
    }
    //recursive multipleSelect states set
    uilibStore.checkboxHanler(data, propsData);
    setCheckedArr(uilibStore.checkedList);
    setExpandiedIds([
      ...uilibStore.checkedList.map((o) => o[names.value]),
      ...uilibStore
        .findAllParentByChild(propsData, data[names.value], names?.mapName)
        .map((o) => o[names.value]),
    ]);
    setSelectState(uilibStore.formDataList);
    handlePranetChange(uilibStore.formDataList, null);
  };

  useEffect(() => {
    propsData.length > 0 && setSelectedData(propsData?.filter(o => o));
    if (propsData.length === 0 && selectedData.length !== 0) {
      setSelectedData([]);
      setSelectState([]);
      setCheckedArr([]);
      uilibStore.checkedList = [];
      uilibStore.formDataList = [];
    }
  }, [propsData]);

  useEffect(() => {
    if (!selectData) return;
    if (selectData?.length > 0) {
      let allCheckedChildren = [].concat.apply([], selectData.map((o) => uilibStore.findAllChild(o)));
      if(allCheckedChildren.length > 0 && !multipleSelect) {
        uilibStore.checkedList = [
          ...selectData?.map(o => {
            if(o.city) return o?.city;
            else if(o.country) return o?.country;
            else if(o.region) return o?.region;
            else return o;
          }),
          ...allCheckedChildren,
        ];
        setSelectState(uilibStore.checkedList);
      }
      else if(multipleSelect) {
        uilibStore.checkedList = selectData;
        setSelectState(selectData ?? []);
      }
      uilibStore.formDataList = selectData;
      return;
    } else {
      uilibStore.checkedList = [];
      uilibStore.formDataList = [];
      setSelectState([]);
      setCheckedArr([]);
    }
    isArray(selectData) ? setSelectState(selectData) : setSelectState([selectData]);
    setCheckedArr([...checkedArr, selectData]);
  }, [selectData]);

  //clean check and data sttes
  useEffect(() => {
    return () => {
      setSelectState([]);
      setCheckedArr([]);
      uilibStore.checkedList = [];
      uilibStore.formDataList = [];
    };
  }, []);

  const MenuItem = (data) => {
    let isChecked = false;
    let localCheckedArr = uilibStore.checkedList?.filter((o) => o !== undefined);
    
    if (localCheckedArr)
    if (singleSelect) isChecked = selectState?.some((o) => o[names.value] === data[names.value]);
    else {
      isChecked = localCheckedArr?.some((o) => o[names.id] === data[names.id]);
    }

    return (
      <MMenuItem onClick={() => selectChange(data)}>
        <MFormControlLabel
          className={styles.checkbox}
          style={{ pointerEvents: "none" }}
          control={
            <MCheckbox
              value={data.id}
              checked={isChecked}
              className="tonal medium"
            />
          }
          label={data[names.value]}
        />
      </MMenuItem>
    );
  };

  const recursiveData = (data, name, key) => {
    return data[names.mapName] ? (
      <div key={key}>
        <div className={styles.menuItemBox} key={name + data[names.id]}>
          {MenuItem(data, name)}
          {data[names.mapName]?.length > 0 && (
            <div
              onClick={() => expandedHandler(data)}
              className={`${styles.collapseIcon} ${
                expandiedIds?.some((o) => o === data[names.value])
                  ? styles.collapsed
                  : ""
              }`}
            ></div>
          )}
        </div>
        <MTransitionGroup>
          {expandiedIds?.some((o) => o === data[names.value]) &&
            data[names.mapName]?.length > 0 && (
              <MCollapse>
                {data[names.mapName]?.length > 0
                  ? data[names.mapName].map((child, key) => {
                      return (
                        <div
                          className={styles.inner}
                          key={key + name + data[names.id]}
                        >
                          {recursiveData(child, name, key)}
                        </div>
                      );
                    })
                  : MenuItem(data, name)}
              </MCollapse>
            )}
        </MTransitionGroup>
      </div>
    ) : (
      <div className={styles.menuItemBox} key={key + name + data[names.id]}>
        {MenuItem(data, name)}
      </div>
    );
  };

  //TODO: not search in childes
  const [searchInput, setSearchInput] = useState("");

  const searchData = (searchParam) => {
    return propsData.filter((o) =>
      o[names.value]
        .toLocaleLowerCase()
        .includes(searchParam.toLocaleLowerCase())
    );
  };

  const searchOnChange = async (e) => {
    const inputVal = e.target.value;
    let result = searchData(inputVal);

    if ((result.length === 0 && inputVal.includes(" ")) || inputVal === " ")
      return;
    if (!inputVal) {
      setSearchInput(inputVal);
      setSelectedData(propsData);
      setExpandiedIds(checkedArr.map((o) => o[names.value]));
      return;
    }
    setSearchInput(inputVal);
    setSelectedData(result);
    setExpandiedIds(result.map((o) => o[names.value]));
  };

  return (
    <Observer
    render={() => 
    <MFormControl>
        {
          showLoading && (
            <div className={styles.inputLoading}>
              <ComponentLoader />
            </div>
          )
        }
      <MInputLabel>{placeholder}</MInputLabel>
      <MSelect
        onChange={(e) => SelectsStore.checkValidation(SELELCT_ID)}
        selelctid={SELELCT_ID}
        selectedcount={selectState?.length}
        multiple
        variant="outlined"
        MenuProps={{classes: { paper: styles.selectDropdown}}}
        disabled={showLoading || disabled || !propsData?.length}
        value={singleSelect ? selectState?.length > 0 ? [selectState[0][names.value]] : [] : selectState?.map((o) => o[names.value]) ?? []}
        renderValue={(selected) => {
          selected = selected.filter(o => o);
          return singleSelect ? selected : selected.join(", ")
        }}
        IconComponent={showLoading ? ()=> null : IconMdKeyboardArrowDown} 
      >
        {propsData?.length > 20 && (
          <SecondaryCustomSearch
            onChange={searchOnChange}
            input={searchInput}
            classText="select-search"
          />
        )}
        {selectedData.length > 0 ? (
          selectedData?.map((o, key) => recursiveData(o, names.mapName, key))
        ) : (
          <span className={styles.noDataText}>{noDataMessage.message}</span>
        )}
      </MSelect>
    </MFormControl>
    } />
  );
  
};

export default RecursiveSelect;
