import { toast } from "react-toastify";

const notificationToast = (message, options) => {
    toast(
        message ? message : "Message", 
        {
            className: 'Toastify__toast--notification',
            progressClassName: 'Toastify__progress-bar--notification',
            toastId: message,
            position: toast.POSITION.TOP_RIGHT,
            ...options
        }
    );
}

// export default  
export default notificationToast
