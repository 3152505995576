export function getLocalFilterStore() {
  try {
    return JSON.parse(localStorage.getItem(window.location.pathname)) || [];
  } catch (e) {
    console.error('wrong json format');
    return [];
  }
}

export function hasFilterSet(filterObj) {
  return !Object.values(filterObj).every((item) => {
    if (Array.isArray(item)) {
      return item.length === 0;
    }
    return !item;
  });
}
