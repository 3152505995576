import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import {
  MTransitionGroup,
  MCollapse,
  IconMdKeyboardArrowDown,
  IconMdKeyboardArrowRight,
  IconMdStarRate
} from "shared-modules/ui-lib-module";

import style from "./styles.module.css";

const resolveLinkPath = (childTo, parentTo) => {
  if(parentTo && childTo) return `${parentTo}/${childTo}`;
  if(childTo) return `${childTo}`;
  return `${parentTo}/${childTo}`
};

const NavItemHeader = (props) => {
  const { item } = props;
  const { label, Icon, to: headerToPath, children } = item;
  const location = useLocation();

  const [expanded, setExpand] = useState(
    location.pathname.includes(headerToPath)
  );

  const onExpandChange = (e) => {
    e.preventDefault();
    setExpand((expanded) => !expanded);
  };


  return (
    <div
      className={`${style.navBox} ${props.firstItem ? style.firstItem : ""} ${props.hideDrawer ? style.hideDrawer : ""}`}>
      {!Icon && (
        <button className={style.navItemHead} onClick={onExpandChange}>
          <IconMdKeyboardArrowDown
            className={`${style.navItemHeadArrow} ${expanded ? style.navItemHeadArrowVisible : ""}`}/>
          <span className={`${style.navItemHeadLabel} f-upper`}>{label}</span>
        </button>
      )}
      {Icon && (
        <button className={style.navItemSubHead} onClick={onExpandChange}>
          <Icon className={style.navItemSubHeadIcon} />
          <span className={`${style.navItemSubHeadLabel} f-bold`}>{label}</span>
          <IconMdKeyboardArrowRight className={`${style.navItemSubHeadArrow} ${expanded ? style.navItemSubHeadArrowVisible : ""}`}/>
        </button>
      )}

    <MTransitionGroup>
      {expanded && (
        <MCollapse timeout={400}>
          <div className={style.navChildrenBox}>
            {children.map((item, index) => {
              const key = `${item.label}-${index}`;

              const { label, children } = item;

              if (children) {
                return (
                  <div key={key}>
                    <NavItemHeader item={{...item,to: resolveLinkPath(item.to, props.item.to)}}/>
                  </div>
                );
              }

              if (item.Icon) {
                return (
                  <NavLink
                    key={key}
                    to={resolveLinkPath(item.to, props.item.to)}
                    className={style.navItemSubHead}
                    activeClassName={style.navItemSubHeadActive}
                  >
                    {props.firstItem ?  <IconMdStarRate className={style.navItemSubHeadIcon} /> :  <item.Icon
                      className={style.navItemSubHeadIcon}
                    ></item.Icon>}
                    <span className={`${style.navItemSubHeadLabel} f-bold`}>
                      {label}
                    </span>
                  </NavLink>
                );
              } else {
                return (
                  <NavLink
                    key={key}
                    to={resolveLinkPath(item.to, props.item.to)}
                    className={style.navChildrenItem}
                    activeClassName={style.navChildrenItemActive}
                  >
                    <span className={`${style.navChildrenItemLabel} f-bold`}>
                      <IconMdStarRate />
                      {label}
                    </span>
                  </NavLink>
                );
              }
            })}
          </div>
        </MCollapse>
      )}
    </MTransitionGroup>
    </div>
  );
};

export default NavItemHeader;
