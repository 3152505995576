import React, { useEffect, useState, useRef } from "react";
import { IconMdPhotoCamera, IconMdDelete } from "../../../icons/components";
import { MToast } from "../Toast/";
import { useTranslations } from "shared-modules/translation-module";
import { MButton, MTextValidator } from "../../../material/components";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import styles from "./styles.module.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFilePoster
);


const FilePondLocale = React.memo(({
  required,
  allowMultiple,
  allowReorder,
  maxFiles,
  labelMaxFileSize,
  acceptedFileTypes,
  disabled,
  imageUrls,
  files,
  setFiles,
  imageOnchange = () => {},
  imageUploadHandler = () => {},
  imageRemoveHandler = () => {},
  onRemoveImeage = () => {},
  imageSortHandler = () => {},
}) => {
  const { t } = useTranslations();
 
  let time = null;

  const filesData = React.useMemo(() =>  {

    return imageUrls?.length ?
      imageUrls?.map(image => (
        {
          id: image.id,
          options: {
            type: "local",
            file: {},
            metadata: {
              poster: image.file ?? image
            }
          }
        }
      ))  : (files?.length > 0 ? files?.map(image => {
        return {
          options: {
            type: "local",
            file: image,
            metadata: { }
          }
        }
      }) : [])
  },[imageUrls, files])


  return (
    <FilePond 
      allowFilePoster={true}
      required={required ? true : false}
      allowMultiple={allowMultiple ? true : false}
      allowReorder={allowReorder ? !disabled : false}
      maxFiles={maxFiles ? maxFiles : null}
      allowFileSizeValidation={labelMaxFileSize ? true : false}
      labelMaxFileSize={`${t["maximum-file-size-is"] ?? ""} ${labelMaxFileSize ? labelMaxFileSize : ""}`}
      allowFileTypeValidation={acceptedFileTypes ? true : false}
      disabled={disabled}
      itemInsertLocation="after"
      styleButtonRemoveItemPosition="right"
      labelIdle='<div><div class="plus"></div></div>'
      files={filesData}
      
      server={{
        process: async (source, load, error, progress, abort, headers, ssd) => {
          let res = await imageUploadHandler(load);
          progress(res);
        },
        remove: async (image, cb) => {
          let res = await imageRemoveHandler(image?.options?.metadata?.poster);
          cb(res);
        }
      }}
      onupdatefiles={(files) => {
        imageOnchange(files);
        // setFiles(files); //bug
      }}
      onreorderfiles={(files, oldIndex, newxIndex) => {
        if(disabled) return;
        clearTimeout(time);
        time = setTimeout(() => imageSortHandler(oldIndex, newxIndex), 1000);
      }}
      //returns file and source id
      onremovefile ={(err, file) => onRemoveImeage(file, file.source.id) }
      onwarning={(error) => error.body === "Max files" && MToast.warningToast(`${t["the-maximum-number-of-files-is"]} ${maxFiles}`)}
      maxFileSize={labelMaxFileSize}
      labelMaxFileSizeExceeded={t["file-is-too-large"] ?? ""}
      acceptedFileTypes={acceptedFileTypes}
      labelFileTypeNotAllowed={t["file-of-invalid-type"] ?? ""}
      fileValidateTypeLabelExpectedTypes=""
    />
  );

}, () => true)



export const ImageUploader = React.memo(({
  required,
  fullWidth,
  allowTitle,
  titleValidation,
  allowMultiple,
  allowReorder,
  maxFiles,
  uploadTitle,
  uploadSubTitle,
  labelMaxFileSize,
  acceptedFileTypes,
  icon = IconMdPhotoCamera,
  disabled,
  deleteButton,
  inputValue = '',
  defaultValue = '',
  imageUrls,
  propFiles,
  deleteHandler = () => {},
  onChange = () => {},
  imageOnchange = () => {},
  imageUploadHandler = () => {},
  imageRemoveHandler = () => {},
  onRemoveImeage = () => {},
  imageSortHandler = () => {},
}) => {
  const inpRef = useRef();
  const { t } = useTranslations();
  
  const [files, setFiles] = useState([]);
  const IconComponent = icon;
  
  useEffect(() => {
    if(inputValue && inputValue !== "")
    {
      inpRef.current.value = inputValue;
    }
  }, [inpRef])

  return (
    <div
      className={`
        imageUploader
        ${styles.imageUploaderBox} 
        ${allowMultiple ? styles.multiple : ""} 
        ${fullWidth ? styles.fullWidth : ""} 
        ${disabled ? styles.disabled : ""}
        ${maxFiles && files.length == maxFiles ? styles.uploadedMaxFiles : ""}
        ${!allowMultiple && files.length ? styles.uploadedMaxFiles : ""}
      `}
    >
      <div className={styles.topSection}>
        <div className="d-flex align-items-center">
          <IconComponent className={styles.uploadIcon} />
          <div>
            {uploadTitle && (
              <div className={`${styles.uploadTitle} f-bold`}>
                {uploadTitle}
              </div>
            )}
            {uploadSubTitle && (
              <div className={`${styles.uploadSubTitle}`}>{uploadSubTitle}</div>
            )}
          </div>
        </div>
      </div>
      {allowTitle && (
        <MTextValidator
          className={styles.titleInput}
          autoComplete="off"
          variant="outlined"
          id={"imageDesc"}
          name="key"
          type="text"
          useValueFromProps={false}
          // required={true}
          // validators={[titleValidation ? "required" : ""]}
          // errorMessages={[t[titleValidation ? "thisFieldIsRequired" : ""]]}
          inputRef={inpRef}
          label={defaultValue ?? "Type Here"}
          onChange={onChange}
        />
      )}

      <FilePondLocale 
        required={required}
        allowMultiple={allowMultiple}
        allowReorder={allowReorder}
        maxFiles={maxFiles}
        labelMaxFileSize={labelMaxFileSize}
        acceptedFileTypes={acceptedFileTypes}
        disabled={disabled}
        imageUrls={imageUrls}
        files={propFiles}
        setFiles={setFiles}
        imageOnchange={imageOnchange}
        imageUploadHandler={imageUploadHandler}
        imageRemoveHandler={imageRemoveHandler}
        onRemoveImeage={onRemoveImeage}
        imageSortHandler={imageSortHandler}
      />

      {deleteButton && (
        <div className="d-flex justify-content-end">
          <MButton className={`${styles.deleteButton} f-bold transparent`} onClick={deleteHandler} >
            <IconMdDelete />
            {t["delete"]}
          </MButton>
        </div>
      )}
    </div>
  );
});
export default React.memo(ImageUploader);

{
  /* <ImageUploader 
  required={false}
  fullWidth={true}
  allowTitle={false}
  allowMultiple={true}
  allowReorder={true}
  maxFiles={5}
  uploadTitle="Upload Gallery"
  uploadSubTitle="Recommended Size 1600x1200"
  labelMaxFileSize="5MB"
  acceptedFileTypes={["image/*"]}
  disabled={false}
/> */
}
