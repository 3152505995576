import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { ThemesLocalStorageStore } from "shared-modules/local-storage-module";

export const ThemeStore = class {
  constructor() {
    makeAutoObservable(this);
    this.themesLocalStorageStore = new ThemesLocalStorageStore();
  }

  checkedList = [];
  formDataList = [];
  allData = [];

  setTheme(isDark) {
    this.themesLocalStorageStore.setTheme(isDark ? "dark" : "");

    var HTMLTag = document.getElementsByTagName("html")[0].classList;

    if (isDark) HTMLTag.add("dark");
    else HTMLTag.remove("dark");
  }

  checkboxHanler = (data, fullData) => {
    let isData = this.checkedList.find((o) => o.name === data.name);
    let parent = this.findParentByChild(fullData, data.name);
    if (!isData) {
      let finalCheckList = [...this.checkedList, data];
      let finalFormData = [...this.formDataList, data];
      if (data?.regionId) {
        //city case
        let citiesInList = finalFormData.filter((o) =>
          parent.children?.some((el) => el?.name === o?.name)
        );

        //check if all city is check parent region
        if (citiesInList?.length === parent.children?.length) {
          finalCheckList = [...finalCheckList, parent];
          finalFormData = [
            ...finalFormData.filter(
              (o) => !parent.children?.some((el) => el.name === o.name)
            ),
            parent
          ];
          let country = this.findParentByChild(fullData, parent.name);
          let CountriesInList = finalFormData.filter((o) =>
            country.children?.some((el) => el.name === o.name)
          );

          //check if all region is check parent country
          if (CountriesInList?.length === country.children?.length) {
            finalCheckList = [...finalCheckList, country];
            finalFormData = [
              ...finalFormData.filter(
                (o) => !country.children?.some((el) => el.name === o.name)
              ),
              country
            ];
          }
        }

        this.checkedList = finalCheckList;
        this.formDataList = finalFormData;
        return;
      }

      else if (data?.countryId) {
        //region case
        let regionsInList = finalFormData.filter((o) =>
          parent.children?.some((el) => el.name === o.name)
        );

        if (regionsInList?.length === parent.children?.length) {
          finalCheckList = [...finalCheckList, parent];
          finalFormData = finalFormData.filter(
            (o) => !data.children?.some((el) => el.name === o.name)
          );

          finalFormData = [
            ...finalFormData.filter(
              (o) => !parent.children?.some((el) => el.name === o.name)
            ),
            parent
          ];
        }

        this.checkedList = [...finalCheckList, ...data.children];
        this.formDataList = finalFormData;
        return;
      }
     
      finalFormData = finalFormData.filter((o) =>!this.findAllChild(data, "children")?.some((el) => el.name === o.name));
      this.checkedList = [...finalCheckList, ...this.findAllChild(data, "children")];
      this.formDataList = finalFormData;
      return;
    }

    let finalCheckList = [...this.checkedList];
    let finalFormData = [...this.formDataList];

    finalCheckList = finalCheckList.filter((o) => o.name !== data.name);
    finalFormData = finalFormData.filter((o) => o.name !== data.name);
    let allParent = this.findAllParentByChild(fullData, data.name);

    allParent.map((el) => {
      if (this.checkedList.find((o) => o.name === el.name)) {
        let childs = el.children;
        if (el.children.find((o) => o.name === data.name))
          childs = childs.filter((o) => o.name !== data.name);
        finalFormData = [
          ...finalFormData.filter(
            (o) => !allParent?.some((el) => el.name === o.name)
          ),
          ...childs
        ];
      }
    });
    finalCheckList = finalCheckList.filter(
      (o) => !allParent?.some((el) => el.name === o.name)
    );
    finalCheckList = finalCheckList.filter(
      (o) => !this.findAllChild(data)?.some((el) => el.name === o.name)
    );

    this.checkedList = finalCheckList;
    this.formDataList = finalFormData;
    return;
  };

  allDataHanler = (data) => (this.allData = data);

  findAllParentByChild = (arr, child, name="children") => {
    let parents = [];
    function getPath(model, id, path) {
      if (!path) {
        path = [];
      }

      if (model == null) {
        return;
      }
      if (model.name === id) {
        parents = path;
      }
      if (model[name]) {
        model[name].forEach((child) =>
          getPath(child, id, [...path, model])
        );
      }
    }

    arr?.map((el) => getPath(el, child));
    return parents;
  };

  findAllParentByChildId = (arr, child, name="children") => {
    let parents = [];
    function getPath(model, id, path) {
      if (!path) {
        path = [];
      }

      if (model == null) {
        return;
      }
      if (model.id === id) {
        parents = path;
      }
      if (model[name]) {
        model[name].forEach((child) =>
          getPath(child, id, [...path, model])
        );
      }
    }

    arr?.map((el) => getPath(el, child));
    return parents;
  };

  findAllChild = (parent, mapName='children') => {
    let childs = [];
    if(!parent[mapName]?.length) return []
    const recursive = (obj, mapName) => {
      if (obj[mapName]?.length > 0) {
        obj[mapName].map((el) => {
          childs.push(el);
          return recursive(el, mapName);
        });
      }
      if (obj?.length > 0) childs = [...childs, ...obj];
      return obj;
    };
    recursive(parent, mapName);
    return childs;
  };

  findParentByChild = (arr, child) => {
    let parent = {};
    const recurseSearch = (arr, child) => {
      arr?.map((company) => {
        if (company?.children?.length < 1) {
          return;
        }
        if (company?.children?.some((o) => o.name === child)) {
          return (parent = company);
        }
        return recurseSearch(company.children, child);
      });
    };
    recurseSearch(arr, child);

    return parent;
  };
};

export default createContext(new ThemeStore());
