import React, { memo } from 'react';
import MuiDivider from '@mui/material/Divider';
import "./style.css";

export const MDivider = React.forwardRef(
	({
		 className,
		 flexItem = true,
		 color = 'default',
		 variant = 'fullWidth',
		 sx,
		 ...props
	 }, ref) => {
		return (
			<MuiDivider
				ref={ref}
				flexItem={flexItem}
				variant={variant}
				sx={{ mt: 2.5, mb: 2.5, ...sx }}
				{...props}
			/>
		);
	},
);

export default memo(MDivider);
