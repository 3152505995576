import React from 'react';
import clsx from 'clsx';

import { IconMdClose } from '../../../icons/components';

import styles from './styles.module.css';

export const Tag = ({ label, disabled = false, onClick, hasDelete = true, onDelete }) => {
  return (
    <div
      className={clsx(`d-flex align-items-center ${styles.item}`, {
        [styles.hasDelete]: hasDelete,
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      {label}

      {!disabled && (
        <div
          className={styles.removeButton}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          {hasDelete && <IconMdClose />}
        </div>
      )}
    </div>
  );
};
