import React, { useState, useRef } from "react";
import { useDrawerSearch } from "shared-modules/routes-module";
import { useTranslations } from "shared-modules/translation-module";

import SuggestionsListComponent from "../SuggestionsListComponent";
import { MMenu, MMenuItem, MButton, MBox } from "../../../material";
import { IconMdSearch } from "../../../icons/components";
import styles from "./styles.module.css";
import { useEffect } from "react";

export const MainSearch = (props) => {
  const { history } = props;
  const { t } = useTranslations();
  const mainSearchInputRef = useRef();
  const drawerSearche = useDrawerSearch(t);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [input, setInput] = useState("");

  const onChange = async (e) => {
    const inputVal = e.target.value;
    var serchMethod = await drawerSearche;

    var serchedPages = serchMethod(inputVal).map((pageWithModuleName) => {
      const name = pageWithModuleName.label
        .toLocaleLowerCase()
        .replace(inputVal.toLocaleLowerCase(), "-")
        .split(/(-)/)
        .map((o) =>
          o === "-"
            ? `<span>${inputVal.toLocaleLowerCase()}</span>`
            : `<span style="opacity: 0.3"}>${o}</span>`
        )
        .join("");
      return {
        name: name,
        normalName: pageWithModuleName.label,
        module: pageWithModuleName.moduleName,
        icon: pageWithModuleName["icon"],
        url: pageWithModuleName.url,
      };
    });

    setFilteredSuggestions(serchedPages);

    setInput(inputVal);
    setShowSuggestions(true);
  };
  

  const onKeyDownChange = (e) => {
    if(e.key === "Enter") {
      setIsEnter(true);
      if(filteredSuggestions.length === 0 || !filteredSuggestions) return
      setAnchorEl(null);
      setShowSuggestions(false);
      history.push(filteredSuggestions[0]?.url);
      setInput('')
    }
  }

  const chooseField = () => {
    setFilteredSuggestions([]);
    setInput('');
    setAnchorEl(null);
    setShowSuggestions(false);
  };

  const inputAutoFocus = () => setTimeout(() => mainSearchInputRef.current.focus(), 10);

function handleArrow (e) {
  if(e.key === "Enter") {
    setIsEnter(true);
    if(filteredSuggestions.length === 0 || !filteredSuggestions) return
    setAnchorEl(null);
    setShowSuggestions(false);
    history.push(filteredSuggestions[activeIndex]?.url);
    setInput('')
  }

  mainSearchInputRef.current?.focus();
  //up
  if(e.keyCode === 38) {
    setActiveIndex(activeIndex => {
      let nextIndex = activeIndex-1;
      if(nextIndex < 0) {
        nextIndex = 0;
        mainSearchInputRef.current?.scrollIntoView();
      } else {
        if(document.getElementsByClassName("active-search-suggestion")) document.getElementsByClassName("active-search-suggestion")[0]?.scrollIntoView();
      }
      return nextIndex;
    });
  }
  //down
  if(e.keyCode === 40) {
    if(document.getElementsByClassName("active-search-suggestion")) document.getElementsByClassName("active-search-suggestion")[0]?.scrollIntoView();
    setActiveIndex(activeIndex => {
      let nextIndex = activeIndex+1;
      if(nextIndex > filteredSuggestions?.length-1) {
        nextIndex = 0;
        mainSearchInputRef.current?.scrollIntoView();
      }
      return nextIndex;
    });
  }
};

  useEffect(() => {
    if(anchorEl !== null) setActiveIndex(0);
  }, [anchorEl])

  useEffect(() => {
    document.addEventListener("keypress", (e) => e.key === "\x06" ? setAnchorEl(document.getElementById("main-search-dropdown")) : null)
  }, [])

  return (
    <div onKeyDown={handleArrow}>
      <div
        aria-controls="main-search-dropdown"
        id="main-search-dropdown"
        aria-haspopup="true"
        onClick={(event) => {
          inputAutoFocus();
          setAnchorEl(event.currentTarget);
        }}
        className={`${styles.mainSearchBtn} ${
          Boolean(anchorEl) ? styles.visible : ""
        }`}
      >
        <div className={styles.mainSearchBtnIcon}>
          <IconMdSearch />
        </div>
        <div className={`${styles.mainSearchBtnTitle} f-bold`}>
          {t["search"]}
        </div>
      </div>
      <MMenu
        id="main-search-dropdown"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        className={styles.userInfoDropdown}
      >
        <MMenuItem>
          <MBox className={styles.mainSearchForm}>
            <IconMdSearch className={styles.searchInputIcon} />
            <input
              type="text"
              label={t["search"]}
              name="mainSearch"
              autoComplete="off"
              onChange={onChange}
              value={input}
              placeholder={t["search"]}
              ref={mainSearchInputRef}
              className={styles.searchInput}
              onKeyDown={onKeyDownChange}
            />
            <MButton className={`${styles.searchInputBtn} f-bold`} disabled={!Boolean(filteredSuggestions.length)} onClick={() => {
              if(filteredSuggestions.length <= 0) return;
              history.push(filteredSuggestions[activeIndex]?.url);
              setAnchorEl(null);
              setInput('');
            }}>
              {/* this is shit */}
              <span style={{ opacity: 0, width: 0, overflow: "hidden" }}>`</span>
              {t["go"]}
            </MButton>
          </MBox>
        </MMenuItem>
        {showSuggestions && input && (
          <SuggestionsListComponent
            filteredSuggestions={filteredSuggestions}
            chooseField={chooseField}
            history={history}
            activeIndex={activeIndex}
          />
        )}
      </MMenu>
    </div>
  );
};

export default MainSearch;