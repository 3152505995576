import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Observer } from 'mobx-react';

import { useDrawerRoutes } from 'shared-modules/routes-module';
import { useTranslations } from 'shared-modules/translation-module';
import { UserManagerStore } from 'shared-modules/user-manager-module';
import { AuthLocalStorageStore } from 'shared-modules/local-storage-module';
import { ApiManager } from 'shared-modules/api-manager-module';

import {
  MBox,
  MBreadcrumbs,
  MLink,
  UserButton,
  OptionsButton,
  MainSearch,
  IconMdMenu,
  MuseMediaQuery,
} from 'shared-modules/ui-lib-module';

import { DrawerStore } from '../../Store/DrawerStore';
import { entryStoreContext } from 'shared-modules/shared-store-module';
import imgLogo from 'shared-modules/ui-lib-module/assets/img/logo-white.svg';

import styles from './styles.module.css';

import useNotificationsHook from 'shared-modules/notification-logic-module/LiveNotifications/useNotificationsHook';

function Header() {
  useNotificationsHook();

  const mediaQueryXXL = MuseMediaQuery('xxl');
  const mediaQueryLG = MuseMediaQuery('lg');
  const { t } = useTranslations();
  const history = useHistory();

  const routes = useDrawerRoutes(t, false);
  const entryContext = useContext(entryStoreContext);
  const drawerContext = useContext(DrawerStore);
  const userManagerContext = useContext(UserManagerStore);
  const authLocalStorageContext = new AuthLocalStorageStore();

  const [allRoutes, setAllRoutes] = useState([]);

  let pathName = history.location.pathname;
  let pathArr = pathName.split('/').filter((o) => o !== '');
  let outPath = '';

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const showMenu = () => {
    drawerContext.setShowDrawerState(true);
    drawerContext.setDrawerPositionState(false);
  };
  const showRespMenu = () => {
    drawerContext.setShowRespDrawerState(true);
  };

  const getListFromChildes = (arr, name = 'children') => {
    let list = [];

    const recures = (arr, list, name) => {
      if (arr?.length > 0) {
        return arr.map((company) => {
          if (company && company[name])
            if (company[name]?.length > 0) {
              list.push(company);
              return recures(company[name], list, name);
            } else {
              return list.push(company);
            }
        });
      }
    };
    recures(arr, list, name);
    return list;
  };

  const backToHq = async () => {
    let changedToken = authLocalStorageContext.replaceUserTokeWithHQToken();
    if (!changedToken) {
      userManagerContext.logOut();
      return;
    }
    userManagerContext.initialEmpty();
    ApiManager.setAuthorizationToken(authLocalStorageContext.getUserToken());
    let user = await entryContext.getUser();
    if (!user) userManagerContext.logOut();
    else {
      userManagerContext.setUser(user?.data?.user, user?.data?.cmsLocalStorage);
      userManagerContext.initial();
    }
  };

  const getPageTitle = () => {
    return pathArr.length === 0
      ? t['dashboard']
      : t[pathArr[0]] === undefined
      ? capitalizeFirstLetter(pathArr[0])
      : t[pathArr[0]];
  };

  let isMounted = true;

  useEffect(() => {
    routes.then((route) => {
      if (isMounted) {
        setAllRoutes(
          getListFromChildes(route).filter((o) => o.moduleKeyFromServer !== 'D4tvJoI4HEmmoJ2un7LNVg')
        );
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Observer
      render={() => {
        return (
          <MBox className={styles.mainHeader}>
            {!mediaQueryLG && (
              <IconMdMenu
                className={`custom-icon dark-green ${styles.burgerIcon} ${
                  !drawerContext.getDrawerPositionState() ? 'hide' : ''
                }`}
                onClick={showMenu}
              />
            )}
            <div className="row">
              <div className="col-5 d-flex align-items-center">
                <div className="d-block d-xl-flex align-items-center">
                  {!mediaQueryLG && <h3 className={`${styles.pageTitle} f-bold`}>{getPageTitle()}</h3>}
                  {mediaQueryXXL && (
                    <MBreadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
                      <MLink
                        component={Link}
                        to="/"
                        className={`${pathName === '/' ? styles.disabled : ''} "f-bold"`}
                      >
                        {t['home']}
                      </MLink>
                      {pathName.length > 1
                        ? pathArr.map((path, i, arr) => {
                            let url = '';
                            pathArr.map((o) => (outPath = url += '/' + o));
                            return (
                              <MLink
                                className={`${
                                  allRoutes.find((o) => o.to !== outPath) ? styles.disabled : ''
                                } "f-bold"`}
                                key={i}
                                component={Link}
                                to={i === pathArr.length - 1 ? pathName : outPath}
                                aria-current={i === arr.length && 'page'}
                              >
                                {t[path] ?? path}
                              </MLink>
                            );
                          })
                        : null}
                    </MBreadcrumbs>
                  )}
                  {mediaQueryLG && (
                    <React.Fragment>
                      <IconMdMenu
                        className={`custom-icon white big ${styles.respBurgerIcon}`}
                        onClick={showRespMenu}
                      />
                      <div className={styles.respLogo} onClick={() => history.push("/")} >
                        <img src={imgLogo} alt="" />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-end">
                <Observer render={() => <MainSearch history={history} />} />
                <OptionsButton history={history} />
                <UserButton history={history} backToHQ={backToHq} key={userManagerContext.user} />
              </div>
            </div>
          </MBox>
        );
      }}
    />
  );
}

export default Header;
