import React from "react";
import { IconMdPerson } from "../../../../icons/components";

export const TableColorBoxComponent  = ({item}) => {
  return (
    item.colorBox ? (
      <div className={'color-box'} style={{backgroundColor: item.colorBox}}></div>
    ) : <></>
  );
};

export default TableColorBoxComponent;
