import React from "react";

import styles from "./styles.module.css";

export const NewAndOldPrices = ({ price, newPrice, currency="", alignRow = false }) => {
  return (
    <div className={alignRow ? styles.priceChangeContainer : ""}>
      {newPrice && <p>{newPrice} {currency}</p>} 
      {price &&  <p className={styles.priceChangeOld}>{price} {currency}</p>} 
    </div>
  );
};
