import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
// import { ApiManager } from 'shared-modules/api-manager-module';

import mobxRouting from '../historyStore';

export const TabClasStore = class {
  urlKey = 'tab';

  defaultTab = null;
  currentTab = this.defaultTab;
  setInUrl = true;
  tabsKeys = [];

  init = (tabs) => {
    let params = new URLSearchParams(window.location.search);
    if (params.get(this.urlKey) && params.get(this.urlKey) === this.currentTab) return;
    this.currentTab =
      tabs?.find((o) => o?.tab === params.get(this.urlKey))?.tab ?? tabs?.find((o) => o?.isDefault)?.tab;
    this.defaultTab = tabs?.find((o) => o?.isDefault)?.tab;

    this.tabsKeys = tabs?.map(({ tab }) => tab);
  };

  unMountTabs = () => (this.currentTab = '');

  onTabChangeClick = (event, newValue) => {
    if (newValue === this.currentTab) return;
    this.currentTab = newValue;
  };

  setTab = (newTab) => {
    this.currentTab = newTab;
  };

  getTabFromUrl = () => new URLSearchParams(window.location.search).get(this.urlKey) ?? this.defaultTab;

  getNextTab = () => {
    const currenTabIndex = this.tabsKeys.findIndex((tabKey) => tabKey === this.currentTab);

    const isLastTab = this.tabsKeys.length - 1 === currenTabIndex;
    if (isLastTab || currenTabIndex < 0) return this.currentTab;

    return this.tabsKeys[currenTabIndex + 1];
  };

  goNextTab = () => {
    this.currentTab = this.getNextTab();
  };

  isCurrentTab = (tabName) => {
    return this.currentTab === tabName;
  };

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.currentTab,
      async (code) => {
        let params = new URLSearchParams(window.location.search);
        if (this.currentTab) {
          params.set(this.urlKey, code);
          mobxRouting.history.replace({ search: params?.toString() ?? '' });
        } else {
          params.delete(this.urlKey);
          mobxRouting.history.replace({ search: params?.toString() ?? '' });
        }
      }
    );
  }
};

export const TabStore = createContext(new TabClasStore());
