//create languages for specifications module
let data = {
    "products": "Products",
    "product": "Product",
    "productName": "Product Name",
    "coverPhoto": "Cover Photo",
    "hoverPhoto": "Hover Photo",
    "addIntegrationInput": "Add integration input",
}

export default data;