//create languages for logs module
let data = {
    "offerName": "Offer Name",
    "offers-list": "Offers List",
    "addNewOffer": "Add New Offer",
    "updateOffer": "Update Offer",
    "offerType": "Offer Type",
    "chooseCompany": "Choose Company",
    "addGroup": "Add Group",
    "unRestrictedGroups": "unrestricted group",
    "restrictedGroups": "restricted group",
    "whatToDoInCaseOfStockOut": "What to do in case of stock out",
    "offerPublished": "Offer Published",
    "offerUnPublished": "Offer unPublished",
    "youShouldCheckType": "You should check type",
    "offerStartEnddate": "Offer Start - End date",
    "uploadCover": "Upload Cover",
    "uploadHover": "Upload Hover",
    "mainPhoto": "Main Photo",
    "shortDescription": "Short Description",
    "oldPrice": "Old Price",
    "newPrice": "New Price",
    "qty": "Qty",
    "minus": "Minus",
    "addPrimaryCategories": "add Primary Categories",
    "whetherOrNotToDisplayOfferInTheList": "Whether Or Not To Display Offer In The List",
    "addPrimaryCategorie": "Add Primary Categorie",
    "allCategories": "All Categories",
    "addonType": "Addon Type",
    "perCategories": "per Categories",
    "perProducts": "Per Products",
    "primaryCategory": "add primary Categories",
    "secondaryCategory": "add secondary Categories",
    "optionalGiftOffer": "add optional offer",
    "giftOffer": "add gift offer",
    "maxQuantity" : "Max Quantity",
    "minQuantity" : "Min Quantity",
    "youMustChoosePrimaryCategory" : "You Must Choose Primary Category",
    "uniqueNewPriceQTY" : "uniqueNewPriceQTY",
    "add_gift_categories": "add Gift Categories"
}

export default data;