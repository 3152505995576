// b2b drafts translations
let data = {
	"draftAgreements": "დრაფტ ხელშეკრულებები",
	"uploadDate": "ატვირთვის თარიღი",
	"addNewSelector": "ახალი სელექტორის დამატება",
	"date": "თარიღი",
	"chooseType": "ტიპის არჩევა",
	"fileUpload": "ფაილის ატვირთვა",
	"selectors": "სელექტორები",
	"selectorName": "სელექტორის სახელი",
	"editDraft": "დრაფტის განახლება",
	"addNewDraft": "ახალი დრაფტის დამატება",
	"reminderAddedSuccessfully": "შეხსენება დაემატა წარმატებით",
};

export default data;