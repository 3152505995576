import React, {useState, useEffect} from "react";
import { Observer } from "mobx-react";
import moment from 'moment';

import { useTranslations } from "shared-modules/translation-module";

import { MFormControlLabel, MRadio, MRadioGroup, MValidatorForm } from "../../../material";
import { MTransitionGroup } from "../../../transitions";
import { MDivider } from './../../../material/components/Divider';
import { MToast } from "../Toast";
import CustomDateTimePicker from "../CustomDateTimePicker";

import styles from "./styles.module.css";
import CustomExpandConteiner from "../CustomExpandContainer";


export const Publish = ({onDataChange = () => {}, data = undefined, disabled = false, disableStartTimeInput = false, disableEndTimeInput = false}) => {
  const [isExpandPublish, setIsExpandPublish] = useState(true);
  const { t } = useTranslations();
  const [formData, setFormData] = useState(data ?? 
    {
      isPublishedFromResponse: null,
      isPublished: null, //bool
      publishStartTime: null, //string
      publishEndTime: null, //string

      visibilityText: "public", //string
  });

  const validatePublishTime = (date) => {
    if(!moment(date).isValid()) {
      return true;
    }
    return moment().diff(date, 'minutes') <= 0;
  };

  useEffect(() => {
    MValidatorForm.addValidationRule('dateValidation', (date) => {
      return validatePublishTime(date)
    })
    MValidatorForm.addValidationRule('valdiateFormat', (date) => {
      return date === true ? true : moment(date).isValid();
    })
  }, [])

  useEffect(() => {
    onDataChange({
      ...formData,
      publishEndTime: formData.isPublished == false ? null : (formData.publishEndTime ? moment(new Date(formData.publishEndTime).setSeconds(0,0)).utc().toISOString() : formData.publishEndTime),
      publishStartTime: formData.isPublished == false ? null : (formData.publishStartTime ? moment(new Date(formData.publishStartTime).setSeconds(0,0)).utc().toISOString() : formData.publishStartTime),
      isPublished: (formData.publishStartTime && formData.isPublished != false) ? null : formData.isPublished
    })
  }, [formData]);
  
  return (
    <div className={styles.sectionWrapper}>
      <MTransitionGroup>
        <Observer render={() => (
          <CustomExpandConteiner 
            title={[t["publish"]]}
            isExspanded={isExpandPublish}
            openCloseFunction={()=> setIsExpandPublish(o => !o)}
          >
            <div className="row">
              <div className="col-4 col-sm-12">
                <div className={styles.visibility}>
                  {`${t['visibility']}: `} 
                  <span>
                    {t[formData.visibilityText]}
                  </span>
                </div>
              </div>
              <div className="col-8 col-md-12">
                <div className="d-flex align-items-center">
                  <MRadioGroup className={`flex-row flex-sm-column ${styles.checkItemsRow}`}>
                    <Observer render={()=>(
                      <MFormControlLabel
                        className={styles.checkItem}
                        control={
                            <MRadio
                              disabled={disabled}
                              className="medium tonal"
                              name="publish"
                              value={true}
                              checked={formData.isPublished || formData.isPublished === null}
                              onClick={() =>  {
                                setFormData(o => ({
                                  ...o,
                                  isPublished: true,
                                  publishEndTime: o.publishEndTime ?? null,
                                  publishStartTime: o.publishStartTime ?? null,
                                  visibilityText: "published",
                                }))
                              }}
                            />
                        }
                        label={t['published']}
                      />
                    )}/>
                    <Observer render={()=>(
                      <MFormControlLabel
                        className={styles.checkItem}
                        control={
                            <MRadio
                              disabled={disabled}
                              className="medium tonal"
                              name="Unpublished"
                              checked={formData.isPublished === false}
                              onClick={() => {
                                setFormData(o => ({
                                  ...o,
                                  isPublished: false,
                                  visibilityText: "unpublished",
                                }))
                              }}
                            />
                        }
                        label={t['unpublished']}
                      />
                    )}/>
                  </MRadioGroup>
                </div>
              </div>
              {(formData.isPublished || formData.isPublished === null)
                ? 
                  <>
                    <div className="col-12"><MDivider/></div>
                    <div className="col-6 col-sm-12">
                      <CustomDateTimePicker
                        disabled={disabled || disableStartTimeInput || formData.isPublishedFromResponse}
                        label={t["publishStartTime"]}
                        name='publishStartTime'
                        validators={['dateValidation', 'valdiateFormat']}
                        errorMessages={['youCantChoosePastInPublish', "invalidFormat"]}
                        date={formData.publishStartTime ? moment.utc(formData.publishStartTime).local() : null}
                        onChange={date => setFormData(o => ({...o, publishStartTime: date}))}
                      />
                    </div>
                    <div className="col-6 col-sm-12">
                      <CustomDateTimePicker
                        disabled={disabled || disableEndTimeInput}
                        label={t["publishEndTime"]}
                        validators={['dateValidation', 'valdiateFormat']}
                        errorMessages={['youCantChoosePastInPublish', 'invalidFormat']}
                        name='publishEndTime'
                        date={formData.publishEndTime ? moment.utc(formData.publishEndTime).local() : null}
                        onChange={date => setFormData(o => ({...o, publishEndTime: date}))}
                      />
                    </div>
                  </>
                : null
              }
            </div>
          </CustomExpandConteiner>
        )}/>
      </MTransitionGroup> 
    </div>
  )
};

export default Publish;