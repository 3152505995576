import Axios from 'axios';
import { makeAutoObservable, observable } from 'mobx';

import Config from '../config';
import handleException from '../private-methods/handleException';
import wrapToMeamaResponse from '../private-methods/wrapToMeamaResponse';
import requestRefreshToken from '../private-methods/refreshToken';
import logout from '../private-methods/logOut';
import { MToast } from 'shared-modules/ui-lib-module';
import { AuthLocalStorageStore } from 'shared-modules/local-storage-module';
import * as Sentry from '@sentry/browser';

const singleton = Symbol();
const singletonEnforcer = Symbol();

export const API = class ApiManager {
  static lstRequestTime = null;
  static isGettingRefreshToken = false;
  static arr = [];

  constructor(enforcer) {
    //check if the enforcer is singletoneEnforcer.
    if (enforcer != singletonEnforcer) throw 'Cannot construct singleton';
    //make this class observable.
    makeAutoObservable(this, { isRequestError: observable });

    const authLocalStorageStore = new AuthLocalStorageStore();
    // axios configuration.
    this.Config = Config;
    const token = authLocalStorageStore.getUserToken(); // get token form localStorage if not exsist set empty string
    Axios.defaults.headers.common.Authorization =
      token === undefined || token === null ? '' : `Bearer ${authLocalStorageStore.getUserToken()}`;
    Axios.interceptors.request.use(
      async (request) => {
        // if(ApiManager.arr.some(o => o.url === request.url)) {
        //   ApiManager.arr = ApiManager.arr.filter(o => o.url !== request.url);
        //   console.log(ApiManager.arr)
        //   const cancelToken = Axios.CancelToken;
        //   const source = cancelToken.source();
        //   request.cancelToken = source.token;
        //   source.cancel("Request canceled");
        //   // removePending(request);
        //   // Axios.delete(request.url, request);
        //   // request.url = '/';
        //   return request;

        //   // return;
        // }

        if (request.url === '/RefreshToken' && !ApiManager.isGettingRefreshToken) {
          ApiManager.isGettingRefreshToken = true;
          return request;
        }

        if (request.url === '/RefreshToken' && ApiManager.isGettingRefreshToken) {
          return;
        }

        ApiManager.arr.push(request);

        return request;
      },
      async (ex) => {
        return ex;
      }
    );
    //exception http status code catcher
    Axios.interceptors.response.use(
      async (response) => {
        if (response?.request?.responseURL?.includes('/RefreshToken') && ApiManager.isGettingRefreshToken) {
          ApiManager.isGettingRefreshToken = false;
        }
        return response;
      },
      async (error) => {
        Sentry.captureException(error);
        //if refreshToken request fails logout user
        if (error.config?.url === '/RefreshToken') logout();

        //if getUser feild
        if (error.config?.url === '/api/UIData/GetUserWithUIData' && error?.response?.status === 400)
          logout();

        //if keep me is "true" makes string to boolean for next ifs
        const isKeepMe = authLocalStorageStore.getIsKeepMe() === 'true';
        //if keep me is false and 401 happens logout user
        if (!isKeepMe && error?.response?.status === 401) logout();
        //if keep me is true and 401 happens try get new user token and refresh token
        if (isKeepMe && error?.response?.status === 401)
          return await requestRefreshToken(error.config, Axios);

        //http Error handling ----------------------------------------------------------------
        //check if server response is 400 or 500
        if (error?.response?.status === 400 || error?.response?.status === 500);
        // check if server handle error and display it
        if (error?.response?.data?.isError) MToast.errorToast(error?.response?.data?.message);
        //if connection is lost
        if (error?.message == 'Network Error') MToast.errorToast(error?.message);
        //END http Error handling ----------------------------------------------------------------

        //return error from axios
        return Promise.reject(error);
      }
    );
    //set baseUrl...
    Axios.defaults.baseURL = this.Config.url;
  }

  //get class instance
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiManager(singletonEnforcer);
      return ApiManager.instance;
    } else {
      return this[singleton];
    }
  }

  initLanguage = () => {
    Axios.defaults.headers.common.ResponseLanguage = localStorage?.i18nextLng ?? '';
  };

  getData = async (params, config) => {
    try {
      this.initLanguage();
      var response = await Axios.get(params, config);
      return wrapToMeamaResponse(response);
    } catch (ex) {
      return handleException(ex);
    }
  };

  postEmptyData = async (params) => {
    try {
      this.initLanguage();
      var response = await Axios.post(params);
      return wrapToMeamaResponse(response);
    } catch (error) {
      this.isRequestError = true;
      return handleException(error);
    }
  };

  postData = async (params, data) => {
    try {
      this.initLanguage();
      var response = await Axios.post(params, data);
      return wrapToMeamaResponse(response);
    } catch (error) {
      return handleException(error);
    }
  };

  postMultiFormData = async (params, data) => {
    try {
      this.initLanguage();
      var response = await Axios.post(params, data, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      return wrapToMeamaResponse(response);
    } catch (error) {
      return handleException(error);
    }
  };

  setConfig = (config) => {
    this.Config = config;
  };

  //Set token to request headers
  setAuthorizationToken = (token) =>
    (Axios.defaults.headers.common.Authorization =
      token === undefined || token === null ? '' : `Bearer ${token}`);
}.instance;
