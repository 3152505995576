import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
import mobxRouting from '../historyStore';

export const ViewsStore = createContext(
  new (class {
    urlKey = 'view';
    currentViewComponent = undefined;
    currentDefaultName = '';
    currentViewName = '';

    setDefaultView = (views) => {
      var defaultView = views?.find((o) => o?.isDefaultView); //views[views.length - 1]?.currentView;
      this.currentDefaultName = defaultView?.viewName?.toLowerCase();
      if (mobxRouting?.location?.search) {
        const search = new URLSearchParams(mobxRouting.location.search);
        if (search) {
          const valFromUrl = search.get(this.urlKey);
          if (valFromUrl) {
            let currentView =
              views?.find((o) => o.viewName?.toLowerCase() === valFromUrl + 'view') ?? undefined;
            this.currentViewName = currentView
              ? valFromUrl + 'view'
              : views.find((view) => view.isDefaultView).viewName?.toLowerCase();
            this.currentViewComponent = currentView
              ? currentView?.currentView
              : views.find((view) => view.isDefaultView)?.currentView;
            return;
          }
        }
      }

      this.currentViewName = this.currentDefaultName;
      this.currentViewComponent = defaultView?.currentView;
    };

    setView = (view) => {
      this.currentViewComponent = view?.currentView ?? undefined;
      if (this.currentViewComponent && this.currentViewComponent?.type)
        this.currentViewName = view?.viewName?.toLowerCase();
    };

    constructor() {
      makeAutoObservable(this);
      reaction(
        () => this.currentViewName,
        (currentViewName) => {
          const currentViewParsedName = currentViewName?.toLowerCase()?.replace('view', '');
          const isDefaultView = currentViewName?.toLowerCase() === this.currentDefaultName?.toLowerCase();
          let params = new URLSearchParams(window.location.search);
          if (isDefaultView) {
            params.delete(this.urlKey);
            mobxRouting.replace({ search: params?.toString() ?? '' });
          } else {
            params.set(this.urlKey, currentViewParsedName);
            mobxRouting.replace({ search: params?.toString() });
          }
        }
      );
    }
  })()
);
