import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { Modules } from 'shared-modules/modules-for-routes-module';
import { RoutingStore } from './RoutingStore';

export const DrawerStore = class {
  constructor() {
    makeAutoObservable(this);
    //setting routingStore as private variable 
    this.routingModule = new RoutingStore();
    //initializing user manager store as null
    this.userManagerContext = null;
  }

  //setting user manager store from outside as private variavle
  setUserManager = (userManagerContext) => {
    this.userManagerContext = userManagerContext;
  }

  //getting all modules without sended in props name
  getAllModulesWithoutSendedName = (withoutThisModule) => Object.getOwnPropertyNames(new Modules()).filter(o => o !== withoutThisModule);

  //returns default module item without childrens
  makeRootItem = (moduleKey, moduleKeyFromServer, translation) => {
    return { 
        moduleKeyFromServer: moduleKeyFromServer,
        label: translation[moduleKey], // module key gous from module class in @meama/modules-for-routes-module
        to: `/${moduleKey}`, // module key gous from module class in @meama/modules-for-routes-module and setting this as module url
        Icon: "", // you can add module icon but arrow becomes hidden
        children: [
            
        ],
    };
  }

  //add childer in root module element 
  getRoutesByRouter = (currentModuleInDrawer, router, pageKeyFromServer, translation) => {
    router.forEach(route => {
      if(route["drawer"]) {
        currentModuleInDrawer.children.push({
          label: translation[route["name"]], // this is route name
          to: route["url"], //this is route url withour first slash
          Icon: route["icon"], // route icon
          pageKeyFromServer: pageKeyFromServer
        });
      }
    });
    return currentModuleInDrawer;
  }


  //getting sub module routes and check if user has access on routes
  getSubRoutes = (subModulePageKeys, currentModuleInDrawer, loadedRootModule, pageKeyOrSubModuleKey, translation) => {
    //this is sub modules keys
    var item = {
      label: translation[loadedRootModule[pageKeyOrSubModuleKey]["ModuleName"]], // this is sub route name
      to: loadedRootModule[pageKeyOrSubModuleKey]["SubRoute"],// this is sub route
      Icon: loadedRootModule[pageKeyOrSubModuleKey]["Icon"].icon, // sub route icon
      //pages inside sub module
      children: []
    };

    subModulePageKeys.forEach(subPageKey => {
      var routes = loadedRootModule[pageKeyOrSubModuleKey][subPageKey]["Route"];
      routes.forEach(o => {
        if(this.canAddThisPageOrModuleInDrawer(loadedRootModule[pageKeyOrSubModuleKey], subPageKey) || this.userManagerContext.hasAccessOnAction(loadedRootModule["ModuleKey"])) {
          if(o["drawer"]) {
            item.children.push({ pageKeyFromServer: loadedRootModule[pageKeyOrSubModuleKey][subPageKey]['PageKey'], to: o["url"], Icon: o["icon"], label: translation[o['name']] });
          }
        }
      })
    })
            
    currentModuleInDrawer.children.push(item);
    return currentModuleInDrawer;
  }

  // checking if user has access on page claim
  getTrueIfSomePageClaimExsists = (claims) => Object.getOwnPropertyNames(claims).map((name) => claims[name]["Key"]).map(pageClaim => this.userManagerContext.hasAccessOnAction(pageClaim)).find(pageClaim => pageClaim) !== undefined;

  //checking if user has access on module or page or page claim
  canAddThisPageOrModuleInDrawer = (loadedRootModule, pageKeyOrSubModuleKey) => {
    const hasAccessOnModuleRoot = this.userManagerContext.hasAccessOnAction(loadedRootModule["ModuleKey"]);
    const hasAccessOnPageRoot = this.userManagerContext.hasAccessOnAction(loadedRootModule[pageKeyOrSubModuleKey]["PageKey"]);
    const hasAccessOnPageClaimsRoot = this.getTrueIfSomePageClaimExsists(loadedRootModule[pageKeyOrSubModuleKey]["Accesses"]);
    return hasAccessOnModuleRoot || hasAccessOnPageRoot || hasAccessOnPageClaimsRoot;
  }

  //this is root method this returns grupped drawer items
  getModuleWithGruppedSubRoutes = async (moduleKey, translation) => {
    //all modules
    const lazyModule = Object.getOwnPropertyDescriptor(new Modules(), moduleKey)
    if(!lazyModule) return null; // check if this exsists
    const moduleProps = await lazyModule?.value; //load lazy module form modules class 
    const loadedRootModule =  moduleProps[Object.getOwnPropertyNames(moduleProps)[1]]; // take loaded modules class instance
    const pageKaysWithSubModules = this.routingModule.getModulePageKeys(loadedRootModule); // here is all pages and sub module names

    //this array returns drawer items
    var drawerUrlsArr = [];
    
    pageKaysWithSubModules.forEach(pageKeyOrSubModuleKey => {
      // checking if module has access on drawer if not skipping this module
        if(loadedRootModule["drawer"]) {
          //taking this module drawer root item 
          var currentModuleInDrawer = drawerUrlsArr.find(o => o.to === `/${moduleKey}`);
          //checking if is undefined then add root item without childrens and setting again currentModuleInDrawer
          if(!currentModuleInDrawer) {
            drawerUrlsArr.push(this.makeRootItem(moduleKey, loadedRootModule["ModuleKey"], translation));
            currentModuleInDrawer = drawerUrlsArr.find(o => o.to === `/${moduleKey}`);
          }

          //checking if is sub module if not add as root item child
          if(pageKeyOrSubModuleKey?.includes('_subSelection')) {
            currentModuleInDrawer = this.getSubRoutes(this.routingModule.getModulePageKeys(loadedRootModule[pageKeyOrSubModuleKey]), currentModuleInDrawer, loadedRootModule, pageKeyOrSubModuleKey, translation);
          }
          else {
            if(this.canAddThisPageOrModuleInDrawer(loadedRootModule, pageKeyOrSubModuleKey)) currentModuleInDrawer = this.getRoutesByRouter(currentModuleInDrawer, loadedRootModule[pageKeyOrSubModuleKey]["Route"], loadedRootModule[pageKeyOrSubModuleKey]["PageKey"], translation)
          }
        }
    })

    // //filtering root module item if it hasn't children remove this module from drawerUrlsArr
    // drawerUrlsArr = drawerUrlsArr.filter(o => o.children.length !== 0);
    return drawerUrlsArr;
  }


  drawerChangeEvent = undefined;

  setDrawerChangeEvent = (event) => {
    if(typeof(event) === "function") this.drawerChangeEvent = event;
  }

};

export default createContext(new DrawerStore());