import React, { useContext, useEffect } from "react";
import { observer, Observer } from "mobx-react";
import { useHistory, useLocation } from "react-router";

import imgLogo from "shared-modules/ui-lib-module/assets/img/logo-white.svg";
import {
  IconMdMenuOpen,
  IconMdClose,
  IconMdOutlineDragIndicator,
  MuseMediaQuery,
} from "shared-modules/ui-lib-module";
import { useTranslations } from "shared-modules/translation-module";
import { DrawerStore } from "../../Store/DrawerStore";
import Sidebar from "./Sidebar";

import styles from "./styles.module.css";

function Drawer(match) {
  const mediaQueryLG = MuseMediaQuery("lg");
  const mediaQueryXS = MuseMediaQuery("xs");

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslations();
  const drawerContext = useContext(DrawerStore);

  const drawerPosition = () => {
    drawerContext.setDrawerPositionState(true);
  };

  const showMenu = () => {
    drawerContext.setShowDrawerState(true);
    if (drawerContext.getDrawerPositionState())
      drawerContext.setHideDrawerState(true);
    else drawerContext.setHideDrawerState(false);
  };
  const hideMenu = () => {
    drawerContext.setShowDrawerState(false);
    drawerContext.setHideDrawerState(true);
    setTimeout(() => drawerContext.setDrawerPositionState(true), 500);
  };
  const hideRespMenu = () => {
    drawerContext.setShowRespDrawerState(false);
  };

  useEffect(() => {
    showMenu();
    drawerContext.setDrawerPositionState(false);
  }, [mediaQueryLG]);

  useEffect(() => {
    hideRespMenu()
  }, [location.pathname]);

  return (
    <Observer
      render={() => {
        return (
          <React.Fragment>
            <div
              className={`${styles.drawer} ${
                !drawerContext.getShowDrawerState()
                  ? styles.hideDrawer + " hideDrawer"
                  : ""
              } ${
                drawerContext.getShowRespDrawerState()
                  ? styles.showRespDrawer
                  : ""
              }`}
              onMouseOver={
                !mediaQueryLG && drawerContext.getDrawerPositionState()
                  ? showMenu
                  : () => {}
              }
              onMouseOut={
                !mediaQueryLG && drawerContext.getDrawerPositionState()
                  ? hideMenu
                  : () => {}
              }
            >
              <div className={styles.fixedBox}>
                <div className={styles.inner}>
                  {!mediaQueryLG && (
                    <div className={styles.menuHead}>
                      <div
                        className={styles.logo}
                        onClick={() => history.push("/")}
                      >
                        <img src={imgLogo} alt="" />
                      </div>
                      <div
                        className={`${styles.burgerBtn} ${
                          drawerContext.getDrawerPositionState()
                            ? styles.hide
                            : ""
                        }`}
                        onClick={
                          !drawerContext.getShowDrawerState()
                            ? drawerPosition
                            : hideMenu
                        }
                      >
                        <IconMdMenuOpen className={styles.burgerIcon} />
                      </div>
                    </div>
                  )}
                  <Sidebar hideDrawer={!drawerContext.getShowDrawerState()} />
                  {!mediaQueryLG && (
                    <div
                      className={`${styles.menuOptionsBtn} f-upper`}
                      onClick={() => history.push("/menu-options")}
                    >
                      <IconMdOutlineDragIndicator
                        className={styles.menuOptionsBtnIcon}
                      />
                      <span className={styles.menuOptionsBtnText}>
                        {t["menuoptions"]}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {mediaQueryLG && (
              <div
                className={`${styles.backdrop} ${
                  drawerContext.getShowRespDrawerState()
                    ? styles.showDrawerBackdrop
                    : ""
                }`}
                onClick={hideRespMenu}
              >
                {mediaQueryXS && (
                  <IconMdClose className={styles.respMenuCloseIcon} />
                )}
              </div>
            )}
          </React.Fragment>
        );
      }}
    />
  );
}

export default observer(Drawer);
