import React from "react";
import styles from "./styles.module.css";

export const ChangeViews = ({ currentViewName, handleView, views }) => {
  return (
    <React.Fragment key={currentViewName}>
      {views.map((view, index) => {
        const Icon = view.icon;
        return (
          <Icon
            key={index}
            className={`custom-icon light-green ${styles.icon} ${
              currentViewName == view?.viewName?.toLowerCase() ? "active" : ""
            }`}
            onClick={() => handleView(view)}
          />
        );
      })}
    </React.Fragment>
  );
};
export default ChangeViews;
