import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslations } from 'shared-modules/translation-module';
import NoDataMessage from '../NoDataMessage';
import ListItem from './components/ListItem';
import { reorder } from './helpers';
import style from './style.module.css';

const DraggableListComponent = ({
  data = [],
  dragDisabled = false,
  expandHandler,
  dragHandler,
  withIndexes = true,
  detailsButton,
  editButton,
  deleteButton,
  retryButton,
  downloadButton,
  detailsHandler,
  editHandler,
  deleteHandler,
  retryHandler,
  downloadHandler,
  // only for recursion
  _isRecursiveChild = false,
}) => {
  const { t } = useTranslations();
  const [list, setList] = useState([...data] ?? []);

  useEffect(() => {
    setList([...data]);
  }, [data]);

  const buttonsLength = useMemo(() => {
    return (
      (editButton ? 1 : 0) +
      (deleteButton ? 1 : 0) +
      (detailsButton ? 1 : 0) +
      (retryButton ? 1 : 0) +
      (downloadButton ? 1 : 0)
    );
  }, []);

  /*Drag*/
  const onDragStart = () => {
    // vibrate on mobile device when drag started
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const draggableId = result.draggableId;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let rankBeforeId = null;
    let rankAfterId = null;

    let oldIndex = list[sourceIndex]?.index; //index from back-end
    let newIndex = list[destinationIndex]?.index; //index from back-end

    if (sourceIndex > destinationIndex) {
      rankBeforeId = list[destinationIndex].id.toString();
    } else {
      rankAfterId = list[destinationIndex].id.toString();
    }

    const newState = reorder(list, result.source.index, result.destination.index);

    setList(newState);

    dragHandler?.({
      draggableId,
      oldIndex,
      newIndex,
      rankBeforeId,
      rankAfterId,
    });
  };
  /*---*/

  if (!data?.length) {
    return (
      <div style={{ margin: '30px 0' }}>
        <NoDataMessage title={t['noData']} />
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className={style.listContainer} style={{ margin: _isRecursiveChild ? 0 : '' }}>
        <Droppable droppableId="mainDroppableList" direction="vertical">
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              className={style.listBody}
              style={{
                padding: _isRecursiveChild ? '8px 0 0 0' : '',
              }}
            >
              {list?.map((listItem, index) => (
                <Draggable
                  isDragDisabled={dragDisabled}
                  key={listItem.id}
                  draggableId={listItem?.id?.toString()}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <ListItem
                      dragDisabled={dragDisabled}
                      withIndexes={withIndexes}
                      _isRecursiveChild={_isRecursiveChild}
                      provided={draggableProvided}
                      snapshot={draggableSnapshot}
                      data={listItem}
                      index={index}
                      expandHandler={expandHandler}
                      dragHandler={dragHandler}
                      buttonsLength={buttonsLength}
                      detailsButton={detailsButton}
                      editButton={editButton}
                      deleteButton={deleteButton}
                      retryButton={retryButton}
                      downloadButton={downloadButton}
                      detailsHandler={detailsHandler}
                      editHandler={editHandler}
                      deleteHandler={deleteHandler}
                      retryHandler={retryHandler}
                      downloadHandler={downloadHandler}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export const DraggableList = React.memo(DraggableListComponent);
