import { useTranslations } from "shared-modules/translation-module";
import React, { useState } from "react";

import {
  IconMdPhotoCamera,
  IconMdFilePresent,
  IconMdOutlineFileDownload,
  IconMdModeEdit,
  IconMdDelete,
} from "../../../icons/components";
import { MButton } from "../../../material";
import PrimarySmallDialog from "../PrimarySmallDialog";
import QuillEditor from "../QuillEditor";

import styles from "./styles.module.css";
import { TimeMethos } from 'shared-modules/custum-extension-methods';
import { useEffect } from "react";

export const Comment = ({ 
  deleteLoading = false,
  canRemove = false,
  canEdit = false,
  canSeeUserDetails = false,  
  replyId,
  commentDesc = "",
  commentAuthorName = "",
  addComemntLocalTime = "",
  fileData = undefined,
  isProfile = ()=>{},
  onChangeCommentDesc=()=>{},
  edithandler=()=>{},
  userDetailHandler=()=>{},
  updateReplyHandler=async()=>{},
  deleteReplyHandler=async()=>{},
}) => {
  const {t} = useTranslations();

  const [showEdtior, setShowEditor] = useState(false);
  const [showEditLoading, setShowEditLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isProfileName, setIsProfileName] = useState(false)

  useEffect(()=>{
    setIsProfileName(isProfile())
    return ()=> {
      setIsProfileName(false)
    }
  },[isProfile])

  return (
    <div>
      <div className={`${styles.commentHead} d-flex align-items-start`}>
        <div className={`${styles.nameAndTime} d-flex align-items-center flex-wrap flex-grow-1`}>
          <div className={`${styles.author} ${(canSeeUserDetails || isProfileName) && styles.cursorPointer} f-bold`} onClick={userDetailHandler}>{commentAuthorName ?? ""}</div>
          <div className={styles.passedTime}>{TimeMethos.getPastTimeString(null, addComemntLocalTime) ?? ""}</div>
        </div>
          {
            canEdit && (
              <IconMdModeEdit className={`${styles.commentIcon} custom-icon black small`} onClick={() => {
                  setShowEditor(true);
                  edithandler(replyId)
                }}
              />
            )
          }
          {
            canRemove && (
              <IconMdDelete className={`${styles.commentIcon} custom-icon black small`} onClick={() => setOpenDeleteModal(true)} />
            )
          }
      </div>
      {!showEdtior 
        ? 
          <div className={styles.comment}  dangerouslySetInnerHTML={{__html: commentDesc}} />
        :  
          <div>
            <QuillEditor
              disabled={!canEdit}
              quillId={`comment-quillEditor-${replyId}`}
              placeholder={"description"}
              value={commentDesc}
              onChange={text => onChangeCommentDesc(text, replyId)} 
            />
            <MButton 
              processing={showEditLoading}
              onClick={async() => {
                setShowEditLoading(true);
                let res = await updateReplyHandler(replyId);
                if(res) {
                  setShowEditor(false);
                }
                setShowEditLoading(false);
            }} >{t['save']}</MButton>
            <MButton className="transparent" onClick={() => setShowEditor(false)}>{t['cencel']}</MButton>
          </div>
      }

      {/* ---- attached files */}
      {fileData && 
        <div className='d-flex flex-wrap'>
          <a className={styles.downloadFileBox}>
            <div className='row no-gutters'>
                <div className='col-3 d-flex flex-column justify-content-center'>
                    {
                      fileData?.file ? <IconMdPhotoCamera className={styles.downloadImageIcon}/>
                      : <IconMdFilePresent className={styles.downloadFileIcon}/>
                    }
                </div>
                <div className='col-9 d-flex flex-column justify-content-center'>
                    <div className={styles.downloadContentWrapper}>
                      <div className={`${styles.fileTitle} f-bold`}>{fileData?.name ?? ""}</div>
                      <IconMdOutlineFileDownload className={styles.downloadIcon}/>
                    </div>
                </div>
            </div>
          </a>
        </div>
      }
      <PrimarySmallDialog
        open={openDeleteModal}
        close={() => setOpenDeleteModal(o => !o)}
        title={`${t['delete']} ${t['comment']}`}
        text={t['suretoDelete']}
        icon={<IconMdDelete className="dialog-main-icon" />}
        buttons={
          <React.Fragment>
            <MButton className="f-bold rounded transparent" onClick={() => setOpenDeleteModal(o => !o)}>{t["cancel"]}</MButton>
            <MButton
              className="f-bold rounded danger"
              processing={deleteLoading}
              onClick={async () => {
                let res = await deleteReplyHandler(replyId);
                if(res) setOpenDeleteModal(o => !o);
              }}
            >
              {t["yes"]} {t["delete"]}
            </MButton>
          </React.Fragment>
        }
      />
    </div>
  );
};
export default Comment;
