import React from 'react';
import { Observer } from 'mobx-react';

import { ComponentLoader } from '../ComponentLoader';

export const PageLoader = ({ children, isLoading, color }) => {
  if (isLoading)
    return (
      <Observer
        key={1}
        render={() => {
          return <ComponentLoader color={color} />;
        }}
      />
    );

  return <Observer key={2} render={() => <React.Fragment key={2}>{children}</React.Fragment>} />;
};

export default PageLoader;
