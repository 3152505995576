import React from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";

import styles from "./styles.module.css";

SwiperCore.use([Pagination]);

export const Slider = ({ children, ...props }) => {
  return (
    <Swiper {...props}>
      {children?.map((item, index) => {
        return <SwiperSlide key={index}>{item}</SwiperSlide>;
      })}
    </Swiper>
  );
};
export default Slider;
