import React, { useState } from "react";
import { Select } from "@material-ui/core";
import "./style.css";
export const MSelect = React.forwardRef((props, ref) => {
  const { MenuProps, disabled, ...localProps } = props;
  return (
    <Select
      innerRef={ref}
      MenuProps={{
        anchorOrigin: { vertical: "center", horizontal: "right" },
        transformOrigin: { vertical: "top", horizontal: "right" },
        getContentAnchorEl: null,
        ...MenuProps,
      }}
      disabled={disabled}
      {...localProps}
    />
  );
});
export default MSelect;
