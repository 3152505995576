//create languages for auth module
let data = {
    "roles": "როლები",
    "role": "როლი",
    "mode": "მოდი",
    "administrator": "ადმინისტრატორი",
    "RoleDetails": "როლის დეტალი",
    "UserList": "მომხმარებლების სია",
    "roleSuccessfulyDeleted": "როლი წარმატებით წაიშალა",
    "roleSuccessfulyUpdated": "როლი წარმატებით გაახლდა",
    "roleUnSuccessfulyUpdated": "როლის გაახლება ვერ მოხერხდა",
    "roleSuccessfulyCreated": "როლი წარმატებით შეიქმნა",
    "roleUnSuccessfulyCreated": "როლის შექმნა ვერ მოხერხდა",
    "deleteUsersFromRoleTitle": "მომხმარებლებზე როლის წაშლა"
}

export default data;