import React from "react";
import { useTranslations } from "shared-modules/translation-module";
import { MIconButton, MTooltip } from "../../../../material/components";
import { IconMdVerified, IconMdPrivacyTip } from "../../../../icons/components";

export const TableVerifyStatusComponent = ({item}) => {
  const { t } = useTranslations();

  return (
    item?.verified === "verified" ? (
      <>
        {item?.label}
        <MTooltip title={t["verified"]} placement="top" arrow disableFocusListener>
          <MIconButton className="verify-icon-btn"><IconMdVerified className="verify-icon verified" /></MIconButton>
        </MTooltip>
      </>
    ) : (
      <>
        {item?.label}
        <MTooltip title={t["unverified"]} placement="top" arrow disableFocusListener>
          <MIconButton className="verify-icon-btn"><IconMdPrivacyTip className="verify-icon unverified" /></MIconButton>
        </MTooltip>
      </>
    )
  );
};

export default TableVerifyStatusComponent;
