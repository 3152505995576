
import moment from 'moment';

export const TimeMethos = class {

  static getPastTimeString = (utcTime, localTime) => {
    let date = new Date();
    let localDate =  utcTime ? moment.utc(utcTime).local().toLocaleString() : localTime;
    let differenceInSecondes = Math.floor((date.getTime() / 1000) - moment(localDate).unix());
    let monthCountInCurrentYear = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    let time;

    if(differenceInSecondes >= (86400*monthCountInCurrentYear*12)) {
      time = `${Math.floor(differenceInSecondes >= (86400*monthCountInCurrentYear*12))} year ago`;
    }
    if(differenceInSecondes >= (86400*monthCountInCurrentYear)) {
      time = `${Math.floor(differenceInSecondes / (86400*monthCountInCurrentYear))} month ago`;
    } else if(differenceInSecondes >= 86400) {
      time = `${Math.floor(differenceInSecondes / 86400)} days ago`;
    } else if(differenceInSecondes > 3600) {
      console.log(differenceInSecondes / 3600);
      time = `${Math.floor(differenceInSecondes / 3600)} hours ago`;
    } else if(differenceInSecondes > 60) {
      time = `${Math.floor(differenceInSecondes / 60)} minutes ago`;
    } else {
      time = `${Math.floor(differenceInSecondes)} secondes ago`;
    };
    
    return time;
  }
}