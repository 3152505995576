import React from "react";

import MTextField from "../../../material/components/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import MomentUtils from '@date-io/moment';

import styles from "./styles.module.css";

export const CustomTimePicker = ({label, date, onChange, ...props}) => (
  <LocalizationProvider dateAdapter={MomentUtils}>
    <TimePicker
      {...props}
      label={label}
      value={date}
      onChange={onChange}
      ampm={false}
      inputFormat="HH:mm"
      classes={{
        root: 'date-picker-wrap'
      }}
      renderInput={(params) => <MTextField autoComplete="off" variant="outlined" {...params} />}
    />
  </LocalizationProvider>
);

export default CustomTimePicker;
