//create languages for auth module
let data = {
    "client": "Client",
    "clients": "Clients",
    "clientsList": "Clients List",
    "shop": "Shop",
    "birthDate": "Birth Date",
    "registerSource": "Register Source",
    "sources": "Source",
    "Users": "Users",
    "userDetails": "User Details",
    "rolesSuccessfulyDeleted": "User successfully deleted",
    "rolesUnSuccessfulyDeleted": "Role can't deleted",
    "rolesSuccessfulyEdeted": "Role successfully Edeted",
    "rolesUnSuccessfulyEdeted": "Role can't Edeted",
    "usersSuccessfulyDeleted": "Users successfully Deleted",
    "usersUnSuccessfulyDeleted": "Users can't Deleted",
    "userSuccessfulyAdded": "User Added",
    "userUnSuccessfulyAdded": "User can't Added",
    //transactions
    "transactions": "Transactions",
    "id": "Id",
    "Id": 'Id',
    "UserId": 'User Id',
    "TransationRefId": 'Reference Id',
    "PaymentDate": 'Payment Date',
    "OrderDate": 'Order Date',
    "CustomerId": 'Customer Id',
    "TransactionStatus": 'Status',
    "TransactionType": 'Type',
    "PaymentChannel": 'Payment Channel',
    "PaymentType": 'Payment Type',
    "SalePointId": 'Sales Point Id',
    "SalesPointName": 'Sales Point Name',
    "SalesPointType": 'Sales Point Type',
    "SalesPointTypeValue": 'Sales Point Type Value',
    "Amount": 'Amount',
    "Currency": 'Currency',
    "FreeCapsuleAmount": 'Free Capsule Amount',
    "CompanyId": 'Company Id',
    "CompanyName": 'Company',
    "CardNumber": 'Card Number',
    "Cashier": 'Cashier',
    "OrderCode": 'Order Code',
    "UniqueCode": 'Unique Code',
    "ChangeTime": 'Change Time',
    "DetailedInformation": 'Detailed Information',
    "TransactionDetails": 'Transaction Details',
    "ProductsDetails": 'Products Details',
    "History": 'History',
    "city": 'City',
    "district": "District",
    "default": 'Default',
    "address": 'Address',
    "changeInfo": "Change Info",
    "comments": "Comments",
    "smsInfo": "SMS Info",
    "title": "Title",
    "sentDate": "Sent Date",
    "date": "date",
    "message": "message",
    "callCenter": "Call Center",
    "primary": "Primary",
    "isDiplomat": "Is Diplomat",
    "contactInfo": "Contact Info",
    'verificationCode': 'Verification Code',
    "PleaseTypeTheVerificationCodeSentTo": "Please Type The Verification Code Sent To",
    "selectNumber": "Select Number",
    "sendSuccessfully": "Send Successfully",
    "customerAddedSuccessfully": "Customer Added Successfully",
    "verifyClient": "Verify Client",
    "districtId": "District ID",
    "sendOtpToUser": "Send OTP To User",
    "clientId": "client Id"
}

export default data;