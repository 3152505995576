import React from 'react';
import { Menu } from '@material-ui/core';
import '../style.css';

export const MMenu = React.forwardRef((props, ref) => (
  <Menu
    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    innerRef={ref}
    {...props}
  />
));
export default MMenu;
