import React, { useState } from 'react';
import { useTranslations } from 'shared-modules/translation-module';

import { MuseMediaQuery } from '../../../hooks/MuseMediaQuery/';
import { MMenu, MButton } from '../../../material/components';
import { IconMdTune, IconMdClose } from '../../../icons/components';
import styles from './styles.module.css';
import PageLoader from '../PageLoader';
import { Tag } from '../Tag';
import clsx from 'clsx';

export const CustomFilter = ({
  large = false,
  isSubmitDisabled = false,
  showSaveFilterBtn = false,
  savedFiltersNames = [],
  handleSavedFilterClick,
  handleSavedFilterDelete,
  handleSaveFiltersBtnClick,
  children,
  resultCount,
  choosedCount = 0,
  handleSubmit,
  clearHandler,
  getFilterOpen = () => {},
  isLoading = false,
  defaultTags = [],
}) => {
  const { t } = useTranslations();
  const mediaQueryLG = MuseMediaQuery('lg');
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div onKeyDown={(e) => (e.key === 'Enter' ? handleSubmit() : null)}>
      {mediaQueryLG ? (
        <div
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            getFilterOpen(event.currentTarget);
          }}
        >
          <div className={styles.countContainer}>
            {choosedCount ? <span className={`${styles.count} f-bold`}> {choosedCount} </span> : null}
            <IconMdTune className={`${styles.filterRespIcon} custom-icon big`} />
          </div>
        </div>
      ) : (
        <div
          className={styles.label}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            getFilterOpen(event.currentTarget);
          }}
        >
          {choosedCount ? <span className={`${styles.count} f-bold`}> {choosedCount} </span> : null}
          <span className={styles.labelTitle}>{t['filter']}</span>
        </div>
      )}
      <MMenu
        className={clsx({ 'filter-dropdown-large': large })}
        id="filter-dropdown"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        disableEnforceFocus
      >
        <div className={`${styles.filterTitle}`}>{t['filter']}</div>
        <PageLoader isLoading={isLoading}>{children}</PageLoader>
        <div className={styles.bottom}>
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <MButton
                className={`${styles.clearButton} transparent`}
                onClick={() => {
                  clearHandler();
                  setAnchorEl(null);
                }}
              >
                <IconMdClose />
                {t['clearFilter']}
              </MButton>
            </div>
            <div className="col-6 d-flex  align-items-center justify-content-end">
              <MButton
                disabled={isSubmitDisabled}
                className={`${styles.button} rounded`}
                onClick={() => {
                  handleSubmit();
                  setAnchorEl(null);
                }}
              >
                {t['filter'] + (resultCount ? `(${resultCount})` : '')}
              </MButton>
            </div>
            <div className="col-12 justify-content-center ">
              {showSaveFilterBtn && (
                <MButton
                  fullWidth
                  className={`${styles.button} tonal rounded`}
                  onClick={handleSaveFiltersBtnClick}
                >
                  Save Filter
                </MButton>
              )}
            </div>
            <div className="col-12">
              <br />
            </div>
            <div className="col-12 d-flex flex-wrap">
              {defaultTags.map(({ label, onClick }, i) => (
                <Tag key={i} hasDelete={false} label={label} onClick={onClick} />
              ))}
              {savedFiltersNames.map((filterName) => (
                <Tag
                  key={filterName}
                  label={filterName}
                  onClick={() => handleSavedFilterClick?.(filterName)}
                  onDelete={() => handleSavedFilterDelete?.(filterName)}
                />
              ))}
            </div>
          </div>
        </div>
      </MMenu>
    </div>
  );
};

export default CustomFilter;
