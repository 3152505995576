import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const SearchStore = class {
    inputText = '';

    searchHandler = (data, searchParam, searcPropertyName) => {
        return data?.map(o => {
            if(o[searcPropertyName] && o[searcPropertyName]?.toLowerCase().includes(searchParam?.toLowerCase())) {
                return o;
            } else return null
        })?.filter(o => o !== null);
    }

  constructor() {
    makeAutoObservable(this);
  }
};

export default createContext(new SearchStore());
