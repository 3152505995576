import React from "react";
import { useTranslations } from "shared-modules/translation-module";

import {
  MDialog,
  MDialogTitle,
  MDialogContent,
  MDialogActions
} from "../../../material";

import { IconMdDelete } from "../../../icons";

import styles from "./styles.module.css";

export const PrimarySmallDialog = ({open, close, title, text, contentComponent,  icon = <IconMdDelete className="dialog-main-icon" />, buttons }) => {
  return (
    <MDialog open={open} onClose={close} className="small">
      <MDialogTitle>
        {icon && icon}
        {title && <div className="dialog-main-title">{title}</div>}
      </MDialogTitle>
      <MDialogContent>
        {text && <div className="dialog-main-text">{text}</div>}
        {typeof contentComponent === "function" && <div className={styles.marginTop}>{contentComponent()}</div>}
      </MDialogContent>
      {buttons && <MDialogActions>{buttons}</MDialogActions>}
    </MDialog>
  );
};
export default PrimarySmallDialog;
