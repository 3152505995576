//create languages for auth module
let data = {
    "tags": "თეგები",
    "attachedTo": "მიმაგრებულია",
    "tag": "თეგი",
    "tagName": "თეგის სახელი",
    "comment": "კომენტარი",
    "tagSuccessfullyUpdated": "თეგი წარმატებით განახლდა",
    "tagUnSuccessfullyUpdated": "თეგი ვერ განახლდა",
    "tagSuccessfullyAdded": "თეგი წარმატებით დაემატა",
    "tagSuccessfulyDeleted": "თეგი წარმატებით წაიშალა"
}

export default data;