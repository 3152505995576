import React, { useEffect, useState, useContext } from "react";
import { observer, Observer } from "mobx-react";

import { useDrawerRoutes, DrawerStoreInstance } from "shared-modules/routes-module";
import { UserManagerStore } from "shared-modules/user-manager-module/user";
import { useTranslations } from "shared-modules/translation-module";
import { PageLoader } from "shared-modules/ui-lib-module";

import { entryStoreContext } from "shared-modules/shared-store-module";
import NavItem from "./NavItem";

import style from "./styles.module.css";

const Sidebar = (props) => {
  const userManagerContext = useContext(UserManagerStore);
  const entryContext = useContext(entryStoreContext);
  const drawerContext = useContext(DrawerStoreInstance);

  const { t } = useTranslations();

  const [sideMenu, setSideMenu] = useState([]);
  const routes = useDrawerRoutes(t);

  useEffect(() => {
    if (!userManagerContext.user) userManagerContext.initial();
    else {
      drawerContext.setDrawerChangeEvent(async () => {
        var user = await entryContext.getUser();
        userManagerContext.setUser(user.data.user, user.data.cmsLocalStorage);
        userManagerContext.initial();
        routes.then((o) => setSideMenu(o.filter((el) => el !== undefined)));
      });
      routes.then((o) => setSideMenu(o.filter((el) => el !== undefined)));
    }
  }, [userManagerContext.user]);

  return (
    <Observer
      render={() => {
        return (
          <nav
            className={`${style.nav} ${
              props.hideDrawer ? style.hideDrawer : ""
            }`}
          >
            <PageLoader color="#fff" isLoading={entryContext.loading.drawerLoading}>
              {sideMenu?.map((item, index) => {
                return (
                  <NavItem
                    key={`${item.label}-${index}`}
                    item={item}
                    firstItem={index === 0}
                    hideDrawer={props.hideDrawer}
                  />
                );
              })}
            </PageLoader>
          </nav>
        );
      }}
    />
  );
};

export default observer(Sidebar);
