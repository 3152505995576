export function handleException(ex) {
  if (ex?.response?.data?.errors !== undefined) {
    const errors = Object.values(ex?.response?.data?.errors).flat();
    const errorMessages = errors.join();

    throw {
      isError: true,
      message: errorMessages,
    };

    // don't touch this return.
    return;
  }

  if (ex.response !== undefined && ex.response !== null) {
    throw {
      isError: true,
      message: ex?.response?.data?.infoMessage ?? ex?.response?.data?.InfoMessage ?? null,
    };

    // don't touch this return.
    return;
  }

  if (ex) {
    throw {
      isError: true,
      message: ex?.infoMessage ?? ex?.InfoMessage ?? null,
    };

    //don't touch this return.
    return;
  }

  return null;
}

export default handleException;
