export const isType = (item, type) => {
  return typeof item === type;
};

export const getCountriesFormCompany = (company) => {
  return company?.companyAreals?.map((areal) => ({
    id: areal.country.id,
    name: areal.country.name,
  }));
};

export const getFromURL = (key) => {
  return new URLSearchParams(window.location.search).get(key);
};

export const getPaginationString = (rowsPerPage, currentPage) => {
  return `count=${rowsPerPage}&FromIndex=${(currentPage - 1) * rowsPerPage}`;
};

export const isWholeNumber = (num) => {
  return num % 1 === 0;
};

export function flattenArray(arr) {
  const result = [];

  function recursiveFlatten(obj) {
    result.push(obj);

    if (obj.childLocations && obj.childLocations.length > 0) {
      obj.childLocations.forEach((child) => {
        recursiveFlatten(child);
      });
    }
  }

  arr.forEach((item) => {
    recursiveFlatten(item);
  });

  return result;
}

export const tryParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const isTruthyValue = (value) => {
  return Array.isArray(value) ? value.length > 0 : !!value;
};

export const appendFormDataArray = (formData, dataArray, paramName) => {
  dataArray.forEach((data, i) => {
    formData.append(`${paramName}[${i}]`, data);
  });
};

export const appendFormDataIfExists = (formData, data, value = null) => {
  if (typeof data === 'object') {
    Object.entries(data).forEach(([propertyKey, propValue]) => {
      if (propValue) formData.append(propertyKey, propValue);
    });
  } else if (typeof data === 'string') {
    formData.append(data, value);
  }
};

export const createFormDataAppender = (formData) => {
  return (data, value = null) => appendFormDataIfExists(formData, data, value);
}

export const convertFormDataToObject = (formData) => {
  const object = {};
  for (let key of formData.keys()) {
    object[key] = formData.get(key);
  }
  return object;
};

export const lowerCaseFirstChar = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

/**
 * Generates a query string from the provided parameters.
 * @param {Object} params - The parameters to be included in the query string.
 * @returns {string} The generated query string.
 */
export const generateQueryString = (params) => {
  const urlParams = new URLSearchParams();
  for (const key in params) {
    if (params[key] instanceof Array) {
      params[key].forEach((value) => {
        urlParams.append(key, value);
      });
    } else {
      urlParams.append(key, params[key]);
    }
  }
  return `${urlParams.toString()}`;
};

export function removeDuplicates(arr) {
  let unique = [];
  arr?.forEach((element) => {
    if (!unique?.includes(element)) {
      unique?.push(element);
    }
  });
  return unique;
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}