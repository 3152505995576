//create languages for auth module
let data = {
  Users: 'მომხმარებლები',
  User: 'მომხმარებელი',
  userDetails: 'დეტალები',
  rolesSuccessfulyDeleted: 'როლები წარმატებით წაიშალა',
  rolesUnSuccessfulyDeleted: 'როლიები ვერ წაიშალა',
  rolesSuccessfulyEdeted: 'მომხამრებლის როლები წარმატებით შეიცვალა',
  rolesUnSuccessfulyEdeted: 'მომხამრებლის როლების  შეიცვალა ვერ მხოერხდა',
  usersSuccessfulyDeleted: 'წარმატებით წაიშალნენ მომხამრებლები',
  usersUnSuccessfulyDeleted: 'მომხამრებლების წაშლა ვერ მოხერხდა',
  userSuccessfulyAdded: 'მომხმარებელი წარმატებით დაემატა',
  userUnSuccessfulyAdded: 'მომხმარებლის დამატება ვერ მოხერხდა',
  confirmations: 'დადასტურებები',
  isUserActive: 'მომხმარებლის აქტივობა',
};

export default data;
