import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const NotificationUIStore = class {
    currentTab = "1";
    changeTab = (e, newTab) => this.currentTab = newTab;
    
    notification = null;
    
    sendReadedNotificationId = async (id) => {/*don't implement this here*/}

    openNotification = (notification) => {
        if(notification?.readDate === null) this.sendReadedNotificationId(notification?.id)?.catch();
        this.notification = notification;
    }
    closeNotification = () => this.notification = null;

    isComposeOpen = false;

    openCompose = () => {
        this.closeNotification();
        this.isComposeOpen = true;
    }
    
    closeCompose = () => {
        this.closeNotification();
        this.isComposeOpen = false;
        this.formData = { 
            title: "",
            subject: "",
            content: "",
    
            selectedUsers: [],
            messageFiles: []
        }
    }

    formData = { 
        title: "",
        subject: "",
        content: " ",

        selectedUsers: [],
        messageFiles: []
    }

    constructor() {
        makeAutoObservable(this);
    };
}

export default createContext(new NotificationUIStore());
