import React from 'react';
import { useEffect } from 'react';

export const useInterval = (func, time = 1) => {
  useEffect(() => {
    let interval = setInterval(() => {
      func();
    }, time * 1000);
    return () => {
      clearInterval(interval);
    };
  });
};
