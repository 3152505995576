export const generateSeoFormData = (formData = undefined, seoData = undefined) => {
  if(!formData) {
    return null;
  };
  seoData?.id && formData.append("seoId", seoData?.id ?? "");
  formData.append(`NoIndex`, seoData?.noIndex ?? false);
  formData.append(`NoFollow`, seoData?.noFollow ?? false);
  seoData?.image && formData.append(`Image`,  seoData?.image ?? {});
  formData.append(`shouldDeleteSocialShareImage`,  seoData?.shouldDeleteSocialShareImage ?? false);
  seoData?.hreflang?.length && seoData?.hreflang?.forEach((o, i) => {
    formData.append(`Hreflang[${i}].HreflangUrl`, o.hreflangUrl);
    formData.append(`Hreflang[${i}].LanguageCode`, o.languageCode);
  });
  seoData?.slug && formData.append(`slug`, seoData?.slug ?? "");
  seoData?.title && formData.append(`Title`, seoData?.title ?? "");
  seoData?.description && formData.append(`SeoDescription`, seoData?.description ?? "");
  seoData?.canonical && formData.append(`Canonical`, seoData?.canonical ?? "");
  seoData?.structedDataMarkup && formData.append(`StructedDataMarkup`, seoData?.structedDataMarkup ?? "");
  seoData?.socialTitle && formData.append(`SocialTitle`, seoData?.socialTitle ?? "");
  seoData?.socialDescription && formData.append(`SocialDescription`, seoData?.socialDescription ?? "");
  seoData?.ogURL && formData.append(`OgURL`, seoData?.ogURL ?? "");
  seoData?.ogLocal && formData.append(`OgLocal`, seoData?.ogLocal ?? "");
  seoData?.ogType && formData.append(`OgType`, seoData?.ogType ?? "");
  seoData?.ogSiteName && formData.append(`OgSiteName`, seoData?.ogSiteName ?? "");
  seoData?.ogAltName && formData.append(`OgAltName`, seoData?.ogAltName ?? "");
  seoData?.ogSite && formData.append(`OgSite`, seoData?.ogSite ?? "");
  seoData?.ogHandle && formData.append(`OgHandle`, seoData?.ogHandle ?? "");
  seoData?.ogCartType && formData.append(`OgCartType`, seoData?.ogCartType ?? "");
};