import React, { useState } from "react";
import { Observer } from "mobx-react";

import { useTranslations } from "shared-modules/translation-module";
import { MButton, MDialog, MDialogActions, MDialogContent, MDialogTitle, MTextField } from "../../../../material";
import { useEffect } from "react";

const AddMarckupModal = ({disabled=false, open=false, close=()=>{}, saveHandler=()=>{}, data=""}) => {
  const {t} = useTranslations();

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(data);
    return () => {
      setInputValue("")
    };
  }, [open])

  return (
    <Observer render={() => (
      <MDialog open={open} onClose={close} className="medium">
        <MDialogTitle>
          <div className="dialog-main-title">Add new markup</div>
        </MDialogTitle>
          <MDialogContent>
            <Observer render={()=>(
              <MTextField
                type="text"
                autoComplete="off"
                variant="outlined"
                multiline={true}
                name="structedDataMarkup"
                label="Write your markup in JSON-LD format"
                disabled={disabled}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
              />
            )}/>
          </MDialogContent>
          <MDialogActions>
            <MButton
              className="f-bold rounded transparent"
              onClick={close}
            >
              {t["close"]}
            </MButton>
           {!disabled 
            ? <MButton
                className="f-bold rounded tonal"
                onClick={() => {
                  saveHandler(inputValue);
                  close();
                }}
              >
              {t["save"]}
            </MButton>
            : null}
          </MDialogActions>
      </MDialog>
    )} />
  )
}

export default AddMarckupModal;