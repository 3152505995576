import React, { useEffect, useContext } from "react";
import { Observer } from "mobx-react";

import {
  PageFilterSection,
  CustomFilter,
  CustomDateTimePicker,
  SingleSelect,
  MTextField
} from "shared-modules/ui-lib-module";


import { useTranslations } from "shared-modules/translation-module";

import { FilesGroupFilterStore } from "./filterStore";
import moment from "moment";
import NotificationStore from "shared-modules/notification-logic-module/store/NotificationStore";
import styles from "./styles.module.css";

export const filesGroupFilterContext = new FilesGroupFilterStore();

const InboxFilter = ({isMySended}) => {
  const { t } = useTranslations();
  const notificationsContext = useContext(NotificationStore)
  const notificationStatuses = [{ value: "false", name: t['read'] }, { value: "true", name: t["unread"]}]

  useEffect(() => {
    filesGroupFilterContext.initFromUrl();
  }, []);

  return (
    <PageFilterSection
      FilterComponent={() => (
        <CustomFilter
          handleSubmit={() => {
            filesGroupFilterContext.setUrl();
            
            notificationsContext.fromIndex = 0;
            notificationsContext.notifications = { notifications: [], count: 0 };
            notificationsContext.getAllNotifications(isMySended).catch();
          }}
          clearHandler={() => {
            filesGroupFilterContext.clearFilter();
            
            filesGroupFilterContext.filter = {};
            notificationsContext.fromIndex = 0;
            notificationsContext.notifications = { notifications: [], count: 0 };
            notificationsContext.getAllNotifications(isMySended).catch();
          }}
          choosedCount={filesGroupFilterContext.getChoosedFilter()?.length ?? 0}
        >
          <Observer render={()=> (
            <MTextField
              autoComplete="off"
              variant="outlined"
              type="text"
              name='title'
              label={t["title"]}
              value={filesGroupFilterContext.filter.title}
              onChange={(e) => filesGroupFilterContext.filter.title = e.target.value}
            />
          )}/>
          
          <div className={`${styles.dateTitle} f-bold`}>{t["date"]}</div>  
          <Observer render={()=> (
            <CustomDateTimePicker
              label={t['from']}
              name="dateFrom"
              date={filesGroupFilterContext.filter.dateFrom ? moment(new Date(filesGroupFilterContext.filter.dateFrom)).local().toLocaleString() : null}
              onChange={date => filesGroupFilterContext.filter.dateFrom = moment(date).utc().toISOString()} 
            />
          )}/>
          <Observer render={()=> (
            <CustomDateTimePicker
              label={t['to']}
              name="dateTo"
              date={filesGroupFilterContext.filter.dateTo ? moment(new Date(filesGroupFilterContext.filter.dateTo)).local().toLocaleString() : null}
              onChange={date => filesGroupFilterContext.filter.dateTo = moment(date).utc().toISOString()}
            />
          )}/>
          <Observer render={()=> (
            <SingleSelect
              placeholder={t["status"]}
              names = {{ id: "value", value: "name", selectedId: 'id' }}
              propsData={notificationStatuses}
              selectedData={notificationStatuses?.find(o => filesGroupFilterContext?.filter?.isUnReaded?.some(i => i === o?.value)) ?? []}
              handleSingleSelectChange={(item) => {
                if(filesGroupFilterContext.filter.isUnReaded.some(o => o === item?.value)) filesGroupFilterContext.filter.isUnReaded = [];
                else filesGroupFilterContext.filter.isUnReaded = [item?.value];
              }}
            />
          )}/>
        </CustomFilter>
      )}
    />
  )
};

export default InboxFilter;
