//create languages for auth module
let data = {
    "company": "Company",
    "modules": "Modules",
    "salePoints": "Sales Points",
    "locations": "Locations",
    "childes": 'Childes',
    "noAreals": 'No Areals',
    "mustChooseParentData": "You must choose parent yet!",
    'noChildren': 'No Children',
    "modules": "Modules",
    "salesPoints": "Sales Points",
    "locations": "Locations",
    "children": "Children",
    "currency": "Currency",
    'noCurrency': 'No Currency',
    "deleteDialogCompanyTitle": "Delete action, which have children companies",
    "deleteDialogCompanyText": " This company have children companies. Do you want really delete full company femaly",
    "deleteCompanyWithoutChildes": " Delete Company (without childs)",
    "deleteCompanyWithChildOrFull": " Delete Company (full or Partial)",
    "feildGetCompanies": "Can't Get Companies",
    "sort": "Sort",
    "lastAdded": "Last Added",
    "newestAdded": "Newest Added",
    "byStatus": "By Status",
    "companyColor": "Company Color",
    "chooseLocation": "Choose Location",
    "parentCompany": "Parent Company",
    "companyName": "Company Name",
    'addNewCompany': "Add New Company",
    "working_area": "Working area",
    "successUpdateCompany": "Company updated successfully",
    "notAUpdatedCompany": "Company could not be updated",    
    "ProductName": "By product name",
    "CategoryName": "By category name",
    "productName": "Product name",
    "categoryName": "Category name",
    "costFrom": "Cost from",
    "costTo": "Cost to",
    "costCurrency": "Cost price currency",
    "priceCurrency": "Selling price currency",
    "moreThan0LessThen100": "input number between 0.1 and 99.99"
}

export default data;