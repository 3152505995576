import React, { useContext, useEffect, useState } from "react";

import { UserManagerStore } from "shared-modules/user-manager-module";
import ThemesLocalStorageStore from "shared-modules/local-storage-module/ThemesLocalStorageStore";
import LanguagesLocalStorageStore from "shared-modules/local-storage-module/LanguagesLocalStorageStore";
import { useTranslations, languages } from "shared-modules/translation-module";

import { MMenu, MMenuItem, MSwitch } from "../../../material";
import {
  IconMdSettings,
  IconMdLanguage,
  IconMdOutlineDarkMode,
  IconMdSpaceDashboard,
  IconMdKeyboardArrowRight,
  IconMdKeyboardArrowLeft,
  IconMdCheckCircle,
} from "../../../icons/components";

import styles from "./styles.module.css";

export const OptionsButton = ({ history }) => {
  const { t, configStore } = useTranslations();

  const userManagerContext = useContext(UserManagerStore);
  const themeContext = new useContext(ThemesLocalStorageStore);
  const languageContext = new useContext(LanguagesLocalStorageStore);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSubEl, setAnchorSubEl] = useState(null);
  const [switchThemeState, setSwitchThemeState] = useState(themeContext.getTheme());

  const enableDarkMode = () => {
    setSwitchThemeState(switchThemeState === "dark" ? "" : "dark");
    themeContext.setTheme(switchThemeState);
  };

  const handleLanguage = (e) => {
    languageContext.setLanguageCode(e);
    configStore.setLanguage(e);
  };

  const closeLangDropdown = () => {
    setAnchorSubEl(null);
  };

  useEffect(() => {
    let theme = themeContext.getTheme();

    // userManagerContext.initTheme();
    setSwitchThemeState(theme);
  }, []);

  useEffect(() => {
    themeContext.setTheme(switchThemeState);
    userManagerContext.initTheme();
  }, [switchThemeState]);

  return (
    <div className={`${styles.optionsBtnBox} f-bold`}>
      <div
        aria-controls="options-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={`${styles.optionsBtn} ${
          Boolean(anchorEl) ? styles.visible : ""
        }`}
      >
        <IconMdSettings className={`custom-icon dark-green ${styles.optionsBtnIcon}`} />
      </div>
      <MMenu
        id="options-dropdown"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        className={styles.optionsInfoDropdown}
      >
        <MMenuItem>
          <div
            aria-controls="lang-dropdown"
            aria-haspopup="true"
            onClick={(event) => setAnchorSubEl(event.currentTarget)}
            className={`${styles.dropdownItem} ${styles.languagesBtn}`}
          >
            <IconMdLanguage />
            <div className={`${styles.title} f-bold`}>
              {t["changeLanguage"]}
            </div>
            <div className={`${styles.currentLangText} f-bold`}>
              {configStore.getCurrentLanugage()}
              <IconMdKeyboardArrowRight />
            </div>
          </div>
          <MMenu
            id="lang-dropdown"
            anchorEl={anchorSubEl}
            open={Boolean(anchorSubEl)}
            onClose={() => setAnchorSubEl(null)}
            getContentAnchorEl={null}
          >
            <MMenuItem>
              <div
                className={`${styles.dropdownItem}`}
                onClick={closeLangDropdown}
              >
                <IconMdKeyboardArrowLeft className={styles.backIcon} />
                <div className={`${styles.title} f-bold`}>{t["back"]}</div>
              </div>
            </MMenuItem>
            {languages.map((lan, index) => (
              <MMenuItem
                key={`lanKey${index}`}
                value={lan.code}
                onClick={() => handleLanguage(lan.code)}
              >
                <div
                  className={`${styles.dropdownItem} ${
                    styles.langDropdownItem
                  } lang-dropdown-item ${
                    localStorage.getItem("i18nextLng") === lan.code && "active"
                  }`}
                >
                  <div className={`${styles.shortLangText} f-bold`}>
                    {" "}
                    {lan.code}{" "}
                  </div>
                  <div className={`${styles.text} f-bold`}> {lan.name} </div>
                  <div
                    className={`${styles.langCircleIcon} lang-dropdown-icon`}
                  >
                    <IconMdCheckCircle />
                  </div>
                </div>
              </MMenuItem>
            ))}
            <style>{`
              .lang-dropdown-item.active .lang-dropdown-icon {
                border-color: transparent;
              }
              .lang-dropdown-item.active .lang-dropdown-icon svg {
                opacity: 1;
              }
            `}</style>
          </MMenu>
        </MMenuItem>
        <MMenuItem>
          <div className={`${styles.dropdownItem}`} onClick={enableDarkMode}>
            <IconMdOutlineDarkMode />
            <div className={`${styles.title} f-bold`}>{t["darkTheme"]}</div>
            <MSwitch
              className={styles.switchCheckbox}
              checked={switchThemeState === "dark"}
            />
          </div>
        </MMenuItem>
        <MMenuItem
          onClick={() => {
            setAnchorEl(null);
            history.push("/menu-options");
          }}
        >
          <div className={`${styles.dropdownItem}`}>
            <IconMdSpaceDashboard />
            <div className={`${styles.title} f-bold`}>{t["menuoptions"]}</div>
          </div>
        </MMenuItem>
      </MMenu>
    </div>
  );
};
export default OptionsButton;
