//create languages for auth module
let data = {
    "countries": "ქვეყნები",
    "country": "ქვეყანა",
    "region": "რეგიონი",
    "city": "ქალაქი",
    "parentRegion": "მშობელი რეგიონი",
    "parentCountries": "მშობელი ქვეყანა",
    "countryAddedSuccessfully": "ქვეყანა წარმატებით დაემატა",
    "regionAddedSuccessfully": "რეგიონი წარმატებით დაემატა",
    "cityAddedSuccessfully": "ქალაქი წარმატებით დაემატა",
    "districtAddedSuccesfully": "რაიონი წარმატებით დაემატა",
    "districtUpdatedSuccesfully": "რაიონი წარმატებით განახლდა",
    "countryUpdatedSuccessfully": "ქვეყანა წარმატებით განახლდა",
    "regionUpdatedSuccessfully": "რეგიონი წარმატებით განახლდა",
    "cityUpdatedSuccessfully": "ქალაქი წარმატებით განახლდა",
    "removedSuccesfully": "წარმატებით წაიშალა"
}

export default data;