import React, { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import * as themelocalstorage from "shared-modules/local-storage-module/ThemesLocalStorageStore";
import { IconMdError, IconMdClose } from "../../../../icons";

export const MToastifyAlert = ({
  theme = new themelocalstorage.ThemesLocalStorageStore().getTheme() === "dark" ? "dark" : "light",
  position = "bottom-center",
  autoClose = 5000,
}) => {
  const themeLocalStorageContext = useContext(themelocalstorage.default);
  const [toastTheme, setToastTheme] = useState(theme);

  useEffect(
    () => {
      document.addEventListener("onThemeChangeEvent", () => setToastTheme( themeLocalStorageContext.getTheme() === "dark" ? "dark" : "light"), false);
    },
    []
  );

  return (
    <ToastContainer
      limit={1}
      key={toastTheme}
      closeButton={() => <IconMdClose />}
      theme={toastTheme}
      position={position}
      autoClose={autoClose}
    />
  );
};
export default MToastifyAlert;
