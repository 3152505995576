import { createContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
import { ApiManager } from 'shared-modules/api-manager-module';
import { entryStore } from 'shared-modules/shared-store-module';

import mobxRouting from '../historyStore';

export class Store {
  languageCodeFromUrlKey = 'ecommerceLanguage';
  companyIdLocalStorageKey = 'ecommerceCompanyId';

  showLoading = false;
  setInUrl = true;

  allLanguages = [];
  defautlLanuageObj = {};
  defaultLanguage = '';
  currentLanugage = '';
  isDefaultLanguage = false;
  disabled = false;

  allCompanies = [];
  companyId = 0;
  prevCompanyId = 0;

  getCurrentCompanyIdOrDefault = () => {
    let companyId = this.getCompanyIdFromLocalStorage(); //?? new URLSearchParams(window.location.search).get("companyid");
    let defaultCompanyId = entryStore.userData?.user?.companies?.length
      ? entryStore.userData.user.companies[0]?.companyId
      : 0;
    if (companyId) {
      return companyId;
    } else {
      return defaultCompanyId;
    }
  };

  getUserCompnayIdOrDefault = (id = undefined) => {
    if (id) {
      return Number(id);
    } else {
      return Number(this.getCurrentCompanyIdOrDefault());
    }
  };

  setCompanyIdInUrl = (companyId) => {
    if (+this.getCompanyIdFromUrl() != +companyId) {
      let searchQuery = new URLSearchParams(window.location.search);
      searchQuery.set('companyid', companyId ?? this.getCurrentCompanyIdOrDefault());
      mobxRouting.push({ search: searchQuery.toString() });
    }
  };

  getCompanyIdFromUrl = () => new URLSearchParams(window.location.search).get('companyid');

  setCompanyIdInLocaltorage = (companyId = undefined) => {
    if (this.getCompanyIdFromLocalStorage() != companyId) {
      companyId &&
        localStorage.setItem(this.companyIdLocalStorageKey, this.getUserCompnayIdOrDefault(this.companyId));
    }
  };
  getCompanyIdFromLocalStorage = () => localStorage.getItem(this.companyIdLocalStorageKey);

  getCurrentCompanyId = () =>
    this.getUserCompnayIdOrDefault(
      this.getCompanyIdFromLocalStorage() ?? new URLSearchParams(window.location.search).get('companyid')
    );
  setCompanyId = (companyid) => {
    const companyId = Number(companyid) ?? Number(this.getCompanyIdFromLocalStorage());
    this.setCompanyIdInLocaltorage(companyid);
    this.setCompanyIdInUrl(companyid);
  };

  initStore = async () => {
    this.allCompanies = entryStore.userData?.user?.companies;

    if (this.companyId) {
      this.setCompanyId(this.companyId);
      let languages = await this.getAllLanguages(this.companyId);
      if (languages) {
        let defaultLang = languages.find((language) => language.isDefault) ?? languages[0] ?? {};

        this.defautlLanuageObj = { ...defaultLang, flagIconUrl: defaultLang?.flagUrl };
        this.defaultLanguage = this.defautlLanuageObj?.languageCode;
        this.currentLanugage = this.defaultLanguage;
      }
      return languages;
    } else {
      this.companyId = Number(this.getCurrentCompanyIdOrDefault());
      return Promise.reject('');
    }
  };

  get isDefaultTab() {
    return this.defaultLanguage === this.currentLanugage || !Boolean(this.currentLanugage);
  }

  deleteTabFromUrl = () => {
    let searchquery = new URLSearchParams(window.location.search);
    searchquery.delete(this.languageCodeFromUrlKey);
    mobxRouting.push({ search: searchquery.toString() });
  };

  getTabFromUrl = () => {
    let currentLang = new URLSearchParams(window.location.search)
      .get(this.languageCodeFromUrlKey)
      ?.toString();
    if (currentLang != '' && currentLang) {
      return currentLang;
    } else {
      return this.defaultLanguage;
    }
  };

  onTabChangeClick = (event, newValue) => {
    if (newValue === this.currentLanugage) return;
    this.currentLanugage = newValue;
  };

  setCurrentLanguage = (newLang) => {
    if (newLang === this.currentLanugage) return;
    this.currentLanugage = newLang;
  }

  setLanguageFromUrl = (code) => {
    if (this.allLanguages.length > 0) {
      var languageCode = this.allLanguages
        .find((o) => o.languageCode?.toLowerCase() === code?.toLowerCase())
        ?.languageCode?.toLowerCase();
      if (languageCode) {
        this.currentLanugage = languageCode;
      } else {
        this.currentLanugage = this.defaultLanguage;
        mobxRouting.replace(window.location.search);
      }
    }
  };

  ecommerceRedirectPage = (redirectUrl, searchparams = []) => {
    let searchQuery = new URLSearchParams(window.location.search);
    if (searchparams?.length) {
      searchparams?.forEach((param) => searchQuery.set(param?.name, param?.value));
    }
    searchQuery.set('companyid', this.getCurrentCompanyIdOrDefault());
    searchQuery.set(this.languageCodeFromUrlKey, this.defaultLanguage);
    this.currentLanugage = this.defaultLanguage;
    mobxRouting.history.push(`${redirectUrl}?${searchQuery.toString()}`);
  };

  //GET Methods form server
  getAllLanguages = async (companyId) => {
    try {
      this.showLoading = true;
      let searchQuery = new URLSearchParams(window.location.search);
      let query = '?CompanyId=';

      if (this.companyId == this.prevCompanyId || !companyId || companyId < 1) return this.allLanguages;

      if (companyId > 0) {
        query += companyId;
      } else if (searchQuery.get('companyid')) {
        query += searchQuery.get('companyid');
      }
      let res = await ApiManager.getData(`/api/ecommerce/Language/GetLanguages${query}`);
      this.allLanguages = res?.data?.eCommerceLanguages
        ?.map((o) => ({ ...o, flagIconUrl: o?.flagUrl }))
        ?.sort((a, b) => b?.isDefault - a?.isDefault);
      return res?.data?.eCommerceLanguages;
    } catch (ex) {
      // MToast.errorToast(ex.message);
      return false;
    } finally {
      this.showLoading = false;
    }
  };

  filterByLanguageIds = (langIds) => {
    return this.allLanguages.filter(({ languageId }) =>
      langIds.includes(languageId)
    );
  }

  filterByLanguageCodes = (langCodes) => {
    return this.allLanguages.filter(({ languageCode }) =>
      langCodes.includes(languageCode.toLowerCase())
    );
  }

  companyOnChangeHandler = () => {};

  constructor() {
    makeAutoObservable(this);
    //detect tab change
    reaction(
      () => this.currentLanugage,
      async (code) => {
        let params = new URLSearchParams(window.location.search);
        if (this.allLanguages?.length) {
          if (code === null) {
            this.currentLanugage = this.allLanguages.find((o) => o.isDefault)?.languageCode?.toLowerCase();
            return;
          }

          if (
            this.allLanguages?.some(
              (language) => language?.languageCode?.toLowerCase() === code?.toLowerCase()
            )
          ) {
            params.set(this.languageCodeFromUrlKey, code);
            mobxRouting.history.replace({ search: params.toString() });
          } else {
            params.delete(this.languageCodeFromUrlKey);
            mobxRouting.history.replace({ search: params.toString() });
          }
          this.isDefaultLanguage = this.defaultLanguage?.toLocaleLowerCase() === code?.toLowerCase();
        }
      }
    );
    //detect company change
    reaction(
      () => this.companyId,
      async (companyId) => {
        if (companyId !== this.prevCompanyId) {
          this.setCompanyId(this.companyId);
          await this.initStore();
          this.deleteTabFromUrl();
          this.prevCompanyId = companyId;
          this.companyOnChangeHandler();
        }
      }
    );
  }
}

export const ecommerceLanguageContext = new Store();
export const EcommerceLanguageStore = createContext(ecommerceLanguageContext);
