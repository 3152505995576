import React, { useMemo, useState } from 'react';
import { MDialog, MDialogTitle } from '../../material';

export const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  const Modal = useMemo(() => {
    return ({ children, title, ...props }) => {
      return (
        <MDialog open={isOpened} onClose={closeModal} className="small" {...props}>
          <MDialogTitle>
            <div className="dialog-main-title">{title}</div>
          </MDialogTitle>
          {children}
        </MDialog>
      );
    };
  }, [isOpened]);

  return {
    Modal,
    openModal,
    closeModal,
  };
};
