import React, { useState } from "react";
import ColorPicker from "material-ui-color-picker";
import { IconMdOutlineDoNotDisturbAlt } from '../../../icons/components';
import { useTranslations } from "shared-modules/translation-module";
import styles from "./styles.module.css";

export const CustomColorPicker = ({onChange, color = '#000000', classtext, disabled, label}) => {
  
  const { t } = useTranslations();

  return (
    <React.Fragment>
      <div className={styles.colorPickerBox + " " + classtext || ""}>
        <ColorPicker
          name="color"
          value={color || ""}
          TextFieldProps={{
            value: color || "",
            variant: "outlined",
            className: "color-picker-input",
            label: label,
            autoComplete: "off",
            disabled: disabled
          }}
          onChange={onChange}
        />
        {
          color?.match(new RegExp('^(rgb\\s*?\\(\\s*?(000|0?\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\s*?,\\s*?(000|0?\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\s*?,\\s*?(000|0?\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\s*?\\))$|^(rgba\\s*?\\(\\s*?(000|0?\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\s*?,\\s*?(000|0?\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\s*?,\\s*?(000|0?\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\s*?,\\s*?(0|0\\.\\d*|1|1.0*)\\s*?\\))$|^(transparent)$|^(#([a-fA-F0-9]){3})$|^(#([a-fA-F0-9]){6}$)|(^hsl\\s*?\\(\\s*?(000|0?\\d{1,2}|[1-2]\\d\\d|3[0-5]\\d|360)\\s*?,\\s*?(000|100|0?\\d{2}|0?0?\\d)%\\s*?,\\s*?(000|100|0?\\d{2}|0?0?\\d)%\\s*?\\)$)|(^hsla\\s*?\\(\\s*?(000|0?\\d{1,2}|[1-2]\\d\\d|3[0-5]\\d|360)\\s*?,\\s*?(000|100|0?\\d{2}|0?0?\\d)%\\s*?,\\s*?(000|100|0?\\d{2}|0?0?\\d)%\\s*?,\\s*?(0|0\\.\\d*|1|1.0*)\\s*?\\)$)$', '')) != null ?
          <div className={styles.colorBox} style={{ backgroundColor: color }}></div> :
          <div className={styles.colorBox}><IconMdOutlineDoNotDisturbAlt /></div>   
        }
      </div>
    </React.Fragment>
  );
};

export default CustomColorPicker;