//create languages for auth module
let data = {
    "deleteWithFile": "Delete With File",
    "deleteClientsFromGroup": "Delete clients from group",
    "addClientsToGroup": "Add clients to group",
    "groupList": "Group List",
    "uploadFile": "Upload File",
    "uploadedFiles": "Uploaded Files",
    "addClientGroup": "Add Client Group",
    "addViaPhoneNumber": "Add va phone number",
    "pauseGroup": "Pause Group",
    "resumeGroup": "Resume Group",
    "clientId": "Client Id",
    "clientName": "client Name",
    "phoneNumber": "Phone Number",
    "clientList": "client List",
    "processEndDate": "Process EndDate",
    "groupName": "Group Name",
    "numebrOfClients": "Number of clients",
    "uploadedUsersamount": "Uploaded user name",
    "source": "Source",
    "status": "Status",
    "failed": "Failed",
    "action": "Action"
}

export default data;