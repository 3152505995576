//create languages for auth module
let data = {
    "tags": "Tags",
    "attachedTo": "Attached To",
    "tag": "Tag",
    "tagName": "Tag Name",
    "comment": "Comment",
    "tagSuccessfullyUpdated": "Tag Successfully Updated",
    "tagUnSuccessfullyUpdated": "Tag UnSuccessfully Updated",
    "tagSuccessfullyAdded": "Tag Successfully Added",
    "tagSuccessfulyDeleted": "Tag Successfuly Deleted"
}

export default data;