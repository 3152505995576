//create languages for transactions module
let data = {
    "transactions": "Transactions",
    "id": "Id",
    "Id": 'Id',
    "UserId": 'User Id',
    "TransationRefId": 'Reference Id',
    "PaymentDate": 'Payment Date',
    "OrderDate": 'Order Date',
    "CustomerId": 'Customer Id',
    "TransactionStatus": 'Status',
    "TransactionType": 'Type',
    "PaymentChannel": 'Payment Channel',
    "PaymentType": 'Payment Type',
    "SalePointId": 'Sales Point Id',
    "SalesPointName": 'Sales Point Name',
    "SalesPointType": 'Sales Point Type',
    "SalesPointTypeValue": 'Sales Point Type Value',
    "Amount": 'Amount',
    "Currency": 'Currency',
    "FreeCapsuleAmount": 'Free Capsule Amount',
    "CompanyId": 'Company Id',
    "CompanyName": 'Company',
    "CardNumber": 'Card Number',
    "Cashier": 'Cashier',
    "OrderCode": 'Order Code',
    "UniqueCode": 'Unique Code',
    "ChangeTime": 'Change Time',
    "DetailedInformation": 'Detailed Information',
    "TransactionDetails": 'Transaction Details',
    "ProductsDetails": 'Products Details',
    "History": 'History',
    "live": "Live",
    "transactionsIsInLive": "Transactions is in live",
    "transactionsIsNotInLive": "Transactions is not in live",
    "transactionType": "Transaction Type",
    "paymentChannel": "Payment Channel",
    "salesPointName": "SalesPoint Name",
    "salesPointType": "salesPoint Type",
}

export default data;