import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export const ThemesLocalStorageStore = class {
    constructor() {
        makeAutoObservable(this);
        this.themeKey = "theme";
    }

    setTheme(value) {
        localStorage.setItem(this.themeKey, value);
    }

    getTheme() {
        return localStorage.getItem(this.themeKey);
    }

    removeTheme() {
        localStorage.removeItem(this.themeKey);
    }
};

export default createContext(new ThemesLocalStorageStore());
