import React, { useState } from "react";

import { useTranslations } from "shared-modules/translation-module";

import {
  IconMdPhotoCamera,
  IconMdFilePresent,
  IconMdOutlineFileDownload,
  IconMdKeyboardArrowDown,
} from "../../../icons/components";
import { MTransitionGroup } from "../../../transitions";
import { MButton, MCollapse } from "../../../material";
import Comment from "../Comment";
import QuillEditor from "../QuillEditor";
import PrimarySmallDialog from "../PrimarySmallDialog";
import ImageUploader from "../ImageUploader";

import styles from "./styles.module.css";

export const CommentsList = ({ 
  loading = {
    delete: false,
    addReply: false,
    deleteReply: false,
  },
  mainCommentId,
  canRemove = false,
  canEdit = false,
  canSeeUserDetails = false,
  mainCommentAuthorId,
  commentDesc = "",
  commentAuthorName = "",
  addComemntLocalTime = "",
  addReplyCommentValue = undefined,
  fileData = undefined,
  replyComemntsArr = [],
  isProfile = ()=>{},
  edithandler=()=>{},
  addReplycommentOnChange=()=>{},
  editReplycommentOnChange=()=>{},
  addReplyCommentHandler=()=>{},
  userDetailHandler=()=>{},
  deleteReplyCommentHandler=async()=>{},
  deleteMainCommentHandler=async()=>{},
  updateReplyHandler=async()=>{},
}) => {
  const { t } = useTranslations();

  const [expandReply, setExpandReply] = useState(false)
  
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.commentBox}>
          <Comment 
            canRemove = {canRemove}
            canEdit = {canEdit}
            canSeeUserDetails = {canSeeUserDetails}
            commentDesc={commentDesc ?? ""} 
            commentAuthorName={commentAuthorName}
            addComemntLocalTime={addComemntLocalTime}
            fileData={fileData}
            deleteLoading={loading.delete}
            replyId={mainCommentId}
            isProfile = {()=> isProfile(mainCommentAuthorId)}
            onChangeCommentDesc={(text, replyid) => editReplycommentOnChange(text, replyid, true)}
            updateReplyHandler={(id) => updateReplyHandler(id, true)}
            userDetailHandler={() => userDetailHandler(mainCommentAuthorId)}
            edithandler={edithandler}
            deleteReplyHandler={deleteMainCommentHandler}
          />
          <div className={styles.collapseBox}>    
              <div
                className={`${styles.collapseBoxTitle} 
                ${styles.active} 
                d-flex align-items-center`}
                onClick={() => {
                  // handleShowHideCollapse(rootItem.collapseState ?? Math.random())
                }
                }
              >
                <IconMdKeyboardArrowDown
                  className={`${styles.dropdownArrow} ${(expandReply && replyComemntsArr?.length )? styles.rotateDropDownArrow : ""}`}
                  onClick={() => replyComemntsArr?.length && setExpandReply(o => !o)}
                />
                <div className={`${styles.numberOfReply} f-bold`}>{replyComemntsArr?.length}</div>
                <div className={`${styles.reply} f-bold`}>{t['reply']}</div>
              </div>
            <MTransitionGroup>
              {
                (expandReply ) && (
                  <MCollapse key={1} timeout={400}>
                    {replyComemntsArr?.map(reply => (
                      <div key={reply?.id} className={styles.replyCommentWrapper}>
                        <Comment 
                          canRemove = {canRemove}
                          canEdit = {canEdit}
                          canSeeUserDetails = {canSeeUserDetails}
                          deleteLoading={loading.deleteReply}
                          replyId={reply?.id}
                          commentDesc={reply?.text} 
                          commentAuthorName={reply?.fullName}
                          addComemntLocalTime={reply?.lastUpdated}
                          isProfile = {()=> isProfile(reply?.authorUser?.userId)}
                          userDetailHandler={() => userDetailHandler(reply?.authorUser?.userId)}
                          deleteReplyHandler={deleteReplyCommentHandler}
                          onChangeCommentDesc={editReplycommentOnChange}
                          edithandler={edithandler}
                          updateReplyHandler={updateReplyHandler}
                        />
                      </div>
                    ))}
                  </MCollapse>
                )
              }
            </MTransitionGroup>
          </div>
              
          {canEdit ? 
            <div className={`${styles.replyBox}`}>
              <QuillEditor
                  quillId={`comment-quillEditor`}
                  placeholder={t["addComment"]}
                  value={addReplyCommentValue ?? ""}
                  onChange={addReplycommentOnChange}
              />
              <div className="d-flex align-items-center">
                <MButton onClick={addReplyCommentHandler} processing={loading.addReply} >{t['comment']}</MButton>
                {/* <MButton onClick={()=> {}} className="transparent f-bold">Attach File</MButton> */}
              </div>
            </div>
          : null}
        </div>
      </div>

      <PrimarySmallDialog
        open={false}
        close={() => {}}
        title={`Upload File`}
        icon={()=> {}}
        contentComponent = { ()=> {
          return (
            <ImageUploader 
              required={false}
              fullWidth={true}
              allowTitle={false}
              allowMultiple={true}
              allowReorder={false}
              maxFiles={100}
              uploadTitle={"Upload File"}
              uploadSubTitle="Recommended Xls Type File"
              labelMaxFileSize="5MB"
              imageOnchange={files => notificationsUIContext.formData.messageFiles = files}
            />
          )
        }
        }
        buttons={
          <React.Fragment>
            <MButton
              onClick={() => {}}
              className="f-bold rounded transparent"
            >
              {t["cancel"]}
            </MButton>
            <MButton
              className="f-bold rounded "
              onClick={() => {}}
              processing={false}
            >
              {t["confirm"]}
            </MButton>
          </React.Fragment>
        }
      />
    </>
  );
};
export default CommentsList;
