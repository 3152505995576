import React from "react";
import RespActionsButtons from "../../../RespActionsButtons";
import { MuseMediaQuery } from "../../../../../hooks";
import DownloadButton from "../../../DownloadButton";
import RetryButton from "../../../RetryButton";
import DetailsButton from "../../../DetailsButton";
import EditButton from "../../../EditButton";
import DeleteButton from "../../../DeleteButton";
import style from "./style.module.css";

const ListActionButtons = ({
  data,
  buttonsLength,
  detailsButton,
  editButton,
  deleteButton,
  retryButton,
  downloadButton,
  detailsHandler,
  editHandler,
  deleteHandler,
  retryHandler,
  downloadHandler,
}) => {
  const mediaQueryLG = MuseMediaQuery("lg");

  if (!buttonsLength) {
    return null;
  }

  return (
    <div className={style.listItemActions}>
      <div className={style.listItemButtons}>
        {mediaQueryLG && buttonsLength > 1 ? (
          <RespActionsButtons
            id={data.id}
            detailsButton={
              detailsButton && { onClick: () => detailsHandler(data) }
            }
            disableDetailsButton={
              data?.disabledButtons?.disableDetails === true
            }
            hideDetailsButton={
              data?.hiddenButtons?.hideDownload === true ? "true" : undefined
            }
            editButton={
              editButton && {
                onClick: () => editHandler(data),
                disabled: data?.isHQ,
              }
            }
            disableEditButton={data?.disabledButtons?.disableEdit === true}
            hideEditButton={
              data?.hiddenButtons?.hideEdit === true ? "true" : undefined
            }
            deleteButton={
              deleteButton && {
                onClick: () => deleteHandler(data),
                disabled: data?.isHQ,
              }
            }
            disableDeleteButton={data?.disabledButtons?.disableDelete === true}
            hideDeleteButton={
              data?.hiddenButtons?.hideDelete === true ? "true" : undefined
            }
            retryButton={
              retryButton && {
                onClick: () => retryHandler(data),
                disabled: data?.isHQ,
              }
            }
            disableRetryButton={data?.disabledButtons?.disableRetry === true}
            hideRetryButton={
              data?.hiddenButtons?.hideRetry === true ? "true" : undefined
            }
            downloadButton={
              downloadButton && {
                onClick: () => downloadHandler(data),
                disabled: data?.isHQ,
              }
            }
            disableDownloadButton={
              data?.disabledButtons?.disableDownload === true
            }
            hideDownloadButton={
              data?.hiddenButtons?.hideDownload === true ? "true" : undefined
            }
          />
        ) : (
          <>
            {downloadButton && (
              <DownloadButton
                onClick={() => downloadHandler(data)}
                disabled={data?.disabledButtons?.disableDownload === true}
                hide={
                  data?.hiddenButtons?.hideDownload === true
                    ? "true"
                    : undefined
                }
              />
            )}
            {retryButton && (
              <RetryButton
                onClick={() => retryHandler(data)}
                disabled={data?.disabledButtons?.disableRetry === true}
                hide={
                  data?.hiddenButtons?.hideRetry === true ? "true" : undefined
                }
              />
            )}
            {detailsButton && (
              <DetailsButton
                onClick={() => detailsHandler(data)}
                disabled={data?.disabledButtons?.disableDetails === true}
                hide={
                  data?.hiddenButtons?.hideDetails === true ? "true" : undefined
                }
              />
            )}
            {editButton && (
              <EditButton
                onClick={() => editHandler(data)}
                disabled={
                  data?.isHQ || data?.disabledButtons?.disableEdit === true
                }
                hide={
                  data?.hiddenButtons?.hideEdit === true ? "true" : undefined
                }
              />
            )}
            {deleteButton && (
              <DeleteButton
                onClick={() => deleteHandler(data)}
                disabled={
                  data?.isHQ || data?.disabledButtons?.disableDelete === true
                }
                hide={
                  data?.hiddenButtons?.hideDelete === true ? "true" : undefined
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ListActionButtons;
