import React, { useEffect, useState, useContext, Suspense } from 'react';
import { matchPath } from 'react-router';
import { useLocation, Route } from 'react-router-dom';
import moment from 'moment';

import { UserManagerStore } from 'shared-modules/user-manager-module';
import { useTranslations } from 'shared-modules/translation-module';
import { ComponentLoader, MToastifyAlert } from 'shared-modules/ui-lib-module';
import { EcommerceLanguageStore, LanguageStore } from 'shared-modules/url-helper-module';
import { useRoutes } from 'shared-modules/routes-module';
import AuthLocalStorageStore from 'shared-modules/local-storage-module/AuthLocalStorageStore';

import useLocationBlocker from '../Hooks/History';
import { entryStoreContext } from 'shared-modules/shared-store-module';
import Layout from '../Layout';
import ThemesLocalStorageStore from 'shared-modules/local-storage-module/ThemesLocalStorageStore';
import UserActivityComponent from './UserTrackerComponent';
import { reaction } from 'mobx';

export const ConfigureComponent = () => {
  //routes from routes-module
  const routes = useRoutes();
  //react router location
  const loc = useLocation();
  //auth local storage context
  const authLocalStorageContext = useContext(AuthLocalStorageStore);
  //user store
  const userContext = useContext(UserManagerStore);

  const themeContext = new useContext(ThemesLocalStorageStore);

  //use translation hook form @meama/translation-module
  var { configStore } = useTranslations();

  //refreshKey when changed language and login
  const [refreshKey, setRefreshKey] = useState(Math.random());
  useEffect(() => {
    const addLanguageHandler = (ele, trigger, handler) => {
      if (window.addEventListener) {
        ele.addEventListener(trigger, handler, false);
        return false;
      }
      window.attachEvent(trigger, handler);
    };

    const onStorageEvent = (event) => {
      if (event.key === 'userToken' && event.newValue === null) {
        userContext.logOut();
        setReactRoutes(false);
      }
      if (event.key === 'theme') {
        themeContext.setTheme(event.newValue);

        var HTMLTag = document.getElementsByTagName('html')[0].classList;
        event.newValue === 'dark' ? HTMLTag.add('dark') : HTMLTag.remove('dark');
      }
      if (event.key === 'i18nextLng') {
        configStore.setLanguage(event.newValue);
        setRefreshKey(Math.random());
      }

      if (event.key === 'userToken' && event.newValue !== null) {
        location.reload();
      }
    };

    addLanguageHandler(window, 'storage', onStorageEvent);
  }, []);

  //history store
  const languageStore = useContext(LanguageStore);
  const ecommerceLanguageStore = useContext(EcommerceLanguageStore);
  //set current theme
  useEffect(() => userContext.initTheme(), [userContext]);

  //get user object
  const entryModuleContext = useContext(entryStoreContext);

  const getUserFirstInit = (showLoading = true) => {
    if (authLocalStorageContext.getUserToken()) {
      entryModuleContext
        .getUser(showLoading)
        .then((fulData) => {
          userContext.setUser(fulData?.data?.user, fulData?.data?.cmsLocalStorage);
          routes.then((o) => setAllModuleRoutes(o));
        })
        .catch((o) => setRoutesLoading(false));
    }
  };

  // if this is commented then requests are not resend
  useEffect(() => {
    const handleRejectedToken = () => {
      getUserFirstInit(false);
    };
    document.addEventListener('refreshTokenChangeEvent', handleRejectedToken);
    return () => {
      document.removeEventListener('refreshTokenChangeEvent', handleRejectedToken);
    };
  }, []);

  //init language store
  //! this runs initStre() two times on every page
  // useEffect(() => {
  //   if (userContext.isAuthorized) {
  //     languageStore.initStre();
  //     // if(window.location.pathname?.toLocaleLowerCase()?.includes("ecommerce")) {
  //     //   ecommerceLanguageStore.initStore();
  //     // }
  //   }
  // }, [userContext.isAuthorized, entryModuleContext.loading.drawerLoading]);

  //get user data for setting permissions to routes
  useEffect(() => getUserFirstInit(), [entryModuleContext]);

  //awaited routes from routes-module
  const [allModuleRoutes, setAllModuleRoutes] = useState([]);
  //setting routes to state
  useEffect(() => {
    if (userContext?.user !== null || !authLocalStorageContext.getUserToken())
      routes.then((o) => setAllModuleRoutes(o));
    return () => {};
  }, [loc.key, userContext?.user]);

  //prevent same url push in history
  // useLocationBlocker(() => userContext.isAuthorized &&  setRefreshKey(loc.key)); //GANDON: es chavakomentare amauntebda xelaxla mtels CMS mere ganvixilot ar sehmce rato chamikomentareo

  //routes to return in <EntryComponent />
  const [reactRoutes, setReactRoutes] = useState([]);
  const [currentModule, setCurrentModule] = useState('');
  //filter auth module routes wothout making 404 page

  useEffect(() => {
    if (
      reactRoutes.length === 0 ||
      currentModule !== location.pathname.split('/')[1]?.toLowerCase() ||
      !reactRoutes.every((val, i) => val?.url === allModuleRoutes[i]?.key)
    ) {
      setReactRoutes(
        allModuleRoutes
          ?.map(
            (el) =>
              !(
                allModuleRoutes.find((o) => o.key.toLowerCase() === location.pathname.toLowerCase()) &&
                el.key === '*'
              ) && {
                route: (
                  <Route exact path={el.key} key={el.key} component={(props) => <el.value {...props} />} />
                ),
                url: el.key,
              }
          )
          .filter((o) => o)
      );
      setCurrentModule(location.pathname.split('/')[1]?.toLowerCase());
    }
  }, [allModuleRoutes]);

  //
  useEffect(() => {
    if (reactRoutes && reactRoutes.length > 0) {
      var arr = [];
      reactRoutes
        .map((o) => o.url)
        .forEach((route) => arr.push(matchPath(location.pathname, route) || location.pathname === '/'));
      if (!arr.find((o) => o) || location.pathname.includes(':')) throw new Error('404');
    }
  }, [reactRoutes]);

  //handle languageChange event and then change refresh Key
  useEffect(() => {
    document.addEventListener('changeLanguageEvent', () => setRefreshKey(Math.random()), false);
  }, [configStore.refreshKey]);

  useEffect(() => {
    if (configStore.currentLanguage !== 'en') require(`moment/locale/${configStore.currentLanguage}`);
    moment.locale(configStore.currentLanguage);
    setRefreshKey(Math.random());
  }, [configStore.currentLanguage]);

  const [routesLoading, setRoutesLoading] = useState(false);
  reaction(
    () => entryModuleContext.loading.drawerLoading,
    () => {
      setRoutesLoading(entryModuleContext.loading.drawerLoading);
      if (entryModuleContext.loading.drawerLoading) {
        setRefreshKey(Math.random());
      }
    }
  );

  //wait for getuser response
  if (routesLoading) {
    return <ComponentLoader />;
  }

  //return all filtered routes from reactRoutes state
  return (
    <div key={refreshKey}>
      <UserActivityComponent
        loc={loc}
        userContext={userContext}
        authLocalStorageContext={authLocalStorageContext}
      />
      {/* toast alert component you can set configs */}
      <MToastifyAlert />
      <Layout>
        <Suspense fallback={null}>{reactRoutes.map((o) => o.route)}</Suspense>
      </Layout>
    </div>
  );
};

export default ConfigureComponent;
