import React from "react";
import { MButton, MBox } from "../../../material";

import { IconMdSearch } from "../../../icons/components";
import { useTranslations } from "shared-modules/translation-module";

import styles from "./styles.module.css";
import { useEffect } from "react";

export const SecondaryCustomSearch = React.forwardRef(({
  onChange,
  input,
  handleSearch,
  classText,
}, ref) => {
  const { t } = useTranslations();

  return (
    <div className={classText}>
      <MBox
        noValidate
        autoComplete="off"
        className={`${styles.customSearchForm} `}
      >
        <MButton
          className={`${styles.searchInputBtn} f-bold`}
          type="submit"
        >
          <IconMdSearch
            className={styles.searchInputIcon}
            onClick={handleSearch}
          />
        </MButton>
        <input
          placeholder={t["search"]}
          label={t["search"]}
          name="companySearch"
          className={`${styles.searchInput}`}
          autoComplete="off"
          onChange={onChange}
          value={input}
        />
      </MBox>
    </div>
  );
});
export default SecondaryCustomSearch;
