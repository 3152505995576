import React, { useState, useContext } from "react";
import { useTranslations } from "shared-modules/translation-module";
import {
  IconMdOutlineDarkMode,
  IconMdOutlineLightMode,
} from "../../../icons/components";
import ThemeStore from '../../../Store';

import styles from "./styles.module.css";
import ThemesLocalStorageStore from "shared-modules/local-storage-module/ThemesLocalStorageStore";

export const DarkLightThemeBtn = () => {
  const themeLocalStorageContext = useContext(ThemesLocalStorageStore)
  let { t } = useTranslations();
  const themeContext = useContext(ThemeStore);

  const [darkThemeState, setDarkThemeState] = useState(themeLocalStorageContext.getTheme() == "dark");

  const changeTheme = () => {
    let theme = !darkThemeState;
    setDarkThemeState(theme);
    themeContext.setTheme(theme);
  };

  return (
    <div className={`${styles.btn} f-bold`} onClick={changeTheme}>
      {darkThemeState ? <IconMdOutlineLightMode /> : <IconMdOutlineDarkMode />}
      <span>{darkThemeState ? t['ligth'] : t['dark']}</span>
    </div>
  );
};
export default DarkLightThemeBtn;
